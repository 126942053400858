import { create } from 'zustand'
import { devtools } from 'zustand/middleware'


interface CodeRuleStoreState {
  globalTimer: number
  setGlobalTimer: (globalTimer: number) => void
}
/**
 * 全局计时
 */
const globalTimerStore = create<CodeRuleStoreState>()(
  devtools((set) => ({
    globalTimer: 0,
    setGlobalTimer: (globalTimer: number) => set(() => ({ globalTimer })),
  })),
)

export default globalTimerStore

import clx from 'classnames';
import { Tooltip } from 'antd';
import Iconfont from '@/components/iconfont';
import React, { useMemo } from 'react';

export interface MiniSidebarItemProps {
  item?: any;
  selectedKeys?: string[];
  index: number;
}

const MiniSidebarItem: React.FC<MiniSidebarItemProps> = (props) => {
  const { item, index } = props;
  const isActive = useMemo(() => props?.selectedKeys?.includes(item.key), [props]);

  const iconName = useMemo(() => {
    // if (isActive) {
    //   return <Iconfont name={item?.activeIconName} size={24} color="inherit" fill="currentColor"/>
    // }
    return <Iconfont name={item?.iconName} size={24} color="inherit" fill="currentColor" />;
  }, [item, isActive]);

  return (
    <div
      key={item.key}
      onClick={item?.onClick}
      className={clx('app-mini-sidebar__list__item', {
        'app-mini-sidebar__list__item__highlight': index === 0
      })}
    >
      <Tooltip title={item?.title} placement="right" trigger={['hover']}>
        <div
          className={clx('app-mini-sidebar__list__item__pill', {
            'app-mini-sidebar__list__item__active': isActive
          })}
        >
          {item?.iconName && <Iconfont className="app-mini-sidebar__list__item__icon" name={item?.iconName} size={24} color="inherit" fill="currentColor" />}
          {item?.activeIconName && (
            <Iconfont className="app-mini-sidebar__list__item__icon__acive" name={item?.activeIconName} size={24} color="inherit" fill="currentColor" />
          )}

          {/*<IconFont name="ic_fluent_grid_dots_24_filled" size={16} />*/}
        </div>
      </Tooltip>
    </div>
  );
};

export default MiniSidebarItem;

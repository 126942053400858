import { create } from 'zustand'
import { persist, createJSONStorage, devtools } from 'zustand/middleware'

interface accountState {
  addCacheData?: any
  setAddCacheData?: (addCacheData: any) => void
}

const accountStore = create<accountState>()(
  devtools(
    persist(
      (set) => ({
        addCacheData: {},
        setAddCacheData: (addCacheData: any) => set(() => ({ addCacheData })),
      }),
      {
        name: 'accountStorage',
        storage: createJSONStorage(() => localStorage),
      },
    ),
  ),
)

export default accountStore

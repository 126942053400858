import { useUnwrapRecoilValue } from '@/v2/hooks';
import { permissionDB } from '../../store/store';
import { isFalsy } from '@/v2/utils';
export const useCanPermsision = () => {
  const permission = useUnwrapRecoilValue(permissionDB) ?? new Set();
  return useRecoilCallback(
    ({ snapshot }) =>
      (auth?: string) => {
        // todo 支持多 auth
        // const permission = (await snapshot.getPromise(permissionDB)) ?? new Set();
        if (isFalsy(auth)) return false;
        return permission?.has?.(auth) ?? false;
      },
    [permission]
  );
};

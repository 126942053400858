import { css } from '@emotion/css'
import { GlobalToken } from 'antd'
import classNames from 'classnames'
import { isMobile } from 'react-device-detect'
import BitzFormModal from "@/components/BitzFormModal/index";

export default function className(token: GlobalToken) {
  return classNames(
    'bitzModalFormBlock',
    { bitzAppModalFormBlock: isMobile },
    css`
      &.bitzModalFormBlock {
        .ant-modal-close-x {
          // font-size: 24px;
        }
        .ant-modal-header {
          .ant-modal-title {
            font-size: 16px;
            font-weight: 700;

            .subhead {
              font-size: ${token.fontSize}px;
              margin-top: 4px;
              font-weight: normal;

              .strong {
                color: ${token.colorPrimary};
                margin-right: 5px;
              }
            }
          }
        }
        .ant-modal-body {
          max-height: var(--bitz-form-modal-maxheight);
          padding: 24px;
          overflow-y: auto;
        }
        .ant-modal-footer {
          padding: 8px 24px;
        }
      }

      &.bitzAppModalFormBlock {
        .ant-modal {
          position: absolute;
          top: initial;
          bottom: 0;
          max-width: 100%;
          margin: 0;
          bottom: 0;
          padding-bottom: 0;
        }
        .ant-modal-content {
          .ant-modal-footer {
            .ant-btn {
              flex: 1;
            }
          }
        }
      }
    `,
  )
}

import apiWeb from '@/services/Web';
import { mapping, toIs, toTry } from '@/utils';
import { isEmpty, omit } from 'lodash-es';
import { selectorFamily, useRecoilCallback } from 'recoil';
import { CreateFinancialBillingFixedServiceFeeInput } from '@/services/WebApi';
import { HeaderNoProcess, WidgetModule } from '@/constants';
import { billBaseInfoDB, billTokenAtom, billTotalFeeDB, useBillIds } from '../BillApplication.store';
import dayjs from 'dayjs';
import { updateKeysStore } from '@/v2/store/store';

export const billFixedServiceFeeDB = selectorFamily({
  key: 'billFixedServiceFeeDB',
  get:
    (billingId: string) =>
    async ({ get }) => {
      if (!billingId) return {};
      const { response } = await apiWeb.webFinancialBillingFinancialbillingfixedservicefeeGetpageCreate(
        { billingId, page: 1, size: 1000 },
        { headers: { ...HeaderNoProcess } }
      );
      const data = mapping(response?.data ?? [], {
        // taxRate 需要转成字符串
        taxRate: 'taxRate::toString'
      });

      return { data };
    }
});

/**
 * 数据处理
 * 计算超期时间
 *
 * - 数据行状态结构
 * -- STATUS: 行状态样式
 * -- '{key}::{status}': 属性状态
 * -- status: none | default | success | processing  | error | warning
 * -- 对应行状态样式: tr-{status}
 */
export const billFixedServiceFeeStore = selectorFamily({
  key: 'billFixedServiceFeeDB/compute',
  get:
    (billingId: string) =>
    async ({ get }) => {
      const { data: baseInfo } = get<Row>(billBaseInfoDB(billingId));
      const { data } = get(billFixedServiceFeeDB(billingId));

      const start = dayjs(baseInfo?.startDate);
      const end = dayjs(baseInfo?.endDate);

      const status = (record) => {
        const { serviceDate } = record;
        const between = dayjs(serviceDate).isBetween(start, end);
        return between ? { value: 'none' } : { value: 'warning', label: '超出账单周期' };
      };

      const result = mapping(data, {
        'serviceDate::status': status,
        'STATUS': (record) => status(record)?.value
      });

      return result;
    }
});

/**
 * 固定服务费统计计算
 */
export const totalBillFixedServiceFeeStore = selectorFamily({
  key: 'billFixedServiceFeeDB/totalBillFixedServiceFeeStore',
  get:
    (billingId: string) =>
    async ({ get }) => {
      const { data = [] } = get(billFixedServiceFeeDB(billingId));
      const { totalAmount, totalTax } = data.reduce(
        (temp, item) => {
          let { totalAmount, totalTax } = temp;
          const { canTotal, amountFee, taxFee, isBillable } = item;
          if (canTotal && toIs(isBillable!)) {
            totalAmount = totalAmount + amountFee;
            totalTax = totalTax + taxFee;
          }
          return { totalAmount, totalTax };
        },
        { totalAmount: 0, totalTax: 0 }
      );

      return { data: { totalAmount, totalTax, total: totalAmount + totalTax } };
    }
});

/**
 * 新建/保存
 */
export const useSaveBillFixedServiceFee = (billingId?: string) => {
  return useRecoilCallback(({ snapshot, refresh }) => async (values: CreateFinancialBillingFixedServiceFeeInput, id?: string) => {
    if (isEmpty(values)) return {};
    const { response } = await apiWeb.webFinancialBillingFinancialbillingfixedservicefeePostCreate(values, { headers: { ...HeaderNoProcess } });
    refresh(billFixedServiceFeeDB(values.billingId!));
    return { id: response, values };
  });
};

/**
 * 更新
 */
export const useUpdateBillFixedServiceFee = (billingId: string) => {
  return useRecoilCallback(({ snapshot, refresh }) => async (values: CreateFinancialBillingFixedServiceFeeInput, id: string) => {
    if (isEmpty(values)) return {};
    await toTry(async () => {
      const payload = omit({ billingId, ...values }, 'adjustment');
      await apiWeb.webFinancialBillingFinancialbillingfixedservicefeePutUpdate(id, payload, { headers: { ...HeaderNoProcess } });
    });

    refresh(billFixedServiceFeeDB(billingId));
    refresh(billTotalFeeDB(billingId));
  });
};

export const useRemoveBiillFixedServiceFee = (billingId: string, module: WidgetModule) => {
  const { removeBillIds } = useBillIds(billingId, module);
  return useRecoilCallback(({ snapshot, refresh, set }) => async (id: string) => {
    if (!id) return false;
    set(updateKeysStore({ module }), [id]);
    await apiWeb.webFinancialBillingFinancialbillingfixedservicefeeDeleteDelete(id, { id }, { headers: { ...HeaderNoProcess } });
    await removeBillIds(id);
    refresh(billFixedServiceFeeDB(billingId));
    refresh(billTotalFeeDB(billingId));
  });
};

/**
 * 批量删除
 */
export const useBatchRemoveFixedServiceFee = (billingId: string, module: WidgetModule) => {
  const { clearBillIds } = useBillIds(billingId, module);
  return useRecoilCallback(({ snapshot, refresh, set }) => async (ids: string[]) => {
    const ids$ = ids.map((item) => ({ id: item }));
    set(updateKeysStore({ module }), ids);
    await apiWeb.webFinancialBillingFinancialbillingfixedservicefeeBatchdeleteCreate(ids$, { headers: { ...HeaderNoProcess } });
    await clearBillIds();
    refresh(billFixedServiceFeeDB(billingId));
    refresh(billTotalFeeDB(billingId));
    return true;
  });
};

/**
 * 批量计费/不计费
 */
export const useBatchBillableFixedServiceFee = (billingId, module) => {
  return useRecoilCallback(({ refresh, set }) => async (ids: string[], isBillable: string) => {
    set(updateKeysStore({ module }), ids);
    await apiWeb.webFinancialBillingFinancialbillingfixedservicefeeChangebillablestateCreate({ ids, isBillable });
    refresh(billFixedServiceFeeDB(billingId));
    refresh(billTotalFeeDB(billingId));
  });
};

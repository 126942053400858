import React, { FC, memo, useMemo } from 'react'
import { theme, TooltipProps, Typography } from 'antd'
import { isMobile } from 'react-device-detect'
import { css } from '@emotion/css'

export interface TextOverflowProps {
  /** 是否为货币 */
  isMoney?: boolean
  /** 货币符号 */
  currencySymbol?: string
  value?: any
  children?: React.ReactNode
  /** 是否是代码 */
  isCode?: boolean
  /** 是否有操作行为，传true可以自动阻止事件冒泡 */
  isAction?: boolean
}

const cls = css`
  .ant-typography-copy {
    opacity: 0;
    pointer-events: none;
  }

  &:hover .ant-typography-copy {
    opacity: 1;
    pointer-events: auto;
  }
`

/** 缺省值 */
const defaultVal = '-'

/** 格式化金额，每三位数用,隔开 */
const formatCurrency = (amount) => {
  const parts = `${amount}`.split('.')
  const integerPart = parts[0]
  const decimalPart = parts.length > 1 ? `.${parts[1]}` : ''
  const formatted = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return `${formatted}${decimalPart}`
}

const TextOverflow: FC<TextOverflowProps> = (props) => {
  const { isMoney, currencySymbol, value, isAction, children } = props

  const childrenNode = useMemo(() => {
    if (['bigint', 'number', 'string'].includes(typeof value) && isMoney) {
      const val = value ?? defaultVal
      if (val === defaultVal) return val

      let amountString = formatCurrency(value)
      if (currencySymbol) {
        amountString = `${currencySymbol} ${amountString}`
      }
      return amountString
    }
    return value || defaultVal
  }, [isMoney, currencySymbol, value])

  const tooltipProps = useMemo<TooltipProps>(() => {
    // 代码样式
    if (props?.isCode) {
      if (value) {
        try {
          const json = JSON.parse(value)
          return {
            title: <pre>{JSON.stringify(json, null, 2)}</pre>,
            overlayInnerStyle: {
              maxHeight: 300,
              overflowY: 'auto',
              width: '100%',
            },
          } as TooltipProps
        } catch (e) {
          return childrenNode
        }
      }
      return {
        title: childrenNode,
        overlayInnerStyle: {
          maxHeight: 500,
          overflow: 'auto',
          width: '100%',
        },
      }
    }
    return {
      title: childrenNode,
      overlayInnerStyle: {
        maxHeight: 500,
        overflow: 'auto',
        width: '100%',
      },
    }
  }, [props?.isCode, value, childrenNode])

  const tooltip: TooltipProps = {
    ...tooltipProps,
  }

  const mobileTooltip: TooltipProps = {
    open: false,
  }

  const node = useMemo(() => {
    if (children && !isAction) {
      return children
    }
    if (children && isAction) {
      return React.cloneElement(children as any, {
        onClick: (...args) => {
          args
            .find((arg) => {
              return typeof arg === 'object' && 'stopPropagation' in arg
            })
            ?.stopPropagation()
        },
      })
    }

    return childrenNode
  }, [children, isAction, childrenNode])

  return (
    <Typography.Text
      ellipsis={{
        tooltip: isMobile ? mobileTooltip : tooltip,
      }}
      style={{ fontSize: 'inherit', width: '100%' }}
      {...(childrenNode !== defaultVal && {
        copyable: { text: childrenNode, tooltips: false },
      })}
      className={cls}
    >
      {node}
    </Typography.Text>
  )
}

export default memo(TextOverflow)

import { RowSelectionType, TableRowSelection } from 'antd/es/table/interface';
import { Key } from 'react';
import { Updater, useImmer } from 'use-immer';

interface UseRowSelectionProps<T extends RowSelectionType, F extends boolean> {
  /** 与table rowKey相同 */
  rowKey?: string;
  /** 多选/单选 */
  type?: T;
  /** 是否需要选中行的数据 */
  needRows?: F;
}

interface HookBaseReturnType<T> {
  type: RowSelectionType;
  fixed: 'left';
  selectedRowKeys: Key[];
  setSelectedRowKeys: Updater<Key[]>;
  clean: () => void;
}

interface NeedRowsReturnType<T> {
  selectedRows: T[];
  setSelectedRows: Updater<T[]>;
}

type UseRowRadioReturnType<T = any> = HookBaseReturnType<T> & {
  onChange?: TableRowSelection<T>['onChange'];
};

type CheckboxReturnType<T = any> = HookBaseReturnType<T> & {
  onSelect?: TableRowSelection<T>['onSelect'];
  onSelectAll?: TableRowSelection<T>['onSelectAll'];
};

type UseRowSelectionReturnType<T, P extends RowSelectionType, F extends boolean> = (P extends 'checkbox' ? CheckboxReturnType : UseRowRadioReturnType) &
  (F extends false ? Row : NeedRowsReturnType<T>);

/** 处理table单选和跨页多选的hook */
const useRowSelection = <T = any, P extends RowSelectionType = 'checkbox', F extends boolean = false>(
  props?: UseRowSelectionProps<P, F>
): UseRowSelectionReturnType<T, P, F> => {
  const rowKey = props?.rowKey ?? 'id';
  const type = props?.type ?? 'checkbox';
  const needRows = props?.needRows ?? false;
  const [selectedRowKeys, setSelectedRowKeys] = useImmer<Key[]>([]);
  const [selectedRows, setSelectedRows] = useImmer<T[]>([]);
  return {
    type,
    fixed: 'left',
    selectedRowKeys,
    selectedRows,
    setSelectedRowKeys,
    setSelectedRows,
    clean: () => {
      setSelectedRowKeys(() => []);
      if (needRows) {
        setSelectedRows(() => []);
      }
    },
    ...(type === 'checkbox'
      ? {
          onSelect: (record, selected) => {
            if (selected) {
              setSelectedRowKeys((draft) => {
                draft.push(record[rowKey]);
              });
              if (needRows) {
                setSelectedRows((draft) => {
                  draft.push(record);
                });
              }
            } else {
              setSelectedRowKeys((draft) => {
                return draft.filter((v) => v !== record[rowKey]);
              });
              if (needRows) {
                setSelectedRows((draft) => {
                  return draft.filter((v) => v[rowKey] !== record[rowKey]);
                });
              }
            }
          },
          onSelectAll: (selected, _, changeRows) => {
            const ids = changeRows.map((v) => v[rowKey]);
            if (selected) {
              setSelectedRowKeys((draft) => draft?.concat(ids));
              if (needRows) {
                setSelectedRows((draft) => draft?.concat(changeRows));
              }
            } else {
              setSelectedRowKeys((draft) => draft?.filter((v) => !ids.includes(v)));
              if (needRows) {
                setSelectedRows((draft) => draft?.filter((v) => !ids.includes(v[rowKey])));
              }
            }
          }
        }
      : {
          onChange: (selectedRowKeys: Key[]) => {
            setSelectedRowKeys(selectedRowKeys);
          }
        })
  };
};

export default useRowSelection;

/*
 * # Title: useStyle
 * - Description: 样式管理
 * - Date: 2024-04-11
 * - LastEditors: liujin liujin@elinklaw.com
 * - LastEditTime: 2024-05-08 09:16:12
 */

import { css } from '@emotion/css'
import { theme } from 'antd'

const useStyle = () => {
  const { token } = theme.useToken()
  return {
    wrapper: css`
      width: 100%;
      display: flex;
      background-color: ${token.colorBgBase};
      h2 {
        margin: 0 20px;
        font-size: 16px;
        cursor: pointer;
        height: 100%;
        display: flex;
        align-items: center;
        white-space: nowrap;
      }
    `,
    title: css`
      display: flex;
      align-items: center;
      border-bottom: 1px solid ${token.colorBorderSecondary};
      .anticon {
        margin-left: 6px;
      }
    `,
    menu: css`
      flex: 1;
    `,
    popupMenu: css`
      .ant-menu-item {
        height: 40px !important;
        line-height: 40px !important;
        padding: 0 30px !important;
        &:hover {
          background: ${token.colorPrimaryBgHover} !important;
        }
      }
      .ant-menu-item-selected,
      .ant-menu-item-selected:hover {
        background: ${token.colorPrimary} !important;
        color: #fff !important;
      }

      .ant-menu-vertical.ant-menu-sub {
        min-width: unset !important;
      }
    `,
    icon: css`
      transform: translateY(2px);
    `,
  }
}

export default useStyle

import { FC, useState } from 'react'
import { Button, Checkbox, Drawer, Form, Input, Modal } from 'antd'
import ForgetPassword from '@/businessComponents/BnForgetPassword'

import IconFont from '@/assets/iconfont'
import api from '@/services'
import './index.less'
import { BitzLocalStorage, BitzToast } from 'bitz-react-admin-ui'
import BitzFormModal from '@/components/BitzFormModal'
import BitzFormItem from '@/components/BitzFormItem'
import { CancelButton, SbumitButton } from '@/businessComponents/BnButton'
import {antdModalV5, create, show, useModal} from '@ebay/nice-modal-react'

interface ChangePasswordProps {}

const ChangePassword: FC<ChangePasswordProps> = () => {
  const modal = useModal()
  const modalProps = antdModalV5(modal)
  const [form] = Form.useForm()
  const [forgetPasswordOpen, setForgetPasswordOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState(false)

  const onSubmit = async () => {
    try {
      const values = await form.validateFields()
      setLoading(true)
      const res = await api.hostSysUserinfoPasswordUpdate(values)
      if (res.success) {
        BitzToast.success('修改密码成功')
        setTimeout(() => {
          BitzLocalStorage.clear()
          window.location.reload()
        }, 1000)
      } else {
        BitzToast.error('修改密码失败，请稍后重试')
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <BitzFormModal
      {...modalProps}
      title="修改密码"
      rootClassName="bitzModalFormBlock passwordFormBlock"
      footer={[
        <SbumitButton key="SbumitButton" loading={loading} onClick={onSubmit} />,
        <CancelButton key="CancelButton" onClick={modalProps.onCancel} />
      ]}
    >
      <Form form={form} name="dynamic_rule" initialValues={{ remember: true }} autoComplete="off">
        <BitzFormItem label="当前密码" name="currentPassWord" rules={[{ required: true, message: '请输入当前密码' }]}>
          <Input.Password allowClear />
        </BitzFormItem>

        <div className="forgetRow">
          <span
            onClick={() => {
              show(ForgetPassword)
            }}
            className="setText"
          >忘记密码？</span>
        </div>

        <BitzFormItem
          label="新密码" name="newPassWord" rules={[{ required: true, message: '请输入新密码' }]}
        >
          <Input.Password allowClear />
        </BitzFormItem>

        <BitzFormItem
          label="核对新密码"
          name="confirmNewPassWord"
          rules={[{ required: true, message: '请再次输入新密码' }]}
        >
          <Input.Password allowClear />
        </BitzFormItem>
      </Form>
    </BitzFormModal>
  )
}

export default create(ChangePassword)

import React, { useMemo } from 'react'
import BitzQueryFilter2 from '@/components/BitzQueryFilter2'
import { useQuery } from '@tanstack/react-query'
import { Select, SelectProps } from 'antd'
import webApi from '@/services/Web'
import {uniqWith} from "lodash-es";

export interface BnFlowStatusSelectSelectProps extends SelectProps {
  /**
   * 流程业务标识
   */
  flowBusiness: string
  /**
   * 是否启用Fluent组件模式
   */
  fluent?: boolean
}

const BnFlowStatusSelectSelect: React.FC<BnFlowStatusSelectSelectProps> = (props) => {
  const { flowBusiness, fluent = false, ...rest } = props
  const { data, isFetched, isFetching } = useQuery({
    queryKey: ['FlowserviceFlowapprovalStatus', flowBusiness],
    queryFn: ({signal}) => {
      // @ts-ignore
      return webApi.webSysFlowserviceFlowapprovalStatusDetail(flowBusiness,flowBusiness,signal)
    },
    select: (res) => {
      if (res?.success) {
        return res?.response?.map((item) => ({
          key: item?.value,
          value: item?.value,
          label: item?.displayText,
        }))
      }
      return []
    },
    enabled: !!flowBusiness,
    // 缓存60分钟
    staleTime: 60 * 60 * 1000
  })

  const loading = useMemo(() => {
    if (isFetched) {
      return false
    }
    return isFetching
  }, [isFetched, isFetching])

  const Component = useMemo(() => (fluent ? BitzQueryFilter2.Select : Select), [fluent])

  // const dropdownRender = useCallback((originNode) => {
  //   if (loading) {
  //     return <Flex align="center" justify="center" style={{ width: '100%', height: 50 }}><Spin /></Flex>
  //   }
  //   return originNode
  // }, [loading])

  const options = uniqWith(data, (item, item2) => {
    return item.value === item2.value
  })

  return (
    <Component
      style={{ width: '100%' }}
      placeholder="请选择"
      loading={loading}
      // @ts-ignore
      options={options}
      maxTagCount="responsive"
      // optionFilterProp="label"
      // showSearch={getShowSearch()}
      // @ts-ignore
      // dropdownRender={dropdownRender}
      {...rest}
    />
  )
}

export default React.memo(BnFlowStatusSelectSelect)

import { useContext, useMemo, useState } from 'react'
import { ColumnStateType, StylesStateType, ExportColumnStateType } from 'bitz-react-admin-ui/dist/components/BitzTable'
import { tableConfigStore } from '@/store'
import { useShallow } from 'zustand/react/shallow'
import { BitzConfigContext } from 'bitz-react-admin-ui/dist/components/BitzConfigProvider/context'
import { omit } from 'lodash-es'

const tableStylesSuffix: TableStylesSuffix = '-tableStylesConfig'
const tableColumnsSuffix: TableColumnsSuffix = '-tableColumnsConfig'
const tableExportColumnsSuffix: TableExportColumnsSuffix = '-tableExportColumnsConfig'
const tableGlobalKey: TableGlobalKey = 'global'

interface UseConfigTableProps {
  /** table的key */
  key: TableKeys
}

const useConfigTable = ({ key }: UseConfigTableProps) => {
  const {
    stylesConfig: styles,
    setStylesConfig,
    getStylesConfig,
    setColumns,
    getColumns,
    setExportColumns,
    getExportColumns,
  } = tableConfigStore(useShallow((state) => state))
  const context = useContext(BitzConfigContext).Table

  const [columnsConfig] = useState<ColumnStateType<TableColumnsKey>>({
    persistenceKey: `${key}${tableColumnsSuffix}`,
    persistenceType: 'online',
    onlineChange: async (module, columns) => {
      await setColumns({ module, settingType: 0, setting: columns })
    },
    onlineGet: (module) => {
      return getColumns({ module, settingType: 0 })
    },
  })

  const [exportConfig] = useState<ExportColumnStateType<TableExportColumnsKey>>({
    persistenceKey: `${key}${tableExportColumnsSuffix}`,
    persistenceType: 'online',
    onlineChange: async (module, columns) => {
      await setExportColumns({
        module,
        settingType: 1,
        setting: columns,
      })
    },
    onlineGet: (module) => {
      return getExportColumns({ module, settingType: 1 })
    },
  })

  const onlineChange: StylesStateType<TableSizeKey>['onlineChange'] = async (_, val) => {
    if (val.stylesScope) {
      // 应用到全局
      return setStylesConfig({
        module: 'global-tableStylesConfig',
        settingType: 0,
        setting: { global: omit(val, 'stylesScope') },
      })
    } else {
      // 应用到当前
      if (styles?.global) {
        await setStylesConfig({
          module: 'global-tableStylesConfig',
          settingType: 0,
          setting: { global: undefined },
        })
      }
      return setStylesConfig({
        module: `${key}${tableStylesSuffix}`,
        settingType: 0,
        setting: { [`${key}`]: omit(val, 'stylesScope') },
      })
    }
  }

  const stylesConfig: StylesStateType<TableSizeKey> = useMemo(() => {
    if (styles?.global) {
      context?.setStylesScope?.('global')
      return {
        persistenceKey: `${tableGlobalKey}${tableStylesSuffix}`,
        persistenceType: 'online',
        onlineChange,
      }
    }

    context?.setStylesScope?.('current')
    return {
      persistenceKey: `${key}${tableStylesSuffix}`,
      persistenceType: 'online',
      onlineChange,
      onlineGet: async (module) => {
        const setting = await getStylesConfig({ module, settingType: 0 })
        return setting?.[key]
      },
    }
  }, [styles])

  const stylesState = useMemo(() => {
    return styles?.global || styles?.[key]
  }, [styles])

  return { stylesConfig, columnsConfig, exportConfig, stylesState }
}

export default useConfigTable

import React, { useCallback, useMemo } from 'react';
import BitzQueryFilter2 from '@/components/BitzQueryFilter2';
import { getShowSearch } from '@/utils/mobile';
import { useQuery } from '@tanstack/react-query';
import apiCommon from '@/services/GeneralPublicInterface';
import { Flex, Select, SelectProps, Spin } from 'antd';

export interface BzGeneralCodeSelectProps extends SelectProps {
  /**
   * 数据字典名称
   */
  name: string;
  /**
   * 禁用的值
   */
  disabledList?: Array<string>;

  /**
   * 是否启用Fluent组件模式
   */
  fluent?: boolean;

  /**
   * 自定义转换options方法
   */
  transformOptions?: any;
}

const BzGeneralCodeSelect: React.FC<BzGeneralCodeSelectProps> = (props) => {
  const { name, fluent = false, disabledList = [], transformOptions, ...rest } = props;

  const { data, isFetched, isFetching } = useQuery({
    queryKey: ['generalcodeTreeSelectCreate', name],
    queryFn: ({ signal }) => {
      return apiCommon.generalcodeTreeSelectCreate(
        {
          class: name,
          code: '',
          parentCode: ''
        },
        { signal }
      );
    },
    select: (data) => {
      if (!data?.success) {
        return null;
      }
      let arr = data?.response
        ?.filter((v) => v?.isActive)
        ?.map((item) => {
          return {
            value: item.value,
            label: item.displayText,
            disabled: disabledList?.includes(item?.value ?? '')
          };
        });

      if (transformOptions) {
        arr = transformOptions(arr);
      }

      // console.log('arr', arr)
      if (name === 'SEPARATOR') {
        arr?.unshift({
          value: '',
          label: '无',
          disabled: false
        });
        return arr;
      }

      // if (disabledList?.length) {
      //   disabledList.forEach((res) => {
      //     const item: any = arr?.find((item) => item.value === res);
      //     if (item) {
      //       item.disabled = true;
      //     }
      //   });
      // }
      return arr;
    },
    enabled: !!name,
    // 缓存60分钟
    gcTime: 60 * 60 * 1000,
    staleTime: 60 * 60 * 1000
  });

  const loading = useMemo(() => {
    if (isFetched) {
      return false;
    }
    return isFetching;
  }, [isFetched, isFetching]);

  const Component = useMemo(() => (fluent ? BitzQueryFilter2.Select : Select), [fluent]);

  const dropdownRender = useCallback(
    (originNode) => {
      if (loading) {
        return (
          <Flex align="center" justify="center" style={{ width: '100%', height: 50 }}>
            <Spin />
          </Flex>
        );
      }
      return originNode;
    },

    [loading]
  );

  return (
    <Component
      style={{ width: '100%' }}
      placeholder="请选择"
      loading={loading}
      // @ts-ignore
      options={data}
      maxTagCount="responsive"
      filterOption={(value, option) => {
        return [option!.value, option?.label].join().toLowerCase().includes(value.toLowerCase());
      }}
      showSearch={getShowSearch()}
      // @ts-ignore
      dropdownRender={dropdownRender}
      {...rest}
    />
  );
};

export default BzGeneralCodeSelect;

import { Button, Result } from 'antd'
import { CSSProperties, useEffect } from 'react'

const FallbackRender = (props) => {
  const style: CSSProperties = {
    fontSize: '85%',
    lineHeight: '1.45',
    // width: 'min-content',
    overflow: 'auto',
    height: 'calc(100vh - 650px)',
  }

  return (
    <Result
      status="500"
      title="页面出错了"
      subTitle="清尝试刷新页面，如无法恢复请联系开发人员。"
      extra={[
        <Button type="primary" key="console" onClick={() => props?.resetErrorBoundary()}>
          刷新页面
        </Button>,
        <Button key="buy">技术支持</Button>,
      ]}
    >
      {/*<div className="desc">*/}
      {/*  <pre style={style}>{props?.componentStack}</pre>*/}
      {/*</div>*/}
    </Result>
  )
}

export default FallbackRender

import {FC, useMemo, useState} from 'react'
import {Select, SelectProps} from 'antd'
import { useTranslation } from 'react-i18next'
import BitzQueryFilter2 from '@/components/BitzQueryFilter2'

interface BnWhetherSelectProps extends Omit<SelectProps, 'options' | 'fieldNames' | 'maxTagCount' | 'optionFilterProp' | 'allowClear'> {
  /**
   * 是否启用Fluent组件模式
   */
  fluent?: boolean
}

/** 是/否下拉列表 */
const BnWhetherSelect: FC<BnWhetherSelectProps> = ({ fluent = false, ...rest }) => {
  const { t } = useTranslation()

  const [options] = useState<any[]>([
    { label: t('', '是'), value: true },
    { label: t('', '否'), value: false },
  ])

  const Component = useMemo(() => (fluent ? BitzQueryFilter2.Select : Select), [fluent])

  return (
    <Component
      options={options}
      optionFilterProp="label"
      allowClear
      showSearch {...rest}
    />
  )
}

export default BnWhetherSelect

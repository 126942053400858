import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FC } from 'react';
import './index.less';
import clx from 'classnames';
import { useHover } from 'ahooks';

export interface SidenavProps {
  children?: React.ReactNode;
  width?: number;
  setWidth?: (width: number) => void;
  opened?: boolean;
  direction?: 'left' | 'right' | 'top' | 'bottom';
  mode?: 'side' | 'overlay';
  hoverAble?: boolean;
  setOpened?: (opened: boolean) => void;
  size?: string;
  transparent?: boolean;
  toggleableBtnAlwaysVisible?: boolean;
  toggleableBtn?: boolean;
  /**
   * 是否开启拖动调整大小  默认开启
   */
  isResize?: boolean;
  minWidth?: number;
  maxWidth?: number;
}

const Sidenav: FC<SidenavProps> = (props) => {
  const {
    width,
    opened,
    setOpened,
    mode = 'over',
    direction = 'left',
    size = 'md',
    transparent = false,
    toggleableBtnAlwaysVisible = false,
    toggleableBtn = false,
    setWidth,
    hoverAble,
    isResize = true,
    minWidth = 10,
    maxWidth = window.innerWidth / 2
  } = props;

  const [rendered, setRendered] = useState(false);
  const sidebarRef = useRef<HTMLDivElement>(null);
  const innerRef = useRef<HTMLDivElement>(null);
  const [isResizing, setIsResizing] = useState(false);
  // const [sidebarWidth, setSidebarWidth] = useState(268);
  const hoverEvent = useHover(innerRef);
  // const [hoverEvent, setHoverEvent] = useState(false);

  const startResizing = React.useCallback((mouseDownEvent) => {
    setIsResizing(true);
  }, []);

  const stopResizing = React.useCallback(() => {
    setIsResizing(false);
  }, []);

  const resize = React.useCallback(
    (mouseMoveEvent) => {
      if (isResizing) {
        let width = 0;
        if (direction === 'left') {
          width = mouseMoveEvent.clientX - sidebarRef.current!.getBoundingClientRect().left;
        }
        if (direction === 'right') {
          const rect = sidebarRef.current!.getBoundingClientRect();
          const right = window.innerWidth - rect.right;

          width = window.innerWidth - mouseMoveEvent.clientX - right;
        }
        if (width >= minWidth && width <= maxWidth) {
          setWidth?.(width);
        }
      }
    },
    [isResizing]
  );

  React.useEffect(() => {
    window.addEventListener('mousemove', resize);
    window.addEventListener('mouseup', stopResizing);
    return () => {
      window.removeEventListener('mousemove', resize);
      window.removeEventListener('mouseup', stopResizing);
    };
  }, [resize, stopResizing]);

  const onToggle = () => {
    setOpened?.(!opened);
  };

  useEffect(() => {
    if (props?.mode !== 'side') {
      setTimeout(() => {
        setRendered(true);
      }, 450);
    } else {
      setRendered(true);
    }
  }, []);

  const btnHolderDom = useMemo(() => {
    if (direction === 'right') {
      return (
        <button
          className={clx('app-layout-btn', {
            'app-layout-btn--closed': !opened,
            'app-layout-btn--horizontal-flipped': opened
          })}
          onClick={() => onToggle()}
        >
          <svg width="24" height="24" viewBox="0 0 26 26" focusable="false" role="presentation">
            <path
              d="M13.706 9.698a.988.988 0 0 0 0-1.407 1.01 1.01 0 0 0-1.419 0l-2.965 2.94a1.09 1.09 0 0 0 0 1.548l2.955 2.93a1.01 1.01 0 0 0 1.42 0 .988.988 0 0 0 0-1.407l-2.318-2.297 2.327-2.307z"
              fill="currentColor"
              fill-rule="evenodd"
            ></path>
          </svg>
        </button>
      );
    }
    if (direction === 'left') {
      return (
        <button
          className={clx('app-layout-btn', {
            'app-layout-btn--closed': !opened,
            'app-layout-btn--horizontal-flipped': !opened
          })}
          onClick={() => onToggle()}
        >
          <svg width="24" height="24" viewBox="0 0 26 26" focusable="false" role="presentation">
            <path
              d="M13.706 9.698a.988.988 0 0 0 0-1.407 1.01 1.01 0 0 0-1.419 0l-2.965 2.94a1.09 1.09 0 0 0 0 1.548l2.955 2.93a1.01 1.01 0 0 0 1.42 0 .988.988 0 0 0 0-1.407l-2.318-2.297 2.327-2.307z"
              fill="currentColor"
            ></path>
          </svg>
        </button>
      );
    }
    if (direction === 'top') {
      return (
        <button
          className={clx('app-layout-btn  app-layout-btn--vertical-flipped', {
            'app-layout-btn--closed': !opened,
            'app-layout-btn--vertical-flipped--alt-i': !opened
          })}
          onClick={() => onToggle()}
        >
          <svg width="24" height="24" viewBox="0 0 26 26" focusable="false" role="presentation">
            <path
              d="M13.706 9.698a.988.988 0 0 0 0-1.407 1.01 1.01 0 0 0-1.419 0l-2.965 2.94a1.09 1.09 0 0 0 0 1.548l2.955 2.93a1.01 1.01 0 0 0 1.42 0 .988.988 0 0 0 0-1.407l-2.318-2.297 2.327-2.307z"
              fill="currentColor"
            ></path>
          </svg>
        </button>
      );
    }
    if (direction === 'bottom') {
      return (
        <button
          className={clx('app-layout-btn  app-layout-btn--vertical-flipped--alt', {
            'app-layout-btn--closed': !opened,
            'app-layout-btn--vertical-flipped': !opened
          })}
          onClick={() => onToggle()}
        >
          <svg width="24" height="24" viewBox="0 0 26 26" focusable="false" role="presentation">
            <path
              d="M13.706 9.698a.988.988 0 0 0 0-1.407 1.01 1.01 0 0 0-1.419 0l-2.965 2.94a1.09 1.09 0 0 0 0 1.548l2.955 2.93a1.01 1.01 0 0 0 1.42 0 .988.988 0 0 0 0-1.407l-2.318-2.297 2.327-2.307z"
              fill="currentColor"
            ></path>
          </svg>
        </button>
      );
    }
  }, [direction, onToggle, opened]);

  return (
    <div
      style={{
        width: width,
        height: '100%',
        visibility: 'inherit'
      }}
      className={clx('app-sidenav-v2', {
        'app-sidenav-v2--rendered': rendered,
        'app-sidenav-v2--opened': opened,
        'app-sidenav-v2--right': direction === 'right',
        'app-sidenav-v2--left': direction === 'left',
        'app-sidenav-v2--top': direction === 'top',
        'app-sidenav-v2--bottom': direction === 'bottom',
        // 'app-sidenav-v2--lg': size === 'lg',
        // 'app-sidenav-v2--xl': size === 'xl',
        'app-sidenav-v2--custom1': size === 'custom1',
        'app-sidenav-v2--transparent': transparent,
        'app-sidenav-v2--hoverAble': hoverAble, // hoverAble
        'app-sidenav-v2--toggleBtn-always': toggleableBtnAlwaysVisible,
        // 'app-sidenav-v2--hoverEvent': hoverEvent,
        'app-sidenav-v2--hoverEvent': !opened && hoverEvent && props?.hoverAble
      })}
      ref={sidebarRef}
      // onMouseDown={(e) => e.preventDefault()}
    >
      <div ref={innerRef} className="app-sidenav-v2__inner">
        {props?.children}
      </div>

      <div
        onMouseDown={isResize ? startResizing : undefined}
        className={clx('resize-handle-container', {
          'resize-handle-container--left': direction === 'right',
          'resize-handle-container--right': direction === 'left',
          'resize-handle-container--top': direction === 'bottom',
          'resize-handle-container--bottom': direction === 'top'
        })}
      >
        {isResize && opened && <div className="resize-handle"></div>}

        {toggleableBtn && (
          <div
            className={clx('app-layout-btn__holder', {
              'app-layout-btn-show': !opened
            })}
          >
            {btnHolderDom}
          </div>
        )}
      </div>
    </div>
  );
};

export default Sidenav;

import {useMutation, useQueryClient} from "@tanstack/react-query";
import ApiWeb from '@/services/Web'
import {UpdateNarrativeInput} from "@/services/WebApi";
import {message} from "@/components/BitzAntApp/Static";

/**
 * 修改工时描述
 */
export const useWorkRecordDesc = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ApiWeb.webTimesheetsTimesheetTimesheetUpdatenarrationCreate,
    onSuccess: (data) => {
      if (data?.success) {
        message.success('操作成功')
        console.log('修改描述成功')
      }
    }
  })
}

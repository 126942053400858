import apiCommon from '@/services/GeneralPublicInterface';
import { atomFamily, selectorFamily } from 'recoil';
import { WidgetModule } from '@/constants';

export const widgetModuleAtom = atomFamily<Config, WidgetModule>({
  key: 'widgetModuleAtomFamily',
  default: {}
});

/**
 * 汇率计算
 */
export const ExchangeStore = selectorFamily({
  key: 'base/ExchangeStore',
  get:
    ({ target, base, amount }: Row) =>
    async () => {
      // const { response } = await apiWeb.webFinancialBaseForeignexchangeratesPostCreate({ target, base, amount });
    }
});

export const apiSettingDB = selectorFamily({
  key: 'base/apiSettingDB',
  get: (api: string) => async () => {
    const { response } = await apiCommon.commonListSettingsList({ requestUrl: api });
    return response;
  }
});

import Fraction from 'fraction.js'

/**
 * 加
 * @param a number
 * @param b number
 * @returns number
 */
export function add(a: number | string, b: number | string) {
  return new Fraction(a).add(new Fraction(b)).valueOf()
}

/**
 * 减
 * @param a number
 * @param b number
 * @returns number
 */
export function sub(a: number | string, b: number | string) {
  return new Fraction(a).sub(new Fraction(b)).valueOf()
}

/**
 * 乘
 * @param a number
 * @param b number
 * @returns number
 */
export function mul(a: number | string, b: number | string) {
  return new Fraction(a).mul(new Fraction(b)).valueOf()
}
/**
 * 除
 * @param a number
 * @param b number
 * @returns number
 */
export function div(a: number | string, b: number | string) {
  return new Fraction(a).div(new Fraction(b)).valueOf()
}

/**
 *
 * @param value 向上保留2位小数
 * @returns number
 */
export function roundUpToTwoDecimals(value: number) {
  const roundedValue = Math.ceil(value * 100) / 100 // 将小数乘以100并向上取整，然后再除以100
  return Number(roundedValue.toFixed(2) || 0) // 转换为字符串并保留两位小数
}

export default Fraction

import { selector, atom, useRecoilCallback } from "recoil";
import dayjs from "@/utils/dayjs-facade";
import useUserStore from "@/store/userStore";

export const submitTipDate$DB = atom({
  key: 'WorkListCreateOrEditModal/submitTipDate',
  default: '',
  effects: [
    (props) => {
      const { setSelf, onSet, node } = props
      const userid = useUserStore.getState().id
      const key = `${node.key}_userid_${userid}`
      const savedValue = localStorage.getItem(key)
      if (savedValue != null) {
        setSelf(JSON.parse(savedValue));
      }

      onSet((newValue, _, isReset) => {
        isReset
          ? localStorage.removeItem(key)
          : localStorage.setItem(key, JSON.stringify(newValue));
      });
    }
  ]
})

// 今天是否已经提示过Late Entry
export const IsToDaySubmit = selector({
  key: 'WorkListCreateOrEditModal/isToDaySubmit',
  get: ({get}) => {
    const date = get(submitTipDate$DB)
    return !!date && dayjs(date).isToday()
  }
})

export const IsTip = atom({
  key: 'WorkListCreateOrEditModal/isTip',
  default: false
})

export const useSetTipDate = (): [boolean, () => void] => {
  const isToDaySubmit = useRecoilValue(IsToDaySubmit)

  const handle = useRecoilCallback(({ set, snapshot }) => async () => {
    const isTip = await snapshot.getPromise(IsTip)
    if (isTip) {
      set(submitTipDate$DB, dayjs().format('YYYY-MM-DD HH:mm:ss'));
    }
  })

  return [isToDaySubmit, handle]
}



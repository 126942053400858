import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import isoWeek from 'dayjs/plugin/isoWeek';
import isToday from 'dayjs/plugin/isToday';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import quarterOfYear from "dayjs/plugin/quarterOfYear"

// 添加 isoWeek 插件
dayjs.extend(LocalizedFormat);
dayjs.extend(isoWeek);
dayjs.extend(isToday);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(duration);
dayjs.extend(isBetween);
dayjs.extend(weekOfYear);
dayjs.extend(quarterOfYear);

export default dayjs

/**
 * 传入一个日期 返回周开始和结束的日期
 * @param date
 */
export function getWeekStartAndEnd(date: dayjs.Dayjs): { startOfWeek: dayjs.Dayjs; endOfWeek: dayjs.Dayjs } {
  const startOfWeek = date.startOf('isoWeek');
  const endOfWeek = date.endOf('isoWeek');

  return {
    startOfWeek,
    endOfWeek
  };
}

/**
 * 传入一个日期 月开始的和月的结束
 * @param date
 */
export function getMonthStartAndEnd(date: dayjs.Dayjs): { startOfMonth: dayjs.Dayjs; endOfMonth: dayjs.Dayjs } {
  // 获取该月的开始日期
  const startOfMonth = date.startOf('month');

  // 获取该月的结束日期
  const endOfMonth = date.endOf('month');

  return {
    startOfMonth,
    endOfMonth
  };
}

/**
 * 判断是否是周末
 */
export const handleIsWeekend = (date: dayjs.Dayjs) => {
  // Get the day of the week (0-6, where 0 represents Sunday and 6 represents Saturday)
  const dayOfWeek = dayjs.isDayjs(date) ? date.day() : dayjs(date);

  // Check if the day is either Saturday (6) or Sunday (0)
  return dayOfWeek === 0 || dayOfWeek === 6;
};

/**
 * 判断是否当年当月
 * @param year
 * @param month
 * @param dateToCheck
 */
export const isDateInMonth = (year: number, month: number, dateToCheck: dayjs.Dayjs) => {
  // 将dateToCheck转换为dayjs对象
  const date = dayjs(dateToCheck);

  // 检查日期的年份和月份是否匹配给定的year和month
  const isSameYear = date.year() === year;
  const isSameMonth = date.month() === month;

  // 如果年份和月份都匹配，则返回true，否则返回false
  return isSameYear && isSameMonth;
};

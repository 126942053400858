import React, {FC, useContext, useMemo, useRef, useState} from "react";
import { useModal, antdDrawerV5, create } from "@ebay/nice-modal-react";
import {Button, Drawer, Empty, Flex, Input, Spin, Tooltip} from "antd";
import TimeItem from '../BnHeadTimer/TimeItem'
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import api from "@/services/Web";
import {useImmer} from "use-immer";
import {GetTimerInput} from "@/services/WebApi";
import dayjs from "dayjs";
import {FieldTimeOutlined} from "@ant-design/icons";
import {TimerContext} from "@/components/BitzTimerContext";
import {message} from "@/components/BitzAntApp/Static";
import {loadFun} from "@/pages/Works/Common";


interface TimeDrawer {
  timeDom?: HTMLDivElement | null
}

const TimeDrawer: FC<TimeDrawer> = (props) => {
  const {
    timeDom
  } = props
  const modal = useModal()
  const [loading, setLoading] = useState(false)
  const drawerRef = useRef<HTMLDivElement>(null);
  const [pagination, setPagination] = useImmer({
    page: 1,
    size: 99,
  })
  const queryClient = useQueryClient()
  const {
    runningId,
    runningTimer,
    runningTimerState,
    onGlobalStart,
    onGlobalPause,
    onGlobalContinue,
    onOnlyPause,
    createNewTimer
  } = useContext(TimerContext)

  const formatStr = 'YYYY-MM-DD HH:mm:ss'
  const [params, setParams] = useImmer<GetTimerInput>({

  })

  const dayData = useQuery({
    queryKey: ['Base', 'BnHeadTimer', 'TimerModal', { ...pagination, ...params }],
    queryFn: ({ signal, queryKey }) => {
      // debugger
      return api.webTimesheetsTimesheetTimesheetGetmytimerpageCreate(
        {
          // workDate: {
          //   startDate: dayjs().startOf('day').format(formatStr),
          //   endDate: dayjs().endOf('day').format(formatStr),
          // },
          ...queryKey[3] as GetTimerInput,
        },
        { signal },
      )
    },
    select: (data) => data?.response,
    gcTime: 1000 * 30
    // placeholderData: keepPreviousData,
    // enabled: show
  })

  const addTimerMutation = useMutation({
    mutationFn: () => {
      return api.webTimesheetsTimesheetTimesheetCreatetimerCreate({
        workDate: dayjs().format('YYYY-MM-DD')
      })
    },
    onSuccess: (res) => {
      if (res?.success) {
        createNewTimer(res?.response)
        message.success('计时器创建成功')
        loadFun(queryClient)
      } else {
        message.error(res.msg)
      }
    },
  })

  const createTimeTask = () => {
    setLoading(true)
    onGlobalStart().finally(() => {
      setLoading(false)
    })
  }

  // useEffect(() => {
  //   if (modal.visible) {
  //     window.addEventListener('click', handleClickOutside);
  //   } else {
  //     window.removeEventListener('click', handleClickOutside);
  //   }
  //   return () => {
  //     window.removeEventListener('click', handleClickOutside);
  //   };
  // }, [modal.visible]);

  const handleClickOutside = (event) => {
    const clickTime = timeDom?.contains(event.target)
    const cllickDrawer = drawerRef.current?.contains(event.target)
    const time_item_dropdown = document.getElementsByClassName('time_item_dropdown')?.[0]
    if (time_item_dropdown) {
      if (time_item_dropdown?.contains(event.target)) {
        return;
      }
    }

    if (!clickTime && !cllickDrawer) {
      modal.hide()
      return
    } else if (clickTime && !cllickDrawer) {
      // 1
    } else if (!clickTime && cllickDrawer) {
      // 2
    }
  };

  const drawerRender = (Node) => {
    return (
      <div
        ref={drawerRef}
        style={{ width: '100%', height: '100%' }}
        id="TimeDrawerContainer"
      >
        {Node}
      </div>
    )
  }

  const content = useMemo(() => {
    if (dayData?.data?.length) {
      return (
        <Flex
          vertical
          gap={10}
          style={{ width: '100%' }}
        >
          {
            dayData?.data?.map(item => (
              <TimeItem key={item.id} data={item} />
            ))
          }
        </Flex>
      )
    }
    return (
      <Flex justify="center" align="center" style={{ width: '100%', height: '100%' }}>
        {
          dayData.isFetching ? <Spin /> : <Empty />
        }
      </Flex>
    )
  }, [dayData?.data, dayData.isFetching])

  return (
    <Drawer
      {...antdDrawerV5({...modal})}
      // mask={false}
      drawerRender={drawerRender}
      getContainer="#layout_content"
      rootStyle={{
        position: 'absolute'
      }}
      styles={{
        header: {
          display: 'none'
        },
        body: {
          backgroundColor: 'var(--ant-color-bg-layout)'
        }
      }}
    >
      <Flex gap={10} style={{ marginBottom: 10 }}>
        <Input.Search
          loading={dayData.isFetching}
          // value={params?.filter ?? undefined}
          onSearch={(e) => {
            setParams((draft) => {
              draft.filter = e
            })
          }}
          style={{ width: '100%' }}
          placeholder="请输入"
          allowClear
        />
        <Tooltip title="开始计时">
          <Button
            onClick={() => addTimerMutation.mutate()}
            loading={addTimerMutation.isPending}
            type="primary"
            icon={<FieldTimeOutlined />}
          />
        </Tooltip>
      </Flex>

      {content}
    </Drawer>
  )
}


export default create(TimeDrawer)

import React, { CSSProperties, FC, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { Button, Form, Col, Row, Popover, InputProps, FormProps, Input, Skeleton, FormInstance } from 'antd';
import RcResizeObserver from 'rc-resize-observer';
import { SettingOutlined, SearchOutlined } from '@ant-design/icons';
import './index.less';
import { useImmerReducer } from 'use-immer';
import { isMobile } from 'react-device-detect';

import MyProvide, { MyContext } from './BitzQueryFilterContext';
import reducer from './reducer';

import BQFItem from './components/Item';
import RenderFormItem from './components/RenderFormItem';
import BQFCascader from './components/Cascader';
import BQFInput from './components/Input';
import BQFSelect from './components/Select';
import BQFDataRangePicker from './components/DataRangePicker';
import BQFDatePicker from './components/DatePicker';
import BQFTreeSelect from './components/TreeSelect';

import BQFTimePicker from './components/TimePicker';
import BQFTimeRangePicker from './components/TimeRangeicker';

import GroupCheckboxList from './GroupCheckboxList';
import { columnSort, genColumnKey } from './utils';
import useMergedState from '@/hooks/useMergedState';

import DefaultFilter from './DefaultFilter';
import AdvancedFilter2 from './AdvancedFilter2';
import AdvancedFilter from './AdvancedFilter';

import IconSousuo from '@/assets/iconfont/IconSousuo';
import IconZhongzhi from '@/assets/iconfont/IconZhongzhi';

import { show } from '@ebay/nice-modal-react';
import MobileFilterModal, { type MobileFilterModalProps } from './MobileFilterModal';
import IconLiebiao2 from '@/assets/iconfont/IconLiebiao2';
import SearchList from './SearchList';
import useBasicSettingsStore from '@/store/useBasicSettingsStore';
import { useShallow } from 'zustand/react/shallow';
import { useQuery } from '@tanstack/react-query';
import commonApi from '@/services/GeneralPublicInterface';
import { GetListAllSettingDto } from '@/services/GeneralPublicInterfaceApi';
import { BitzFluentInput } from 'bitz-react-admin-ui';
import { forEach, isArray, isBoolean, isObject, isString, join, values, toString } from 'lodash-es';

export interface BitzQueryFilter2Props {
  /**
   * api接口
   * 不填写api无法使用高级搜索
   */
  api?: string;
  /**
   * 点击搜索回调
   * @param values
   */
  onFinish?: (values: any) => void;

  children?: ReactNode;
  /**
   * 点击重置回调
   */
  onReset?: () => void;
  /**
   * loading 事件不传也可以
   */
  loading?: boolean;
  /**
   * 搜索按钮左侧搜索框 props 用于自定义配置
   */
  searchProps?: InputProps & { hidden?: boolean };
  /**
   * 提交字段名称
   * search: 搜索框提交时字段名 默认： key
   */
  fieldNames?: {
    search?: string;
  };

  /**
   * 默认状态下是否折叠超出的表单项
   */
  defaultCollapsed?: boolean;
  /**
   * 是否折叠超出的表单项，用于受控模式
   */
  collapsed?: boolean;
  /**
   * 切换表单折叠状态时的回调
   */
  onCollapse?: (collapsed: boolean) => void;
  /**
   * 查询表单栅格间隔
   *
   * @example searchGutter={24}
   * */
  searchGutter?: number | [number, number];

  style?: CSSProperties | undefined;

  /**
   *layout 的布局设置
   * @type 'horizontal' | 'inline' | 'vertical';
   */
  layout?: FormProps['layout'];

  /**
   * 配置列数，一般而言是 8 的倍数
   *
   * @example 配置一行4个
   * span={6}
   *
   * @example 配置一行3个
   * span={8}
   *
   * @example 根据屏幕宽度配置
   * span={xs: 24, sm: 12, md: 8, lg: 6, xl: 6, xxl: 4}
   * */
  span?: SpanConfig;
  /**
   * 是否能够查询收起的数据，如果设置为 false，收起后的表单数据将会丢失
   */
  preserve?: boolean;
  /**
   * 是否隐藏高级搜索按钮
   */
  hideAdvanced?: boolean;
  /**
   * 关键字回调事件
   */
  keywordEmit?: (value?: string) => void;
  /**
   * 隐藏搜索项
   */
  hiddenName?: string | string[];
  /**
   * 是否不展示搜索
   */
  hiddenOrdinary?: boolean;
  /**
   * 数据回填
   */
  initialValues?: FormProps['initialValues'];
  /**
   * form
   */
  form?: FormInstance<any>;
}

export type SpanConfig =
  | number
  | {
      xs: number;
      sm: number;
      md: number;
      lg: number;
      xl: number;
      xxl: number;
    };

const CONFIG_SPAN_BREAKPOINTS = {
  xs: 513,
  sm: 513,
  md: 785,
  lg: 992,
  xl: 1057,
  xxl: Infinity
};

/** 配置表单列变化的容器宽度断点 */
const BREAKPOINTS = {
  vertical: [
    // [breakpoint, cols, layout]
    [513, 1, 'vertical'],
    [785, 2, 'vertical'],
    [1057, 3, 'vertical'],
    [Infinity, 4, 'vertical']
  ],
  default: [
    [513, 1, 'vertical'],
    [701, 2, 'vertical'],
    [1062, 3, 'horizontal'],
    [1352, 4, 'horizontal'],
    [Infinity, 6, 'horizontal']
  ]
};

/**
 * 合并用户和默认的配置
 *
 * @param layout
 * @param width
 * @param span
 */
const getSpanConfig = (layout: FormProps['layout'], width: number, span?: SpanConfig): { span: number; layout: FormProps['layout'] } => {
  if (span && typeof span === 'number') {
    return {
      span,
      layout
    };
  }

  const spanConfig = span
    ? ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'].map((key) => [CONFIG_SPAN_BREAKPOINTS[key], 24 / span[key], 'horizontal'])
    : BREAKPOINTS[layout || 'default'];

  const breakPoint = (spanConfig || BREAKPOINTS.default).find(
    (item: [number, number, FormProps['layout']]) => width < item[0] + 16 // 16 = 2 * (ant-row -8px margin)
  );

  return {
    span: 24 / breakPoint[1],
    layout: breakPoint[2]
  };
};

const defaultWidth = document?.body?.clientWidth;

const BitzQueryFilter2Render: FC<BitzQueryFilter2Props> = (props) => {
  const isShowMoreFilter = useBasicSettingsStore(useShallow((state) => state?.filterConfig?.isShowMoreFilter ?? false));
  const [form] = Form.useForm(props?.form);
  // const form = wrapForm
  const [MobileForm] = Form.useForm();
  const {
    children,
    searchGutter = [24, 0],
    style,
    layout,
    span,
    // defaultCollapsed = true,
    hideAdvanced
  } = props;

  // 用于搜集表单信息
  const { state, dispatch, advancedWhere, ...counter } = useContext(MyContext);

  // 合并用户传入数据
  const fieldNames = useMemo(
    () => ({
      search: 'key',
      ...props.fieldNames
    }),
    [props.fieldNames]
  );

  const defaultCollapsed = isBoolean(props?.defaultCollapsed) ? props?.defaultCollapsed : !isShowMoreFilter;

  // 切换表单折叠状态
  const [collapsed, setCollapsed] = useMergedState<boolean>(
    () => defaultCollapsed, // && !!props.submitter // defaultCollapsed // !isShowMoreFilter ||
    {
      value: props.collapsed,
      onChange: props.onCollapse
    }
  );

  const [searchText, setSearchText] = useState(undefined);

  const [needCollapseRender, setNeedCollapseRender] = useState(false);
  // 高级搜索标识
  const [advanced, setAdvanced] = useState(false);
  // 普通搜索 tag 列表
  const [filterParam, setFilterParam] = useState<any[]>([]);

  const [width, setWidth] = useMergedState(() => (typeof style?.width === 'number' ? style?.width : defaultWidth) as number);

  const spanSize = useMemo(() => getSpanConfig(layout, width + 16, span), [layout, width, span]);

  const showLength = useMemo(() => {
    return Math.max(1, 24 / spanSize.span);
  }, [spanSize.span]);

  const commonListSettingsList = useQuery({
    queryKey: ['commonListSettingsList', props?.api],
    queryFn: ({ signal, queryKey }) => {
      return commonApi.commonListSettingsList({ requestUrl: queryKey[1] as string }, { signal });
    },
    enabled: !!props?.api,
    // 缓存60分钟
    gcTime: 60 * 60 * 1000,
    staleTime: 60 * 60 * 1000
    // select: data1 => data1?.response ?? {},
  });

  useEffect(() => {
    const data = commonListSettingsList.data;
    if (data?.success) {
      const { advancedWhere, inputWhere } = data?.response as GetListAllSettingDto;
      if (advancedWhere?.fieldList) {
        counter.setAdvancedWhere(advancedWhere?.fieldList?.filter((item) => item.description));
      }
      if (inputWhere?.fieldList) {
        counter.setInputWhere(inputWhere?.fieldList?.filter((item) => item.description));
      }
    }
  }, [commonListSettingsList.data]);

  /**
   * 原始列表数据
   * 数据流向  childrenItems -> list
   * 进行分别处理
   */
  const childrenItems = useMemo(() => {
    if (props?.api) {
      // 过滤掉默认搜索项 一般是可以搜索多字段的
      let list: any[] = [];
      list = counter?.inputWhere?.filter((v) => v.inputFieldName !== fieldNames?.search);
      if (props?.hiddenName) {
        if (isArray(props?.hiddenName)) {
          list = list.filter((v) => !props?.hiddenName?.includes(v.inputFieldName));
        } else {
          list = list.filter((v) => v.inputFieldName !== props?.hiddenName);
        }
      }
      const columnKeyMap = {} as Record<string, any>;
      list?.forEach((item, index) => {
        // @ts-ignore
        columnKeyMap[item?.inputFieldName] = {
          show: true,
          label: item?.description
        };
      });
      counter?.setColumnsMap(columnKeyMap);
      return list?.map((item) => {
        return <RenderFormItem key={item?.inputFieldName} item={item} name={item?.inputFieldName ?? ''} />;
      });
    }
    if (isArray(children)) {
      return children;
    }
    if (!children) {
      return [];
    }
    return [children];
    // fixed bug
    // https://devops.aliyun.com/projex/bug/KNGN-74# 《修正组件BitzQueryFilter2，初始加载时不能正常加载的问题》
    // 原因: childrenItems 不能跟随 commonListSettingsList 加载结束后数据更新
    // 修正: useMemo 添加依赖项 commonListSettingsList?.isLoading
    // fixed by mizi@20240905
  }, [counter?.inputWhere, props?.api, props?.hiddenName, commonListSettingsList?.isLoading]);

  const keywordPlaceholder: string = useMemo(() => {
    let str: string = '关键字查询';
    if (props?.api) {
      const cur = counter?.inputWhere?.find((v) => v.inputFieldName === fieldNames?.search);
      if (cur?.description) {
        str = cur?.description;
      }
    }
    return str;
  }, [counter?.inputWhere, props?.api]);

  useEffect(() => {
    if (childrenItems && childrenItems.length > 0) {
      // 重新生成key的字符串用于排序
      const columnKeys = childrenItems.map((item) => item?.props?.name);
      counter.setSortKeyColumns(columnKeys);
    }
  }, [childrenItems]);

  // 隐藏和排序的数据
  const list = useMemo(() => {
    if (props?.api && commonListSettingsList?.isLoading) {
      return [1, 2, 3, 4, 5, 6]?.map((idx) => <Skeleton.Input key={idx} active block />);
    }

    if (childrenItems?.length) {
      const loopFilter = (column: any[]): any[] => {
        return column
          .map((item) => {
            const columnKey = genColumnKey(item?.props?.name);

            const config = counter.columnsMap[columnKey];

            if (config && config?.show === false) {
              return false;
            }
            return item;
          })
          .filter(Boolean);
      };

      // @ts-ignore
      const filter = loopFilter(childrenItems);
      const list2 = filter.sort(columnSort(counter.columnsMap));
      return list2;
    }
    return [];
  }, [childrenItems, counter.columnsMap, props?.api, commonListSettingsList?.isLoading]);

  const filterParameters = (values) => {
    const list: any[] = [];
    forEach(values, function (value, key) {
      // console.log(value, key)
      const item = state[key];
      if (item && (value || isBoolean(value))) {
        list.push({
          label: item?.label,
          name: item?.name,
          valueName: item?.valueName || value
        });
      }
    });

    // @ts-ignore
    setFilterParam(list?.filter((v) => v?.valueName));
  };

  const handleValueData = (value: any): string => {
    if (isString(value)) {
      return value;
    }
    if (isArray(value)) {
      return join(value, ',');
    }
    if (isObject(value)) {
      return handleValueData(values(value));
    }
    if (!isString(value)) {
      return toString(value);
    }
    return value;
  };

  const handleFinish = (values) => {
    if (advanced) {
      const data =
        values?.advanced
          ?.filter((v) => v?.value)
          ?.map((item) => {
            const dataItem = advancedWhere?.find((v) => v.inputFieldName === item?.fieldNames);

            const value = handleValueData(item?.value);
            return {
              // @ts-ignore
              type: dataItem?.valueType,
              inputFieldName: item?.fieldNames,
              fieldNames: dataItem?.fieldNames,
              conditionalType: item?.conditionalType,
              andOrCondition: item?.andOrCondition,
              value
            };
          }) ?? [];
      props?.onFinish?.({
        whereInputs: data
      });
    } else {
      // 正常筛选
      delete values?.advanced;
      // 时间与时间范围选择器处理
      props?.onFinish?.({
        ...values,
        [fieldNames.search]: searchText
      });
      // console.log('正常筛选', values)
      filterParameters(values);
    }
  };

  // 搜索条件tag关闭事件
  const handleTagClose = (name) => {
    form.resetFields([name]);
    form.submit();
  };

  /* 重置 */
  const handleReset = () => {
    form.resetFields();
    setSearchText(undefined);
    filterParameters({});
    dispatch({
      type: 'reset'
    });
    props?.onReset?.();
  };

  /* 切换高级搜索 */
  const handleAdvanced = () => {
    handleReset();
    setFilterParam([]);
    // setSearchText(undefined)
    setAdvanced(!advanced);
    // form.resetFields()
    counter.setAdvanced(!advanced);
    if (!advanced) {
      const fieldList = list?.slice(0, 3);
      form.setFieldValue(
        'advanced',
        fieldList?.map((v) => ({
          fieldNames: v.props.name
        }))
      );
      // console.log(fieldList?.map((v) => ({
      //   fieldNames: v.props.name,
      // })));
    }
  };

  const field = Form.useWatch('advanced', form);

  const handleMoreFilter = () => {
    show<MobileFilterModalProps>('MobileFilterModal', {
      items: childrenItems,
      onReset: () => {
        setSearchText(undefined);
      },
      onFinish: (values) => {
        props?.onFinish?.({
          ...values,
          [fieldNames.search]: searchText
        });
      }
    });
  };

  return isMobile ? (
    <div className="BitzQueryFilterBlockMobile">
      <Row gutter={[10, 0]}>
        <Col span={childrenItems?.length ? 21 : 24}>
          <Input
            suffix={<SearchOutlined />}
            type="search"
            allowClear
            value={searchText}
            // @ts-ignore
            onChange={(e) => setSearchText(e?.target.value)}
            placeholder={keywordPlaceholder}
            disabled={props?.loading}
            onPressEnter={() => {
              if (childrenItems?.length) {
                MobileForm.submit();
              } else {
                props?.onFinish?.({
                  [fieldNames?.search]: searchText
                });
              }
            }}
            {...props?.searchProps}
          />
        </Col>
        {!!childrenItems?.length && (
          <Col span={3}>
            <Button
              block
              onClick={handleMoreFilter}
              loading={props?.loading}
              icon={
                <span className="anticon">
                  <IconLiebiao2 color="currentColor" size={12} />
                </span>
              }
            />
          </Col>
        )}
      </Row>
      <MobileFilterModal id="MobileFilterModal" form={MobileForm} />
    </div>
  ) : (
    <RcResizeObserver
      key="resize-observer"
      onResize={(offset) => {
        if (width !== offset.width && offset.width > 17) {
          setWidth(offset.width);
        }
      }}
    >
      <div className="BitzQueryFilterBlock">
        <Form form={form} onFinish={handleFinish} initialValues={props?.initialValues}>
          {advanced ? (
            props?.api ? (
              <AdvancedFilter2 form={form} api={props?.api} />
            ) : (
              // field={field} items={childrenItems}
              <AdvancedFilter field={field} items={childrenItems} />
            )
          ) : (
            <>
              {props?.hiddenOrdinary ? (
                <></>
              ) : (
                <DefaultFilter
                  setNeedCollapseRender={setNeedCollapseRender}
                  collapsed={collapsed}
                  setCollapsed={setCollapsed}
                  searchGutter={searchGutter}
                  spanSize={spanSize}
                  items={list}
                  showLength={showLength}
                  baseClassName={'bitz'}
                  preserve={props?.preserve}
                />
              )}
            </>
          )}
        </Form>
        {/*{!advanced && !!list?.length && <div className="dividerFilter"></div>}*/}
        <Row gutter={[24, 10]}>
          {!advanced && !props.searchProps?.hidden && (
            <Col span={spanSize.span}>
              <BitzFluentInput
                allowClear
                // className={inputBottomBorder(token)}
                value={searchText}
                // @ts-ignore
                onChange={(e) => {
                  // @ts-ignore
                  setSearchText(e?.target?.value);
                  props?.keywordEmit?.(e?.target?.value);
                }}
                placeholder={keywordPlaceholder}
                disabled={props?.loading}
                onPressEnter={form.submit}
                {...props?.searchProps}
              />
            </Col>
          )}
          <Col span={spanSize.span}>
            <div className="colSpace">
              <Button
                block
                onClick={form.submit}
                type="primary"
                loading={props?.loading}
                icon={
                  <span className="anticon">
                    <IconSousuo color="currentColor" size={12} />
                  </span>
                }
              >
                搜索
              </Button>
              <Button
                block
                disabled={props?.loading}
                onClick={handleReset}
                icon={
                  <span className="anticon">
                    <IconZhongzhi color="currentColor" size={12} />
                  </span>
                }
              >
                重置
              </Button>
            </div>
          </Col>
          {/*<Col {...{ xs: 24, sm: 24, md: 6, lg: 3, xl: 3, xxl: 3 }}>*/}
          {/* */}
          {/*</Col>*/}

          {/*{needCollapseRender && !advanced && (*/}
          {/*  <Col {...{ xs: 24, sm: 24, md: 6, lg: 4, xl: 4, xxl: 2 }}>*/}
          {/*    <Button block onClick={() => setCollapsed(!collapsed)} disabled={props?.loading}>*/}
          {/*      {collapsed ? '展开' : '收起'}筛选*/}
          {/*    </Button>*/}
          {/*  </Col>*/}
          {/*)}*/}

          <Col span={spanSize.span}>
            <div className="colSpace">
              {/*{!!list?.length && !hideAdvanced && props?.api && (*/}
              {/*  <Button*/}
              {/*    block*/}
              {/*    onClick={() => handleAdvanced()}*/}
              {/*    disabled={props?.loading}*/}
              {/*    icon={*/}
              {/*      <span className="anticon">*/}
              {/*        <IconGaojisousuo color="currentColor" size={12} />*/}
              {/*      </span>*/}
              {/*    }*/}
              {/*  >*/}
              {/*    {advanced ? '普通' : '高级'}筛选*/}
              {/*  </Button>*/}
              {/*)}*/}

              {!!childrenItems?.length && !advanced && (
                <Popover arrow={false} content={<GroupCheckboxList />} trigger="click" placement="bottomRight" overlayClassName="queryFilterSettingShow">
                  <Button style={{ width: '32px' }} icon={<SettingOutlined />} />
                </Popover>
              )}
            </div>
          </Col>
        </Row>

        {!!filterParam?.length && [
          <div className="dividerFilter2" key="dividerFilter2"></div>,
          <SearchList key="SearchList" onReset={handleReset} list={filterParam} onConfirm={handleTagClose} />
        ]}
      </div>
    </RcResizeObserver>
  );
};

const QueryFilter2 = (props) => {
  const [state, dispatch] = useImmerReducer(reducer, {});

  return (
    <MyProvide dispatch={dispatch} state={state} {...props}>
      <BitzQueryFilter2Render {...props} />
    </MyProvide>
  );
};

type CompoundedComponent = React.ForwardRefExoticComponent<BitzQueryFilter2Props> & {
  Input: typeof BQFInput;
  Select: typeof BQFSelect;
  Item: typeof BQFItem;
  DataRangePicker: typeof BQFDataRangePicker;
  DatePicker: typeof BQFDatePicker;
  Cascader: typeof BQFCascader;
  TreeSelect: typeof BQFTreeSelect;
  TimePicker: typeof BQFTimePicker;
  TimeRangePicker: typeof BQFTimeRangePicker;
};

const BitzQueryFilter2 = QueryFilter2 as CompoundedComponent;

if (process.env.NODE_ENV !== 'production') {
  BitzQueryFilter2.displayName = 'BitzQueryFilter2';
}

BitzQueryFilter2.Input = BQFInput;
BitzQueryFilter2.Select = BQFSelect;
BitzQueryFilter2.Item = BQFItem;
BitzQueryFilter2.DataRangePicker = BQFDataRangePicker;
BitzQueryFilter2.DatePicker = BQFDatePicker;
BitzQueryFilter2.Cascader = BQFCascader;
BitzQueryFilter2.TreeSelect = BQFTreeSelect;
BitzQueryFilter2.TimePicker = BQFTimePicker;
BitzQueryFilter2.TimeRangePicker = BQFTimeRangePicker;

export default BitzQueryFilter2;

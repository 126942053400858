import { getCurrencyInfo } from '@/v2/utils';
import './index.less';

export const BzCurrencyToFlag = ({
  currency,
  tooltipText = null,
  style = {},
  justifyContent = 'end',
  size = 28,
  showNum = 5,
  isInline = false,
  isShowTooltip = true
}) => {
  const currencyInfo = getCurrencyInfo(currency);

  // 展示数据
  const newCurrencyInfo = currencyInfo.filter((_, index) => index < showNum);
  // 剩余数据
  const surplusCurrencyInfo = currencyInfo.filter((_, index) => index >= showNum);
  const num = currencyInfo.length - showNum;
  const getInfo = (list) => {
    return list.map((info) => {
      if (!info?.flag_svg) {
        const title = isShowTooltip ? { title: tooltipText || info.code } : {};
        return (
          <Tooltip {...title}>
            <Avatar
              className="bzCurrencyInfo-child"
              size={size}
              style={{
                margin: isInline ? '0' : '0 -5px',
                ...style
              }}
            >
              {currency}
            </Avatar>
          </Tooltip>
        );
      }
      const title = isShowTooltip ? { title: tooltipText || info.code } : {};
      return (
        <Tooltip {...title}>
          <Avatar
            className="bzCurrencyInfo-child"
            size={size}
            style={{
              margin: isInline ? '0' : '0 -5px',
              ...style
            }}
            src={info.flag_svg}
          />
        </Tooltip>
      );
    });
  };
  return (
    <div
      className="bzCurrencyInfo"
      style={{
        display: isInline ? 'inline-flex' : 'flex',
        justifyContent,
        height: `${size}px`
      }}
    >
      {getInfo(newCurrencyInfo)}
      {num > 0 && (
        <Tooltip
          title={
            <div className="bzCurrencyInfo-tooltip">
              {surplusCurrencyInfo.map((info) => {
                return (
                  <div>
                    <img src={info.flag_svg} alt={info.code} />: {info.code}
                  </div>
                );
              })}
            </div>
          }
        >
          <div
            style={{
              fontSize: `${size - 8}px`,
              lineHeight: `${size}px`,
              marginLeft: '10px'
            }}
          >
            +{num}
          </div>
        </Tooltip>
      )}
    </div>
  );
};

import { omiting, toTry, upArray } from '@/utils';
import { groupBy, isEmpty } from 'lodash-es';
import { atomFamily, selectorFamily, useRecoilCallback } from 'recoil';
import { HeaderNoProcess, WidgetModule } from '@/constants';
import apiWeb from '@/services/Web';
import { CreateFinancialBillingTimesheetServiceFeeInput } from '@/services/WebApi';
import { billBaseInfoDB, billIdsAtom, billTokenAtom, billTotalFeeDB, useBillIds } from '../BillApplication.store';
import { widgetModuleAtom } from '@/store/v2';

/**
 * 计时计费列表
 */
export const billTimesheetServiceFeeDB = selectorFamily({
  key: 'billTimesheetServiceFeeDB',
  get:
    (billingId: string) =>
    async ({ get }) => {
      if (!billingId) return [];
      get(billTokenAtom(billingId));
      const baseInfo = get<Row>(billBaseInfoDB(billingId));
      const { response } = await apiWeb.webFinancialBillingFinancialbillingtimesheetservicefeeGetpageCreate(
        { billingId, page: 1, size: 1000 },
        { headers: { ...HeaderNoProcess } }
      );

      const { data } = response!;

      const data$transform = data?.map((item) => {
        const { hourlyRate, billableHours, adjustmentType, adjustment, taxRate, currency } = item;
        // const conditions = [hourlyRate, billableHours, adjustmentType, adjustment, taxRate];
        // const conditions$active = conditions.filter(isExist);
        // const canTotal = conditions.length === conditions$active.length;

        // const amount = evaluate(`${hourlyRate} * ${billableHours}`);

        // 净服务费
        // const amountFee = adjustmentType === 'FlatDiscount' ? evaluate(`${amount} - ${adjustment}`) : evaluate(`${amount} * (${adjustment} / 100)`);
        // 税费
        // const taxFee = evaluate(`${amountFee} * (${taxRate} / 100)`);

        // (金额-固定金额)*(1+税率)
        // (金额 * 百分比) * (1 + 税率)
        // const formula =
        //   adjustmentType === 'FlatDiscount'
        //     ? `(${amount} - ${adjustment}) * (1 + ${taxRate} / 100) `
        //     : `(${amount} * (${adjustment} / 100)) * (1 + ${taxRate} / 100 )`;
        // const subtotal$ = subtotal || canTotal ? evaluate(formula) : 0;

        return {
          ...item,
          adjustmentType: item.adjustmentType || 'FlatDiscount',
          adjustmentRate: item.adjustmentRate || 0,
          taxRate: String(taxRate),
          currency: currency ?? baseInfo.data?.currency
        };
      });

      return { ...response, data: data$transform };
    }
});

/**
 * 固定服务费统计计算
 */
// export const totalBillFixedServiceFeeStore = selectorFamily({
//   key: 'billFixedServiceFeeDB/totalBillFixedServiceFeeStore',
//   get:
//     (billingId: string) =>
//     async ({ get }) => {
//       const { data = [] } = get<Row>(billTimesheetServiceFeeDB(billingId));
//       const { totalAmount, totalTax, totalSub } = data.reduce(
//         (temp, item) => {
//           let { totalAmount, totalTax, totalSub } = temp;
//           const { canTotal, amountFee, taxFee, subtotal, isBillable } = item;
//           if (canTotal && toIs(isBillable!)) {
//             totalAmount = totalAmount + amountFee;
//             totalTax = totalTax + taxFee;
//             totalSub = totalSub + subtotal;
//           }
//           return { totalAmount, totalTax, totalSub };
//         },
//         { totalAmount: 0, totalTax: 0, totalSub: 0 }
//       );

//       return { data: { totalAmount, totalTax, total: totalAmount + totalTax } };
//     }
// });

/**
 * 计时计费保存/更新
 */
export const useSaveOrUpdateBillTimesheetServiceFee = (billingId: string) => {
  return useRecoilCallback(({ snapshot, refresh }) => async (values: CreateFinancialBillingTimesheetServiceFeeInput, id?: string) => {
    if (isEmpty(values)) return {};
    if (id) {
      // console.log(values);

      // const values$ = mapping({ billingId, ...values }, { hourlyRate: 'foreignAmount' });
      await apiWeb.webFinancialBillingFinancialbillingtimesheetservicefeePutUpdate(
        id,
        // { ...values$, amount: 0, foreignAmount: 0 },
        values,
        { headers: { ...HeaderNoProcess } }
      );
      refresh(billTimesheetServiceFeeDB(billingId!));
      refresh(billTotalFeeDB(billingId!));
      refresh(billTimesheetSubtotalDB(billingId));
      return;
    }

    const { response } = await apiWeb.webFinancialBillingFinancialbillingtimesheetservicefeePostCreate(values, { headers: { ...HeaderNoProcess } });
    refresh(billTimesheetServiceFeeDB(billingId!));
    return { id: response, values };
  });
};

/**
 * 计时计费移除/删除
 */
export const useRemoveBiillTimesheetServiceFee = (billingId: string, module: WidgetModule) => {
  const { clearBillIds } = useBillIds(billingId, module);
  return useRecoilCallback(({ snapshot, refresh }) => async (id: string | string[]) => {
    if (!id) return false;

    await toTry(async () => {
      if (typeof id === 'string') {
        await apiWeb.webFinancialBillingFinancialbillingtimesheetservicefeeDeleteDelete(id, { id }, { headers: { ...HeaderNoProcess } });
      }

      if (Array.isArray(id)) {
        const ids = id.map((item) => ({ id: item }));
        await apiWeb.webFinancialBillingFinancialbillingtimesheetservicefeeBatchdeleteCreate(ids, { headers: { ...HeaderNoProcess } });
        clearBillIds();
      }
    });

    refresh(billTimesheetServiceFeeDB(billingId));
    refresh(billTimesheetSubtotalDB(billingId));
    refresh(billTotalFeeDB(billingId));
    return true;
  });
};

/**
 * 计时计费候选列表
 */
export const billTimesheetCandidateDB = selectorFamily({
  key: 'billTimesheetCandidateDB',
  get:
    (billingId: string) =>
    async ({ get }) => {
      if (!billingId) return [];
      get(billTokenAtom(billingId));
      const baseInfo = get(billBaseInfoDB(billingId));
      const { response } = await apiWeb.webFinancialBillingFinancialbillingtimesheetservicefeeGetcandidatespageCreate(
        // @ts-ignore
        { caseId: baseInfo?.data?.caseId, page: 1, size: 10000 },
        { headers: { ...HeaderNoProcess } }
      );
      return response;
    }
});

export const useInsertBillingTimesheetServiceFee = (billingId: string) => {
  return useRecoilCallback(({ snapshot, refresh }) => async (values: Row) => {
    if (isEmpty(billingId)) return;
    const baseInfo = await snapshot.getPromise(billBaseInfoDB(billingId));
    await apiWeb.webFinancialBillingFinancialbillingtimesheetservicefeeAddcandidatestobillingCreate(
      { ...values, billingId },
      { headers: { ...HeaderNoProcess } }
    );
    refresh(billTimesheetServiceFeeDB(billingId));
    refresh(billTimesheetCandidateDB(billingId));
    refresh(billTotalFeeDB(billingId));
  });
};

/**
 * 计时计费销账列表
 */
export const billTimesheetWriteoffDB = selectorFamily({
  key: 'billTimesheetWriteoffDB',
  get:
    (billingId: string) =>
    async ({ get }) => {
      if (!billingId) return [];
      const baseInfo = get(billBaseInfoDB(billingId));
      const { response } = await apiWeb.webFinancialBillingFinancialbillingtimesheetservicefeeGetwriteoffpageCreate(
        // @ts-ignore
        { caseId: baseInfo?.data?.caseId, page: 1, size: 10000 },
        { headers: { ...HeaderNoProcess } }
      );
      return response?.data;
    }
});

/**
 * 计时计费合计
 */
export const billTimesheetTotalDB = selectorFamily({
  key: 'billTimesheetTotalDB',
  get:
    (billingId: string) =>
    async ({ get }) => {
      if (!billingId) return [];
      get(billTokenAtom(billingId));
      const { response } = await apiWeb.webFinancialBillingFinancialbillingtimesheetservicefeeGetwriteoffpageCreate(
        { billingId, page: 1, size: 10000 },
        { headers: { ...HeaderNoProcess } }
      );
      return response;
    }
});

/**
 * 合计接口
 */
export const billTimesheetSubtotalDB = selectorFamily({
  key: 'billTimesheetSubtotalDB',
  get:
    (billingId: string) =>
    async ({ get }) => {
      if (!billingId) return [];
      get(billTokenAtom(billingId));
      const { response } = await apiWeb.webFinancialBillingFinancialbillingtimesheetservicefeeGetsubtotalpageCreate(
        { id: billingId },
        { headers: { ...HeaderNoProcess } }
      );

      return response;
    }
});

/**
 * 批量计费/不计费
 */
export const useBatchBillable = (billingId) => {
  return useRecoilCallback(({ refresh }) => async (ids: string[], isBillable: string) => {
    await apiWeb.webFinancialBillingFinancialbillingtimesheetservicefeeChangebillablestateCreate({ ids, isBillable });
    refresh(billTimesheetServiceFeeDB(billingId));
    refresh(billTotalFeeDB(billingId));
  });
};

/**
 * 批量设置税费/小时费率
 */
export const useBatchItem = (billingId) => {
  return useRecoilCallback(({ refresh }) => async (ids: string[], values: Row) => {
    await apiWeb.webFinancialBillingFinancialbillingtimesheetservicefeeChangetaxratesCreate({ ids, ...values });
    refresh(billTimesheetServiceFeeDB(billingId));
    refresh(billTotalFeeDB(billingId));
  });
};

/**
 * 批量合并
 */
export const useBatchMerge = (billingId: string, module: WidgetModule) => {
  const { setBillIds } = useBillIds(billingId, module);
  return useRecoilCallback(({ refresh, set }) => async (values: Row) => {
    await apiWeb.webFinancialBillingFinancialbillingtimesheetservicefeeMergetimesheetsCreate(values);
    refresh(billTimesheetServiceFeeDB(billingId));
    refresh(billTotalFeeDB(billingId));
    // 合并后，清空合并的ids
    setBillIds([]);
  });
};

/**
 * 批量设置小时费率
 * 按工时所属人分组获取数据
 * 若工时所属人为空则可以选择，不为空不能选，需要在数据项标明
 */
export const billGroupEmployeeIdByTimesheedDB = selectorFamily({
  key: 'billGroupEmployeeIdByTimesheedDB',
  get:
    (billingId: string) =>
    async ({ get }) => {
      const { data } = get<Row>(billTimesheetServiceFeeDB(billingId));
      const { [WidgetModule.BillTimesheetServiceFeeModule]: ids } = get(billIdsAtom(billingId));

      const data$ = data.filter((item) => isEmpty(ids) || ids.includes(item.id));
      // 分组按照 employeeId 与 currency 进行分组，且过滤空employeeId的数据
      const data$group = groupBy(
        data$.filter(({ employeeId }) => !!employeeId),
        ({ employeeId, currency }) => {
          return `${employeeId}-${currency}`;
        }
      );
      const contents = Object.keys(data$group).map((employeeId$) => {
        const records = data$group[employeeId$];
        const ids = records.map((item) => item.id);
        const { employeeId, hourlyRate, currency, exchangeRate } = records[0];
        return { employeeId, hourlyRate, currency, exchangeRate, ids };
      });

      return contents;
    }
});

/**
 * 批量修改小时费率
 * @param billingId
 * @returns
 */
export const useBatchHourly = (billingId) => {
  return useRecoilCallback(({ refresh }) => async (values: Row) => {
    const data = omiting(values?.data, ['canEditEmployee']) as Rows;
    await apiWeb.webFinancialBillingFinancialbillingtimesheetservicefeeChangetimekeeperratesCreate(data);
    refresh(billTimesheetServiceFeeDB(billingId));
    refresh(billTotalFeeDB(billingId));
  });
};

/**
 * 保存/拆分工时
 */
export const useSaveSplitTimesheet = (billingId) => {
  return useRecoilCallback(({ refresh }) => async (values: Row) => {
    await apiWeb.webFinancialBillingFinancialbillingtimesheetservicefeeSplittimesheetsCreate({ billingId, ...values });
    refresh(billTimesheetServiceFeeDB(billingId));
    refresh(billTotalFeeDB(billingId));
  });
};

/**
 * 批量取消销账
 */
export const useBatchCancelWriteOffTimesheet = (billingId: string) => {
  return useRecoilCallback(({ refresh }) => async (ids: string | string[]) => {
    console.log(ids, upArray(ids));
    await apiWeb.webFinancialBillingFinancialbillingtimesheetservicefeeWriteoffcandidatestimesheetsCreate({ ids: upArray(ids), isWriteOff: 'N' });
    refresh(billTimesheetWriteoffDB(billingId));
    refresh(billTimesheetCandidateDB(billingId));
  });
};

/**
 * ---------
 */

export const BillTimesheetAtom = atomFamily({
  key: 'BillTimesheetAtom',
  default: billTimesheetServiceFeeDB,
  effects: []
});

import RetrievalItem from './RetrievalItem';
import { Button, Divider, Popover, Space, Tooltip } from 'antd';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { AlignLeftOutlined, CloseOutlined } from '@ant-design/icons';
import { isString } from 'lodash-es';

interface SearchListProps {
  list?: any[];
  onConfirm?: (name: any) => void;
  onReset?: () => void;
}

const SearchList: React.FC<SearchListProps> = (props) => {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const [width, setWtith] = useState(0);

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const handleResize = () => {
    const width = ref.current?.clientWidth ?? 0;
    setWtith(width);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const listFilter = useMemo(() => {
    return (
      props.list?.filter((item) => {
        if (isString(item?.valueName)) {
          return !!item?.valueName;
        }
        return false;
      }) ?? []
    );
  }, [props.list]);

  const list = useMemo(() => {
    if (listFilter?.length) {
      return (
        <div className="filterTagList_tips">
          {listFilter?.map((v, index) => (
            <RetrievalItem key={v?.name ?? index} label={v.label} valueName={v.valueName} onConfirm={() => props?.onConfirm?.(v?.name)} />
          ))}
        </div>
      );
    }
    return;
  }, [listFilter]);

  return (
    <div className="SearchList">
      <Popover overlayInnerStyle={{ width }} placement="bottom" arrow={false} content={list} trigger="contextMenu" open={open} onOpenChange={handleOpenChange}>
        <div className="filterTagList" ref={ref}>
          {listFilter?.map((v, index) => (
            <RetrievalItem key={v?.name ?? index} label={v.label} valueName={v.valueName} onConfirm={() => props?.onConfirm?.(v?.name)} />
          ))}
        </div>
      </Popover>
      <Divider type="vertical" className="SearchList_divider" />
      <div className="SearchList_active">
        <Space>
          <Tooltip title="清空">
            <Button type="link" icon={<CloseOutlined />} onClick={() => props?.onReset?.()} />
          </Tooltip>
          <Tooltip title="查看全部过滤条件">
            <Button
              type="link"
              onClick={() => {
                if (!open) {
                  setOpen(true);
                }
              }}
              icon={<AlignLeftOutlined />}
            />
          </Tooltip>
        </Space>
      </div>
    </div>
  );
};

export default React.memo(SearchList);

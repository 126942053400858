import NiceModal from '@ebay/nice-modal-react';
import dayjs from 'dayjs';
import { flatten, isEmpty, map, toLower, uniq } from 'lodash-es';
import { message } from '@/components/BitzAntApp/Static';
import api from '@/services/Web';
import { BitzConfirmModal } from 'bitz-react-admin-ui';
import Decimal from 'decimal.js';
import { QueryClient } from '@tanstack/react-query';
import workDateStore from '../../../store/workDateStore';
import { formatText } from '@/pages/Works/WorkHourList/const';

// 匹配状态颜色
export const mateColorBystatus = (status: string) => {
  const key = toLower(status);
  let color = '';
  switch (key) {
    case 'incomplete':
      color = '#e7e6e6';
      break;
    case 'ready':
      color = '#c5e1a5';
      break;
    case 'submitted':
      color = '#bbdefb';
      break;
    case 'submit':
      color = '#bbdefb';
      break;
    case 'approved':
      color = '#ffcdd2';
      break;
  }
  return color;
};

/**
 * 处理客户逗号字符串
 * @param clientId
 * @returns
 */
export const handleCommaFun = (str: string) => {
  if (str && str.split(',').length > 1) {
    const arr: any[] = str.split(',');
    return arr[0];
  }
  return str;
};

// 分组
export const groupByFun = (arr: any[], groupType) => {
  const ret: any[] = [];
  switch (groupType) {
    case 'Duration':
      arr.forEach((item) => {
        const dayHourList = item.dayHourList;
        const itemData = item.timesheetList[0];
        const timesheetList = item.timesheetList || [];
        ret.push({ ...itemData, dayHourList, timesheetList });
      });
      break;
    case 'Client-Matter': {
      // eslint-disable-next-line no-case-declarations
      const caseIds: string[] = uniq(
        arr.map((m) => {
          return m.caseId;
        })
      );
      console.log(caseIds);
      const list: any[] = [];
      caseIds.forEach((item) => {
        const dayHourList = arr
          .filter((f) => {
            return item === f.caseId;
          })
          .map((m) => {
            return m.dayHourList;
          });
        const timesheetList = arr
          .filter((f) => {
            return item === f.caseId;
          })
          .map((m) => {
            return m.timesheetList;
          });
        list.push({ caseId: item, dayHourList, timesheetList });
      });
      console.log('list', list);
      break;
    }

    case 'Status':
      break;
  }
  // console.log('ret', ret);

  return ret;
};

export const getAllKeys = (dataSource: any[]) => {
  const dayHours: any[] = dataSource.map((m) => {
    return m.dayHourList;
  });
  let ids: string[] = [];
  const arr: string[] = flatten(dayHours)
    .filter((f) => {
      return !isEmpty(f.ids);
    })
    .map((m) => m.ids);
  arr.forEach((item) => {
    const arr = item.split(',');
    if (arr.length > 1) {
      ids = ids.concat(arr);
    } else {
      ids.push(item);
    }
  });
  return uniq(ids);
};

/**
 * 验证日期
 * @param values
 * @returns
 */
export const VerificationDateFun = (values: any[]) => {
  const s = dayjs(values[0]).format('HH:mm');
  const e = dayjs(values[1]).format('HH:mm');
  if (s == e) {
    return Promise.reject(new Error('开始时间等于结束时间'));
  }
  return Promise.resolve();
};

// 修改工时
export const onChangeHourWork = async (row: any, refreshGetpage) => {
  const { id, status } = row;
  if (status === 'Incomplete') {
    await api.webTimesheetsTimesheetTimesheetPutdraftUpdate(id, { ...row, isEnabled: true }).then((r) => {
      const { success, msg } = r;
      if (success) {
        message.success('成功');
        refreshGetpage();
        // refreshSubtotal()
      } else {
        message.error(msg);
      }
    });
  } else {
    await api.webTimesheetsTimesheetTimesheetPutUpdate(id, { ...row, isEnabled: false }).then((r) => {
      const { success, msg } = r;
      if (success) {
        message.success('成功');
        refreshGetpage();
        // refreshSubtotal()
      } else {
        message.error(msg);
      }
    });
  }
};

// 删除工时
export const onDeleteWork = async (ids: any[], refresh?: () => void) => {
  NiceModal.show(BitzConfirmModal, {
    okType: 'primary',
    okButtonProps: {
      danger: true
    },
    // modalText: '确认删除!',
    okText: '确定',
    onOk: async () => {
      const result = await api.webTimesheetsTimesheetTimesheetBatchdeleteCreate(map(ids, (id) => ({ id })));
      if (result.status === 200) {
        message.success('删除成功');
        refresh?.();
        // refreshGetpage()
        // refreshSubtotal()
      } else {
        message.error(result.msg || '删除失败');
      }
    }
  });
};

// 获取计时详情
export const getWorkInfo = async (id) => {
  const r = await api.webTimesheetsTimesheetTimesheetGetCreate(id);
  return r?.response;
};

// 获取当天我的运行计时
export const getFirstMyWorkFilrst = (size: number = 1) => {
  const params = {
    page: 1,
    size,
    workDate: {
      startDate: dayjs().startOf('day').format(formatText),
      endDate: dayjs().endOf('day').format(formatText)
    }
  };
  return api.webTimesheetsTimesheetTimesheetGetmytimerpageCreate({ ...params });
};

// 开启新的计时器
export const createNewWork = async () => {
  const res = await api.webTimesheetsTimesheetTimesheetCreatetimerCreate({ workDate: dayjs().format('YYYY-MM-DD') });
  return res;
};
// 刷新
export const loadFun = (queryClient: QueryClient) => {
  console.log('统一刷新');
  // 刷新刷新计时弹窗
  queryClient.refetchQueries({ queryKey: ['Base', 'BnHeadTimer', 'TimerModal'], type: 'active' });
  // 刷新工时列表
  queryClient.refetchQueries({ queryKey: ['Works', 'WorkHourList', 'DayPanel'], type: 'active' });
  // 刷新工时日历
  queryClient.refetchQueries({ queryKey: ['webTimesheetsTimesheetTimesheetGetmycalendarCreate'], type: 'active' });
  // 刷新月视图
  queryClient.refetchQueries({ queryKey: ['webTimesheetsTimesheetTimesheetGettimesheetsubtotalCreate'], type: 'active' });
};

// 撤回校验
export const revokeFun = (rows: any[]) => {
  let canDo = true;
  rows.forEach((m: any) => {
    if (m.submittedOn) {
      const start = dayjs(m.submittedOn);
      const end = dayjs();
      const minute = end.diff(start, 'minute');
      console.log('minute', minute);
      if (minute > 30) {
        canDo = false;
      }
    } else {
      canDo = false;
    }
  });
  return canDo;
};

// 获取运行中的计时器信息
export const getTimesheetRunningtimerInfo = async (id) => {
  const res = await api.webTimesheetsTimesheetTimesheetRunningtimerCreate({ ids: [id], timerState: 2 });
  if (res.success) {
    return res?.response;
  } else {
    message.error(res.msg);
  }
};

/***
 * 计时器状态变更
 * @param record
 * @param status 0:开始 1:暂停 2:继续 3:停止 4:重置 5:完成
 */
export const handleTimerStatusChange = async (id, status) => {
  const res: any = await api.webTimesheetsTimesheetTimesheetRunningtimerCreate({ ids: [id], timerState: status });
  if (res.success) {
    return res?.response;
  } else {
    message.error(res.msg);
  }
};

// 换算毫秒
export const hourToSec = (watchTime) => {
  // if (watchTime) {
  //   return dayjs.duration(watchTime).format('HH:mm:ss');
  // }
  // return 0
  if (watchTime) {
    const time = `${dayjs().format('YYYY-MM-DD')} ${watchTime}`;
    const hour = dayjs(time).get('hour');
    const minute = dayjs(time).get('minute');
    const second = dayjs(time).get('second');
    const num1 = hour > 0 ? hour * 3600000 : 0;
    const num2 = minute > 0 ? minute * 60000 : 0;
    const num3 = second > 0 ? second * 1000 : 0;
    return num1 + num2 + num3;
  } else {
    return 0;
  }
};

// Incomplete 状态处理
export const IncompleteStateHandle = (row: any) => {
  const status = row?.status;
  const caseId = row?.caseId;
  const clientId = row?.clientId;
  const hoursWorked = row?.hoursWorked;
  if (status === 'Incomplete') {
    // console.log('hoursWorked',hoursWorked);
    if (caseId && clientId && hoursWorked >= 0.1) {
      return 'Ready';
    }
  }
  return status;
};

// 是否是当天
export const isToday = (date: dayjs.Dayjs) => {
  if (date) {
    return dayjs(date).isToday();
  }
  return false;
};

/**
 * 日期组件可选范围判断
 * @param current
 * @param preferenceSet 偏好设置
 * @returns
 */
export const isDateOptional = (current: any, preferenceSet: any) => {
  if (preferenceSet) {
    // 是否开启过去工时输入截止日期
    const isEnabledPastTimesheetEntryDeadline = preferenceSet?.timesheetRecordPreferences?.isEnabledPastTimesheetEntryDeadline;
    // 过去工时输入截止日期
    // 单位默认为天
    const pastTimesheetEntryDeadline = preferenceSet?.timesheetRecordPreferences?.pastTimesheetEntryDeadline;
    // 是否开启未来工时输入限制
    const isEnabledFutureTimesheetEntryLimit = preferenceSet?.timesheetRecordPreferences?.isEnabledFutureTimesheetEntryLimit;
    // 未来工时输入限制
    // 单位默认为天
    const futureTimesheetEntryLimit = preferenceSet?.timesheetRecordPreferences?.futureTimesheetEntryLimit;

    // 过去判断
    if (isEnabledPastTimesheetEntryDeadline || isEnabledFutureTimesheetEntryLimit) {
      const sVal = isEnabledPastTimesheetEntryDeadline ? Number(pastTimesheetEntryDeadline || 0) : 0;
      const start = sVal > 0 ? dayjs().subtract(sVal, 'day') : dayjs();
      //------
      const eVal = isEnabledFutureTimesheetEntryLimit ? Number(futureTimesheetEntryLimit || 0) : 0;
      const end = eVal > 0 ? dayjs().add(eVal, 'day') : dayjs(); // dayjs().add(3, 'day')

      const result: boolean = dayjs(current).isBetween(start, end, 'day', '[]');
      return !result;
    } else {
      return current && current > dayjs().endOf('day');
    }
  }
};

export const timeLimiter = (current: dayjs.Dayjs, preferenceSet?: any) => {
  const today = dayjs();
  preferenceSet = workDateStore.getState().preferenceSet;

  if (preferenceSet) {
    // console.log('isDateOptional2', current.format('YYYY-MM-DD'))
    const {
      // 是否开启过去工时输入截止日期
      isEnabledPastTimesheetEntryDeadline = false,
      // 过去工时输入截止日期
      // 单位默认为天
      pastTimesheetEntryDeadline = 0,
      // 是否开启未来工时输入限制
      isEnabledFutureTimesheetEntryLimit = false,
      // 未来工时输入限制
      // 单位默认为天
      futureTimesheetEntryLimit = 0
    } = preferenceSet?.timesheetRecordPreferences ?? {};

    // 限制过去 不限制未来
    if (isEnabledPastTimesheetEntryDeadline && !isEnabledFutureTimesheetEntryLimit) {
      const pastLimitDate = today.subtract(pastTimesheetEntryDeadline, 'day');
      return current.isBefore(pastLimitDate, 'day');
    }

    // 不限制过去 限制未来
    if (!isEnabledPastTimesheetEntryDeadline && isEnabledFutureTimesheetEntryLimit) {
      const futureLimitDate = today.add(futureTimesheetEntryLimit, 'day');
      return current.isAfter(futureLimitDate, 'day');
    }

    // 限制过去 限制未来
    if (isEnabledPastTimesheetEntryDeadline && isEnabledFutureTimesheetEntryLimit) {
      const pastLimitDate = today.subtract(pastTimesheetEntryDeadline, 'day');
      const futureLimitDate = today.add(futureTimesheetEntryLimit, 'day');
      return !current.isBetween(pastLimitDate, futureLimitDate, 'day', '[]');
    }
  }
  return false;
};

function isEmptyObject(obj) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}
// 依据偏好设置判断是否创建工时
export const isCreateWork = (val: any, preferenceSet: any) => {
  if (isEmpty(val)) return false;

  const date = dayjs(val)
  if (!isEmptyObject(preferenceSet)) {
    const isEnabledPastTimesheetEntryDeadline = preferenceSet?.timesheetRecordPreferences?.isEnabledPastTimesheetEntryDeadline;
    const pastTimesheetEntryDeadline = preferenceSet?.timesheetRecordPreferences?.pastTimesheetEntryDeadline;
    const isEnabledFutureTimesheetEntryLimit = preferenceSet?.timesheetRecordPreferences?.isEnabledFutureTimesheetEntryLimit;
    const futureTimesheetEntryLimit = preferenceSet?.timesheetRecordPreferences?.futureTimesheetEntryLimit;
    // 过去判断
    if (isEnabledPastTimesheetEntryDeadline || isEnabledFutureTimesheetEntryLimit) {
      const sVal = isEnabledPastTimesheetEntryDeadline ? Number(pastTimesheetEntryDeadline || 0) : 0;
      const start = sVal > 0 ? dayjs().subtract(sVal, 'day') : dayjs();
      //------
      const eVal = isEnabledFutureTimesheetEntryLimit ? Number(futureTimesheetEntryLimit || 0) : 0;
      const end = eVal > 0 ? dayjs().add(eVal, 'day') : dayjs();

      // console.log('start', start.format('YYYY-MM-DD'));
      // console.log('end', end.format('YYYY-MM-DD'));
      // console.log('date', date.format('YYYY-MM-DD'));

      const result: boolean = date.isBetween(start, end, 'day', '[]');
      return result;
    } else {
      // 是否在这之后
      if (date.isAfter(dayjs())) {
        return false;
      }
      // 是否在这之前
      if (date.isBefore(dayjs())) {
        return true;
      }
      // 是否等于今天
      return isToday(date);
    }
  } else {
    // 是否在这之后
    if (date.isAfter(dayjs())) {
      return false;
    }
    // 是否在这之前
    if (date.isBefore(dayjs())) {
      return true;
    }
    // 是否等于今天
    return isToday(date);
  }
};

// 判断当前周是否能创建
export const judgeCurWeekIsCreate = (val: any, preferenceSet: any) => {
  if (isEmpty(val)) return false;

  const date = dayjs(val);
  const weekStart = date.startOf('week');
  const weekEnd = date.endOf('week');

  if (preferenceSet) {
    const isEnabledPastTimesheetEntryDeadline = preferenceSet?.timesheetRecordPreferences?.isEnabledPastTimesheetEntryDeadline;
    const pastTimesheetEntryDeadline = preferenceSet?.timesheetRecordPreferences?.pastTimesheetEntryDeadline;
    const isEnabledFutureTimesheetEntryLimit = preferenceSet?.timesheetRecordPreferences?.isEnabledFutureTimesheetEntryLimit;
    const futureTimesheetEntryLimit = preferenceSet?.timesheetRecordPreferences?.futureTimesheetEntryLimit;
    // 过去判断
    if (isEnabledPastTimesheetEntryDeadline || isEnabledFutureTimesheetEntryLimit) {
      const sVal = isEnabledPastTimesheetEntryDeadline ? Number(pastTimesheetEntryDeadline || 0) : 0;
      const start = sVal > 0 ? dayjs().subtract(sVal, 'day') : dayjs();
      //------
      const eVal = isEnabledFutureTimesheetEntryLimit ? Number(futureTimesheetEntryLimit || 0) : 0;
      const end = eVal > 0 ? dayjs().add(eVal, 'day') : dayjs();
      const startResult = weekStart.isBetween(start, end, 'day', '[]');
      const endResult = weekEnd.isBetween(start, end, 'day', '[]');
      const result: boolean = startResult || endResult;
      return result;
    } else {
      const result: boolean = date.isBetween(weekStart, weekEnd, 'day', '[]');
      return result;
    }
  }
};

// 获取偏好设置最小计时单位，换算成小时
export const getMinTimeFun = (preferenceSet) => {
  let value = preferenceSet?.timerPreferences?.minimumTimingUnit ?? 6;
  value = value >= 6 ? value : 6;
  const hour = new Decimal(value / 60).toFixed(1);
  const num1 = new Decimal(hour);
  const num2 = new Decimal(0.1);
  // 小于
  const ret = num1.lt(num2) ? 0.1 : num1.toFixed(1);
  return ret;
};

// 判断是否 LateEntry
export const handleLateEntry = (date?: string) => {
  if (date) {
    const startOfCurrentMonth = dayjs().startOf('month');
    return dayjs(date).isBefore(startOfCurrentMonth)
  }
  return false
}

import React, { useContext, useEffect, useMemo } from 'react';
import { TreeSelect } from 'antd';
import type { GetRef, TreeSelectProps } from 'antd';
import { MyContext } from '@/components/BitzQueryFilter2/BitzQueryFilterContext';
import { getShowSearch } from '@/utils/mobile';
import { BitzFluentTreeSelect } from 'bitz-react-admin-ui';
import { split } from 'lodash-es';

// export interface BQFTreeSelectProps extends TreeSelectProps { }
interface BQFTreeSelectProps extends Omit<TreeSelectProps, 'options'> {
  /**
   * 是否启用Fluent组件模式
   */
  fluent?: boolean;
}

export type BQFTreeSelectRef = GetRef<typeof TreeSelect>;

const BQFTreeSelect = React.forwardRef<BQFTreeSelectRef, BQFTreeSelectProps>((props, ref) => {
  const { dispatch, advanced = false } = useContext(MyContext);
  const { onChange, fieldNames, ...rest } = props;

  const id = useMemo(() => {
    if (props.id) {
      if (props.id?.indexOf('advanced') !== -1) {
        const list = split(props.id, '_');
        return list[list.length - 1];
      }
      return props.id;
    }
    return '';
  }, [props.id]);

  useEffect(() => {
    if (id) {
      dispatch?.({
        type: 'update',
        name: id,
        value: props?.value
      });
    }
  }, [props?.value, id]);

  // value, option
  const handleChange = (value, label, extra) => {
    onChange?.(value, label, extra);
    console.log(value, label, extra);
    if (id && dispatch) {
      let valueName;
      // let valueName = isArray(option) ? option?.map((v) => v[fieldNames?.label ?? 'label']).join(',') : option[fieldNames?.label ?? 'label']
      if (isArray(label)) {
        valueName = label.join(',');
      } else {
        // valueName = option[fieldNames?.label ?? 'label']
      }
      dispatch?.({
        type: 'update',
        name: id,
        valueName
      });
    }
  };

  // console.log(props);
  const Component = useMemo(() => (props?.fluent ? BitzFluentTreeSelect : TreeSelect), [props]);

  // return <TreeSelect ref={ref} onChange={handleChange} fieldNames={fieldNames} showSearch={getShowSearch()} {...rest} />
  return <Component ref={ref} onChange={handleChange} fieldNames={fieldNames} showSearch={getShowSearch()} {...rest} />;
});

export default BQFTreeSelect;

import { get, random } from 'lodash-es';
import { atomFamily, selector, selectorFamily, useRecoilCallback } from 'recoil';
import apiCommon from '@/services/GeneralPublicInterface';
import apiWeb from '@/services/Web';
import { mapping, run, sleep, upArray } from '@/utils';
import { DictDB } from './dict.db';
import { getDictInfo, getUpdateDictKey } from './utils';
import { GetGeneralCodeTreeInput, RequestParams } from '@/services/GeneralPublicInterfaceApi';
import { URLHeaders } from '@/constants';
import DictWorker from './dict.worker?worker';
import { BitzLocalStorage } from 'bitz-react-admin-ui';
import useLanguageStore from '@/store/languageStore';
import { isFalsy, isPowerFalsy } from '@/v2/utils';
import request from '@/api/request';

const pool = {
  apiCommon,
  apiWeb
};

/**
 * apiStore
 */
type ApiStoreParams = {
  store: keyof typeof pool;
  api: keyof typeof apiCommon | keyof typeof apiWeb;
  args?: any[];
};

export const apiStore = selectorFamily({
  key: 'hooks/apiStore',
  get: (request: ApiStoreParams) => async () => {
    try {
      const { api, store, args } = request;
      const params = upArray(args);
      return await pool[store][api]?.(...params);
    } catch (e) {
      console.log('apiStore -> error', e);
    }
  }
});

/**
 * 读取字典数据列表
 */
export const dictListStore = selector({
  key: 'hooks/loadDictStore',
  get: async ({ get }) => {
    const { response } = get(
      apiStore({
        store: 'apiCommon',
        api: 'generalcodeGroupsListCreate',
        args: [{}, { headers: { [URLHeaders.NoProcess]: true, [URLHeaders.NoError]: true } }]
      })
    );
    const data = response?.data ?? response ?? [];
    // return data.slice(0, 5);
    return data;
  }
});

/**
 * 获取单个字典数据
 */
export const dictStore = selectorFamily({
  key: 'hooks/dictStore',
  get: (key: string) => async () => {
    const { response } = await apiCommon.generalcodeTreeListCreate(
      { class: key, code: '', parentCode: '' },
      { headers: { [URLHeaders.NoProcess]: true, [URLHeaders.NoError]: true } }
    );
    // @ts-ignore
    return response?.data ?? response;
  }
});

/**
 * 获取单个字典数据
 * 若字典数据不存在，则缓存数据
 * 若缓存数据存在，则直接返回缓存数据
 */
export const dictDB = atomFamily<any, string>({
  key: 'hooks/dictDB',
  default: void 0,
  effects: (key: string) => [
    ({ setSelf, onSet, trigger }) => {
      if (!key) return;
      const updateStoryKey = getUpdateDictKey(key);
      const args: [GetGeneralCodeTreeInput, RequestParams] = [
        { class: key, code: '', parentCode: '' },
        { headers: { [URLHeaders.NoProcess]: true, [URLHeaders.NoError]: true } }
      ];
      const mapper = { value: 'code', label: 'displayName', display: 'displayName', code: 'code', parentCode: 'parentCode', group: 'class', sort: 'sort' };

      if (trigger === 'get') {
        DictDB.getItem(key).then(async (cache) => {
          const dictInfo = await getDictInfo();
          /**
           * 默认优先读取缓存数据
           */
          if (!isFalsy(cache)) {
            setSelf({ data: cache });

            // 获取上次数据更新时间
            const lastUpdate = (await DictDB.getItem<number>(updateStoryKey)) || 0;

            // 每天更新一次
            // 基础数据
            if (Date.now() - lastUpdate! > 24 * 60 * 60 * 1000 && !dictInfo?.[key]) {
              // @todo 非字典数据
              const { response } = await apiCommon.generalcodeTreeListCreate(...args);
              const data = response!['data'] ?? response;
              // @todo
              if (!data?.length) return;
              if (JSON.stringify(data) !== JSON.stringify(cache)) {
                await DictDB.setItem(key, mapping(data, mapper));
                await DictDB.setItem(updateStoryKey, Date.now());
                setSelf({ data });
              }
            }
          }

          /**
           * 若缓存数据不存在，则请求数据
           */
          if (isFalsy(cache)) {
            if (!dictInfo?.[key]) {
              const { response } = await apiCommon.generalcodeTreeListCreate(...args);
              const data = response!['data'] ?? response;
              if (!isPowerFalsy(data)) {
                const data$ = mapping(data, mapper);
                await DictDB.setItem(key, data$);
                await DictDB.setItem(updateStoryKey, Date.now());
                setSelf({ data: data$ });
              }
            }

            if (dictInfo?.[key]) {
              const { api, body, mapper, method } = dictInfo?.[key];
              const api$ = /(\/api)+/.test(api) ? api.replace(/^\/api/, '') : api;
              const headers = { [URLHeaders.NoProcess]: true };
              const response = await request<Row, any>(api$, { method, data: body, headers });
              run(mapping(get(response, 'data.response.data'), mapper), async (data) => {
                setSelf({ data });
                await DictDB.setItem(key, data);
                await DictDB.setItem(updateStoryKey, Date.now());
              });
            }
          }
        });
      }

      if (trigger === 'set') {
        onSet(async (newValue) => {
          if (!isFalsy(newValue)) {
            await DictDB.setItem(key, newValue);
            await DictDB.setItem(updateStoryKey, Date.now());
          }
          return newValue;
        });
      }
    }
  ]
});

/**
 * 预加载数据
 */
export const usePreloadDict = () => {
  const dictWorker = new DictWorker();

  dictWorker.onmessage = (res) => {
    console.log('home收到消息', res.data);
  };
  dictWorker.onerror = (err) => {
    console.log('home收集到错误', err);
    // 关闭
    dictWorker.terminate();
  };

  const headers = {};

  const lang = useLanguageStore.getState().language;
  if (lang) {
    headers['Accept-Language'] = `${lang}`;
  }
  const lzhHostToken = BitzLocalStorage.getItem('lzhHostToken') || null;
  if (lzhHostToken) {
    headers['Authorization'] = `${'Bearer ' + lzhHostToken}`;
  }
  const lzhTenantId = BitzLocalStorage.getItem('lzhTenantId') || null;
  if (lzhTenantId) {
    headers['Abp.TenantId'] = lzhTenantId;
  }

  // dictWorker.postMessage({ type: 'ready' });

  return useRecoilCallback(({ snapshot }) => async () => {
    // const dictList = await snapshot.getPromise(dictListStore);
    await sleep(random(0, 1000));
    dictWorker.postMessage({ type: 'cacheDict', headers });
    await sleep(random(0, 1000));
    dictWorker.postMessage({ type: 'cacheUserInfo', headers });
    await sleep(random(0, 1000));
    dictWorker.postMessage({ type: 'cacheOffice', headers });
    await sleep(random(0, 1000));
    dictWorker.postMessage({ type: 'cacheOfficeTaxRate', headers });
    // dictWorker.postMessage({ type: 'cacheClient', headers });
    // dictWorker.postMessage({ type: 'cacheCase', headers });
  });
};

/**
 * Cache Map
 */
export const cacheMapDB = selectorFamily({
  key: 'hooks/cacheMapDB',
  get:
    (key: string) =>
    async ({ get }) => {
      const data = (await DictDB.getItem<Row[]>(key)) || [];
      const group = data.reduce((temp, item) => {
        temp[item.value] = item;
        return temp;
      }, {});
      const enties = Object.entries<Row>(group);
      return new Map(enties);
    }
});

import { ComponentType, CSSProperties, useMemo } from 'react';
import { theme } from 'antd';
import { css } from '@emotion/css';
import { SwapRightOutlined } from '@ant-design/icons';
import { isArray, isBoolean } from 'lodash-es';

export interface Props {
  [name: string]: any;
  children?: any;
  placeholder?: string | string[];
  hideFluent?: boolean;
  fluentStyle?: CSSProperties | undefined;
}

const isTruthy = (value) => {
  if (isBoolean(value)) {
    return true;
  }
  if (isArray(value)) {
    return !!value?.length;
  }
  return !!value;
};

function withFluent<P>(Component: ComponentType<P>) {
  return (props: P & Props) => {
    const { token } = theme.useToken();
    const { children, placeholder, hideFluent, fluentStyle, ...restProps } = props;
    const { value, defaultValue } = restProps;

    const hasValue = useMemo(() => isTruthy(value) || isTruthy(defaultValue), [value, defaultValue]);

    const labelStyle = css`
      position: absolute;
      top: ${hasValue ? 0 : '50%'};
      left: 7px;
      z-index: 10;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      transform: translateY(-50%);
      pointer-events: none;
      transition: all 0.1s 0.3s ease-out;
      font-size: ${hasValue ? token.fontSizeSM : token.fontSize}px;
      background-color: ${token.colorBgBase};
      color: ${token.colorTextPlaceholder};
      padding: 0 2px;
      border-radius: 2px;
      max-width: 190px;
    `;

    const containerStyle = css`
      position: relative;
      width: 100%;
      &:focus-within {
        label {
          top: 0;
          background-color: ${token.colorBgBase};
          font-size: ${token.fontSizeSM}px;
          color: ${token.colorTextPlaceholder};
        }
      }
    `;

    const placeholderDom = useMemo(() => {
      if (typeof placeholder === 'string') {
        return placeholder;
      }
      return [placeholder?.[0], <SwapRightOutlined key="icon" />, placeholder?.[1]];
    }, [placeholder]);

    if (hideFluent) {
      return children;
    }

    return (
      <div style={fluentStyle} className={containerStyle}>
        <Component allowClear {...(restProps as P)}>
          {children}
        </Component>
        <label className={labelStyle}>{placeholderDom}</label>
      </div>
    );
  };
}

export default withFluent;

/*
 * # Title: CheckoutVersion Component
 * - Description: 检测版本更新
 * - Date: 2024-06-07
 * - LastEditors: liujin liujin@elinklaw.com
 * - LastEditTime: 2024-06-13 19:44:10
 */
import { useRequest } from 'ahooks'
import { notification } from '../BitzAntApp/Static'
import { Button } from 'antd'
import { useEffect } from 'react'
import { show } from '@ebay/nice-modal-react'
import { BitzConfirmModal } from 'bitz-react-admin-ui'

let prevText = ''
let isFirst = true

const CheckoutVersion = () => {
  const { data, cancel, run } = useRequest(
    () =>
      fetch('/?t=' + Date.now()).then((res) => {
        if (res.status === 200) {
          return res.text()
        }
        return Promise.reject(new Error('网络错误，请稍后再试'))
      }),
    {
      pollingInterval: 1000 * 60 * 5, //5分钟检查一次
      pollingErrorRetryCount: 3,
      manual: true,
    },
  )

  useEffect(() => {
    // 生产环境检测版本更新
    if (process.env.NODE_ENV === 'production') {
      run()
    }
  }, [])

  // 首次请求
  if (isFirst && data) {
    isFirst = false
    prevText = data
    return null
  }

  // 版本更新
  if (data && data !== prevText) {
    prevText = data
    cancel()
    show(BitzConfirmModal, {
      okType: 'primary',
      okButtonProps: {
        danger: true,
      },
      modalText: '检测到有新版本发布!',
      okText: '立即更新',
      onOk: async () => {
        window.location.reload()
      },
      onCancel: cancel,
    })
    // notification.info({
    //   message: '检测到有新版本发布!',
    //   placement: 'top',
    //   description: (
    //     <Button type="primary" onClick={() => window.location.reload()}>
    //       立即更新
    //     </Button>
    //   ),
    //   duration: null,
    //   onClose: cancel,
    // })
  }

  return null
}

export default CheckoutVersion

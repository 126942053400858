import React, { useEffect, useMemo, useState } from 'react';
import { Empty } from 'antd';
import MenuHeader from './MenuHeader';
import MenuItem from './MenuItem';

export interface LayoutSidebarMenuProps {
  title?: string;
  items?: any[];
  selectedKeys?: string[];
}

function searchRoutes(routes, searchTerm) {
  // This function will recursively search for the term in the route tree
  function search(route, term = '') {
    // Check if the current route's title matches the search term
    if (route.title && route.title?.indexOf(term) > -1) {
      return [route]; // Return an array containing the matching route
    }

    // If there are children, recursively search them
    if (route.children) {
      const matches = route.children
        .map((child) => search(child, term)) // Search each child
        .filter((result) => result.length > 0) // Keep only non-empty results
        .flat(); // Flatten the array of arrays

      // If matches are found in children, return a new object with those children
      if (matches.length > 0) {
        return [{ ...route, children: matches }];
      }
    }

    // If no matches, return an empty array
    return [];
  }

  // Search each top-level route and flatten the results
  return routes.map((route) => search(route, searchTerm)).flat();
}

const LayoutSidebarMenu: React.FC<LayoutSidebarMenuProps> = ({ title, items, selectedKeys }) => {
  const [isSearch, setIsSearch] = useState(false);
  const [search, setSearch] = useState('');

  const menu = useMemo(() => {
    if (search && items?.length && isSearch) {
      return searchRoutes(items, search);
    }
    return items || [];
  }, [items, isSearch, search]);

  const isEmpty = useMemo(() => {
    return !menu?.length && isSearch;
  }, [menu, isSearch]);

  useEffect(() => {
    if (items) {
      setSearch('');
      setIsSearch(false);
    }
  }, [items]);

  return (
    <div className="app-sidebar__list">
      <MenuHeader groupName={title} isSearch={isSearch} setIsSearch={setIsSearch} search={search} setSearch={setSearch} />
      {menu?.map((v) => <MenuItem defaultOpen={!!search} key={v.key} item={v} selectedKeys={selectedKeys} />)}

      {isEmpty && <div className="">{/* <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> */}</div>}
    </div>
  );
};

export default React.memo(LayoutSidebarMenu);

import { WidgetModule } from '@/constants';
import { BillModuleRequestMap } from '../BillConstants';

export const totalBillListDB = selector({
  key: 'totalBillListDB',
  get: async () => {
    const { response: a } = await BillModuleRequestMap[WidgetModule.BillAudit]({ page: 1, size: 1 });
    const { response: b } = await BillModuleRequestMap[WidgetModule.BillManage]({ page: 1, size: 1 });
    return { [WidgetModule.BillAudit]: a?.dataCount, [WidgetModule.BillManage]: b?.dataCount };
  }
});

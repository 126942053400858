import { useMutation } from '@tanstack/react-query'
import API from '@/services/Web'
import genAPI from '@/services/GeneralPublicInterface'
import useDownload from './useDownload'

import { message, notification } from 'antd'
import { BitzTableToolBarProps } from 'bitz-react-admin-ui/dist/components/BitzTable/ToolBar'

type UseTableExportProps = {
  url: string
  search?: any
  // pagination?: any
  total?: number
  selectedKeys?: any[]
  fileName?: string
  extended?: string
  exportType?: 0 | 1
}

type ExportEvent = BitzTableToolBarProps['onExport']

const useTableExport = (props: UseTableExportProps) => {
  const {
    url,
    search,
    // pagination,
    total,
    selectedKeys,
    fileName,
    extended,
    exportType,
  } = props

  // const downloadFile = useDownload()

  const download = useMutation({
    mutationFn: (fileId: string) => genAPI.fileDownloadDetail(fileId),
    onSuccess: (data) => {
      if(data.response?.downloadUrl) {
        // todo 如果下载链接是 https 改成 a 标签下载
        // downloadFile(data.response?.downloadUrl)
        // window.open(data.response?.downloadUrl, '_blank')
      }
    }
  })

  const onExport: ExportEvent = async (key, exportColumns, info) => {
    const params = {
      selectColumns: exportColumns.filter((v) => v.show),
      search: { ...search },
      checkedIds: undefined,
      fileName: fileName ?? '',
      extended: extended ?? '',
      exportType: exportType ?? 0,
    }

    if(key === 'selectedRows') {
      params.exportType = 1
      params.checkedIds = selectedKeys as any
    }

    if(key === 'all') {
      params.search.page = 1
      params.search.size = total
    }

    if(key === 'any' && info?.type === 'page') {
      params.search.page = 1
      params.search.size = info.value * params.search.size
    }

    if(key === 'any' && info?.type === 'pieces') {
      params.search.page = 1
      params.search.size = info.value
    }

    try {
      const { response, success } = await API[url](params).then(data => {
        notification.info({
          message: '导出中心',
          description: '导出数据生成中，请稍后前往导出中心下载',
          duration: 2,
        })

        return data
      })

      if(response?.fileId && success) {
        genAPI.fileDownloadDetail(response?.fileId).then(res => {
          if (res?.success) {
            if (res?.response?.downloadUrl) {
              window.open(res?.response?.downloadUrl, '_blank')
            }
          }
        })

        // download.mutateAsync(response?.fileId).then((res) => {
        //   if (res?.success) {
        //     res?.response?.downloadUrl && window.open(res?.response?.downloadUrl, '_blank')
        //   }
        // })
      }
    } catch (error) {
      console.error('error', error)
      message.error('数据导出失败')
    }
  }

  return { onExport }
}

export default useTableExport

import React, { FC, ReactNode } from 'react';
import { Empty } from 'antd';

type BnConditionalRenderProps = {
  data: any;
  children: ReactNode;
};

const BnConditionalRender: FC<BnConditionalRenderProps> = ({ data = [], children }: BnConditionalRenderProps): ReactNode => {
  if (Object.prototype.toString.call(data) === '[object Null]' || Object.prototype.toString.call(data) === '[object Undefined]') {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  const isEmpty = !Array.isArray(data) || data.length === 0;
  const hasTwoNulls = Array.isArray(data) && data.length === 2 && data.every((item) => item == null || item === '');

  return <>{isEmpty || hasTwoNulls ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> : children}</>;
};

export default BnConditionalRender;

import { create } from 'zustand'
import { persist, devtools } from 'zustand/middleware'

interface BranchProps {
  isStartBasedOn: boolean // 起始值依据
  isFieldBasedOn: boolean // 业务字段依据
}
interface BusinessFieldsProps {
  fields: string[] // 业务字段分段-选中字段
  isStartBasedOn: boolean
}
interface BatchatchSetParamsProps {
  branch: BranchProps
  businessFields: BusinessFieldsProps
}
interface CodeRuleStoreState {
  batchSetParams: BatchatchSetParamsProps
  submit: any
  setBatchSetParamsFun: (data: BatchatchSetParamsProps) => void
  setTriggerSubmitFun: (data: any) => void
  fieldSourceOptions: any[]
  setFieldSourceOptions: (data: any[]) => void
  previewData: any
  setPreviewData: (data: any) => void
  isDisablebranch: boolean
  setIsDisablebranch: (data: boolean) => void
}
/**
 * 编码规则批量设置参数
 */
const codeRuleStore = create<CodeRuleStoreState>()(
  devtools((set) => ({
    batchSetParams: {
      branch: {
        isStartBasedOn: false,
        isFieldBasedOn: false,
      },
      businessFields: {
        isStartBasedOn: false,
        fields: [],
      },
    },
    submit: null,
    setBatchSetParamsFun: (batchSetParams: any) => set(() => ({ batchSetParams })),
    setTriggerSubmitFun: (submit: any) => set(() => ({ submit })),
    fieldSourceOptions: [],
    setFieldSourceOptions: (fieldSourceOptions: any) => set(() => ({ fieldSourceOptions })),
    previewData: {
      sortList: [],
    },
    setPreviewData: (previewData: any) => set(() => ({ previewData })),
    isDisablebranch: true,
    setIsDisablebranch: (isDisablebranch: boolean) => set(() => ({ isDisablebranch })),

  })),
)

export default codeRuleStore

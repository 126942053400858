import { useEffect, useState } from 'react';
import { TableProps } from 'antd';
import { BitzTableColumnType } from 'bitz-react-admin-ui/dist/components/BitzTable';
import api from '@/services/GeneralPublicInterface';
import { Updater, useImmer } from 'use-immer';
import { lowerFirst, find, replace } from 'lodash-es';
import { useQuery } from '@tanstack/react-query';

const SortType = {
  descend: 'desc',
  ascend: 'asc'
};

const SortTypeFlip = {
  desc: 'descend',
  asc: 'ascend'
};

interface UseTableSortProps {
  url?: string;
  columns: BitzTableColumnType[];
}

type UseTableSortReturnType = [
  col: BitzTableColumnType[],
  onTableChange: TableProps['onChange'],
  sortText: string | undefined,
  setCol: Updater<BitzTableColumnType[]>
];

export function useTableSort2(props: UseTableSortProps): UseTableSortReturnType {
  const { url = '', columns = [] } = props;
  const initSortText = () => {
    const item = columns?.find((v) => v?.sortOrder);
    if (item) {
      const key = item?.dataIndex || item?.key;
      return `${key} ${SortType[item?.sortOrder || 'descend']}`;
    }
    return undefined;
  };

  const [sortText, setSortText] = useState<string | undefined>(initSortText());
  const [col, setCol] = useImmer<BitzTableColumnType[]>(columns);

  const des = useQuery({
    queryKey: ['useTableSort2', url],
    queryFn: ({ signal }) => {
      const requestUrl = url[0] === '/' ? url.slice(1) : url;
      return api.commonOrderByList({ requestUrl }, { signal });
    },
    enabled: !!url,
    // 缓存60分钟
    staleTime: 60 * 60 * 1000
  });

  useEffect(() => {
    if (des.data?.success) {
      const map = new Map();
      des.data.response?.fieldList?.forEach((v) => {
        map.set(lowerFirst(v.fieldName!), v);
      });
      const sortCol = col.map((v) => {
        const uniqueKey = v.dataIndex || v.key;
        const item = map.get(uniqueKey);

        return {
          ...v,
          field: item?.fieldNames,
          ellipsis: false,
          sorter: map.has(uniqueKey)
        };
      });
      setCol(sortCol);
    }
  }, [des.data?.success]);

  const onTableChange: TableProps['onChange'] = (_a, _b, sorter) => {
    if (!Array.isArray(sorter)) {
      const key = sorter?.field || sorter?.column?.key || sorter?.column?.dataIndex;

      setCol((draft) => {
        // const index = draft?.findIndex(v => (v.key === key || v?.dataIndex === key)) ?? -1
        const index2 = columns?.findIndex((v) => v?.dataIndex === key) ?? -1;
        if (index2 >= 0) {
          const list: BitzTableColumnType[] = draft?.map((v) => ({ ...v, sortOrder: null }));
          if (sorter?.order) {
            list[index2].sortOrder = sorter?.order;
            // @ts-ignore
            const sortKey = find(col, (v) => v.key === key)?.field;
            // console.log(sortKey);
            setSortText(`${sortKey} ${SortType[sorter?.order]}`);
            return list;
          } else {
            setSortText(undefined);
            return list;
          }
        }
      });
    }
  };

  return [col, onTableChange, sortText, setCol];
}

export default useTableSort2;

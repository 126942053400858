import { create } from 'zustand'
import { persist, createJSONStorage, devtools } from 'zustand/middleware'
/**
 * @description BranchState取名采用大驼峰,State前面的命名与文件名保持一致
 */

interface LayoutState {
  navKey?: string
  setNavKey?: (val: string) => void
  menuData?: any
  setMenuData?: (menuData: any) => void
  menuKey?: string
  setMenuKey?: (val: string) => void
  layoutNavData?: any
  setLayoutNavData?: (val: string) => void
  flexibleMenu?: boolean
  setFlexibleMenu?: (val: boolean) => void
  layoutItems?: any
  setLayoutItems?: (val: any) => void
  layoutActiveKey?: any
  setLayoutActiveKey?: (val: any) => void
}
/**
 * @description BranchStore取名采用大驼峰,Store前面的命名与文件名保持一致
 * @description 以下是有Storage的store写法
 */
const layoutStore = create<LayoutState>()(
  devtools(
    persist(
      (set) => ({
        navKey: '1',
        setNavKey: (navKey: string) => set(() => ({ navKey })),
        menuData: {},
        setMenuData: (menuData: any) => set(() => ({ menuData })),
        menuKey: '',
        setMenuKey: (menuKey: string) => set(() => ({ menuKey })),
        layoutNavData: [],
        setLayoutNavData: (layoutNavData: any) => set(() => ({ layoutNavData })),
        flexibleMenu: false,
        setFlexibleMenu: (flexibleMenu: boolean) => set(() => ({ flexibleMenu })),
        layoutItems: [],
        setLayoutItems: (layoutItems: any) => set(() => ({ layoutItems })),
        layoutActiveKey: '',
        setLayoutActiveKey: (layoutActiveKey: any) => set(() => ({ layoutActiveKey })),
      }),
      /**
       * @description layoutStorage取名采用大驼峰,layout与文件名保持一致，后面则取名为Storage
       */
      {
        name: 'layoutStorage',
        storage: createJSONStorage(() => localStorage),
      },
    ),
  ),
)

export default layoutStore

import useCollectStore from "@/store/useCollect";
import useBasicSettingsStore from "@/store/useBasicSettingsStore";
import useStore from "@/components/LayoutContentTabs/useStore";
import useTimerStore from '@/store/useTimerStore'
// import {disconnectSignalr} from "@/utils/signalr";
// import useUserStore from '@/store/userStore'

export const Loguot = async () => {
  localStorage.removeItem('lzhHostToken')
  localStorage.removeItem('lzhOriginalToken')
  useCollectStore.persist.clearStorage()
  useBasicSettingsStore.persist.clearStorage()
  useStore.persist.clearStorage()
  useTimerStore.persist.clearStorage()
  // 清空用户信息
  // useUserStore.persist.clearStorage()
  // 停止Signalr
  // disconnectSignalr()
  // 跳转到登录页
  window.location.href = '/login'
}

import { FC, memo, useContext, useEffect, useMemo, useState } from 'react';
import style from './index.module.less';
import {
  CaretRightFilled,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  ExclamationCircleFilled,
  ExclamationOutlined,
  PauseOutlined
} from '@ant-design/icons';
import { Dropdown, Flex, MenuProps, Modal, Space, Spin, Tooltip, Typography } from 'antd';
import { GetTimerOutput } from '@/services/WebApi';
import dayjs from 'dayjs';
import { NonEditableStatus } from '@/pages/Works/WorkHourList/const';
import { TimerContext } from '@/components/BitzTimerContext';
import { loadFun, onDeleteWork } from '@/pages/Works/Common';
import { css } from '@emotion/css';
import clx from 'classnames';
import { show } from '@ebay/nice-modal-react';
import WorkListCreateOrEditModal from '@/pages/Works/WorkHourManager/WorkListCreateOrEditModal';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import ApiWeb from '@/services/Web';
import { isNumber } from 'lodash-es';
const { confirm } = Modal;

interface TimeItemProps {
  data?: GetTimerOutput;
}

// eslint-disable-next-line no-constant-condition
const StatusIcon = (props) => {
  const size = 38;

  const iconCommonStyle = {
    fontSize: 22,
    color: '#fff'
  };

  const commonIconStyle = css`
    height: 42px;
    width: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    border: var(--ant-line-width) var(--ant-line-type) var(--ant-color-border-secondary);
  `;

  switch (props.type) {
    case '1':
      return (
        <div
          {...props}
          className={clx(
            commonIconStyle,
            css`
              background-color: var(--ant-color-error-hover);
              &:hover {
                background-color: var(--ant-color-error);
              }
            `
          )}
        >
          <PauseOutlined style={iconCommonStyle} />
        </div>
      );
    case '2':
      return (
        <div
          {...props}
          className={clx(
            commonIconStyle,
            css`
              background-color: var(--ant-color-success);

              &:hover {
                background-color: var(--ant-color-success-hover);
              }
            `
          )}
        >
          <CaretRightFilled style={iconCommonStyle} />
        </div>
      );
    case '3':
      return (
        <Tooltip title="已经过期" placement="top">
          <div
            {...props}
            className={clx(
              commonIconStyle,
              css`
                background-color: var(--ant-color-text-quaternary);
              `
            )}
          >
            <ExclamationOutlined style={iconCommonStyle} />
          </div>
        </Tooltip>
      );
  }
};

const TimeItem: FC<TimeItemProps> = (props) => {
  const { data } = props;
  const [actionIcon, setActionIcon] = useState('3');
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();
  const { workInfo, runningId, runningTimer, runningTimerState, onGlobalStart, onGlobalPause, onGlobalContinue, deleteCurTimer } = useContext(TimerContext);

  // const [time, setTime] = useState(hourToSec(data?.watchTime))

  const time = useMemo(() => {
    if (data?.timerState === 'Start' && runningId === data?.id && isNumber(runningTimer)) {
      return dayjs.duration(runningTimer).format('HH:mm:ss');
    }
    return data?.watchTime;
  }, [data, runningTimer, runningId]);

  const handlTimeStatus = () => {
    // if (!dayjs(data?.workDate).isToday() || NonEditableStatus.includes(data?.status ?? '')) {
    //   return
    // }

    if (data?.timerState === 'Start') {
      // 暂停
      setLoading(true);
      onGlobalPause(data?.id).finally(() => {
        setLoading(false);
      });
    } else if (!dayjs(data?.workDate).isToday() || NonEditableStatus.includes(data?.status ?? '')) {
      return;
    } else if (data?.timerState === 'Pause') {
      // 开始
      setLoading(true);
      // @ts-ignore
      onGlobalStart(data?.id).finally(() => {
        setLoading(false);
      });
    }
  };

  const handleEdit = () => {
    show(WorkListCreateOrEditModal, {
      data: data
    }).then((res) => {
      if (res) {
        queryClient.refetchQueries({
          queryKey: ['Base', 'BnHeadTimer', 'TimerModal'],
          type: 'active'
        });
        queryClient.refetchQueries({
          queryKey: ['Works', 'WorkHourList', 'DayPanel'],
          type: 'active'
        });
      }
    });
  };

  const mutationCopy = useMutation({
    mutationFn: () => {
      return ApiWeb.webTimesheetsTimesheetTimesheetPostdraftBatchCreate([
        {
          ...data,
          id: '',
          timerState: data?.timerState === 'Start' ? 'Pause' : data?.timerState
        }
      ]);
    },
    onSuccess: (res) => {
      if (res?.success) {
        loadFun(queryClient);
      }
    }
  });

  const handleRemove = (id) => {
    onDeleteWork([id], () => {
      loadFun(queryClient);
      // @ts-ignore
      if (runningId === id || workInfo?.id === id) {
        deleteCurTimer();
      }
    });
  };

  const items: MenuProps['items'] = [
    {
      key: 'copy',
      label: '复制',
      icon: <CopyOutlined />,
      onClick: () => {
        confirm({
          title: '您确定复制吗?',
          icon: <ExclamationCircleFilled />,
          // content: '提交超过30分钟的工时选项不会执行撤回',
          onOk: async () => {
            await mutationCopy.mutateAsync();
          }
        });
      }
    },
    {
      key: 'remove',
      label: '删除',
      icon: <DeleteOutlined />,
      danger: true,
      onClick: () => handleRemove(data?.id)
    }
  ];

  useEffect(() => {
    if (data?.timerState === 'Start') {
      setActionIcon('1');
    } else if (!dayjs(data?.workDate).isToday() || NonEditableStatus.includes(data?.status ?? '')) {
      setActionIcon('3');
    } else if (data?.timerState === 'Pause') {
      setActionIcon('2');
    }
  }, [data]);

  const caseText = data?.caseId ? `${data?.caseSerialId}:${data?.caseName}` : '未知案件';
  const clientText = data?.clientId ? `${data?.clientSerialId}:${data?.clientName}` : '未知客户';
  const worksCaseInfo = [
    <Typography.Text key="case" className={style.worksCase} type="secondary">
      {caseText}
    </Typography.Text>,
    <Typography.Text key="client" type="secondary">
      {clientText}
    </Typography.Text>
  ];

  return (
    <Spin spinning={loading}>
      <div className={style.container}>
        <div>
          <Spin spinning={false}>
            <StatusIcon onClick={() => handlTimeStatus()} type={actionIcon} />
          </Spin>
        </div>
        <div className={style.rightContainer}>
          <Flex justify="space-between" align="center">
            <Typography.Title level={4}>{time}</Typography.Title>
            <Space>
              <Typography.Text type="secondary">{data?.workDate ? dayjs(data?.workDate).format('YYYY/MM/DD') : '-'}</Typography.Text>
              <Dropdown
                overlayClassName="time_item_dropdown"
                destroyPopupOnHide
                menu={{ items }}
                trigger={['click']}
                // getPopupContainer={() => document.getElementById('TimeDrawerContainer')}
              >
                <Typography.Text type="secondary">
                  <EllipsisOutlined size={10} style={{ cursor: 'pointer' }} />
                </Typography.Text>
              </Dropdown>
            </Space>
          </Flex>
          <Flex justify="space-between" align="end" className={style.worksInfo}>
            <Flex vertical>{worksCaseInfo}</Flex>
            <div onClick={handleEdit} className={style.worksClient} style={{ marginLeft: 5, flexShrink: 0, cursor: 'pointer' }}>
              <EditOutlined />
            </div>
          </Flex>
        </div>
      </div>
    </Spin>
  );
};

export default memo(TimeItem);

import React, {useCallback, useEffect, useRef, useState} from 'react'
import { useControllableValue, useDebounceFn } from 'ahooks'
import {SelectProps,Button, Spin, Flex, AutoComplete, Divider, Input } from 'antd'
import {PlusOutlined} from '@ant-design/icons'
import { useMutation } from '@tanstack/react-query'
import apiWeb from '@/services/Web'
import { CreateTimesheetNickNameInput, GetTimesheetNickNameOutput} from '@/services/WebApi'
import type { InputRef } from 'antd';
import { message } from '@/components/BitzAntApp/Static'



interface defaultOptionsItem {
  name?: string
  id: string
}

interface AliasTempSelectProps extends Omit<SelectProps, 'options'> {
  defaultOptions?: defaultOptionsItem[]
}

const AliasTempSelect: React.FC<AliasTempSelectProps> = (props) => {
  const {
    value,
    onChange,
    ...rest
  } = props

  const inputRef = useRef<InputRef>(null);
  const [nickName, setNickName] = useState<string>('')
  const [state, setState] = useControllableValue<SelectProps['value']>(props)
  const [optArr, setOptArr] = useControllableValue<GetTimesheetNickNameOutput[]>(props, {
    defaultValue: [
      // {
      //   name: 'Not Data',
      //   id: ''
      // }
    ],
    defaultValuePropName: 'defaultOptions',
    valuePropName: 'options',
    trigger: 'onChangeOptions',
  })

  useEffect(() => {
    if (props?.defaultOptions?.length) {
      setOptArr(props?.defaultOptions)
    }
  }, [props?.defaultOptions]);

  const mutation = useMutation({
    mutationFn: (newTodo?: string) => {
      return apiWeb.webTimesheetsTimesheetnicknameGetpageCreate({
        // filter: newTodo,
        // nickName: ''
        page: 1,
        size: 199,
        // isClient: true,
        toOrderBy: 'createTime desc',
      })
      // }
    },
    onSuccess: (data) => {
      if (data?.success) {
        setOptArr(data.response?.data ?? [])
        !data?.response?.data?.length &&  setOptArr([
          {
            nickName: 'Not Data',
          }
        ])
      }
    },
  })

  const { run, cancel } = useDebounceFn(mutation.mutate)

  const dropdownVisibleChange = useCallback( (open: boolean) => {
    if (open && !optArr?.length) {
      mutation.mutate('')
    }
  }, [optArr])

  const handleChange: SelectProps['onChange'] = (value, option) => {
    setState(value, option)
  }

  const addNickname = useMutation({
    mutationFn: (newTodo: CreateTimesheetNickNameInput) => {
      return apiWeb.webTimesheetsTimesheetnicknamePostCreate(newTodo)
    },
    onSuccess: (res) => {
      if (res?.success) {
        setNickName('')
        mutation.mutate('')
        // !res?.response?.data?.length &&

      }
    }
  })

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNickName(event.target.value);
  };

  const handleAddNickName = (e) => {
    e.preventDefault();
    if (nickName) {
      addNickname.mutate({
        nickName,
      })
    } else {
      message.error('请填写内容')
    }
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  }

  const dropdownRender = (originNode) => {

    return (
      <>
        <Spin size="small" spinning={mutation.isPending}>
          {originNode}
        </Spin>
        <Divider style={{ margin: '8px 0' }} />
        <Flex gap={10} style={{ padding: '0 8px 4px', width: '100%' }}>
          <Input
            style={{ width: '100%' }}
            placeholder="添加的暂代昵称"
            ref={inputRef}
            value={nickName}
            onChange={onNameChange}
            onKeyDown={(e) => e.stopPropagation()}
          />
          <Button
            loading={addNickname.isPending}
            onClick={handleAddNickName}
            type="text"
            icon={<PlusOutlined />}
          >
            添加
          </Button>
        </Flex>
      </>
    )
  }

  return (
    <AutoComplete
      loading={mutation.isPending}
      fieldNames={{
        label: 'nickName',
        value: 'nickName',
      }}
      showSearch
      placeholder="请输入"
      defaultActiveFirstOption={false}
      filterOption={false}
      suffixIcon={false}
      onDropdownVisibleChange={dropdownVisibleChange}
      onSearch={run}
      dropdownRender={dropdownRender}
      // notFoundContent={mutation.isPending ? <Spin size="small" /> : null}
      value={state}
      onChange={handleChange}
      options={optArr}
      {...rest}
    >
      <Input />
    </AutoComplete>
  )
}

export default AliasTempSelect

import { useEffect } from "react";
import { Flex, Checkbox } from "antd";
import { IsTip } from "./store";


const confirmFooter = (props) => {
  const [isTip, setIsTip] = useRecoilState(IsTip)

  useEffect(() => {
    return () => {
      setIsTip(false)
    }
  }, []);

  return (
    <Flex justify="space-between" align="center">
      <Checkbox checked={isTip} onChange={(e) => setIsTip(e.target?.checked)}>
        今日不再提醒
      </Checkbox>
      <div>{props?.params}</div>
    </Flex>
  )
}

export default confirmFooter

import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { persist, createJSONStorage, devtools } from 'zustand/middleware'
import pkg from '~/package.json'

type State = {
  runningId?: string,
  watchTime?: string,
}

type Actions = {
  setRunning: (id?: string, watchTime?: string) => void
}

// const storedObject: any = localStorage.getItem(pkg.name + '_timer');
// console.log('storedObject', storedObject);

const useStore = create<State & Actions>()(
  devtools(
    immer(
      persist(
        (set, get) => {
          return {
            runningId: '',
            watchTime: '',
            setRunning: (id, watchTime) => {
              set((state) => {
                state.runningId = id
                state.watchTime = watchTime
              })
            },
            destory() {
              set((state) => ({
                // clear state
              }))
            },
            // setHasHydrated: (state) => {
            //   debugger
            //   // set({
            //   //   _hasHydrated: state
            //   // });
            // }
          }
        },
        {
          name: pkg.name + '_timer',
          storage: createJSONStorage(() => localStorage),
          // partialize: (state) => Object.fromEntries(Object.entries(state).filter(([key]) => ['runningId'].includes(key))),
          // onRehydrateStorage: (state) => {
          //   debugger
          //   // state.setHasHydrated(true)
          // }
        },
      ),
    ),
  ),
)

export default useStore

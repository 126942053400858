import { ImmerReducer } from "use-immer";

export interface StateProps {
  [key: string]: any;
}

export interface ActionProps {
  type: string;
  [key: string]: any;
}

const reducer: ImmerReducer<StateProps, ActionProps> = (
  draft = {},
  action
) => {
  switch (action.type) {
    case "update":
      // eslint-disable-next-line no-case-declarations
      const item =  draft[action?.name]
      if (item) {
        draft[action?.name] = {
          ...item,
          ...action,
        }
      } else {
        draft[action?.name] = action
      }
      break;
    case "reset":
      draft = {}
      break
    case "remove":
      if (action?.name) {
        delete draft[action.name]
      }
      break
    default:
      break;
  }
}

export default reducer;

import { FC } from 'react'
import { ButtonProps } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import { BitzButton } from 'bitz-react-admin-ui'

type CancelButtonProps = Omit<ButtonProps, 'icon' | 'type'>
const CancelButton: FC<CancelButtonProps> = ({ ...rest }) => {
  return (
    <BitzButton icon={<CloseCircleOutlined />} {...rest}>
      {rest.children || '取消'}
    </BitzButton>
  )
}

export default CancelButton

import { useUnwrapRecoilValue } from '@/v2/hooks';
import { permissionDB } from '@/v2/store/store';

interface BzPermissionProps extends PropsWithChildren {
  auth: string;
}
export const BzPermission: FC<BzPermissionProps> = ({ auth, children }) => {
  const permission = useUnwrapRecoilValue(permissionDB, new Set());
  return permission?.has?.(auth) ? children : null;
};

import { create } from 'zustand'
import { persist, devtools } from 'zustand/middleware'


interface conflictListStoreProps {
  conflictCheckCount: any
  setConflictCheckCountFun: (data: any) => void
}
/**
 * 利冲列表模块
 */
const conflictListStore = create<conflictListStoreProps>()(
  devtools((set) => ({
    conflictCheckCount: undefined,
    setConflictCheckCountFun: (conflictCheckCount: any) => set(() => ({ conflictCheckCount })),
  })),
)

export default conflictListStore

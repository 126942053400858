import { Button, Form, theme, Tooltip } from 'antd';
import React, { FC, memo, useContext, useMemo } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import clx from 'classnames';
import { MyContext } from '@/components/BitzQueryFilter2/BitzQueryFilterContext';
import { getShowSearch } from '@/utils/mobile';
import { BitzSelect } from 'bitz-react-admin-ui';
import { css } from '@emotion/css';
import { forEach } from 'lodash-es';

export interface AdvancedFilterProps {
  items: React.ReactNode[];

  field: any[];
}

const AdvancedFilter: FC<AdvancedFilterProps> = (props) => {
  const { field, items } = props;
  const { token } = theme.useToken();
  // 用于搜集表单信息
  const { state, columnsMap } = useContext(MyContext);

  const advancedOptions = useMemo(() => {
    const list: any = [];
    forEach(state, (value, key) => {
      const config = columnsMap[key];
      if (config && config?.show === true) {
        const disabled = field?.findIndex((v) => v?.field === value.name) != -1;
        list.push({
          value: value.name,
          label: value.label,
          disabled
        });
      }
    });

    return list;
  }, [state, field, columnsMap]);

  return (
    <Form.List name="advanced">
      {(fields, { add, remove }) => (
        <React.Fragment>
          {fields.map(({ key, name, ...restField }) => {
            return (
              <div className="advancedFilter" key={key}>
                <Tooltip placement="top" title="移除此项">
                  <Button type="text" onClick={() => remove(name)} icon={<DeleteOutlined style={{ color: token.colorPrimary }} />} />
                </Tooltip>

                <Form.Item {...restField} name={[name, 'field']}>
                  <BitzSelect style={{ width: '100%' }} options={advancedOptions} showSearch />
                </Form.Item>

                <Form.Item {...restField} name={[name, 'state']} initialValue="1">
                  <BitzSelect
                    style={{ width: '100%' }}
                    options={[
                      { value: '1', label: '包含' },
                      { value: '2', label: '不包含' },
                      { value: '3', label: '等于' }
                    ]}
                    showSearch={getShowSearch()}
                  />
                </Form.Item>

                <Form.Item
                  style={{ margin: 0, padding: 0 }}
                  shouldUpdate={(prevValues, curValues) => {
                    // console.log("prevValues, curValues", prevValues, curValues)
                    return prevValues.advanced[name]?.field !== curValues.advanced[name]?.field;
                  }}
                >
                  {({ getFieldValue }) => {
                    const fieldName = getFieldValue(['advanced', name, 'field']);
                    // @ts-ignore
                    const item = items?.find((v) => v.props.name === fieldName);

                    if (item) {
                      // @ts-ignore
                      const itemName = item?.props?.name;
                      // @ts-ignore
                      return React.cloneElement(item, {
                        // @ts-ignore
                        ...item?.props,
                        name: [name, itemName]
                      });
                    }
                    return '请选择';
                  }}
                </Form.Item>
              </div>
            );
          })}

          <div
            className={clx(
              'advancedFilterDivider',
              css`
                &:before,
                &:after {
                  border-color: ${token.colorBorder};
                }
              `
            )}
          >
            <span
              onClick={() => !(fields?.length >= items?.length) && add()}
              className={clx(
                'advancedFilterDivider-text',
                css`
                  color: ${token.colorPrimary};
                `,
                {
                  disabled: fields?.length >= items?.length,
                  [css`
                    color: ${token.colorTextDisabled};
                  `]: fields?.length >= items?.length
                }
              )}
            >
              添加条件
            </span>
          </div>
        </React.Fragment>
      )}
    </Form.List>
  );
};

export default memo(AdvancedFilter);

import styled from '@emotion/styled'
import { css } from '@emotion/css'

// Timer style start

export const timerwrap = (border = true, bgc?: string) => css`
  height: 32px;
  border-radius: 4px;
  border: ${border ? '1px solid var(--ant-color-primary)' : 'none'};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: ${bgc ?? '#fff'};
`

export const timeBtnClass = css`
  width: 32px;
  height: 32px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  &:hover {
    filter: brightness(1.2);
  }
`

export const timeBtnIconClass = css`
  width: 32px;
  height: 32px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  background-color: #ff8200;
  color: #fff;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
`

export const Time = styled.div<{ primary?: boolean }>`
  color: #1d2129;
  font-size: 12px;
  font-family: Source Han Sans;
  flex: 1;
  padding: 2px 8px;
  white-space: nowrap;
  ${({ primary }) => primary && 'background-color: var(--ant-color-fill);color:var(--ant-color-text);border-radius: 4px;'}
`

// Timer style end

// Modal style start

export const modalWrap = css`
  position: fixed;
  inset: 60px -600px 0 auto;
  z-index: 1000;
  width: 600px;
  max-width: 100%;
  background-color: #fff;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  overflow: hidden;
  transition: 0.3s ease-out;
`
export const modalWrapShow = css`
  right: 0;
`
export const modalWrapHide = css`
  right: -600px;
`

export const ModalHeader = styled.div<{ bgc: string }>`
  background-color: ${({ bgc }) => bgc};
  color: #fff;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const ModalTitle = styled.div`
  font-size: 18px;
  padding: 16px;
  margin: 0;
`

export const modalClose = css`
  margin-right: 16px;
  color: #efefef;
  &:hover {
    color: #fff !important;
  }
`
export const TimerList = styled.div`
  overflow: auto;
  flex: 1;
`
export const TimerItem = styled.div`
  margin: 10px 32px;
  // height: 65px;
  display: flex;
  align-items: center;
  gap: 12px;
  border-bottom: 1px solid #f3f4f4;
`
export const ModalFooter = styled.div`
  margin: 15px 32px 0;
  padding: 23px 0 32px;
  border-top: 1px solid #f3f4f4;
  display: flex;
  gap: 12px;
  align-items: center;
`

// Modal style end

// TimeList style start

export const timeListWrap = css`
  height: 68px;
  display: grid;
  grid-template-columns: calc(100% - 72px) 72px;
  align-items: center;
  background-color: #f2f2f2;
  text-align: center;
  font-size: 12px;
  box-shadow:
    0 6px 6px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 9px 8px 4px rgba(0, 0, 0, 0.05);
`

export const timeListSlider = css`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-left: 20px;
`

export const timeListItem = css`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 24px;
  border:1px solid;
  cursor: pointer;
`

export const timeListArrowStyle = css`
  font-size: 18px;
  line-height: unset;
  color: unset;
  width: 48px;
  position: unset;
  transform: unset;
  &::before {
    display: none;
  }
  &:hover {
    color: #000;
  }
  &.slick-disabled {
    cursor: not-allowed;
    color: grey;
  }
`
export const timeListToday = css`
  text-align: left;
  margin-left: 8px;
`
// TimeList style end

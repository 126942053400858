/*
 * # Title: LayoutContentTabs Component
 * - Description: 内容标签导航
 * - Date: 2024-04-12
 * - LastEditors: liujin liujin0505@icloud.com
 * - LastEditTime: 2024-06-01 17:15:21
 */

import { Button, Dropdown, Spin, Tag, Tooltip, theme } from 'antd';
import { CaretLeftOutlined, CaretRightOutlined, CloseOutlined, MoreOutlined } from '@ant-design/icons';
import { useCallback, useEffect, useRef, useTransition } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAliveController } from 'react-activation';
import { useUpdate } from 'ahooks';
import { css, cx } from '@emotion/css';
import styles from './index.module.less';
import { adminRoutes } from '@/router/config';
import useStore from './useStore';
import flattenRoutes from './flattenRoutes';
import Iconfont from '../iconfont';
import { usePageContext } from '@/providers/PageManageProvider';

export default () => {
  const { token } = theme.useToken();
  const tabsRef = useRef<HTMLDivElement>(null);
  const [isPending, startTransition] = useTransition();
  // const navgate = useNavigate()
  const { open } = usePageContext();
  const navgate = (key, item) => {
    return open({ key, label: item.label });
  };
  const location = useLocation();

  const { refreshScope, dropScope } = useAliveController();

  const {
    activeColor,
    setActiveColor,
    showArrowLeft,
    toggleArrowLeft,
    showArrowRight,
    toggleArrowRight,
    scrollX,
    setScrollX,
    tabs,
    addTab,
    label,
    setLabel,
    closeTab,
    closeOtherTab,
    clickTab,
    setnavagate,
    isHideAll,
    toggleHide,
    setRefrshKey
  } = useStore();

  const getGroupColor = (color) =>
    color
      ? css`
          color: ${color};
        `
      : '';

  const toolTipData = useCallback(
    (tabItem) => {
      const groups = tabs.filter((item) => item.groupKey === tabItem.groupKey && !item.isBtn && item.hide);
      const active = tabs.find((item) => item.label === label);
      let len = groups.length;
      let text = label;
      if (active?.groupKey !== tabItem?.groupKey) {
        len = len - 1;
        text = groups?.[0]?.label;
      }
      if (groups.length > 1) {
        return {
          text: `“${text}”和另外${len}个标签页`,
          length: groups.length
        };
      }
      if (groups.length === 1) {
        return {
          text: `“${text}”`,
          length: 1
        };
      }
      return {
        length: 0
      };
    },
    [tabs, label]
  );

  useEffect(() => {
    const routeList = flattenRoutes(adminRoutes);
    const item = routeList.find((item) => '/' + item.path === location.pathname);

    if (!item) return;
    const label = item?.meta?.title || '';
    const rootItem = routeList.find((i) => item.path.split('/')[0] === i.path);
    const groupItem = routeList.find((i) => item.path.split('/')[0] + '/' + item.path.split('/')[1] === i.path);
    const tab = {
      id: `${Date.now()}`,
      pathname: location.pathname,
      label,
      isBtn: false,
      hide: false,
      iconName: rootItem?.iconName,
      group: groupItem?.meta?.title,
      groupKey: rootItem?.path,
      groupColor: rootItem?.color
    };
    addTab(tab);
    setLabel(label);
    setnavagate(navgate);
  }, [location]);

  useEffect(() => {
    const scrollLeft = tabsRef.current?.scrollLeft || 0;
    const offsetWidth = tabsRef.current?.offsetWidth || 0;
    const scrollWidth = tabsRef.current?.scrollWidth || 0;
    const x = scrollX ?? scrollLeft;
    if (scrollWidth - offsetWidth > 0) {
      toggleArrowLeft(x > 0);
      toggleArrowRight(x < scrollWidth - offsetWidth);
    } else {
      toggleArrowLeft(false);
      toggleArrowRight(false);
      setScrollX(0);
    }
  }, [tabs, scrollX]);

  function scrollToActiveTab() {
    const active = document.querySelector('.' + styles.active);
    const left = (active as HTMLDivElement).offsetLeft || 0;
    const scrollWidth = tabsRef.current?.offsetWidth || 0;
    const x = left - scrollWidth / 2;
    tabsRef.current?.scrollTo({ left: x, behavior: 'smooth' });
    setScrollX(x);
  }

  return (
    <div
      className={cx(
        styles.container,
        css`
          color: ${activeColor};
        `
      )}
      id="layoutHeaderTabs"
    >
      {showArrowLeft && (
        <CaretLeftOutlined
          className={styles.arrowLeft}
          onClick={() => {
            const x = scrollX - 160;
            tabsRef.current?.scrollTo({ left: x, behavior: 'smooth' });
            setScrollX(x);
          }}
        />
      )}
      {showArrowRight && (
        <CaretRightOutlined
          className={styles.arrowRight}
          onClick={() => {
            const x = scrollX + 160;
            tabsRef.current?.scrollTo({ left: x, behavior: 'smooth' });
            setScrollX(x);
          }}
        />
      )}

      <Dropdown
        overlayClassName={styles['more-menu-popup']}
        menu={{
          items: [
            {
              key: '1',
              disabled: !(showArrowLeft || showArrowRight),
              label: <a onClick={scrollToActiveTab}>定位到选中</a>
            },
            {
              key: '2',
              label: '转到',
              children: tabs
                .filter((item) => !item.isBtn)
                .map((item) => ({
                  label: (
                    <span
                      onClick={() => {
                        clickTab(item);
                        setTimeout(scrollToActiveTab, 100);
                      }}
                    >
                      {item.label}
                    </span>
                  ),
                  key: item.id
                })),
              popupClassName: styles['more-menu-popup']
            },
            {
              key: '3',
              label: (
                <a
                  onClick={() => {
                    refreshScope(location.pathname);
                    setRefrshKey();
                  }}
                >
                  重新加载
                </a>
              )
            },
            {
              key: '4',
              disabled: tabs.length === 2 || !isHideAll,
              label: (
                <a
                  onClick={() => {
                    toggleHide(!isHideAll);
                  }}
                >
                  全部展开
                </a>
              )
            },
            {
              key: '5',
              disabled: tabs.length === 2 || isHideAll,
              label: (
                <a
                  onClick={() => {
                    toggleHide(!isHideAll);
                  }}
                >
                  全部收缩
                </a>
              )
            },
            {
              key: '6',
              disabled: tabs.length === 2,
              label: (
                <a
                  onClick={() => {
                    const tabItem = tabs.find((item) => item.label === label);
                    closeOtherTab(tabItem!, dropScope);
                  }}
                >
                  关闭其它
                </a>
              )
            }
            // {
            //   key: '5',
            //   label: <a onClick={() => {}}>关闭所有</a>,
            // },
          ]
        }}
      >
        <MoreOutlined className={styles.moreIcon} />
      </Dropdown>

      <div className={styles.tabs} ref={tabsRef}>
        {tabs
          .filter((item) => {
            if (item.groupKey) {
              return !item.hide;
            }
            return item;
          })
          .map((tabItem) => (
            <div
              className={cx(
                styles.tab,
                tabItem.isBtn ? styles.btnTab : '',
                tabItem.groupKey ? styles.groupTab : '',
                tabItem.label === label ? styles.active : '',
                getGroupColor(tabItem.groupColor)
              )}
              key={tabItem.id}
            >
              {label === tabItem.label && setActiveColor(tabItem.groupColor as string)}
              {label === tabItem.label ? (
                <>
                  <div className={styles.left}>
                    <svg width="12" height="12" viewBox="0 0 12 12">
                      <path d="M11 0 A12 12 0 0 1 0 11 L0 14 L14 14 L14 0 " strokeWidth="2" />
                    </svg>
                  </div>
                  <div className={styles.right}>
                    <svg width="12" height="12" viewBox="0 0 12 12">
                      <path d="M 1 0 A 12 12 0 0 0 11 11 L11 14 L-2 14 L-2 0" strokeWidth="2" />
                    </svg>
                  </div>
                </>
              ) : null}
              <>
                {tabItem.isBtn ? (
                  <Tag
                    className={styles.tag}
                    color={tabItem.groupColor}
                    onClick={() => {
                      clickTab(tabItem);
                    }}
                  >
                    {toolTipData(tabItem).length ? (
                      <Tooltip title={<span style={{ color: token.colorText }}>{toolTipData(tabItem).text}</span>} color={token.colorBgBase}>
                        <Iconfont name={tabItem.iconName!} size={20} color="inherit" fill="#fff" />
                      </Tooltip>
                    ) : (
                      <Iconfont name={tabItem.iconName!} size={20} color="inherit" fill="#fff" />
                    )}
                  </Tag>
                ) : (
                  <>
                    <span className={styles.textWrap}>
                      <Dropdown
                        menu={{
                          items: [
                            {
                              label: '重新加载',
                              key: '0'
                            },
                            {
                              label: '关闭',
                              key: '1'
                            },
                            {
                              label: '关闭其它',
                              key: '2'
                            }
                          ],
                          onClick(info) {
                            if (info.key === '0') {
                              refreshScope(location.pathname);
                              setRefrshKey();
                            }
                            if (info.key === '1') {
                              dropScope(tabItem?.pathname);
                              closeTab(tabItem);
                              return;
                            }
                            if (info.key === '2') {
                              closeOtherTab(tabItem, dropScope);
                            }
                          }
                        }}
                        trigger={['contextMenu']}
                      >
                        <span
                          className={styles.text}
                          onClick={() => {
                            startTransition(() => {
                              clickTab(tabItem);
                            });
                          }}
                        >
                          <Spin size="small" spinning={isPending && tabItem.label === label}>
                            {tabItem.label}
                          </Spin>
                        </span>
                      </Dropdown>
                      {tabs.filter((item) => !item.isBtn).length === 1 ? null : (
                        <Button
                          className={styles.close}
                          type="text"
                          size="small"
                          shape="circle"
                          icon={<CloseOutlined />}
                          onClick={() => {
                            // console.log('tabItem', tabItem)
                            dropScope(tabItem?.pathname);
                            closeTab(tabItem);
                          }}
                        />
                      )}
                    </span>
                  </>
                )}
              </>
            </div>
          ))}
      </div>
    </div>
  );
};

import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useControllableValue, useDebounceFn } from 'ahooks';
import { SelectProps, Space, Select, Button, Spin } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useMutation } from '@tanstack/react-query';
import { show } from '@ebay/nice-modal-react';
import { isArray, isString, split } from 'lodash-es';

import apiWeb from '@/services/Web';
import BzUserSelectModal from './BzUserSelectModal';
import { UserInfoDto } from '@/services/WebApi';
import { MyContext } from '@/components/BitzQueryFilter2/BitzQueryFilterContext';
import BitzQueryFilter2 from '@/components/BitzQueryFilter2';
import curStyle from './index.module.less';
import { css } from '@emotion/css';

interface BzUserSelectProps extends Omit<SelectProps, 'options'> {
  defaultOptions?: any[];
  /**
   * 是否启用Fluent组件模式
   */
  fluent?: boolean;
  /**
   * 是否开启根据初始化时传入value自动请求接口获取数据
   */
  autoOptions?: boolean;
  /**
   * 隐藏icon
   */
  searchIcon?: boolean;
}

const BzUserSelect: React.FC<BzUserSelectProps> = (props) => {
  // console.log(props.defaultOptions)
  const { dispatch } = useContext(MyContext);
  const { value, onChange, searchIcon = true, ...rest } = props;

  const [rows, setRows] = useState([]);
  const [state, setState] = useControllableValue<SelectProps['value']>(props);
  const [optArr, setOptArr] = useControllableValue<UserInfoDto[]>(props, {
    defaultValue: [],
    defaultValuePropName: 'defaultOptions',
    valuePropName: 'options',
    trigger: 'onChangeOptions'
  });

  useEffect(() => {
    if (props?.defaultOptions?.length) {
      setOptArr(props?.defaultOptions);
    }
  }, [props?.defaultOptions]);

  const getUsersInfo = useMutation({
    mutationFn: (variables: string[]) => {
      return apiWeb.webSysUserinfoListCreate(variables);
    },
    onSuccess: (data) => {
      if (data.success) {
        setOptArr(data?.response ?? []);
      }
    }
  });

  useEffect(() => {
    if (props?.autoOptions) {
      if (isString(props?.value)) {
        getUsersInfo.mutate([props?.value]);
      } else if (isArray(props?.value) && props?.value?.length) {
        getUsersInfo.mutate(props?.value);
      }
    }
  }, []);

  /**
   * 初始化的时候如果有 defaultOptions
   * 需要进行数据组装 赋值给rows, 因为弹窗需要显示已选中项
   */
  useEffect(() => {
    if (optArr?.length) {
      const optList = optArr?.map((v) => ({
        name: v.displayName
      }));
      if (props?.mode === 'multiple') {
        const list = optArr?.filter((item) => {
          return value?.includes(item.id);
        });
        // @ts-ignore
        setRows(list);
      } else {
        const optList = optArr.find((v) => v?.id === value);
        // @ts-ignore
        setRows([optList]);
      }
    }
  }, []);

  const id = useMemo(() => {
    if (props.id) {
      if (props.id?.indexOf('advanced') !== -1) {
        const list = split(props.id, '_');
        return list[list.length - 1];
      }
      return props.id;
    }
    return '';
  }, [props.id]);

  useEffect(() => {
    if (id) {
      dispatch?.({
        type: 'update',
        name: id,
        value: props?.value
      });
    }
  }, [props?.value, id]);

  const mutation = useMutation({
    mutationFn: (newTodo?: string) => {
      return apiWeb.webSysUserinfoSelectCreate({
        page: 1,
        size: 20,
        filter: newTodo
      });
    },
    onSuccess: (data) => {
      if (data?.success) {
        setOptArr(data.response?.data ?? []);
      }
    }
  });

  const { run, cancel } = useDebounceFn(mutation.mutate);

  const dropdownVisibleChange = (open: boolean) => {
    if (open && !optArr?.length) {
      mutation.mutate('');
    }
  };

  const handleChange: SelectProps['onChange'] = (value, option) => {
    setState(value, option);
    if (isArray(option)) {
      // @ts-ignore
      setRows(option);
    } else {
      // @ts-ignore
      setRows([option]);
    }

    if (id && dispatch) {
      let valueName;
      // let valueName = isArray(option) ? option?.map((v) => v[fieldNames?.label ?? 'label']).join(',') : option[fieldNames?.label ?? 'label']
      if (isArray(option)) {
        valueName = option?.map((v) => v?.displayName).join(',');
      } else {
        valueName = option?.displayName;
      }
      dispatch?.({
        type: 'update',
        name: id,
        valueName
      });
    }
  };

  const openSearchModal = () => {
    show(BzUserSelectModal, {
      rows: rows,
      mode: props?.mode
    }).then((state) => {
      // @ts-ignore
      setOptArr(state);
      if (props?.mode === 'multiple') {
        const items = (state as UserInfoDto[]).map((item) => item.id);
        // setState(values)
        // @ts-ignore
        handleChange(items, state);
      } else {
        const item = (state as UserInfoDto[])?.[0];
        // value && setState(value)
        handleChange(item?.id, item);
      }
    });
  };

  const loading = useMemo(() => mutation.isPending || getUsersInfo.isPending, [mutation.isPending, getUsersInfo.isPending]);

  const dropdownRender = useCallback(
    (originNode) => {
      return (
        <Spin size="small" spinning={loading}>
          {originNode}
        </Spin>
      );
    },
    [loading]
  );

  const Component = useMemo(() => (props?.fluent ? BitzQueryFilter2.Select : Select), [props]);

  const style = useMemo<React.CSSProperties | undefined>(() => {
    if (props?.fluent || !searchIcon) {
      return undefined
    }
    return { width: 'calc(100% - 31px)' };
  }, [props?.fluent, searchIcon]);

  const maxTagPlaceholder = useCallback((omittedValues) => {
    const title = omittedValues.map(({ label }, index) => <p>{index + 1}. {label}</p>)
    return (
      <Tooltip
        overlayStyle={{ pointerEvents: 'none' }}
        title={title}
      >
        <span>More</span>
      </Tooltip>
    )
  }, [])

  const className = useMemo(() => {
    if (!searchIcon) {
      return undefined
    }
    return props?.fluent
      ? css`
          .ant-select-selector {
            border-right-width: 0 !important;
          }
        `
      : undefined;
  }, [props?.fluent, searchIcon]);

  return (
    <Space.Compact className={curStyle.wapper} block>
      <Component
        maxTagPlaceholder={maxTagPlaceholder}
        className={className}
        loading={loading}
        fieldNames={{
          label: 'displayName',
          value: 'id'
        }}
        style={style}
        showSearch
        onDropdownVisibleChange={dropdownVisibleChange}
        onSearch={run}
        dropdownRender={dropdownRender}
        // notFoundContent={mutation.isPending ? <Spin size="small" /> : null}
        suffixIcon={null}
        filterOption={false}
        value={state}
        onChange={handleChange}
        options={optArr}
        {...rest}
      />
      {
        searchIcon && (
          <Button
            disabled={props?.disabled ?? false}
            style={{ height: 'auto' }}
            onClick={openSearchModal}
            icon={<SearchOutlined />}
          />
        )
      }
    </Space.Compact>
  );
};

export default BzUserSelect;

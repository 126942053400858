/*
 * # Title: conflictStore
 * - Description: 状态管理
 * - Date: 2024-04-14
 * - LastEditors: liujin liujin@elinklaw.com
 * - LastEditTime: 2024-06-07 18:03:57
 */

import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { persist, createJSONStorage } from 'zustand/middleware';
import { IconNames } from '@/components/iconfont';
import { omit, findLastIndex } from 'lodash-es';
import pkg from '~/package.json';

interface TabItem {
  id: string;
  label: string;
  group?: string;
  groupKey?: string;
  groupColor?: string;
  isBtn?: boolean;
  hide?: boolean;
  pathname: string;
  iconName?: IconNames;
}

interface Store {
  refrshKey: number;
  setRefrshKey: () => void;
  activeColor: string;
  setActiveColor: (color: string) => null;
  showArrowLeft: boolean;
  toggleArrowLeft: (status: boolean) => void;
  showArrowRight: boolean;
  toggleArrowRight: (status: boolean) => void;
  scrollX: number;
  setScrollX: (x: number) => void;
  navagate?: any;
  setnavagate: (navagate: any) => void;
  label: string;
  setLabel: (label: string) => void;
  tabs: TabItem[];
  setTabs: (tabs: TabItem[]) => void;
  addTab: (tab: TabItem) => void;
  deleteTab: (tab: TabItem) => void;
  insertTab: (index: number, tab: TabItem) => void;
  close: (pathname?: string) => void;
  closeTab: (tabItem: TabItem) => void;
  closeOtherTab: (tabItem: TabItem, dropScope: (path: string) => void) => void;
  clickTab: (tabItem: TabItem) => void;
  isHideAll: boolean;
  toggleHide: (show: boolean) => void;
}

const useStore = create<Store>()(
  immer(
    persist(
      // @ts-ignore
      (set, get) => {
        return {
          refrshKey: Date.now(),
          setRefrshKey: () => set((state) => ({ refrshKey: Date.now() })),
          activeColor: '#fff',
          setActiveColor(color) {
            set((state) => {
              state.activeColor = color;
            });
            return null;
          },
          showArrowLeft: false,
          toggleArrowLeft(status) {
            set((state) => {
              state.showArrowLeft = status;
            });
          },
          scrollX: 0,
          setScrollX(x) {
            set((state) => {
              state.scrollX = x;
            });
          },
          showArrowRight: false,
          toggleArrowRight(status) {
            set((state) => {
              state.showArrowRight = status;
            });
          },
          navagate: null,
          setnavagate(navagate) {
            set((state) => {
              state.navagate = navagate;
            });
          },
          label: '',
          setLabel: (label) => {
            set((state) => {
              state.label = label;
            });
          },
          tabs: [],
          setTabs: (tabs) => {
            if (tabs.length) {
              set((state) => {
                state.tabs = tabs;
              });
            }
          },
          addTab: (tab) => {
            const tabs = get().tabs;
            if (tabs.find((item) => item.pathname === tab.pathname)) {
              return;
            }
            const insertIndex = findLastIndex(tabs, (item) => item.groupKey === tab.groupKey);
            if (insertIndex !== -1) {
              get().insertTab(insertIndex + 1, tab);
              return;
            }
            set((state) => {
              state.tabs.push(
                {
                  ...omit(tab, 'label', 'path', 'pathname'),
                  id: `g${Date.now()}`,
                  isBtn: true,
                  hide: false
                } as TabItem,
                tab
              );
            });
          },
          close(pathname = location.pathname) {
            const tabItem = get().tabs.find((item) => item.pathname === pathname);
            if (tabItem) {
              get().closeTab(tabItem);
            }
          },
          closeTab: (tabItem) => {
            const tabs = get().tabs;
            if (tabs.filter((item) => !item.isBtn).length === 1) {
              return;
            }
            const pathname = tabItem.pathname;
            const index = tabs.findIndex((item) => item.id === tabItem.id);

            if (index !== -1) {
              get().deleteTab(tabItem);
            }
            // 没有按钮的tabs
            const tabsWithoutBtn = tabs.filter((item) => !item.isBtn);
            const _index = tabsWithoutBtn.findIndex((item) => item.pathname === pathname);
            let nextIndex = _index + 1;
            if (nextIndex >= tabsWithoutBtn.length) {
              nextIndex = tabsWithoutBtn.length - 2;
            }
            const nextItem = tabsWithoutBtn[nextIndex];
            if (pathname === location.pathname && nextItem?.pathname) {
              get().navagate(nextItem.pathname, nextItem as any);
            }
          },
          closeOtherTab(tabItem, dropScope) {
            const tabs = get().tabs;
            if (tabItem.pathname !== location.pathname) {
              get().navagate(tabItem.pathname);
            }
            const result = tabs.filter((item) => {
              if (item.groupKey === tabItem.groupKey && item.isBtn) return true;
              if (item.id === tabItem.id) return true;
              return false;
            });

            // forEach(result, (item) => {
            //   item?.pathname && dropScope?.(item?.pathname)
            //   console.log()
            // })
            get().setTabs(result);
          },
          deleteTab(tab) {
            const tabs = get().tabs;
            const index = tabs.findIndex((item) => item.id === tab.id);
            set((state) => {
              state.tabs.splice(index, 1);
              const leftItem = state.tabs.filter((item) => item.groupKey === tab.groupKey);
              if (leftItem.length === 1) {
                const leftItemIndex = state.tabs.findIndex((item) => item.id === leftItem[0].id);
                state.tabs.splice(leftItemIndex, 1);
              }
            });
          },
          insertTab(index, tab) {
            set((state) => {
              state.tabs.splice(index, 0, tab);
              state.tabs
                .filter((item) => item.groupKey === tab.groupKey)
                .forEach((item) => {
                  item.hide = false;
                });
            });
          },
          clickTab: (tabItem) => {
            const label = get().label;
            if (tabItem.isBtn) {
              const groupKey = tabItem.groupKey;
              set((state) => {
                state.tabs.map((item) => {
                  if (item.groupKey === groupKey && !item.isBtn && item.label !== label) {
                    item.hide = !item.hide;
                  }
                  return item;
                });
              });
              return;
            }
            get().setLabel(tabItem.label);
            setTimeout(() => {
              get().navagate(tabItem.pathname, tabItem as any);
            });
          },
          toggleHide: (hide) => {
            const label = get().label;
            set((state) => {
              state.isHideAll = hide;
              state.tabs.map((item) => {
                if (!item.isBtn && item.label !== label) {
                  item.hide = hide;
                }
                return item;
              });
            });
          }
        };
      },
      {
        name: pkg.name + '-page-tabs-1.0.0',
        storage: createJSONStorage(() => localStorage),
        partialize: (state) => Object.fromEntries(Object.entries(state).filter(([key]) => ['tabs', 'label', 'isHideAll'].includes(key)))
      }
    )
  )
);

export default useStore;

import { FC, memo, useContext, useEffect, useMemo } from 'react';
// @ts-ignore
import { BitzFluentRangePicker } from 'bitz-react-admin-ui';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';

import { MyContext } from '../../BitzQueryFilterContext';
import { DatePicker } from 'antd';

import { omit } from 'lodash-es';

export interface BQFDataRangePickerProps extends Omit<RangePickerProps, 'onChange'> {
  onChange?: ({ startDate, endDate }: { startDate?: string; endDate?: string }) => void;

  fluent?: boolean;
}

const BQFDataRangePicker: FC<BQFDataRangePickerProps> = (props) => {
  const { dispatch } = useContext(MyContext);
  const { onChange, fluent, ...rest } = props;

  const formatType = {
    week: 'YYYY-wo',
    month: 'YYYY-MM',
    quarter: 'YYYY-QQ',
    year: 'YYYY'
  };

  const format = useMemo<string>(() => {
    if (props?.format) {
      return props?.format;
    }
    if (props?.picker) {
      return formatType[props.picker];
    }
    return 'YYYY-MM-DD';
  }, [props?.format, props?.picker]);

  const id = useMemo(() => {
    if (props.id) {
      // @ts-ignore
      if (props.id?.indexOf('advanced') !== -1) {
        // @ts-ignore
        const list = split(props.id, '_');
        return list[list.length - 1];
      }
      return props.id;
    }
    return '';
  }, [props.id]);

  useEffect(() => {
    if (id) {
      dispatch?.({
        type: 'update',
        name: id,
        value: props?.value
      });
    }
  }, [props?.value, id]);

  const handleChange: RangePickerProps['onChange'] = (date, dateString) => {
    const start = date?.[0] ? dayjs(date[0]) : null;
    const end = date?.[1] ? dayjs(date[1]) : null;

    const startText = start?.format(format) ?? ' ';
    // @ts-ignore
    const endText = date[1]?.format(format) ?? ' ';

    const valueName = `${start ? startText : ''}` + (end ? ` - ${endText}` : '');

    // @ts-ignore
    onChange?.({
      startDate: start ? start?.format('YYYY-MM-DD HH:mm:ss') : '',
      endDate: end ? end?.endOf('day').format('YYYY-MM-DD HH:mm:ss') : ''
    });

    if (id) {
      dispatch?.({
        type: 'update',
        name: id,
        valueName: valueName
      });
    }
  };

  const value: RangePickerProps['value'] = useMemo(() => {
    // @ts-ignore
    if (props?.value) {
      // @ts-ignore
      const start = props?.value?.startDate ? dayjs(props?.value?.startDate) : null;
      // @ts-ignore
      const end = props?.value?.endDate ? dayjs(props?.value?.endDate) : null;
      return [start, end];
    }
    return undefined;
  }, [props?.value]);

  const Component = useMemo(() => (fluent ? BitzFluentRangePicker : DatePicker.RangePicker), [fluent]);

  return <Component style={{ width: '100%' }} value={value} onChange={handleChange} allowClear {...omit(rest, 'value')} />;
};

export default memo(BQFDataRangePicker);

import { HeaderNoProcess } from '@/constants';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend, { HttpBackendOptions } from 'i18next-http-backend';
import Backend from 'i18next-chained-backend';
import LocalStorageBackend from 'i18next-localstorage-backend'; // primary use cache
import { forEach } from 'lodash-es';
import axios from 'axios';
import useLanguageStore from '@/store/languageStore';

const lng = useLanguageStore.getState().language ?? 'zh-Hans';

const httpBackend: HttpBackendOptions = {
  // /api/languages/text
  // lng: 语言code
  // ns 模块名称
  // https://api.vnext.ailinkedlaw.com/api/languages/text?languageCode={{lng}}&module={{ns}}&source=Host
  loadPath: `${import.meta.env.VITE_API_URL}/api/languages/text?languageCode={{lng}}&module={{ns}}&source=Web`,
  addPath: `${import.meta.env.VITE_API_URL}/api/languages/text?languageCode={{lng}}&module={{ns}}&source=Web`,
  request: async (options, url, payload, callback) => {
    const data = await axios({
      url,
      method: 'get',
      headers: { ...HeaderNoProcess }
    });

    const data4 = {};
    forEach(data.data?.response, (value) => {
      data4[value.key] = value.description;
    });
    callback(null, {
      status: data.status,
      data: data4
    });
  }
};

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    backend: {
      backends: [
        LocalStorageBackend, // primary backend
        HttpBackend // fallback backend
      ],
      backendOptions: [
        {
          // 缓存配置 https://github.com/i18next/i18next-localstorage-backend#cache-backend-options
          // expirationTime: 7 * 24 * 60 * 60 * 1000 // 7 days
          expirationTime: 30 * 60 * 1000 // 30分钟
        },
        httpBackend
      ]
    },
    defaultNS: ['common'],
    ns: ['common'],
    // resources: {},
    fallbackLng: lng,
    lng: lng,
    // debug: import.meta.env.DEV,
    debug: false,
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  });

export default i18n;

/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AggregateRouteConfig {
  routeKey?: string | null;
  parameter?: string | null;
  jsonPath?: string | null;
}

export interface AttachmentDownloadDto {
  /** 下载地址 */
  downloadUrl?: string | null;
  /** FileName */
  name?: string | null;
  /**
   * 文件大小
   * @format int32
   */
  size?: number;
  /** 文件扩展名 */
  extension?: string | null;
  /** 文件哈希值 */
  hash?: string | null;
  /**
   * 文件状态
   * @format int32
   */
  status?: 0 | 1;
  statusText?: string | null;
  /**
   * 文件排序
   * @format int32
   */
  sort?: number;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
}

/** 通用返回信息类 */
export interface AttachmentDownloadDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: AttachmentDownloadDto[] | null;
}

/** 通用返回信息类 */
export interface AttachmentDownloadDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  response?: AttachmentDownloadDto;
}

/** Where 条件查询 */
export interface BaseWhereInput {
  /**
   * 筛选类型
   * @format int32
   */
  type?: 0 | 1 | 2 | null;
  /** 传参名 */
  inputFieldName?: string | null;
  /** 字段集 实际数据库查询字段 */
  fieldNames?: string[] | null;
  /**
   * 默认等于   筛选条件
   * @format int32
   */
  conditionalType?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16;
  /** 值 ， True False    数组则为 0,1,2   string  string[] */
  value?: string | null;
  /** 字段集 多关键字模糊查询 */
  andOrCondition?: string[] | null;
}

/** 通用返回信息类 */
export interface BooleanMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: boolean;
}

export interface ComboboxGroupDto {
  items?: ComboboxGroupDto[] | null;
  /** Value of the item. */
  value?: string | null;
  /** Display text of the item. */
  displayText?: string | null;
  /** 是否启用 */
  isActive?: boolean;
  /** 是否选中 */
  isSelected?: boolean;
  /** 父级Id */
  parentCode?: string | null;
  /** 用户组 */
  group?: string | null;
  /**
   * 作用域
   * @format int32
   */
  scope?: number;
}

/** 通用返回信息类 */
export interface ComboboxGroupDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: ComboboxGroupDto[] | null;
}

export interface ComboboxItemDto {
  /** Value of the item. */
  value?: string | null;
  /** Display text of the item. */
  displayText?: string | null;
  /** 是否启用 */
  isActive?: boolean;
  /** 是否选中 */
  isSelected?: boolean;
  /** 父级Id */
  parentCode?: string | null;
  /** 用户组 */
  group?: string | null;
  /**
   * 作用域
   * @format int32
   */
  scope?: number;
}

/** 通用返回信息类 */
export interface ComboboxItemDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: ComboboxItemDto[] | null;
}

export interface CreateGeneralCodeGroupsInput {
  /** 名称 */
  name?: string | null;
  /** 代码 */
  code?: string | null;
  /** 父级代码 */
  parentCode?: string | null;
  /**
   * 模块
   * @format int32
   */
  funcGroup?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 通用返回信息类 */
export interface CreateGeneralCodeGroupsInputMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  response?: CreateGeneralCodeGroupsInput;
}

export interface CreateGeneralCodeInput {
  /** 是否多级字典 */
  isTree?: boolean;
  /** 业务存储键值 */
  code?: string | null;
  /** 自增或雪花ID */
  displayName?: string | null;
  /** 树形多层级字典 */
  parentCode?: string | null;
  /** 分类 */
  class?: string | null;
  /** Groups */
  group?: string | null;
  /** 分类 */
  category?: string | null;
  /**
   * 默认排序
   * @format int32
   */
  sort?: number;
  /** 是否启用 */
  isActive?: boolean;
  /**
   * 作用域
   * @format int32
   */
  scope?: number;
  /** 是否保护 */
  isProtect?: boolean;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

/** 通用返回信息类 */
export interface CreateGeneralCodeInputMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  response?: CreateGeneralCodeInput;
}

export interface CreateGeneralCodeTextsInput {
  /** Code */
  code?: string | null;
  /** 名称 */
  displayName?: string | null;
  /** 层级 */
  nodeCode?: string | null;
  /** 类 */
  class?: string | null;
  /** 层级 */
  nodeDisplayName?: string | null;
  /** 是否启用 */
  isActive?: boolean;
  /** 语言 */
  language?: string | null;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

export interface CreateUserCustomSettingInput {
  /** 模块 */
  module?: string | null;
  /**
   * 配置类型
   * @format int32
   */
  settingType?: 0 | 1;
  /** 具体配置·个 */
  setting?: any;
  /**
   * 是否草稿
   * <param name="如果是草稿传false 不是传true不是传true"></param>
   */
  isEnabled?: boolean;
}

export interface EmailSendingRecordOutput {
  /** 接收人列表 */
  recipientUserList?: RecipientUserInfo[] | null;
  /** 记录分组 */
  group?: string | null;
  /** 记录类型 */
  type?: string | null;
  /**
   * 发送时间
   * @format date-time
   */
  sendDate?: string;
  /** 发送的人 */
  userId?: string | null;
  /** 发送的邮箱 */
  email?: string | null;
  /** 业务Id */
  businessId?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 利冲状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
}

/** 通用分页信息类 */
export interface EmailSendingRecordOutputPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: EmailSendingRecordOutput[] | null;
}

/** 通用返回信息类 */
export interface EmailSendingRecordOutputPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: EmailSendingRecordOutputPageModel;
}

export interface FieldDto {
  /** 表单 */
  tableName?: string | null;
  /** 字段 */
  field?: string | null;
}

export interface FileAggregateRoute {
  routeKeys?: string[] | null;
  routeKeysConfig?: AggregateRouteConfig[] | null;
  upstreamPathTemplate?: string | null;
  upstreamHost?: string | null;
  routeIsCaseSensitive?: boolean;
  aggregator?: string | null;
  upstreamHttpMethod?: string[] | null;
  /** @format int32 */
  priority?: number;
}

export interface FileAuthenticationOptions {
  authenticationProviderKey?: string | null;
  allowedScopes?: string[] | null;
}

export interface FileCacheOptions {
  /** @format int32 */
  ttlSeconds?: number;
  region?: string | null;
}

export interface FileConfiguration {
  routes?: FileRoute[] | null;
  dynamicRoutes?: FileDynamicRoute[] | null;
  aggregates?: FileAggregateRoute[] | null;
  globalConfiguration?: FileGlobalConfiguration;
}

export interface FileDynamicRoute {
  serviceName?: string | null;
  rateLimitRule?: FileRateLimitRule;
  downstreamHttpVersion?: string | null;
}

export interface FileGlobalConfiguration {
  requestIdKey?: string | null;
  serviceDiscoveryProvider?: FileServiceDiscoveryProvider;
  rateLimitOptions?: FileRateLimitOptions;
  qoSOptions?: FileQoSOptions;
  baseUrl?: string | null;
  loadBalancerOptions?: FileLoadBalancerOptions;
  downstreamScheme?: string | null;
  httpHandlerOptions?: FileHttpHandlerOptions;
  downstreamHttpVersion?: string | null;
}

export interface FileHostAndPort {
  host?: string | null;
  /** @format int32 */
  port?: number;
}

export interface FileHttpHandlerOptions {
  allowAutoRedirect?: boolean;
  useCookieContainer?: boolean;
  useTracing?: boolean;
  useProxy?: boolean;
  /** @format int32 */
  maxConnectionsPerServer?: number;
}

export interface FileLoadBalancerOptions {
  type?: string | null;
  key?: string | null;
  /** @format int32 */
  expiry?: number;
}

export interface FileQoSOptions {
  /** @format int32 */
  exceptionsAllowedBeforeBreaking?: number;
  /** @format int32 */
  durationOfBreak?: number;
  /** @format int32 */
  timeoutValue?: number;
}

export interface FileRateLimitOptions {
  clientIdHeader?: string | null;
  quotaExceededMessage?: string | null;
  rateLimitCounterPrefix?: string | null;
  disableRateLimitHeaders?: boolean;
  /** @format int32 */
  httpStatusCode?: number;
}

export interface FileRateLimitRule {
  clientWhitelist?: string[] | null;
  enableRateLimiting?: boolean;
  period?: string | null;
  /** @format double */
  periodTimespan?: number;
  /** @format int64 */
  limit?: number;
}

export interface FileRoute {
  downstreamPathTemplate?: string | null;
  upstreamPathTemplate?: string | null;
  upstreamHttpMethod?: string[] | null;
  downstreamHttpMethod?: string | null;
  addHeadersToRequest?: Record<string, string | null>;
  upstreamHeaderTransform?: Record<string, string | null>;
  downstreamHeaderTransform?: Record<string, string | null>;
  addClaimsToRequest?: Record<string, string | null>;
  routeClaimsRequirement?: Record<string, string | null>;
  addQueriesToRequest?: Record<string, string | null>;
  changeDownstreamPathTemplate?: Record<string, string | null>;
  requestIdKey?: string | null;
  fileCacheOptions?: FileCacheOptions;
  routeIsCaseSensitive?: boolean;
  serviceName?: string | null;
  serviceNamespace?: string | null;
  downstreamScheme?: string | null;
  qoSOptions?: FileQoSOptions;
  loadBalancerOptions?: FileLoadBalancerOptions;
  rateLimitOptions?: FileRateLimitRule;
  authenticationOptions?: FileAuthenticationOptions;
  httpHandlerOptions?: FileHttpHandlerOptions;
  downstreamHostAndPorts?: FileHostAndPort[] | null;
  upstreamHost?: string | null;
  key?: string | null;
  delegatingHandlers?: string[] | null;
  /** @format int32 */
  priority?: number;
  /** @format int32 */
  timeout?: number;
  dangerousAcceptAnyServerCertificateValidator?: boolean;
  securityOptions?: FileSecurityOptions;
  downstreamHttpVersion?: string | null;
}

export interface FileSecurityOptions {
  ipAllowedList?: string[] | null;
  ipBlockedList?: string[] | null;
}

export interface FileServiceDiscoveryProvider {
  scheme?: string | null;
  host?: string | null;
  /** @format int32 */
  port?: number;
  type?: string | null;
  token?: string | null;
  configurationKey?: string | null;
  /** @format int32 */
  pollingInterval?: number;
  namespace?: string | null;
}

export interface GeneralCodeGroupsDto {
  /** 名称 */
  name?: string | null;
  /** 代码 */
  code?: string | null;
  /** 父级代码 */
  parentCode?: string | null;
  /**
   * 模块
   * @format int32
   */
  funcGroup?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;
  /** 是否启用 */
  isEnabled?: boolean;
  /** 作用域说明 */
  remark?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 利冲状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
}

/** 通用返回信息类 */
export interface GeneralCodeGroupsDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: GeneralCodeGroupsDto[] | null;
}

export interface GeneralCodeTreeDto {
  /** 是否多级字典 */
  isTree?: boolean;
  /** 子级字典 */
  childList?: GeneralCodeTreeDto[] | null;
  /** 业务存储键值 */
  code?: string | null;
  /** 自增或雪花ID */
  displayName?: string | null;
  /** 树形多层级字典 */
  parentCode?: string | null;
  /** 分类 */
  class?: string | null;
  /** NodeCode */
  nodeCode?: string | null;
  /** Groups */
  group?: string | null;
  /** 层级显示名称 */
  nodeDisplayName?: string | null;
  /** 分类 */
  category?: string | null;
  /**
   * 默认排序
   * @format int32
   */
  sort?: number;
  /** 是否启用 */
  isActive?: boolean;
  /**
   * 字典层级
   * @format int32
   */
  level?: number;
  /**
   * 作用域
   * @format int32
   */
  scope?: number;
  /** 是否保护 */
  isProtect?: boolean;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 利冲状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
}

/** 通用返回信息类 */
export interface GeneralCodeTreeDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: GeneralCodeTreeDto[] | null;
}

/** Dto描述 */
export interface GetAdvancedWhereDto {
  /** 入参类名 */
  inputName?: string | null;
  url?: string | null;
  methodName?: string | null;
  /** 字段描述 */
  fieldList?: GetAdvancedWhereFieldDto[] | null;
}

/** 通用返回信息类 */
export interface GetAdvancedWhereDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** Dto描述 */
  response?: GetAdvancedWhereDto;
}

export interface GetAdvancedWhereFieldDto {
  /** @format int32 */
  valueType?: 0 | 1 | 2;
  /** 字段类型 */
  fieldType?: string | null;
  /** 是否多选 */
  isChoice?: boolean;
  /**
   * 显示样式
   * @format int32
   */
  displayStyle?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
  /**
   * 数据源
   * @format int32
   */
  dataSource?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20 | 21 | 22 | 23 | 24 | 25 | 26 | 27 | 28 | 29 | 30;
  /** 数据源键 */
  dataSourceKey?: string | null;
  /** 字段描述 */
  description?: string | null;
  /** 传参名 */
  inputFieldName?: string | null;
  /** 字段集 实际数据库查询字段 筛选使用 */
  fieldNames?: string[] | null;
  /** 关键字模糊搜索 */
  andOrCondition?: string[] | null;
}

export interface GetCheckWordInput {
  /** 拼写检查单词组 */
  words?: string[] | null;
  /** 语言 */
  lang?: string | null;
  /** 是否建议 */
  isSuggest?: boolean | null;
}

export interface GetEmailSendingRecordListInput {
  /** 业务Id */
  businessId?: string | null;
  /** 接收人Id */
  userId?: string | null;
  /**
   * 页码
   * @format int32
   */
  page?: number;
  /**
   * 数量
   * @format int32
   */
  size?: number;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderBy?: string | null;
  /** 排序规则， 默认根据创建时间排序 */
  toOrderByList?: OrderByModel[] | null;
  /** 高级筛选 */
  whereInputs?: BaseWhereInput[] | null;
}

export interface GetEntityDto {
  /** 实体名称 */
  entityName?: string | null;
  /** 实体描述 */
  description?: string | null;
  /** 字段列表 */
  fieldList?: GetFieldDto[] | null;
  /** 关联表名 */
  tableList?: FieldDto[] | null;
}

/** 通用返回信息类 */
export interface GetEntityDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: GetEntityDto[] | null;
}

export interface GetFieldDto {
  /** 实体名称 */
  entityName?: string | null;
  /** 实体描述 */
  entityDescription?: string | null;
  /** 字段名称 */
  fieldName?: string | null;
  /** 字段描述 */
  description?: string | null;
  /** 数据源键 */
  dataSourceKey?: string | null;
  /**
   * 数据类型
   * @format int32
   */
  fieldDataType?: 0 | 1 | 2 | 3 | 4;
}

export interface GetGeneralCodeDto {
  /** 是否多级字典 */
  isTree?: boolean;
  /** 业务存储键值 */
  code?: string | null;
  /** 自增或雪花ID */
  displayName?: string | null;
  /** 树形多层级字典 */
  parentCode?: string | null;
  /** 分类 */
  class?: string | null;
  /** NodeCode */
  nodeCode?: string | null;
  /** Groups */
  group?: string | null;
  /** 层级显示名称 */
  nodeDisplayName?: string | null;
  /** 分类 */
  category?: string | null;
  /**
   * 默认排序
   * @format int32
   */
  sort?: number;
  /** 是否启用 */
  isActive?: boolean;
  /**
   * 字典层级
   * @format int32
   */
  level?: number;
  /**
   * 作用域
   * @format int32
   */
  scope?: number;
  /** 是否保护 */
  isProtect?: boolean;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 利冲状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
}

/** 通用返回信息类 */
export interface GetGeneralCodeDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: GetGeneralCodeDto[] | null;
}

/** 通用分页信息类 */
export interface GetGeneralCodeDtoPageModel {
  /**
   * 当前页标
   * @format int32
   */
  page?: number;
  /**
   * 总页数
   * @format int32
   */
  pageCount?: number;
  /**
   * 数据总数
   * @format int32
   */
  dataCount?: number;
  /**
   * 每页大小
   * @format int32
   */
  pageSize?: number;
  /** 返回数据 */
  data?: GetGeneralCodeDto[] | null;
}

/** 通用返回信息类 */
export interface GetGeneralCodeDtoPageModelMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 通用分页信息类 */
  response?: GetGeneralCodeDtoPageModel;
}

export interface GetGeneralCodeGroupsListInput {
  /** 名称 */
  name?: string | null;
  /** 代码 */
  code?: string | null;
  /** 父级代码 */
  parentCode?: string | null;
  /**
   * 模块
   * @format int32
   */
  funcGroup?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | null;
  funcGroupText?: string | null;
}

export interface GetGeneralCodeTreeInput {
  /** 分组键值 */
  class?: string | null;
  /** 字典键值 */
  code?: string | null;
  /** 父级键值 */
  parentCode?: string | null;
  /** 是否激活 */
  isActive?: boolean | null;
  /** 分所Id */
  officeId?: string | null;
  /**
   * 作用域
   * @format int32
   */
  scope?: number | null;
}

export interface GetHolidayListYearInput {
  date?: string | null;
  ignoreHoliday?: boolean;
  isChange?: boolean;
}

/** 获取列表所有配置参数 */
export interface GetListAllSettingDto {
  /** Dto描述 */
  inputWhere?: GetAdvancedWhereDto;
  /** Dto描述 */
  advancedWhere?: GetAdvancedWhereDto;
  orderBy?: GetOrderByDto;
}

/** 通用返回信息类 */
export interface GetListAllSettingDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 获取列表所有配置参数 */
  response?: GetListAllSettingDto;
}

export interface GetOrderByDto {
  /** 入参类名 */
  inputName?: string | null;
  url?: string | null;
  methodName?: string | null;
  /** 字段描述 */
  fieldList?: GetOrderByFieldDto[] | null;
}

/** 通用返回信息类 */
export interface GetOrderByDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  response?: GetOrderByDto;
}

export interface GetOrderByFieldDto {
  /** 字段描述 */
  description?: string | null;
  /** 出参名 */
  fieldName?: string | null;
  /** 字段集 实际数据库查询字段 排序使用 */
  fieldNames?: string | null;
  /**
   * 排序方式
   * @format int32
   */
  orderByType?: 0 | 1;
}

export interface GetSentenceInput {
  /** 拼写检查文本 */
  sentence?: string | null;
  /** 语言 */
  lang?: string | null;
  /** 是否建议 */
  isSuggest?: boolean | null;
}

export interface GetSequenceNumberEntityDto {
  sequenceNumberEntityDto?: GetFieldDto[] | null;
  entityDtos?: GetEntityDto[] | null;
}

/** 通用返回信息类 */
export interface GetSequenceNumberEntityDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  response?: GetSequenceNumberEntityDto;
}

export interface ItemDto {
  /** Key值 */
  key?: string | null;
  /** 冗余字段 */
  keyField?: string | null;
  /** 预留 */
  value?: string | null;
  /** 描述 */
  description?: string | null;
  /** 值分隔符 */
  codeFieldSeparator?: string | null;
  /** 显示文本分隔符 */
  textFieldSeparator?: string | null;
}

/** 通用返回信息类 */
export interface ItemDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: ItemDto[] | null;
}

export interface LanguageDto {
  /** 图标 */
  icon?: string | null;
  /** 语言名称 */
  name?: string | null;
  /** 语言代码 */
  code?: string | null;
  /** 显示名称 */
  displayName?: string | null;
  /** 是否默认语言 */
  isDefault?: boolean;
  /** 是否启用该语言 */
  isEnabled?: boolean;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 利冲状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
}

/** 通用返回信息类 */
export interface LanguageDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: LanguageDto[] | null;
}

export interface MessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功 */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 返回数据集合 */
  response?: any;
}

export interface OrderByModel {
  fieldName?: any;
  /** @format int32 */
  orderByType?: 0 | 1;
}

export interface PubicHolidayDto {
  /** 国家编号 */
  countryCode?: string | null;
  /**
   * 年份
   * @format int32
   */
  year?: number;
  /** 节假日名称 */
  holidayName?: string | null;
  /**
   * 日期
   * @format date-time
   */
  dayDate?: string;
  /** 假日类型 */
  holidayType?: string | null;
  /** 是否激活 */
  isActive?: boolean;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 利冲状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
}

/** 通用返回信息类 */
export interface PubicHolidayDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: PubicHolidayDto[] | null;
}

export interface RecipientUserInfo {
  /** 用户Id */
  userId?: string | null;
  /** 用户名称 */
  userName?: string | null;
  /** 用户头像 */
  userPicture?: string | null;
}

/** 语法检查 */
export interface SpellCheckDto {
  /** 是否正确 */
  isOk?: boolean;
  /** 单词 */
  word?: string | null;
  /** 正确建议 */
  suggestions?: string[] | null;
}

/** 通用返回信息类 */
export interface SpellCheckDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: SpellCheckDto[] | null;
}

/** 通用返回信息类 */
export interface SpellCheckDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 语法检查 */
  response?: SpellCheckDto;
}

/** 通用返回信息类 */
export interface StringItemDtoListDictionaryMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: Record<string, ItemDto[] | null>;
}

/** 通用返回信息类 */
export interface StringMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: string | null;
}

/** 通用返回信息类 */
export interface StringObjectDictionaryMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: Record<string, any>;
}

export interface SysAuditLogDto {
  /** 目标数据Id */
  rowId?: string | null;
  /** 表名 */
  tableName?: string | null;
  /** 变更前数据 */
  oldValue?: string | null;
  itemOldValue?: SysAuditLogValueDto[] | null;
  /** 变更后数据 */
  newValue?: string | null;
  /** 过滤并处理的新值 */
  itemNewValue?: SysAuditLogValueDto[] | null;
  /** 操作人 */
  userName?: string | null;
  /** 操作 */
  action?: string | null;
  /** 操作描述 */
  description?: string | null;
  /** 创建人 */
  createBy?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人Id */
  createId?: string | null;
  /** 利冲状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
}

/** 通用返回信息类 */
export interface SysAuditLogDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: SysAuditLogDto[] | null;
}

export interface SysAuditLogValueDto {
  /** 列名 */
  columnName?: string | null;
  /** 描述 */
  description?: string | null;
  /** 值 可能时间转换 */
  value?: any;
}

export interface UpdateLogDto {
  /** 版本号 */
  updateVersion?: string | null;
  /**
   * 更新时间
   * @format date-time
   */
  updateTime?: string;
  /** 更新文本 */
  content?: string | null;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 利冲状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
}

/** 通用返回信息类 */
export interface UpdateLogDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  response?: UpdateLogDto;
}

/** 用户配置 */
export interface UserCustomSettingDto {
  /** 模块 */
  module?: string | null;
  /**
   * 配置类型
   * @format int32
   */
  settingType?: 0 | 1;
  /** 具体配置 */
  setting?: any;
  /** Id */
  id?: string | null;
  /**
   * 创建时间
   * @format date-time
   */
  createTime?: string;
  /** 创建人 */
  createBy?: string | null;
  /** 创建人Id */
  createId?: string | null;
  /** 利冲状态 */
  status?: string | null;
  /** 流程状态 */
  flowState?: string | null;
  /** 备注 */
  remark?: string | null;
}

/** 通用返回信息类 */
export interface UserCustomSettingDtoListMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 返回数据集合 */
  response?: UserCustomSettingDto[] | null;
}

/** 通用返回信息类 */
export interface UserCustomSettingDtoMessageModel {
  /**
   * 状态码
   * @format int32
   */
  status?: number;
  /** 操作是否成功s */
  success?: boolean;
  /** 返回信息 */
  msg?: string | null;
  /** 开发者信息 */
  msgDev?: string | null;
  /** 用户配置 */
  response?: UserCustomSettingDto;
}

import type { AxiosInstance, AxiosRequestConfig, HeadersDefaults, ResponseType } from 'axios';
import axios from 'axios';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (securityData: SecurityDataType | null) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain'
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || '' });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {})
      }
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    if (input instanceof FormData) {
      return input;
    }
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({ secure, path, type, query, format, body, ...params }: FullRequestParams): Promise<T> => {
    const secureParams = ((typeof secure === 'boolean' ? secure : this.secure) && this.securityWorker && (await this.securityWorker(this.securityData))) || {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body);
    }

    return this.instance
      .request({
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type ? { 'Content-Type': type } : {})
        },
        params: query,
        responseType: responseFormat,
        data: body,
        url: path
      })
      .then((response) => response.data);
  };
}

/**
 * @title 通用公共接口
 * @version 1.0.0
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * No description
     *
     * @tags CallBack
     * @name CallbackExternalLoginList
     * @summary 所有第三方扫码接口回调
     * @request GET:/api/callback/external/login
     */
    callbackExternalLoginList: (
      query?: {
        state?: string;
        /** @format int32 */
        loginProvider?: 1 | 2 | 3 | 4 | 5 | 6;
        tenancyName?: string;
        code?: string;
        authCode?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/callback/external/login`,
        method: 'GET',
        query: query,
        ...params
      }),

    /**
     * No description
     *
     * @tags Common
     * @name CommonVersionList
     * @summary 获取发布的最后一个版本号
     * @request GET:/api/common/version
     */
    commonVersionList: (params: RequestParams = {}) =>
      this.request<UpdateLogDtoMessageModel, any>({
        path: `/api/common/version`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Common
     * @name CommonSendSmsCreate
     * @summary 发送短信 验证码 (Auth)
     * @request POST:/api/common/send/{phonenumber}/sms
     * @secure
     */
    commonSendSmsCreate: (phoneNumber: string, phonenumber: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/common/send/${phonenumber}/sms`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Common
     * @name CommonSendEmailCreate
     * @summary 发送邮件验证码 (Auth)
     * @request POST:/api/common/send/{email}/email
     * @secure
     */
    commonSendEmailCreate: (email: string, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/common/send/${email}/email`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Common
     * @name CommonOperatingLogDetail
     * @summary 通过业务获取数据 (Auth)
     * @request GET:/api/common/operating/log/{id}
     * @secure
     */
    commonOperatingLogDetail: (id: string, params: RequestParams = {}) =>
      this.request<SysAuditLogDtoListMessageModel, void>({
        path: `/api/common/operating/log/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Common
     * @name CommonAdvancedWhereList
     * @summary 高级筛选
     * @request GET:/api/common/advanced/where
     */
    commonAdvancedWhereList: (
      query?: {
        requestUrl?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<GetListAllSettingDtoMessageModel, any>({
        path: `/api/common/advanced/where`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Common
     * @name CommonCreateInputList
     * @summary 创建表单Json
     * @request GET:/api/common/create/input
     */
    commonCreateInputList: (
      query?: {
        requestUrl?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<GetAdvancedWhereDtoMessageModel, any>({
        path: `/api/common/create/input`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Common
     * @name CommonOrderByList
     * @summary 排序
     * @request GET:/api/common/order/by
     */
    commonOrderByList: (
      query?: {
        requestUrl?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<GetOrderByDtoMessageModel, any>({
        path: `/api/common/order/by`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Common
     * @name CommonSequencenumberList
     * @summary 获取序列号设置对象
     * @request GET:/api/common/sequencenumber
     */
    commonSequencenumberList: (params: RequestParams = {}) =>
      this.request<GetSequenceNumberEntityDtoMessageModel, any>({
        path: `/api/common/sequencenumber`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Common
     * @name CommonSequencenumberEntityList
     * @summary 获取序列号表单列表
     * @request GET:/api/common/sequencenumber/entity
     */
    commonSequencenumberEntityList: (params: RequestParams = {}) =>
      this.request<GetEntityDtoListMessageModel, any>({
        path: `/api/common/sequencenumber/entity`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Common
     * @name CommonSequencenumberFieldList
     * @summary 获取序列号字段依据对象
     * @request GET:/api/common/sequencenumber/field
     */
    commonSequencenumberFieldList: (params: RequestParams = {}) =>
      this.request<GetEntityDtoListMessageModel, any>({
        path: `/api/common/sequencenumber/field`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Common
     * @name CommonListSettingsList
     * @summary 获取列表所有配置参数
     * @request GET:/api/common/list/settings
     */
    commonListSettingsList: (
      query?: {
        requestUrl?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<GetListAllSettingDtoMessageModel, any>({
        path: `/api/common/list/settings`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Common
     * @name CommonGetholidaylistyearCreate
     * @summary 取指定年份的节假日及万年历信息
     * @request POST:/api/common/getholidaylistyear
     */
    commonGetholidaylistyearCreate: (data: GetHolidayListYearInput, params: RequestParams = {}) =>
      this.request<PubicHolidayDtoListMessageModel, any>({
        path: `/api/common/getholidaylistyear`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Common
     * @name CommonGetemailsendingrecordlistCreate
     * @summary 获取邮件发送记录列表
     * @request POST:/api/common/getemailsendingrecordlist
     */
    commonGetemailsendingrecordlistCreate: (data: GetEmailSendingRecordListInput, params: RequestParams = {}) =>
      this.request<EmailSendingRecordOutputPageModelMessageModel, any>({
        path: `/api/common/getemailsendingrecordlist`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Enum
     * @name EnumList
     * @summary 获取枚举类型
     * @request GET:/api/enum
     */
    enumList: (
      query?: {
        /**
         * TenantTypeEnum,TenantTypeEnum
         * @default ""
         */
        enumTypeString?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<StringItemDtoListDictionaryMessageModel, any>({
        path: `/api/enum`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Enum
     * @name EnumKeyList
     * @summary 获取所有枚举Key
     * @request GET:/api/enum/key
     */
    enumKeyList: (params: RequestParams = {}) =>
      this.request<ItemDtoListMessageModel, any>({
        path: `/api/enum/key`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags File
     * @name FileUploadCreate
     * @summary 上传文件 (Auth)
     * @request POST:/api/file/upload
     * @secure
     */
    fileUploadCreate: (
      data: {
        files?: File[];
      },
      query?: {
        /** @default "" */
        path?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<AttachmentDownloadDtoListMessageModel, void>({
        path: `/api/file/upload`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags File
     * @name FileUploadSingleCreate
     * @summary 上传单个文件 (Auth)
     * @request POST:/api/file/upload/single
     * @secure
     */
    fileUploadSingleCreate: (
      data: {
        ContentType?: string;
        ContentDisposition?: string;
        Headers?: Record<string, string[]>;
        /** @format int64 */
        Length?: number;
        Name?: string;
        FileName?: string;
      },
      query?: {
        /** @default "" */
        path?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<AttachmentDownloadDtoMessageModel, void>({
        path: `/api/file/upload/single`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags File
     * @name FileDownloadDetail
     * @summary 下载文件 (Auth)
     * @request GET:/api/file/download/{id}
     * @secure
     */
    fileDownloadDetail: (id: string, params: RequestParams = {}) =>
      this.request<AttachmentDownloadDtoMessageModel, void>({
        path: `/api/file/download/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags File
     * @name FileDownloadPathList
     * @summary 直接通过路径下载地址 (Auth)
     * @request GET:/api/file/download/path
     * @secure
     */
    fileDownloadPathList: (
      query?: {
        path?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<AttachmentDownloadDtoMessageModel, void>({
        path: `/api/file/download/path`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags File
     * @name FileDownloadList
     * @summary 下载多文件 (Auth)
     * @request GET:/api/file/download
     * @secure
     */
    fileDownloadList: (data: string[], params: RequestParams = {}) =>
      this.request<AttachmentDownloadDtoListMessageModel, void>({
        path: `/api/file/download`,
        method: 'GET',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags GeneralCode
     * @name GeneralcodePageList
     * @summary 分页列表 (Auth)
     * @request GET:/api/generalcode/page
     * @secure
     */
    generalcodePageList: (
      query?: {
        classNames?: string[];
        category?: string;
        /** 分所id */
        officeId?: string;
        groupId?: string;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<GetGeneralCodeDtoPageModelMessageModel, void>({
        path: `/api/generalcode/page`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags GeneralCode
     * @name GeneralcodeListList
     * @summary 获取所有字典列表（排除内部使用标记字典项） (Auth)
     * @request GET:/api/generalcode/list
     * @secure
     */
    generalcodeListList: (
      query?: {
        classNames?: string[];
        category?: string;
        /** 分所id */
        officeId?: string;
        groupId?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<GetGeneralCodeDtoListMessageModel, void>({
        path: `/api/generalcode/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags GeneralCode
     * @name GeneralcodeTreeSelectCreate
     * @summary 获取字典树状下拉 (Auth)
     * @request POST:/api/generalcode/tree/select
     * @secure
     */
    generalcodeTreeSelectCreate: (data: GetGeneralCodeTreeInput, params: RequestParams = {}) =>
      this.request<ComboboxGroupDtoListMessageModel, void>({
        path: `/api/generalcode/tree/select`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags GeneralCode
     * @name GeneralcodeTreeListCreate
     * @summary 获取字典树状列表 (Auth)
     * @request POST:/api/generalcode/tree/list
     * @secure
     */
    generalcodeTreeListCreate: (data: GetGeneralCodeTreeInput, params: RequestParams = {}) =>
      this.request<GeneralCodeTreeDtoListMessageModel, void>({
        path: `/api/generalcode/tree/list`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags GeneralCode
     * @name GeneralcodeEditinfoDetail
     * @summary 获取字典编辑对象 (Auth)
     * @request GET:/api/generalcode/{id}/editinfo
     * @secure
     */
    generalcodeEditinfoDetail: (id: string, params: RequestParams = {}) =>
      this.request<CreateGeneralCodeInputMessageModel, void>({
        path: `/api/generalcode/${id}/editinfo`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * @description 注释
     *
     * @tags GeneralCode
     * @name GeneralcodeCreate
     * @summary 新增字典 (Auth)
     * @request POST:/api/generalcode
     * @secure
     */
    generalcodeCreate: (data: CreateGeneralCodeInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/generalcode`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags GeneralCode
     * @name GeneralcodeUpdate
     * @summary 修改数据字典 (Auth)
     * @request PUT:/api/generalcode/{id}
     * @secure
     */
    generalcodeUpdate: (id: string, data: CreateGeneralCodeInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/generalcode/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags GeneralCode
     * @name GeneralcodeDelete
     * @summary  (Auth)
     * @request DELETE:/api/generalcode/{id}
     * @secure
     */
    generalcodeDelete: (id: string, params: RequestParams = {}) =>
      this.request<BooleanMessageModel, void>({
        path: `/api/generalcode/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags GeneralCode
     * @name GeneralcodeNullifyUpdate
     * @summary 数据字典作废 (Auth)
     * @request PUT:/api/generalcode/{id}/nullify
     * @secure
     */
    generalcodeNullifyUpdate: (id: string, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/generalcode/${id}/nullify`,
        method: 'PUT',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags GeneralCode
     * @name GeneralcodeScopeUpdate
     * @summary 设置作用域 (Auth)
     * @request PUT:/api/generalcode/{id}/scope
     * @secure
     */
    generalcodeScopeUpdate: (
      id: string,
      query?: {
        /** @format int32 */
        scope?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<MessageModel, void>({
        path: `/api/generalcode/${id}/scope`,
        method: 'PUT',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags GeneralCode
     * @name GeneralcodeActivateUpdate
     * @summary 数据字典激活 (Auth)
     * @request PUT:/api/generalcode/{id}/activate
     * @secure
     */
    generalcodeActivateUpdate: (id: string, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/generalcode/${id}/activate`,
        method: 'PUT',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags GeneralCode
     * @name GeneralcodeLanguageCreate
     * @summary 增加多语言 (Auth)
     * @request POST:/api/generalcode/language
     * @secure
     */
    generalcodeLanguageCreate: (data: CreateGeneralCodeTextsInput, params: RequestParams = {}) =>
      this.request<StringMessageModel, void>({
        path: `/api/generalcode/language`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags GeneralCode
     * @name GeneralcodeSelectList
     * @summary 根据字典类型和所属分组获取字典value和text (Auth)
     * @request GET:/api/generalcode/select
     * @secure
     */
    generalcodeSelectList: (
      query?: {
        classNames?: string[];
        category?: string;
        /** 分所id */
        officeId?: string;
        groupId?: string;
        /**
         * 页码
         * @format int32
         */
        page?: number;
        /**
         * 数量
         * @format int32
         */
        size?: number;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderBy?: string;
        /** 排序规则， 默认根据创建时间排序 */
        toOrderByList?: OrderByModel[];
        /** 高级筛选 */
        whereInputs?: BaseWhereInput[];
      },
      params: RequestParams = {}
    ) =>
      this.request<ComboboxItemDtoListMessageModel, void>({
        path: `/api/generalcode/select`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * @description 注释
     *
     * @tags GeneralCode
     * @name GeneralcodeGroupsListCreate
     * @summary 获取字典分组列表 (Auth)
     * @request POST:/api/generalcode/groups/list
     * @secure
     */
    generalcodeGroupsListCreate: (data: GetGeneralCodeGroupsListInput, params: RequestParams = {}) =>
      this.request<GeneralCodeGroupsDtoListMessageModel, void>({
        path: `/api/generalcode/groups/list`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags GeneralCode
     * @name GeneralcodeGroupsEditinfoDetail
     * @summary 获取字典分组编辑对象 (Auth)
     * @request GET:/api/generalcode/groups/{id}/editinfo
     * @secure
     */
    generalcodeGroupsEditinfoDetail: (id: string, params: RequestParams = {}) =>
      this.request<CreateGeneralCodeGroupsInputMessageModel, void>({
        path: `/api/generalcode/groups/${id}/editinfo`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * @description 注释
     *
     * @tags GeneralCode
     * @name GeneralcodeGroupsCreate
     * @summary 添加字典分组 (Auth)
     * @request POST:/api/generalcode/groups
     * @secure
     */
    generalcodeGroupsCreate: (data: CreateGeneralCodeGroupsInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/generalcode/groups`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * @description 注释
     *
     * @tags GeneralCode
     * @name GeneralcodeGroupsUpdate
     * @summary 修改字典分组 (Auth)
     * @request PUT:/api/generalcode/groups/{id}
     * @secure
     */
    generalcodeGroupsUpdate: (id: string, data: CreateGeneralCodeGroupsInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/generalcode/groups/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Img
     * @name ImgImagesDownPicList
     * @summary 下载图片（支持中文字符） (Auth)
     * @request GET:/api/img/images/down/pic
     * @secure
     */
    imgImagesDownPicList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/img/images/down/pic`,
        method: 'GET',
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags Img
     * @name ImgImagesUploadPicCreate
     * @summary 上传图片,多文件 (Auth)
     * @request POST:/api/img/images/upload/pic
     * @secure
     */
    imgImagesUploadPicCreate: (
      data: {
        Files: File[];
        Foo?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<StringMessageModel, void>({
        path: `/api/img/images/upload/pic`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Language
     * @name LanguagesList
     * @summary 获取系统支持语言列表
     * @request GET:/api/languages
     */
    languagesList: (params: RequestParams = {}) =>
      this.request<LanguageDtoListMessageModel, any>({
        path: `/api/languages`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Language
     * @name LanguagesTextList
     * @summary 获取所有多语言文本
     * @request GET:/api/languages/text
     */
    languagesTextList: (
      query?: {
        languageCode?: string;
        /**
         * 根据模块获取
         * @default ""
         */
        module?: string;
        /**
         * @format int32
         * @default 2
         */
        source?: 0 | 1 | 2;
      },
      params: RequestParams = {}
    ) =>
      this.request<ItemDtoListMessageModel, any>({
        path: `/api/languages/text`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SpellCheck
     * @name SpllcheckCheckworldList
     * @summary 单词检查 (Auth)
     * @request GET:/api/spllcheck/checkworld
     * @secure
     */
    spllcheckCheckworldList: (
      query?: {
        /** 单词 */
        word?: string;
        /**
         * 语言（可选）
         * @default "en_US"
         */
        lang?: string;
        /**
         * 正确建议（可选）
         * @default true
         */
        isSuggest?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<SpellCheckDtoMessageModel, void>({
        path: `/api/spllcheck/checkworld`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SpellCheck
     * @name SpllcheckCheckworldsCreate
     * @summary 多个单词检查 (Auth)
     * @request POST:/api/spllcheck/checkworlds
     * @secure
     */
    spllcheckCheckworldsCreate: (data: GetCheckWordInput, params: RequestParams = {}) =>
      this.request<SpellCheckDtoListMessageModel, void>({
        path: `/api/spllcheck/checkworlds`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags SpellCheck
     * @name SpllcheckChecksentenceCreate
     * @summary 句子语法检查 (Auth)
     * @request POST:/api/spllcheck/checksentence
     * @secure
     */
    spllcheckChecksentenceCreate: (data: GetSentenceInput, params: RequestParams = {}) =>
      this.request<SpellCheckDtoListMessageModel, void>({
        path: `/api/spllcheck/checksentence`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags UserCustomSetting
     * @name CommonUsercustomsettingList
     * @summary 获取用户所有配置 (Auth)
     * @request GET:/api/common/usercustomsetting
     * @secure
     */
    commonUsercustomsettingList: (params: RequestParams = {}) =>
      this.request<UserCustomSettingDtoListMessageModel, void>({
        path: `/api/common/usercustomsetting`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags UserCustomSetting
     * @name CommonUsercustomsettingCreate
     * @summary 更新或修改用户配置 (Auth)
     * @request POST:/api/common/usercustomsetting
     * @secure
     */
    commonUsercustomsettingCreate: (data: CreateUserCustomSettingInput, params: RequestParams = {}) =>
      this.request<MessageModel, void>({
        path: `/api/common/usercustomsetting`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags UserCustomSetting
     * @name CommonUsercustomsettingDicList
     * @summary 获取当前用户所有配置的字典 (Auth)
     * @request GET:/api/common/usercustomsetting/dic
     * @secure
     */
    commonUsercustomsettingDicList: (params: RequestParams = {}) =>
      this.request<StringObjectDictionaryMessageModel, void>({
        path: `/api/common/usercustomsetting/dic`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags UserCustomSetting
     * @name CommonUsercustomsettingInfoList
     * @summary 获取用户配置详情 (Auth)
     * @request GET:/api/common/usercustomsetting/info
     * @secure
     */
    commonUsercustomsettingInfoList: (
      query?: {
        module?: string;
        /** @format int32 */
        settingType?: 0 | 1;
      },
      params: RequestParams = {}
    ) =>
      this.request<UserCustomSettingDtoMessageModel, void>({
        path: `/api/common/usercustomsetting/info`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      })
  };
  configuration = {
    /**
     * No description
     *
     * @tags FileConfiguration
     * @name ConfigurationList
     * @summary  (Auth)
     * @request GET:/configuration
     * @secure
     */
    configurationList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/configuration`,
        method: 'GET',
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags FileConfiguration
     * @name ConfigurationCreate
     * @summary  (Auth)
     * @request POST:/configuration
     * @secure
     */
    configurationCreate: (data: FileConfiguration, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/configuration`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params
      })
  };
  images = {
    /**
     * No description
     *
     * @tags Img
     * @name DownBmdList
     * @request GET:/images/down/bmd
     */
    downBmdList: (
      query?: {
        filename?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/images/down/bmd`,
        method: 'GET',
        query: query,
        ...params
      })
  };
  outputcache = {
    /**
     * No description
     *
     * @tags OutputCache
     * @name OutputcacheDelete
     * @summary  (Auth)
     * @request DELETE:/outputcache/{region}
     * @secure
     */
    outputcacheDelete: (region: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/outputcache/${region}`,
        method: 'DELETE',
        secure: true,
        ...params
      })
  };
}

import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import style from './MenuHeader.module.less'
import IconFont from '@/components/iconfont'
import { Button, Dropdown, Input, Tooltip } from 'antd'
import type { GetRef } from 'antd'
import { SearchOutlined } from '@ant-design/icons'

type InputRefType = GetRef<typeof Input>

interface MenuHeaderProps {
  groupName?: string
  isSearch?: boolean
  setIsSearch?: Dispatch<SetStateAction<boolean>>
  setSearch?: Dispatch<SetStateAction<string>>
  search?: string
}

const MenuHeader: React.FC<MenuHeaderProps> = (props) => {
  const inputRef = useRef<InputRefType>(null)
  // const [opened, setOpened] = useState(false)\
  const { setIsSearch, isSearch = false, search, setSearch } = props

  useEffect(() => {
    if (isSearch) {
      inputRef.current?.focus()
    }
  }, [isSearch])

  const handleBlur = () => {
    if (!search && isSearch) {
      setIsSearch?.(false)
    }
  }

  const items = [
    {
      key: '1',
      label: '1st menu item',
    },
    {
      key: '2',
      label: '2nd menu item (disabled)',
    },
    {
      key: '3',
      label: '3rd menu item (disabled)',
    },
    {
      key: '4',
      label: 'a danger item',
    },
  ]

  return (
    <div className={style['app-sidebar__list__header']}>
      {isSearch ? (
        <div className={style['app-sidebar__list__header__text']}>
          <Input
            ref={inputRef}
            onBlur={handleBlur}
            onChange={(e) => setSearch?.(e.target.value)}
            value={search}
            placeholder="搜索"
            variant="filled"
            allowClear
          />
        </div>
      ) : (
        <div onClick={() => setIsSearch?.(true)} className={style['app-sidebar__list__header__text']}>
          {props?.groupName}
        </div>
      )}

      {/*<div className={style['app-sidebar__list__header__toggle']}>*/}
      {/*  <Tooltip title="快速创建">*/}
      {/*    <Dropdown menu={{ items }} trigger={['click']} placement="bottomLeft">*/}
      {/*      <Button*/}
      {/*        type="text"*/}
      {/*        icon={*/}
      {/*          <span className="ant-btn-icon">*/}
      {/*            <IconFont color="inherit" fill="currentColor" name="kuaisuchuangjian-xianxing" size={20} />*/}
      {/*          </span>*/}
      {/*        }*/}
      {/*      />*/}
      {/*    </Dropdown>*/}
      {/*  </Tooltip>*/}
      {/*</div>*/}

      {/*<div className={style['app-sidebar__list__header__toggle']}>*/}
      {/*  {*/}
      {/*    opened && (*/}
      {/*      <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 24 24">*/}
      {/*        <path d="M0 9h24v6h-24z"/>*/}
      {/*      </svg>*/}
      {/*    )*/}
      {/*  }*/}
      {/*  {*/}
      {/*    !opened && (*/}
      {/*      <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 24 24">*/}
      {/*        <path d="M24 9h-9v-9h-6v9h-9v6h9v9h6v-9h9z"/>*/}
      {/*      </svg>*/}
      {/*    )*/}
      {/*  }*/}
      {/*</div>*/}
    </div>
  )
}

export default React.memo(MenuHeader)

/**
 * 通用公共接口
 * https://api.vnext.ailinkedlaw.com/index.html?urls.primaryName=%E9%80%9A%E7%94%A8%E5%85%AC%E5%85%B1%E6%8E%A5%E5%8F%A3
 */
import { Api } from './GeneralPublicInterfaceApi';
// import Request from './request'
import request from '@/api/request';

const api = new Api();
api.instance = request;
// AxiosInstance

const apiCommon = api.api;

export default apiCommon;

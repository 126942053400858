import {FC, useMemo} from 'react'
import { Form, FormItemProps } from 'antd'

import { useShallow } from 'zustand/react/shallow'
import useBasicSettingsStore from '@/store/useBasicSettingsStore'
import './index.less'

interface BitzFormItemProps extends FormItemProps {
  children?: any
  label?: any
}

const BitzFormItem: FC<BitzFormItemProps> = ({ children, label, ...props }) => {
  const dialogConfig = useBasicSettingsStore(useShallow((state) => state?.dialogConfig))

  const newProps: any = { ...props, ...label }

  const getPropsRulesRequired = () => {
    let isTrue: any
    if (props?.rules && props?.rules?.length) {
      const requiredTrue = props.rules.filter((item: any) => item?.required === true)

      if (requiredTrue.length > 0) {
        isTrue = true
      } else {
        isTrue = false
      }
    } else {
      isTrue = false
    }
    return isTrue
  }

  const getRequiredDisplay = () => {
    return `${dialogConfig?.textTagAlignmentWay}requiredDisplay${dialogConfig?.requiredDisplay}Block`
  }

  const getLabel = (label: any) => {
    if (dialogConfig?.textTagAlignmentWay != 'symmetry') {
      return label
    } else {
      return <span className="disperseText">{label}</span>
    }
  }

  const showRequire = useMemo(() => {
    if (props?.hidden) return true
    return dialogConfig?.isRequiredShow && !getPropsRulesRequired()
  }, [dialogConfig?.isRequiredShow, getPropsRulesRequired(), props?.hidden])

  return (
    <div className={`bitzFormItemBlock ${getRequiredDisplay()}`}>
      <Form.Item
        hidden={showRequire}
        label={getLabel(label)}
        {...newProps}
      >
        {children}
      </Form.Item>
    </div>
  )
}

export default BitzFormItem

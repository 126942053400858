import { css } from '@emotion/css'
import { theme } from 'antd'

const { getDesignToken } = theme

const globalToken = getDesignToken()

export const filterTagItem = css`
  // width: 100%;
  // min-width: 160px;
  max-width: 200px;
  display: flex;
  align-items: center;
  flex-grow: 0;
  white-space: nowrap;
  height: 100%;
  padding: 5px 15px;
  border-radius: 4px;
  font-size: 12px;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  // color: rgba(50, 49, 48, 0.88);
  // box-shadow: 0 2px 0 rgba(50, 49, 48, 0.02);

  box-sizing: border-box;
  margin-left: 10px;
  &:first-child {
    margin-left: 0;
  }
  .filterTagLable {
    flex-shrink: 0;
    display: block;
    // color: ${globalToken.colorTextQuaternary};
  }
  .filterTagClose {
    flex-shrink: 0;
    margin-left: 5px;
  }
  .filterTagValue {
    flex-shrink: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &:hover {
    color: ${globalToken.colorPrimary};
    border-color: ${globalToken.colorPrimary};
    .filterTagValue {
      color: ${globalToken.colorPrimary};
    }
  }
`

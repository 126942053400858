import { useEffect, useState } from 'react'
import { useInterval } from 'ahooks'
import { CaretRightOutlined, PauseOutlined } from '@ant-design/icons'
import { Flex, theme } from 'antd'
import { css, cx } from '@emotion/css'

/**
 * - description: 格式化毫秒数
 * - param {number} duration 毫秒数
 * - return `天.时:分:秒`
 */
function formatMilliseconds(duration: number) {
  if (isNaN(duration)) return '00:00:00'
  const days = Math.floor(duration / (1000 * 3600 * 24))
  duration %= 1000 * 3600 * 24
  const hours = Math.floor(duration / (1000 * 3600))
  duration %= 1000 * 3600
  const minutes = Math.floor(duration / (1000 * 60))
  duration %= 1000 * 60
  const seconds = Math.floor(duration / 1000)
  const padded = (num) => (num < 10 ? '0' + num : num)
  if (days <= 0) return `${padded(hours)}:${padded(minutes)}:${padded(seconds)}`
  return `${days}.${padded(hours)}:${padded(minutes)}:${padded(seconds)}`
}

interface Timer {
  option?: {
    [key: string]: any
    duration?: number
    id?: number
    start?: boolean
    edit?: boolean
  }
  pause?: (id?: number) => void
  start?: (id?: number) => void
  showTime?: boolean
  type?: 'default' | 'primary'
  onChange?: (duration: number) => void
  style?: React.CSSProperties
  value?: number
}

const timeBtnClass = css`
  width: 32px;
  height: 32px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  &:hover {
    filter: brightness(1.2);
  }
`

export const Timer = ({ option, pause, start, showTime = true, type, onChange, style }: Timer) => {
  const duration = option?.duration || 0

  const { token } = theme.useToken()
  const [time, setTime] = useState(duration)
  const [delay, setDelay] = useState<number | undefined>(option?.start ? 1000 : void 0)

  useInterval(
    () => {
      setTime(time + 1000)
      onChange?.(time + 1000)
    },
    delay, // 秒为单位
    { immediate: true },
  )

  const onPause = () => {
    setDelay(void 0)
    pause?.()
  }

  const onStart = () => {
    setDelay(1000)
    start?.()
  }

  useEffect(() => {
    setDelay(option?.start ? 1000 : void 0)
  }, [option?.start])

  useEffect(() => {
    setTime(duration)
  }, [duration])

  const wrapClass = css`
    height: 32px;
    border-radius: 4px;
    border: 1px solid ${type === 'default' ? '#86909C' : token.colorPrimary};
    display: inline-flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  `

  const bgcClass = css`
    background-color: ${type === 'default' ? '#86909C' : token.colorPrimary};
    border-color: ${type === 'default' ? '#86909C' : token.colorPrimary};
    pointer-events: ${type === 'default' ? 'none' : 'auto'};
  `

  const timerBtn = () => {
    return delay === 1000 ? (
      <PauseOutlined className={cx(timeBtnClass, bgcClass)} onClick={onPause} />
    ) : (
      <CaretRightOutlined className={cx(timeBtnClass, bgcClass)} onClick={onStart} />
    )
  }

  return (
    <div className={wrapClass} style={style}>
      {showTime && (
        <div
          className={css`
            color: #1d2129;
            font-size: 12px;
            font-family: Source Han Sans;
            flex: 1;
            padding: 2px 8px;
            white-space: nowrap;
          `}
        >
          {formatMilliseconds(time)}
        </div>
      )}
      {timerBtn()}
    </div>
  )
}

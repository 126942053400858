import React, { useEffect, useMemo, useState } from 'react';
import style from './MenuItem.module.less';
import clx from 'classnames';
import { Typography } from 'antd';
import useMergedState from '@/hooks/useMergedState';
import Iconfont from '@/components/iconfont';

export interface MenuItemProps {
  // 层级
  menuLevel?: number;
  item?: any;
  selectedKeys?: string[];

  open?: boolean;
  defaultOpen?: boolean;
  onOpen?: (open: boolean) => void;
}

const MenuItem: React.FC<MenuItemProps> = (props) => {
  const { menuLevel = 1, item, selectedKeys } = props;

  const [open, setOpen] = useMergedState<boolean>(() => !!props?.defaultOpen, {
    value: props?.open,
    onChange: props?.onOpen
  });

  const onToggle = (e) => {
    e.preventDefault();
    if (item?.children) {
      setOpen(!open);
    } else {
      item?.onClick?.();
    }
  };

  const active = useMemo(() => {
    const res = selectedKeys?.includes(item?.key);
    if (res && item?.children?.length) {
      setOpen(true);
    }
    return res;
  }, [item?.key, selectedKeys]);

  const Component = useMemo(() => {
    if (item.url) {
      return 'a';
    }
    return 'div';
  }, [item.url]);

  const ComponentProps = useMemo(() => {
    if (item.url) {
      return {
        href: item.url
      };
    }
    return null;
  }, [item.url]);

  const activeIcon = useMemo(() => (active ? item?.activeIconName : item?.iconName), [item, active]);

  return menuLevel > 2 ? null : (
    <div
      className={clx(style['app-sidebar__list__item'], {
        [style['app-sidebar__list__item--opened']]: open
        // [style['app-sidebar__list__item--active-opened']]: open && active,
      })}
    >
      <Component
        {...ComponentProps}
        onClick={onToggle}
        className={clx(style['app-sidebar__list__item__inner'], {
          [style['app-sidebar__list__item__inner--active__children']]: active && item?.children,
          [style['app-sidebar__list__item__inner--active']]: active && !item?.children
        })}
      >
        {item?.iconName && (
          <div className={style['app-sidebar__list__item__icon']}>
            <Iconfont name={activeIcon} size={20} color="inherit" fill="currentColor" />
          </div>
        )}
        <div className={style['app-sidebar__list__item__text']}>
          <Typography.Text ellipsis={{ tooltip: item?.title }}>{item?.title}</Typography.Text>
        </div>
        {item?.children?.length && (
          <div className={style['app-sidebar__list__item__caret']}>
            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 24 24">
              <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" fill="currentColor" />
            </svg>
          </div>
        )}
      </Component>
      {item?.children && (
        <div className={style['app-sidebar__list__item__children']}>
          {item?.children?.map((v) => <MenuItem key={v.key} item={v} menuLevel={menuLevel + 1} selectedKeys={selectedKeys} />)}
        </div>
      )}
    </div>
  );
};

export default React.memo(MenuItem);

import { FC, memo, useContext, useEffect, useMemo } from 'react'
import { Form, FormItemProps } from 'antd'
import { MyContext } from '../../BitzQueryFilterContext'
import { isArray } from 'lodash-es'

export interface BQFItemProps extends FormItemProps {
  /**
   * 隐藏 label 默认隐藏
   */
  labelHidden?: boolean
}

const BQFItem: FC<BQFItemProps> = (props) => {
  const { state, dispatch } = useContext(MyContext)
  const { labelHidden = false, label, ...rest } = props

  const name = useMemo(() => {
    if (isArray(props.name)) {
      return props.name[props.name?.length - 1]
    }
    return props.name
  }, [props.name])

  useEffect(() => {
    if (name) {
      dispatch({
        type: 'update',
        name: name,
        label: label,
      })
    }
    return () => {
      dispatch({
        type: 'update',
        name: name,
        label: label,
        value: '',
      })
    }
  }, [name])

  return (
    <Form.Item label={labelHidden ? null : label} {...rest}>
      {props?.children}
    </Form.Item>
  )
}

export default memo(BQFItem)

import { FC, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider as NiceProvider } from '@ebay/nice-modal-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AliveScope } from 'react-activation';
// 图片预览组件
import 'react-photo-view/dist/react-photo-view.css';

import { useConfigProvider } from '@/config/configProvider';
import AuthRouter from '@/accessComponents/AuthRouter';
// import ScrollTop from '@/accessComponents/ScrollTop'
import BitzAntApp from '@/components/BitzAntApp';
import { BitzConfigProvider } from 'bitz-react-admin-ui';
import { AppRouter } from '@/router';
import { BitzConfigProviderContextProps } from 'bitz-react-admin-ui/dist/components/BitzConfigProvider/context';
import useBasicSettingsStore from './store/useBasicSettingsStore';

import { disable as disableDarkMode, enable as enableDarkMode } from 'darkreader';
import CheckoutVersion from './components/CheckoutVersion';
import { RecoilEnv, RecoilRoot } from 'recoil';
// import { ClickToComponent } from 'click-to-react-component';
import { TimerProvider } from './components/BitzTimerContext';
import { MantineProvider, createTheme } from '@mantine/core';

import './utils/dayjs-facade.js';
import { ENV } from './constants';
import { ClickToComponent } from './v2/core/click-to-react-component/src';

import { run } from './utils';

RecoilEnv.RECOIL_DUPLICATE_ATOM_KEY_CHECKING_ENABLED = false;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
      retry: 0,
      staleTime: 0,
      gcTime: 0
    }
  }
});

const theme = createTheme({
  /** Put your mantine theme override here */
});

const App: FC = () => {
  const { themeConfig } = useBasicSettingsStore();
  const configProvider = useConfigProvider();

  const configContext: BitzConfigProviderContextProps = {
    Table: { toolbar: ['export', 'refresh', 'fullscreen', 'config'] }
  };

  useEffect(() => {
    const theme = themeConfig?.theme;
    setTimeout(() => {
      if (theme === 'dark') {
        enableDarkMode({
          brightness: 100,
          contrast: 90,
          sepia: 10
        });
      } else {
        disableDarkMode();
      }
    });
  }, [themeConfig?.theme]);

  return (
    <RecoilRoot>
      {import.meta.env.VITE_ENV === ENV.DEV && <ClickToComponent editor={import.meta.env.VITE_EDITOR} />}
      <BitzConfigProvider {...configProvider} context={configContext}>
        <BitzAntApp>
          <QueryClientProvider client={queryClient}>
            <TimerProvider>
              <MantineProvider theme={theme}>
                <BrowserRouter>
                  <NiceProvider>
                    <AuthRouter title="EF-SNAKE">
                      <CheckoutVersion />
                      <AliveScope>
                        <AppRouter />
                      </AliveScope>
                    </AuthRouter>
                  </NiceProvider>
                </BrowserRouter>
              </MantineProvider>
            </TimerProvider>
          </QueryClientProvider>
        </BitzAntApp>
      </BitzConfigProvider>
    </RecoilRoot>
  );
};
export default App;

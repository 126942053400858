import React from 'react';
import './index.less';
import clx from 'classnames';
import { Tooltip } from 'antd';
import MiniSidebarItem from './MiniSidebarItem';
import IconFont from '@/components/iconfont';
import { Link, useMatches } from 'react-router-dom';
import BitzMenuPopover from '@/components/BitzMenuPopover';
import { Loguot } from '@/utils/loguot';
import { show } from '@ebay/nice-modal-react';
import { BitzConfirmModal } from 'bitz-react-admin-ui';
import { BzPermission } from '@/v2/components';

export interface LayoutMiniSidebarProps {
  items?: any[];
  selectedKeys?: string[];
}

const LayoutMiniSidebar: React.FC<LayoutMiniSidebarProps> = (props) => {
  const handleLoguot = () => {
    show(BitzConfirmModal, {
      okType: 'primary',
      okButtonProps: {
        danger: true
      },
      modalText: '您确定要注销当前账号吗？',
      onOk: async () => {
        Loguot();
      }
    });
  };

  return (
    <div className="app-layout-mini-sidebar">
      <div className="app-relative" style={{ flex: '1 1 1e-09px', boxSizing: 'border-box' }}>
        <div className="app-a-box app-scrollbar-fix">
          <div className="app-mini-sidebar__list">
            <div className={clx('app-mini-sidebar__list__item')} style={{ height: 60 }}>
              <BitzMenuPopover>
                <div className={clx('app-mini-sidebar__list__item__pill')}>
                  <IconFont color="inherit" fill="currentColor" name="ic_fluent_grid_dots_24_filled" size={24} />
                </div>
              </BitzMenuPopover>
            </div>

            {props?.items?.map((item, index) => {
              return <MiniSidebarItem item={item} key={index} index={index} selectedKeys={props?.selectedKeys} />;
            })}
          </div>
        </div>
      </div>
      <div className="app-mini-sidebar__list">
        <BzPermission auth={'ExportManage'}>
          <div className={clx('app-mini-sidebar__list__item')}>
            <Tooltip title="导出中心" placement="right">
              <Link to="/ExportCenter/ExportManage" className={clx('app-mini-sidebar__list__item__pill')}>
                <IconFont color="inherit" fill="currentColor" name="daochuzhongxin-xianxing" size={24} />
              </Link>
            </Tooltip>
          </div>
        </BzPermission>

        <BzPermission auth={'Setting'}>
          <div className={clx('app-mini-sidebar__list__item')}>
            <Tooltip title="设置" placement="right">
              <Link to="/Administration/Setting" className={clx('app-mini-sidebar__list__item__pill')}>
                <IconFont color="inherit" fill="currentColor" name="settings_24_regular" size={24} />
              </Link>
            </Tooltip>
          </div>
        </BzPermission>

        <div className={clx('app-mini-sidebar__list__item')}>
          <Tooltip title="注销" placement="right">
            <div onClick={handleLoguot} className={clx('app-mini-sidebar__list__item__pill')}>
              <IconFont color="inherit" fill="currentColor" name="ic_fluent_sign_out_24_regular" size={24} />
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default React.memo(LayoutMiniSidebar);

import { FC, useMemo } from 'react'
import { Flex, SelectProps, theme, Typography } from 'antd'
import BitzQueryFilter2 from '@/components/BitzQueryFilter2'
import { InfoCircleOutlined } from '@ant-design/icons'
import { useQuery } from '@tanstack/react-query'
import api from '@/services/Web'
import { getShowSearch } from '@/utils/mobile'
import { BitzSelect } from 'bitz-react-admin-ui'

interface BnListSelectProps
  extends Omit<SelectProps, 'loading' | 'options' | 'fieldNames' | 'maxTagCount' | 'optionFilterProp' | 'notFoundContent' | 'allowClear'> {
  /** 枚举key，首字母小写 */
  enumKey: any
  hideFluent?: boolean
}

/** enum下拉列表 */
const BnListSelect: FC<BnListSelectProps> = ({ enumKey, hideFluent = false, ...rest }) => {
  const { token } = theme.useToken()

  const loadAgainStyle = {
    paddingBlock: 40,
    cursor: 'pointer',
  }

  /** 获取enum列表 */
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: ['BnListSelect', enumKey],
    queryFn: ({ signal }) => {
      return api.webSysRoleAllList({ roleTypes: enumKey }, { signal })
    },
    retry: false,
    networkMode: 'always',
    select: data => data?.response || []
  })


  return (
    <>
      {!hideFluent && (
        <BitzQueryFilter2.Select
          placeholder="请选择"
          loading={isLoading}
          options={data}
          maxTagCount="responsive"
          fieldNames={{
            label: 'name',
            value: 'id',
          }}
          notFoundContent={
            isError ? (
              <Flex vertical align="center" style={loadAgainStyle} onClick={() => refetch()}>
                <InfoCircleOutlined style={{ fontSize: 20, color: token.colorPrimary }} />
                <Typography.Text type="danger">{'加载失败，点击重试'}</Typography.Text>
              </Flex>
            ) : undefined
          }
          optionFilterProp="name"
          allowClear
          showSearch={getShowSearch()}
          {...rest}
        />
      )}
      {hideFluent && (
        <BitzSelect
          drawerHeight={400}
          placeholder="请选择"
          loading={isLoading}
          maxTagCount="responsive"
          options={data}
          fieldNames={{
            label: 'name',
            value: 'id',
          }}
          notFoundContent={
            isError ? (
              <Flex vertical align="center" style={loadAgainStyle} onClick={() => refetch()}>
                <InfoCircleOutlined style={{ fontSize: 20, color: token.colorPrimary }} />
                <Typography.Text type="danger">{'加载失败，点击重试'}</Typography.Text>
              </Flex>
            ) : undefined
          }
          optionFilterProp="name"
          allowClear
          showSearch={getShowSearch()}
          {...rest}
        />
      )}
    </>
  )
}

export default BnListSelect

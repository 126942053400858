import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { IconNames } from '@/components/iconfont'

export interface CollectType {
  name: string
  url: string
  icon: IconNames
  color: string
}

interface State {
  collect: CollectType[]
}

interface Action {
  addCollect: (collect: CollectType) => void
  removeCollect: (url: string) => void
}

type Store = State & Action

const useCollectStore = create<Store>()(
  devtools(
    persist(
      (set) => ({
        collect: [],
        addCollect: (collect) =>
          set((state) => ({
            collect: [...state.collect, collect],
          })),
        removeCollect: (url: string) => {
          set((state) => ({
            collect: state.collect?.filter((v) => v.url !== url),
          }))
        },
      }),
      {
        // 本地储存名称
        name: 'BitzContent_hader_Collect',
      },
    ),
    {
      // redux 调试工具名称
      name: 'BitzContent_hader_Collect',
    },
  ),
)

export default useCollectStore

import { isEmpty } from 'lodash-es'

import { FC, useMemo } from 'react'

import { Space, Typography, Tooltip } from 'antd'

import { getValueOrThrow } from '../../../utils'

const { Text } = Typography

interface RuleMatchColTextProps {
  rule: string, // 规则：十二条利冲规则
  csrRuleText: string, // 规则：十二条利冲规则文字
}

const RuleMatchColText: FC<RuleMatchColTextProps> = ({ rule, csrRuleText }) => {
  const typeVal = useMemo(() => {
    const data: any = { type: '', text: '' }
    if(['Rule 1', 'Rule 2', 'Rule 3', 'Rule 4', 'Rule 5', 'Rule 6'].includes(rule)) {
      data.type = 'success'
      data.text = '无利冲'
    }
    if(['Rule 7', 'Rule 8', 'Rule 9', 'Rule 10', 'Rule 11', 'Rule 12'].includes(rule)) {
      data.type = 'danger'
      data.text = '需要确认'
    }
    return data
  }, [rule, csrRuleText])

  return (
    <>
      {isEmpty(rule) ? (
        getValueOrThrow(rule)
      ) : (
        <Tooltip title={csrRuleText}>
          <Space direction="vertical" size={0}>
            <Text>{rule}</Text>
            <Text type={typeVal.type}>{typeVal.text}</Text>
          </Space>
        </Tooltip>
      )}
    </>
  )
}

export default RuleMatchColText

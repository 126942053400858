import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import routes from './config';
import SuspenseLoading from '@/components/SuspenseLoading';
import { PageManageProvider } from '@/providers/PageManageProvider';
import { map } from 'lodash-es';
import { permissionDB } from '@/v2/store/store';
import { RouteGuard } from './RouteGuard';

// eslint-disable-next-line no-constant-condition
const Router = BrowserRouter;

export const AppRouter = (): JSX.Element => {
  // 获取用户权限

  return (
    // <Router></Router>
    <Routes>
      {map(routes, (route) => {
        /**
         * route.component === 'object'  说明是动态加载的路由 lazy
         */
        if (typeof route.component === 'object') {
          const Component = route.component as React.LazyExoticComponent<() => JSX.Element>;
          return (
            <Route
              path={route.path}
              key={route.name}
              element={
                <div style={{ height: '100vh', width: '100%' }}>
                  <SuspenseLoading>
                    <Component />
                  </SuspenseLoading>
                </div>
              }
            />
          );
        } else {
          if (route?.name === 'admin') {
            return (
              <Route
                path={route.path}
                key={route.name}
                element={
                  <PageManageProvider>
                    {/* @ts-ignore */}
                    <route.component route={route} />
                  </PageManageProvider>
                }
              />
            );
          }

          return (
            <Route
              path={route.path}
              key={route.name}
              // @ts-ignore
              element={<route.component route={route} />}
            />
          );
        }
      })}
    </Routes>
  );
};

/*
 * # Title: index Component
 * - Description:
 * - Date: 2024-03-01
 * - LastEditors: liujin liujin@elinklaw.com
 * - LastEditTime: 2024-03-01 16:16:59
 */
import { App } from 'antd';
import Static from './Static';

const index = ({ children }) => {
  return (
    <App component={false}>
      <Static />
      {children}
    </App>
  );
};

export default index;

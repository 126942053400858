import { ReactNode, FC, useMemo } from 'react';
import useMergedState from '@/hooks/useMergedState';
import Sidenav, { SidenavProps } from '../Sidenav';
import clx from 'classnames';
import './index.less';

export interface SidenavContainerProps {
  sidenav?: ReactNode;
  children?: React.ReactNode;
  // defaultWidth?: number;
  isOverlay?: boolean;
  opened?: boolean;
  onOpened?: (opened: boolean) => void;
  defaultOpened?: boolean;
  direction?: 'left' | 'right' | 'top' | 'bottom';
  mode?: 'side' | 'overlay';
  hoverAble?: boolean;
  defaultWidth?: number;
  width?: number;
  onWidth?: (width: number) => void;
  minDimension?: number;
  sidenavProps?: Omit<SidenavProps, 'width' | 'setWidth' | 'opened' | 'setOpened' | 'hoverAble'>;
}

const SidenavContainer: FC<SidenavContainerProps> = (props) => {
  const { isOverlay = false, hoverAble = false, direction = 'left', mode = 'side', minDimension = 20 } = props;

  // const [width, setWidth] = useState(props?.defaultWidth || 0);

  const [width, setWidth] = useMergedState<number>(() => props?.defaultWidth ?? 200, {
    value: props?.width,
    onChange: props?.onWidth
  });

  const [opened, setOpened] = useMergedState<boolean>(() => props?.defaultOpened ?? true, {
    value: props?.opened,
    onChange: props?.onOpened
  });

  const onCloseAll = () => {
    setOpened(false);
  };

  const contentMargins = useMemo(() => {
    const contentMargins: any = {
      top: '0',
      right: '0',
      left: '0',
      bottom: '0'
    };
    const calcWidthMargin = `${opened ? width : hoverAble ? minDimension : 0}px`;
    const calcHeightMargin = `${opened ? width : hoverAble ? minDimension : 0}px`;
    if (direction === 'right') {
      if (mode === 'side') {
        contentMargins.right = calcWidthMargin;
      } else {
        contentMargins.right = `0px`;
      }
    }
    if (direction === 'left') {
      if (mode === 'side') {
        contentMargins.left = calcWidthMargin;
      } else {
        contentMargins.left = `0px`;
      }
    }
    if (direction === 'top') {
      if (mode === 'side') {
        contentMargins.top = calcHeightMargin;
      } else {
        contentMargins.top = `0px`;
      }
    }
    if (direction === 'bottom') {
      if (mode === 'side') {
        contentMargins.bottom = calcHeightMargin;
      } else {
        contentMargins.bottom = `0px`;
      }
    }

    return contentMargins;
  }, [opened, width, hoverAble, direction]);

  return (
    <div
      className={clx('app-sidenav-container app-sidenav-container--opened', {
        'app-sidenav-container--side ': mode === 'side',
        'app-sidenav-container--opened': opened // overlayVisible
      })}
    >
      <div
        id="app-layout-content"
        className="app-sidenav-container__content"
        style={{
          width: '100%',
          overflow: 'hidden',
          // marginLeft: opened ? width : 20,
          marginTop: contentMargins.top,
          marginRight: contentMargins.right,
          marginBottom: contentMargins.bottom,
          marginLeft: contentMargins.left
        }}
      >
        {props?.children}
      </div>
      {isOverlay && (
        <div
          className={clx('app-sidenav-container__overlay', {
            'app-sidenav-container__overlay--visible': opened
          })}
          style={{ opacity: 1 }}
          onClick={onCloseAll}
        ></div>
      )}

      {props?.sidenav && (
        <Sidenav width={width} setWidth={setWidth} opened={opened} setOpened={setOpened} hoverAble={hoverAble} direction={direction} {...props.sidenavProps}>
          {props?.sidenav}
        </Sidenav>
      )}
    </div>
  );
};

export default SidenavContainer;

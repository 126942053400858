import { WidgetModule } from '@/constants';
import apiWeb from '@/services/Web';

/**
 * 状态流对应的api地址
 */
export const FlowTypeApiMap = {
  MyList: 'api/web/financial/billing/financialbilling/getmypage',
  Manage: 'api/web/financial/billing/financialbilling/getpage',
  Audit: 'api/web/financial/billing/financialbilling/getfinancialbillingauditpage'
};

/**
 * 账单模块与工作流状态映射关系
 */
export const BillModuleFlowTypeMap = {
  [WidgetModule.BillMyList]: 'MyList',
  [WidgetModule.BillManage]: 'Manage',
  [WidgetModule.BillAudit]: 'Audit'
};

/**
 * 账单模块与请求映射关系
 */
export const BillModuleRequestMap = {
  [WidgetModule.BillMyList]: apiWeb.webFinancialBillingFinancialbillingGetmypageCreate,
  [WidgetModule.BillManage]: apiWeb.webFinancialBillingFinancialbillingGetpageCreate,
  [WidgetModule.BillAudit]: apiWeb.webFinancialBillingFinancialbillingGetfinancialbillingauditpageCreate
};

export const BillModuleApiMap = {
  [WidgetModule.BillMyList]: 'api/web/financial/billing/financialbilling/getmypage',
  [WidgetModule.BillManage]: 'api/web/financial/billing/financialbilling/getpage',
  [WidgetModule.BillAudit]: 'api/web/financial/billing/financialbilling/getfinancialbillingauditpage'
};

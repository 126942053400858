import { FC, memo, useContext, useEffect, useMemo } from 'react';
import { Select, SelectProps } from 'antd';
import { MyContext } from '../../BitzQueryFilterContext';
import { split, isArray } from 'lodash-es';
import withFluent from "@/utils/WithFluent";
import BitzQueryFilter2 from "@/components/BitzQueryFilter2";
// @ts-ignore
// import { BitzFluentSelect } from 'bitz-react-admin-ui';

const BitzFluentSelect = withFluent(Select)

interface BQFSelectProps extends SelectProps {
  advanced?: boolean;
}

const BQFSelect: FC<BQFSelectProps> = (props) => {
  const { dispatch, advanced = false } = useContext(MyContext);
  const { onChange, fieldNames, ...rest } = props;

  const id = useMemo(() => {
    if (props.id) {
      if (props.id?.indexOf('advanced') !== -1) {
        const list = split(props.id, '_');
        return list[list.length - 1];
      }
      return props.id;
    }
    return '';
  }, [props.id]);

  useEffect(() => {
    if (id) {
      dispatch?.({
        type: 'update',
        name: id,
        value: props?.value
      });
    }
  }, [props?.value, id]);

  const handleChange = (value, option) => {
    onChange?.(value, option);
    if (id && dispatch) {
      let valueName;
      // let valueName = isArray(option) ? option?.map((v) => v[fieldNames?.label ?? 'label']).join(',') : option[fieldNames?.label ?? 'label']
      if (isArray(option)) {
        valueName = option?.map((v) => v[fieldNames?.label ?? 'label']).join(',');
      } else {
        valueName = option[fieldNames?.label ?? 'label'];
      }
      dispatch?.({
        type: 'update',
        name: id,
        valueName
      });
    }
  };

  const Component = useMemo(() => {
    return !advanced ? BitzFluentSelect : Select
  }, [advanced])


  return (
    // @ts-ignore
    <Component
      onChange={handleChange}
      fieldNames={fieldNames}
      {...rest}
    />
  )
};

export default memo(BQFSelect);

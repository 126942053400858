import { create } from 'zustand';
import { persist, createJSONStorage, devtools } from 'zustand/middleware';
/**
 * @description BranchState取名采用大驼峰,State前面的命名与文件名保持一致
 */
interface FormDesignerState {
  selectFormTitle?: string;
  setSelectFormTitle?: (val: string) => void;
  selectFormKey?: any;
  setSelectFormKey?: (val: any) => void;
  selectFormFeature?: any;
  setSelectFormFeature?: (val: any) => void;
  selectFormIndex?: any;
  setSelectFormIndex?: (val: any) => void;
  //! 字段项
  selectFormItem?: any;
  setSelectFormItem?: (val: any) => void;
  selectFormRuleType?: string;
  setSelectFormRuleType?: (val: string) => void;
  formValidateRuleList?: any[];
  setFormValidateRuleList?: (val: any) => void;
  //! dto下的字段列表
  formValidateListPut: any[];
  setFormValidateListPut?: (val: any) => void;
  menuIndex: string;
  setMenuIndex?: (val: any) => void;
  fieldIndex: number;
  setFieldIndex?: (val: any) => void;
  fieldList: any[];
  setFiedList?: (data: Row[]) => void;
}
/**
 * @description BranchStore取名采用大驼峰,Store前面的命名与文件名保持一致
 * @description 以下是有Storage的store写法
 */
const formDesignerStore = create<FormDesignerState>()(
  devtools(
    persist(
      (set) => ({
        selectFormTitle: '客户管理-租户管理',
        setSelectFormTitle: (selectFormTitle: string) => set(() => ({ selectFormTitle })),
        selectFormKey: '11',
        setSelectFormKey: (selectFormKey: any) => set(() => ({ selectFormKey })),
        selectFormFeature: 'TenantManager',
        setSelectFormFeature: (selectFormFeature: any) => set(() => ({ selectFormFeature })),
        selectFormIndex: 0,
        setSelectFormIndex: (selectFormIndex: any) => set(() => ({ selectFormIndex })),
        selectFormItem: null,
        setSelectFormItem: (selectFormItem: any) => set(() => ({ selectFormItem })),
        selectFormRuleType: '',
        setSelectFormRuleType: (selectFormRuleType: string) => set(() => ({ selectFormRuleType })),
        formValidateRuleList: [],
        setFormValidateRuleList: (formValidateRuleList: any) => set(() => ({ formValidateRuleList })),
        formValidateListPut: [],
        setFormValidateListPut: (formValidateListPut: any) => set(() => ({ formValidateListPut })),
        menuIndex: '0',
        setMenuIndex: (menuIndex: string) => set(() => ({ menuIndex })),
        fieldIndex: 0,

        setFieldIndex: (fieldIndex: number) => set(() => ({ fieldIndex })),
        fieldList: [],
        setFiedList: (fieldList: Row[]) => set(() => ({ fieldList }))
      }),
      /**
       * @description languageStorage取名采用大驼峰,language与文件名保持一致，后面则取名为Storage
       */
      {
        name: 'formDesignerStorage',
        storage: createJSONStorage(() => localStorage)
      }
    )
  )
);

export default formDesignerStore;

import apiWeb from '@/services/Web';
import { selectorFamily, useRecoilCallback } from 'recoil';
import { billExchangeMapStore, billBaseInfoDB } from '../BillApplication.store';
import { HeaderNoProcess } from '@/constants';
import { run } from '@/utils';

export const exchangeListDB = selectorFamily({
  key: 'exchangeListDB',
  get:
    (billingId: string) =>
    async ({ get }) => {
      const baseInfo = get<Row>(billBaseInfoDB(billingId));
      if (!baseInfo?.data?.currency) return void 0;
      const baseCurrency: string[] = [baseInfo.data.currency!];
      const { response } = await apiWeb.webFinancialBillingFinancialbillingexchangeListCreate(
        { billingId, baseCurrency, page: 1, size: 1000 },
        { headers: { ...HeaderNoProcess } }
      );
      return response;
    }
});

export const useTouchExchange = (billingId: string) => {
  return useRecoilCallback(({ snapshot, set, refresh }) => async (values: Row) => {
    const exchangeMap = await snapshot.getPromise(billExchangeMapStore(billingId));
    // @todo 传dataIndex 或直接是 dataIndex 的值
    const { hourlyRateExchange, currencyAmount, ...extra } = values;
    const { exchangeRate } = extra;
    const { currency, targetCurrency = currency, baseCurrency } = hourlyRateExchange ?? currencyAmount ?? extra ?? {};

    console.log(baseCurrency, targetCurrency);

    if (!targetCurrency) return;
    if (baseCurrency === targetCurrency) return;

    await run(
      exchangeMap?.[targetCurrency]?.id,
      async (id) => {
        const item = exchangeMap?.[targetCurrency];
        await apiWeb.webFinancialBillingFinancialbillingexchangePutUpdate(id, { ...extra, ...item, targetCurrency, exchangeRate });
      },
      async () => {
        await apiWeb.webFinancialBillingFinancialbillingexchangePostCreate({ ...extra, billingId, targetCurrency, exchangeRate });
      }
    );

    refresh(exchangeListDB(billingId));
  });
};

import { DatePickerProps } from "antd/es/date-picker";
import React, { FC } from "react";
import { DatePicker } from "antd";
import { timeLimiter } from "@/pages/Works/Common";

interface WorksDatePicker extends DatePickerProps {

}

const WorksDatePicker: FC<WorksDatePicker> = (props) => {

  return (
    <DatePicker
      disabledDate={(current) => timeLimiter(current)}
      {...props}
    />
  )
}

export default WorksDatePicker

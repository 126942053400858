import { RouteConfig } from '@/router/config';

export const navData: any = [
  // {
  //   id: '1',
  //   title: '客户管理',
  //   icon: 'kehuguanliLinear',
  //   url: '/Tenants',
  //   feature: 'TenantManager',
  //   children: [{ title: '租户管理', subhead: '租户', icon: 'zuhuguanli', id: '11', url: '/Tenants', feature: 'TenantManager', isCollect: false }],
  // },
  {
    name: 'Developer',
    title: '开发管理',
    id: '3',
    icon: 'kaifaguanliLinear',
    url: '/Developer/Functions',
    children: [
      { title: '功能管理', subhead: '功能', icon: 'gongnengguanli', id: '32', url: '/Developer/Functions', isCollect: false },

      {
        title: '脱敏规则设置',
        subhead: '脱敏',
        icon: 'tuominguizeguanli',
        id: '38',
        url: '/Developer/DesensitizeRule',
        feature: 'DisplayDesensitization',
        isCollect: false
      },
      {
        title: '通知设置',
        subhead: '通知',
        icon: 'tongzhiguanli',
        id: '33',
        url: '/Developer/NotificationSettings',
        feature: 'NotificationSettings',
        isCollect: false
      },
      { title: '更新日志', subhead: '更新', icon: 'rizhiguanli1', id: '34', url: '/Developer/UpdateLogs', feature: 'UpdateLogs', isCollect: false },
      { title: '工作流', subhead: '工作', icon: 'gongzuoliuguanli', id: '35', url: '/Developer/WorkflowDefinitions', isCollect: false },
      { title: 'APP版本更新', subhead: 'APP', icon: 'banbenfenfa', id: '36', url: '/Developer/AppVersionUpdate', feature: 'AppEditions', isCollect: false },
      { title: '计划任务管理', subhead: '计划', icon: 'jihuarenwuguanli', id: '37', url: '/Developer/ScheduledTask', isCollect: false },
      { title: '菜单权限', subhead: '菜单', icon: 'caidanquanxian', id: '39', url: '/Developer/MenuPermissions', feature: 'Permission', isCollect: false },
      { title: '多语言枚举', subhead: '枚举', icon: 'meijushuju', id: '40', url: '/Developer/LanguageEnumeration', feature: 'EnumLanguages', isCollect: false }
    ]
  },
  {
    name: 'Editions',
    id: '4',
    icon: 'ziyuanguanliLinear',
    title: '资源管理',
    url: '/Editions',
    feature: 'AppEditions',
    children: [{ title: '版本列表', subhead: '版本', icon: 'banbenliebiao1', id: '41', url: '/Editions', feature: 'AppEditions', isCollect: false }]
  },
  {
    name: 'Administration',
    title: '系统管理',
    id: '2',
    icon: 'xitongguanliLinear',
    url: '/Administration/User',
    children: [
      { title: '账号管理', subhead: '账号', icon: 'yonghu1', id: '24', url: '/Administration/User', feature: 'UserInfo', isCollect: false },
      { title: '角色', subhead: '角色', icon: 'jiaose', id: '25', url: '/Administration/Role', feature: 'Role', isCollect: false },
      { title: '组织机构', subhead: '机构', icon: 'jigou', id: '26', url: '/Administration/Organization', feature: 'OrganizationUnit', isCollect: false },
      { title: '语言列表', subhead: '语言', icon: 'duoyuyanguanli', id: '21', url: '/Administration/Languages', feature: 'LanguageText', isCollect: false },
      { title: '配置页', subhead: '配置', icon: 'xitongpeizhi', id: '22', url: '/Administration/Configuration', feature: 'Setting', isCollect: false },
      { title: '表单设计', subhead: '表单', icon: 'biaodanshezhi', id: '23', url: '/Administration/FormDesigner', feature: 'FormValidate', isCollect: false },
      { title: '设置', subhead: '设置', icon: 'shezhi1', id: '27', url: '/Administration/Setting', feature: 'FormValidate', isCollect: false },
      { title: '审计日志', subhead: '审计', icon: 'shenjirizhi', id: '28', url: '/Administration/AuditLog', feature: 'FormValidate', isCollect: false }
    ]
  },
  {
    name: 'VendorManagement',
    title: '供应商管理',
    id: '5',
    icon: 'xitongguanliLinear',
    url: '/VendorManagement/VendorPlugin',
    children: [
      {
        title: '第三方插件',
        subhead: '第三方插件',
        icon: 'disanfangchajian',
        id: '29',
        url: '/VendorManagement/VendorPlugin',
        feature: 'VendorPlugin',
        isCollect: false
      },
      {
        title: '供应商列表',
        subhead: '供应商列表',
        icon: 'gongyingshangliebiao',
        id: '30',
        url: '/VendorManagement/VendorList',
        feature: 'VendorList',
        isCollect: false
      }
    ]
  }
];

export function findFirstPath(Route: RouteConfig) {
  // 如果当前节点没有children，直接返回当前节点的path
  // const notMenu = !Route?.notMenu
  if (!Route.children || Route.children.length === 0) {
    return Route.path;
  }
  // 如果有children，递归第一个child，并正确处理路径拼接，避免多余的斜杠
  return Route.path + '/' + findFirstPath(Route.children[0]);
}

export function findPaths(canPermisision, data: RouteConfig, prefix = ''): string[] {
  // Initialize an array to store the valid paths
  const paths: string[] = [];

  // Helper function to collect valid paths
  function collectPaths(canPermisision, node, currentPath) {
    // If `notMenu` attribute is true, skip this node
    // if (node?.notMenu) return;

    if (!canPermisision(node?.name)) return;

    // Append the current path
    const newPath = currentPath ? `${currentPath}/${node.path}` : node.path;

    // If the node has children, recurse into children
    if (node?.children?.length > 0) {
      node.children.forEach((child) => {
        collectPaths(canPermisision, child, newPath);
      });
    } else {
      // If it's a leaf node, add the path to the paths array
      paths.push(newPath);
    }
  }

  // Start with the root node
  collectPaths(canPermisision, data, prefix);

  // https://devops.aliyun.com/projex/bug/KNGN-30# 《部分左侧主菜单点击时，不能正确跳转》
  // 添加undefined菜单处理，@todo 菜单组装使用 react-route 原生机制
  const paths$ = paths.filter(Boolean);

  return paths$;
}

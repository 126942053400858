import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { useShallow } from 'zustand/react/shallow'

import { SystemThemeSettingDto } from '@/services/MyApi'
import apiWeb from '@/services/Web'

interface BasicSettingsStoreState extends SystemThemeSettingDto {}

interface Action {
  updateBasicSettings: (settings: BasicSettingsStoreState) => void
  getBasicSettings: () => Promise<void>
  hideTableSizeTipModal: (settings: BasicSettingsStoreState) => void
}

type Store = BasicSettingsStoreState & Action

const useBasicSettingsStore = create<Store>()(
  devtools(
    persist(
      (set) => ({
        getBasicSettings: async () => {
          try {
            const data = await apiWeb.webSysSystemconfigGeneralList()
            if (data.success && data?.response) {
              return set(data?.response as BasicSettingsStoreState, false, 'BasicSettingsStore/getBasicSettings')
            }
          } catch (e) {
            console.error('BasicSettingsStore/getBasicSettings', e)
          }
        },
        updateBasicSettings: (settings) => set(settings, false, 'BasicSettingsStore/updateBasicSettings'),
        hideTableSizeTipModal: async (settings) => {
          try {
            const res = await apiWeb.webSysSystemconfigGeneralRemindUpdate()
            if (res.success) {
              return set(settings, false, 'BasicSettingsStore/updateBasicSettings')
            }
          } catch (error) {
            console.error(`error in function hideTableSizeTipModal, error info: ${error}`)
          }
        },
        themeConfig: {
          theme: 'light', // 主题样式
          colorPrimary: '#5D73FA', // 主题色
          sideBgColor: '#000000', // 侧边栏
          menuBgColor: '#F0F2F4', // 菜单栏
          navigationBarStyle: 'left',
        },
        tableConfig: {
          dataSize: 10, // 分页数量
          operatePosition: 'right', // 操作位置
          defaultSortWay: 'descend', // 默认排序
          operatorHrefWay: 'self', // 操作跳转方式
          isTrueRemind: true, // 开启密度全局提醒
        },
        formConfig: {
          textTagAlignmentWay: 'left',
          requiredDisplay: 'right',
          isRequiredShow: false,
          componentSize: 'Default',
          componentWidth: 'All',
        },
        filterConfig: {
          isShowMoreFilter: false,
        },
      }),
      {
        // 本地储存名称
        name: 'BasicSettingsStore',
      },
    ),
    {
      // redux 调试工具名称
      name: 'BasicSettingsStore',
    },
  ),
)

export const useTableConfig = () => useBasicSettingsStore(useShallow((state) => state?.tableConfig))

export default useBasicSettingsStore

import { Divider, Empty, Input, Popover } from 'antd';
import cx from 'classnames';
import IconFont from '@/components/iconfont';
import styles from './BitzMenuPopover.module.less';
import { adminRoutes } from '@/router/config';
import { Link } from 'react-router-dom';
import { findFirstPath, findPaths } from '@/utils/nav';
import { SearchOutlined } from '@ant-design/icons';
import { useMemo, useState } from 'react';
import useCollectStore from '@/store/useCollect';
import { useShallow } from 'zustand/react/shallow';
import { BzPermission, useCanPermsision } from '@/v2/components';

const FunctionMenuContent = () => {
  const canPermisision = useCanPermsision();
  const routes = adminRoutes?.filter((v) => canPermisision(v.name));
  const [search, setSearch] = useState<string>();
  const collect = useCollectStore(useShallow((state) => state.collect));

  const showRoutes = useMemo(() => {
    if (search) {
      return routes?.filter((item) => {
        return !(item.meta?.title?.indexOf(search) ?? -1 >= 0);
      });
    }
    return routes;
  }, [search, routes]);

  const items = showRoutes?.map((item) => {
    const url = findPaths(canPermisision, item)?.[0] ?? '/';

    return (
      <BzPermission auth={item.name} key={item.name}>
        <div className={styles.mainViewItem}>
          <Link to={url} className={styles.mainViewItem__button}>
            <div className={styles.mainViewItem__icon}>
              <span className="anticon">
                <IconFont
                  size={40}
                  color={item.color}
                  // color="inherit"
                  // fill="currentColor"
                  name={item?.iconName || 'ic_fluent_clipboard_code_24_regular'}
                />
              </span>
            </div>
            <div className={styles.mainViewItem__text}>
              <div className={styles.mainViewItem__text__wrap}>{item?.meta?.title}</div>
            </div>
          </Link>
        </div>
      </BzPermission>
    );
  });

  const collectList = useMemo(
    () =>
      collect?.map((item) => {
        return (
          <div className={styles.mainViewItem} key={item.name}>
            <Link to={item.url} className={styles.mainViewItem__button}>
              <div className={styles.mainViewItem__icon}>
                <span className="anticon">
                  <IconFont
                    size={40}
                    color={item.color}
                    // color="inherit"
                    // fill="currentColor"
                    name={item?.icon}
                  />
                </span>
              </div>
              <div className={styles.mainViewItem__text}>
                <div className={styles.mainViewItem__text__wrap}>{item.name}</div>
              </div>
            </Link>
          </div>
        );
      }),
    [collect]
  );

  return (
    <div className={styles.appLauncherMainView}>
      <div className={styles.appInputWarp}>
        <Input
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          style={{ width: '100%' }}
          prefix={<SearchOutlined />}
          placeholder="请输入功能名称查找"
          allowClear
        />
      </div>
      <div className={styles.appsModule}>
        {search && !items?.length ? (
          <div className={styles.appsModuleEmpty}>
            <Empty />
          </div>
        ) : (
          items
        )}
      </div>
      {!!collect?.length && [
        <div key="divider" style={{ margin: '12px 0', width: '100%' }}>
          <Divider key="divider" />
        </div>,
        <div key="appsModule2" className={styles.appsModule}>
          {collectList}
        </div>
      ]}
    </div>
  );
};

interface BitzMenuPopoverProps {
  children?: React.ReactNode;
}

const BitzMenuPopover: React.FC<BitzMenuPopoverProps> = (props) => {
  return (
    <Popover trigger={['hover', 'click']} content={FunctionMenuContent()} arrow={false} placement="bottom">
      {props?.children}
    </Popover>
  );
};

export default BitzMenuPopover;

import React, {DetailedHTMLProps, HTMLAttributes} from 'react'
import style from './index.module.less'
import IconRight from "@/assets/iconfont/IconRight";
import cx from "classnames";

type divType = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>

export interface SasideSwitchProps extends divType {
  direction?: 'left' | 'right'
}

const SasideSwitch = React.forwardRef<HTMLDivElement, SasideSwitchProps>(
  (props, ref) => {
    const {
      direction = 'left',
      className,
      ...rest
    } = props

  return (
    <div
      ref={ref}
      className={cx(style.container, className, {
        [style.left]: direction === 'left',
        [style.right]: direction === 'right',
      })} {...rest}>
      <IconRight className={style.icon} size={10} color="#757575" />
    </div>
  )
})


export default SasideSwitch

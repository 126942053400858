import { FC, useEffect, useState } from 'react'
import { BitzTable } from 'bitz-react-admin-ui'
import api from '@/services'
import TextOverflow from '@/components/TextOverflow'
import BitzFormModal from '@/components/BitzFormModal'
import { antdModalV5, create, useModal } from '@ebay/nice-modal-react'
import { CancelButton } from '@/businessComponents/BnButton'
import { useTableConfig } from '@/store/useBasicSettingsStore'
import useTableSort2 from '@/hooks/useTableSort2'
import { Card } from 'antd'

interface EntryRecordProps {}

const EntryRecord: FC<EntryRecordProps> = () => {
  const modal = useModal()
  const modalProps = antdModalV5(modal)
  const tableConfig = useTableConfig()

  const [loading, setLoading] = useState<boolean>(false)
  const [total, setTotal] = useState<number>(0)
  const [dataSource, setDtaSource] = useState<any[]>([])
  const [page, setPage] = useState({
    page: 1,
    size: tableConfig?.dataSize,
  })

  const [col, onTableChange, toOrderBy] = useTableSort2({
    url: 'api/host/sys/userinfo/login/log',
    columns: [
      {
        title: 'IP地址',
        width: 150,
        dataIndex: 'clientIpAddress',
        sorter: true,
        render: (value) => {
          return <TextOverflow value={value} />
        },
      },
      {
        title: '登录浏览器',
        dataIndex: 'browserAgent',
        sorter: true,
        render: (value) => {
          return <TextOverflow value={value} />
        },
      },
      {
        title: '登录时间',
        with: 180,
        dataIndex: 'createTime',
        sorter: true,
        sortOrder: tableConfig?.defaultSortWay,
        render: (value) => <TextOverflow value={value} />,
      },
    ],
  })

  const getInfo = async () => {
    try {
      setLoading(true)
      const res = await api.hostSysUserinfoLoginLogList({ ...page, toOrderBy: toOrderBy as string })
      const data = res?.response?.data || []
      const dataCount = res?.response?.dataCount || 0
      setTotal(dataCount)
      setDtaSource(data)
      console.log('我的res', res)
      console.log('我的data', data)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getInfo()
  }, [page, toOrderBy])

  return (
    <BitzFormModal title="登录记录" {...modalProps} footer={<CancelButton onClick={modalProps.onCancel}>关闭</CancelButton>}>
      <Card bordered={false}>
        <BitzTable
          scroll={{ x: 625 }}
          loading={loading}
          columns={col as any[]}
          dataSource={dataSource}
          total={total}
          enableControlPage
          pagination={page}
          paginationEmit={(page, size) => setPage({ page, size })}
          showTableTool={false}
          rowKey="id"
          onChange={onTableChange}
        />
      </Card>
    </BitzFormModal>
  )
}

export default create(EntryRecord)

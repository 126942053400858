/*
 * # Title: BitzTranslateTextarea Component
 * - Description:
 * - Date: 2024-04-28
 * - LastEditors: liujin liujin0505@icloud.com
 * - LastEditTime: 2024-07-01 23:42:02
 */

import { useLayoutEffect, useRef, useId, useState, useEffect, useMemo, FC } from 'react';
import { Button, Flex, GetRef, Input, GetProps, Dropdown, Spin, Space } from 'antd';
import { useReactive, useRequest } from 'ahooks';
import { css } from '@emotion/css';
import api from '@/services/GeneralPublicInterface';
import { message } from '../BitzAntApp/Static';
import { map, replace } from 'lodash-es';
import IconFont from '@/components/iconfont';
import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import { useShallow } from 'zustand/react/shallow';
import { workDateStore } from '@/store';

interface BitzTranslateTextareaProps extends GetProps<typeof Input.TextArea> {
  editable?: boolean;
  lang?: string;
  isSuggest?: boolean | null;
  showbut?: boolean;
  onSave?: (value: any) => Promise<any>;
  cacheId?: any;
}

const BitzTranslateTextarea: FC<BitzTranslateTextareaProps> = (props) => {
  const { editable = true, lang, isSuggest, showbut = false, onSave, cacheId, ...rest } = props;
  const textareaId = useId();
  const wrapRef = useRef(null);
  const textareaRef = useRef<GetRef<typeof Input.TextArea>>(null);
  const rState = useReactive<{ [name: string]: any }>({
    css: '',
    value: '',
    editable: editable,
    result: null
  });

  const { loading, runAsync } = useRequest((opt = {}) => api.spllcheckChecksentenceCreate(opt), { manual: true });

  async function handleCheck() {
    try {
      const sentence = rState.value || rest.value;
      const res = await runAsync({
        sentence,
        lang: lang || 'en',
        isSuggest: isSuggest || null
      });
      const list = res?.response || [];

      if (list.length === 0) {
        message.success('拼写检查成功');
        setRedState(false);
        return;
      }
      setRedState(true);
      rState.editable = false;
      const result = map(list, ({ suggestions, word }) => {
        const suggestionsList = suggestions && suggestions.length ? suggestions : ['no suggestion'];
        const items = map(suggestionsList, (item, index) => ({
          label: item,
          key: index
        }));

        console.log('word', word);

        const dropdown = (
          <Dropdown
            overlayClassName={css`
              ul {
                max-height: 300px;
                overflow: auto;
              }
            `}
            menu={{
              items: items,
              onClick({ key }) {
                const value = items[key].label;
                if (value !== 'no suggestion') {
                  rState.editable = true;
                  const newSentence = replace(sentence, word || '', value);
                  rState.value = newSentence;
                  // @ts-ignore
                  rest?.onChange?.(newSentence);
                  // @ts-ignore
                  document.getElementById(textareaId).value = newSentence;
                  //
                  setCurValue(newSentence);
                  setRedState(false);
                }
              }
            }}
            trigger={['click']}
          >
            <span
              className={css`
                text-decoration: underline;
                text-decoration-color: red;
                text-decoration-style: wavy;
                cursor: pointer;
              `}
            >
              {word}
            </span>
          </Dropdown>
        );
        return {
          word,
          dropdown
        };
      });
      const words = map(result, 'word');
      const regex = new RegExp(`(${words.join('|')})`, 'gi');
      const parts = sentence.split(regex);
      console.log(words);
      const highlighted = parts.map((part, index) => (words.includes(part) ? result[result?.findIndex((item) => item.word === part) || 0].dropdown : part));
      console.log(parts, highlighted);
      rState.result = highlighted;
    } catch (e: any) {
      console.error(e.message);
    }
  }

  useLayoutEffect(() => {
    if (textareaRef.current) {
      const style: any = window.getComputedStyle((textareaRef.current as any)?.resizableTextArea?.textArea);
      rState.css = style;
    }
  }, []);

  const init = rest?.value ?? '';
  const [edit, setEdit] = useState<boolean>(false);
  const [curValue, setCurValue] = useState(rest?.value ?? '');
  const [butLoading, setButLoading] = useState(false);
  const [redState, setRedState] = useState(false);

  useEffect(() => {
    // @ts-ignore
    setCurValue(rest?.value);
  }, [rest?.value]);

  return (
    <>
      {/* <Spin spinning={loading || butLoading} size="small" ></Spin> */}
      <div
        ref={wrapRef}
        // tabIndex={0}
        className={css`
          position: relative;
        `}
        // onBlur={(e) => {
        //   // e.stopPropagation()
        //   setEdit(false)
        // }}
        // tabIndex={0}
      >
        <div
          className={css`
            ${rState.css};
            margin-top: 1px;
            position: absolute;
            inset: 0;
            z-index: ${rState.editable ? -1 : 1};
            // line-height: 1;
            width: 100%;
            line-height: var(--ant-line-height);
            font-family: var(--ant-font-family), serif;
            min-height: var(--ant-control-height);
          `}
          onDoubleClick={() => {
            rState.editable = true;
          }}
        >
          {rState.result}
        </div>
        <Input.TextArea
          id={textareaId}
          ref={textareaRef}
          rows={2}
          autoSize={{ minRows: 2, maxRows: 3 }}
          showCount
          // maxLength={maxLength}
          {...rest}
          value={curValue}
          onChange={(e) => {
            rState.value = e.target.value;
            rest?.onChange?.(rState.value);
            //
            setCurValue(e.target.value);
          }}
          className={css`
            padding-bottom: 10px;
            padding-right: 22px;
          `}
        />

        {showbut ? (
          <>
            {edit && (
              <Space style={{ marginTop: '8px' }}>
                <Button loading={butLoading} size="small" onClick={handleCheck}>
                  <IconFont
                    className={css`
                      color: #fff;
                    `}
                    size={24}
                    name="pinxiejiancha"
                  />
                </Button>
                <Button
                  loading={butLoading}
                  size="small"
                  type="primary"
                  icon={<SaveOutlined />}
                  onClick={async (e) => {
                    e.stopPropagation();
                    setButLoading(true);
                    await onSave?.(curValue).finally(() => {
                      setEdit(false);
                      setButLoading(false);
                      setRedState(false);
                    });
                  }}
                />
                <Button
                  tabIndex={0}
                  loading={butLoading}
                  size="small"
                  danger
                  icon={<CloseOutlined />}
                  onClick={(e) => {
                    // e.stopPropagation()
                    // onCancel()
                    rState.editable = true;
                    // @ts-ignore
                    rest?.onChange?.(init);
                    setCurValue(init);
                    setEdit(false);
                  }}
                />
              </Space>
            )}
          </>
        ) : (
          <Space style={{ marginTop: '8px' }}>
            <Button loading={butLoading} size="small" onClick={handleCheck}>
              <IconFont
                className={css`
                  color: #fff;
                `}
                size={24}
                name="pinxiejiancha"
              />
            </Button>
          </Space>
        )}
      </div>
    </>
  );
};

export default BitzTranslateTextarea;

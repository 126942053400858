import { css } from '@emotion/css'
import { Flex, FlexProps, Typography } from 'antd'
import defaultRibbonLeft from '@/assets/images/ribbonLeft.png'
import defaultRibbonRight from '@/assets/images/ribbonRight.png'
import { FC, ReactNode } from 'react'

const ribbonWrapperCls = (placement) => css`
  text-align: ${placement};
  width: 100%;
  height: 30px;
  padding-top: 1px;
  position: absolute;
  z-index: 1;
  ${placement}: 0;
  top: 0;

  .text {
    color: #fff;
    ${`margin-${placement}`}: 10px;
    margin-top: 5px;
    position: relative;
    z-index: 1;
  }

  .ribbon {
    position: absolute;
    ${placement}: 0;
    top: 0;
  }
`

interface BnRibbonProps extends FlexProps {
  show?: boolean
  text?: ReactNode
  ribbonImage?: any
  placement?: 'left' | 'right'
}

const BnRibbon: FC<BnRibbonProps> = ({ show = true, text, ribbonImage, placement = 'left', children, style, ...rest }) => {
  return (
    <Flex {...rest} style={{ paddingTop: 12, ...style, position: 'relative' }}>
      {show !== false && (
        <div className={ribbonWrapperCls(placement)}>
          <Typography.Text className="text">{text}</Typography.Text>
          <img height="100%" src={ribbonImage ?? placement === 'left' ? defaultRibbonLeft : defaultRibbonRight} className="ribbon" />
        </div>
      )}
      {children}
    </Flex>
  )
}

export default BnRibbon

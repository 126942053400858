import { Avatar, Dropdown, Input, Layout, MenuProps, Space, theme } from 'antd';
import style from './head.module.less';
import clx from 'classnames';
import { SearchOutlined, UserOutlined } from '@ant-design/icons';
import { IconDenglujilu, IconWodexinxi, IconXiugaimima, IconZhuxiao } from '@/assets/iconfont';
import NiceModal, { show } from '@ebay/nice-modal-react';
import ChangePassword from '@/layout/LayoutHeader/components/ChangePassword';
import EntryRecord from '@/layout/LayoutHeader/components/EntryRecord';
import MyInfo from '@/layout/LayoutHeader/components/MyInfo';
import useUserStore from '@/store/userStore';
import { useNavigate } from 'react-router-dom';
import { Loguot } from '@/utils/loguot';
import BitzMenuPopover from '@/components/BitzMenuPopover';
import IconFont from '@/components/iconfont';
import { useMemo, useRef, useState } from 'react';
import useBasicSettingsStore from '@/store/useBasicSettingsStore';
import { useShallow } from 'zustand/react/shallow';
import eflogin from '@/assets/images/ef-logo.png';
import { BitzConfirmModal } from 'bitz-react-admin-ui';
import { TimerModal } from '../BnHeadTimer';
import { BitzTopTimer } from '@/components/BitzTopTimer';
import TimeDrawer from '@/layout/TimeDrawer';
import { BitzLocalStorage } from 'bitz-react-admin-ui';
import { LeftOutlined, EnterOutlined } from '@ant-design/icons';

const Head = () => {
  const navigate = useNavigate();
  const userinfo = useUserStore((state) => state);
  const navigationBarStyle = useBasicSettingsStore(useShallow((state) => state?.themeConfig?.navigationBarStyle ?? 'left'));
  const { token } = theme.useToken();
  const timeRef = useRef<HTMLDivElement>(null);
  const [showTimer, setShowTimer] = useState<boolean>(false);

  const clickLogin = () => {
    navigate('/');
  };

  const itemBack = [
    {
      label: '返回我的账户',
      key: 'backPassword',
      icon: <EnterOutlined />,
      onClick: () => {
        // @ts-ignore
        NiceModal.show(BitzConfirmModal, {
          okType: 'primary',
          okButtonProps: {
            danger: true
          },
          modalText: '是否返回我的账户!',
          okText: '确定',
          onOk: () => {
            const lzhOriginalToken = BitzLocalStorage.getItem('lzhOriginalToken') || '';
            BitzLocalStorage.setItem('lzhHostToken', lzhOriginalToken);
            BitzLocalStorage.removeItem('lzhOriginalToken');
            // 刷新后会从新获取用户信息
            window.location.reload();
            // const ret = await api.webSysUserinfoCurrentList({
            //   headers: {
            //     Authorization: `${'Bearer ' + lzhOriginalToken}`,
            //   },
            // })
            // setTimeout(() => {
            //   message.success('返回我的账户成功')
            //   userinfo.setUserInfo(ret.response || {})
            //   window.location.reload()
            // }, 300)
          }
        });
      }
    }
  ];

  const itemOhter = [
    {
      label: '修改密码',
      key: 'updatePassword',
      icon: <IconXiugaimima className="icon" color={token.colorTextSecondary} size={14} name="xiugaimima" />,
      onClick: () => show(ChangePassword)
    },
    {
      label: '登录记录',
      key: 'record',
      icon: <IconDenglujilu className="icon" color={token.colorTextSecondary} size={16} name="denglujilu" />,
      onClick: () => show(EntryRecord)
    },
    // {
    //   label: '我的信息',
    //   key: 'info',
    //   icon: <IconWodexinxi className="icon" color={token.colorTextSecondary} size={16} name="wodexinxi" />,
    //   onClick: () => show(MyInfo)
    // },
    {
      label: '注销',
      key: 'logout',
      icon: <IconZhuxiao className="icon" color={token.colorTextSecondary} size={16} name="zhuxiao" />,
      onClick: () => {
        show(BitzConfirmModal, {
          okType: 'primary',
          okButtonProps: {
            danger: true
          },
          modalText: '您确定要注销当前账号吗？',
          onOk: async () => {
            Loguot();
          }
        });
      }
    }
  ];

  const items: MenuProps['items'] = BitzLocalStorage.getItem('lzhOriginalToken') ? [...itemBack, ...itemOhter] : [...itemOhter];

  const openTimeDrawer = () => {
    show(TimeDrawer, {
      timeDom: timeRef.current
    });
    // setShowTimer(true)
  };

  const userInfoDom = useMemo(() => {
    if (BitzLocalStorage.getItem('lzhOriginalToken')) {
      return (
        <>
          <LeftOutlined />
          <div className={style.username} style={{ marginRight: 10 }}>
            {userinfo?.emailAddress}{' '}
          </div>
          <Avatar src={userinfo?.pictureUrl} style={{ backgroundColor: token.colorPrimary }} size={30} icon={<UserOutlined />} />
        </>
      );
    }
    return [
      <Avatar
        key="avatar"
        style={userinfo?.pictureUrl ? {} : { backgroundColor: token.colorPrimary }}
        size={30}
        icon={<UserOutlined />}
        src={userinfo?.pictureUrl}
      />,
      <div key="name" className={style.username}>
        {userinfo?.displayName}
      </div>
    ];
  }, [userinfo]);

  return (
    <>
      <Layout.Header
        id="layoutHeaderBlock"
        style={{
          display: 'flex',
          alignItems: 'center',
          height: 60,
          borderColor: token.colorBorderSecondary
        }}
        className={clx(style.layoutHeaderBlock, 'layoutHeaderBlock')}
      >
        <div className={style.logoBox}>
          {navigationBarStyle !== 'left' && (
            <div className={style.menuWrap}>
              <BitzMenuPopover>
                <div className={style.menuItem}>
                  <IconFont color="inherit" fill="currentColor" name="ic_fluent_grid_dots_24_filled" size={24} />
                </div>
              </BitzMenuPopover>
            </div>
          )}
          <img onClick={clickLogin} className={style.logoDefault} src={eflogin} alt="" />
        </div>
        <div className={style.search}>
          <Input allowClear placeholder="搜索" prefix={<SearchOutlined />} style={{ width: 300 }} />
        </div>
        <Space>
          <BitzTopTimer
            ref={timeRef}
            clock
            clockClick={() => {
              openTimeDrawer();
            }}
          />

          <div className={style.userInfo}>
            <Dropdown menu={{ items, style: { minWidth: 130 } }}>
              <div className={style.userInfoContainer}>{userInfoDom}</div>
            </Dropdown>
          </div>
        </Space>
      </Layout.Header>

      <TimerModal
        show={showTimer}
        onClose={() => {
          setShowTimer(false);
        }}
      />
    </>
  );
};

export default Head;

import { Layout, theme } from 'antd';
import styles from '../index.module.less';
import LayoutMiniSidebar from '@/components/LayoutMiniSidebar';
import Head from '../Head';
import { SidenavContainer } from '@/components/Sidenav';
import clx from 'classnames';
import LayoutSidebarMenu from '@/components/LayoutSidebarMenu';
import React, { useMemo } from 'react';
import useBasicSettingsStore from '@/store/useBasicSettingsStore';
import { useShallow } from 'zustand/react/shallow';
import { useLocalStorageState } from 'ahooks';
import { usePageContext } from '@/providers/PageManageProvider';
import { NavigateOptions, useLocation, useNavigate } from 'react-router-dom';
import { buildPath, findRouteByName } from '@/utils';
import { PageConfig, RouteConfig } from '@/router/config';
import mergePath from '@/utils/mergePath';
import { findFirstPath, findPaths } from '@/utils/nav';
import { ItemType } from 'antd/es/menu/interface';
import { filter, isEmpty, isNil, reduce } from 'lodash-es';
import { permissionDB } from '@/v2/store/store';
import { useUnwrapRecoilValue, useUnwrapRecoilValueLoadable } from '@/v2/hooks';
import { useCanPermsision } from '@/v2/components';

interface BaseNavLeftProps {
  children?: React.ReactNode;
  matchRouteObj?: any;
  /**
   *
   */
  routes?: any[];

  route: RouteConfig;
}

// 渲染导航栏
// @ts-ignore
function renderMenuItems(permission, data: Array<RouteConfig>, open?: (info: { name: string; label: string; key: string }) => void, path?: string, routes) {
  function renderMenu(data: Array<RouteConfig>, path?: string) {
    return reduce(
      data,
      (items, route) => {
        // 不在菜单显示
        // notMenu 使用 permission 代替
        // by mizi.20240911
        // if (route.notMenu) {
        //   return items;
        // }

        if (!permission?.has?.(route.name)) {
          return items;
        }

        // // 权限验证 不通过不显示
        // if (!checkAuthPass(route)) {
        //   return items
        // }
        const thisPath = mergePath(route.path, path);
        const children = filter(route.children ?? [], (route) => permission?.has?.(route.name));
        const hasChildren = isNil(children) || isEmpty(children);

        items.push({
          iconName: route.iconName,
          activeIconName: route.activeIconName,
          key: route?.name ?? '',
          title: route.meta?.title,
          icon: route.icon,
          url: hasChildren ? buildPath(routes, route.name) : null,
          onClick: () => {
            // console.log('renderMenuItems', thisPath)
            hasChildren &&
              open?.({
                key: thisPath,
                label: route.meta?.title as string,
                name: route?.name ?? ''
              });
          },
          label: !hasChildren ? (
            <span className="a-black">{route.meta?.title}</span>
          ) : (
            <a
              onClick={(e) => {
                e.preventDefault();
                open?.({
                  key: thisPath,
                  label: route.meta?.title as string,
                  name: route?.name ?? ''
                });
              }}
              className="a-black"
            >
              {route.meta?.title}
            </a>
          ),
          // @ts-ignore
          children: hasChildren ? undefined : renderMenu(children, thisPath)
        });
        return items;
      },
      [] as ItemType[]
    );
  }

  return renderMenu(data, path);
}

const BaseNavLeft: React.FC<BaseNavLeftProps> = (props) => {
  const { children, matchRouteObj, routes, route } = props;
  const themeConfig = useBasicSettingsStore(useShallow((state) => state?.themeConfig));
  const { token } = theme.useToken();
  const location = useLocation();
  const navigate = useNavigate();

  const { pages, setPages, active, open, close } = usePageContext();

  const permission = useUnwrapRecoilValue(permissionDB);
  const canPermisision = useCanPermsision();

  // const orderItems = [...pages]

  const sideBgColor = useMemo(() => {
    if (themeConfig?.theme === 'light') {
      return themeConfig?.sideBgColor ?? '#e9ecef';
    }
    return token?.colorBgLayout;
  }, [themeConfig]);

  const menuBgColor = useMemo(() => {
    if (themeConfig?.theme === 'light') {
      return themeConfig?.menuBgColor ?? '#dce3e9';
    }
    return token.colorBgContainer;
  }, [themeConfig]);

  const navigateTo = (key: string, options?: NavigateOptions) => {
    const pathname = key.indexOf('?') > -1 ? key.split('?')[0] : key;
    const search = key.indexOf('?') > -1 ? key.split('?')[1] : '';
    navigate(
      {
        pathname,
        search
      },
      options
    );
  };

  function findFirstPath2(routes: RouteConfig[]): string {
    // 遍历路由数组
    for (const route of routes) {
      // 如果当前路由没有children，返回当前路由的path
      if (!route.children || route.children.length === 0) {
        return '/' + route.path;
      }
      // 如果有children，递归搜索
      const childPath = findFirstPath2(route.children);
      if (childPath) return '/' + route.path + childPath;
    }
    // 如果没有找到符合条件的路由，返回空字符串
    return '';
  }

  const renderMenuItems2 = (permission, data: Array<RouteConfig>, open?: (info: PageConfig) => void, path?: string) => {
    function renderMenu(data: Array<RouteConfig>, path: string | undefined) {
      return reduce(
        data,
        (items, route) => {
          // 不在菜单显示
          // if (route.notMenu) {
          //   return items;
          // }

          if (!permission?.has?.(route.name)) {
            return items;
          }

          const thisPath = mergePath(route.path, path);
          // const children = filter(route => not(route.notMenu), route.children ?? [])
          // const hasChildren = isNil(children) || isEmpty(children)
          items.push({
            // @ts-ignore
            iconName: route.iconName,
            activeIconName: route.activeIconName,
            key: route?.name ?? '',
            title: route.meta?.title,
            icon: route.icon,
            onClick: () => {
              const url = findPaths(canPermisision, route)?.[0] ?? '/';
              open?.({
                key: thisPath,
                label: route.meta?.title as string,
                url: url
              });
            },
            label: (
              <a
                onClick={(e) => {
                  e.preventDefault();
                  open?.({
                    key: thisPath,
                    label: route.meta?.title as string,
                    url: findFirstPath(route)
                  });
                }}
                className="a-black"
              >
                {route.meta?.title}
              </a>
            )
          });
          return items;
        },
        [] as ItemType[]
      );
    }

    return renderMenu(data, path);
  };

  const items2 = useMemo(() => {
    if (isNil(route.children)) {
      return [] as ItemType[];
    }

    return renderMenuItems2(permission, route.children, (info) => {
      navigateTo(info?.url as string, {
        state: info.state
      });
      // console.log('一级菜单点击 info', info)
    });
  }, [route, routes, permission]);

  // 获取一级菜单信息
  const aMenu = useMemo(() => {
    if (location.pathname !== '/' && routes) {
      const arr = location.pathname.split('/').filter((v) => v);
      const item = findRouteByName(routes, arr[0] as string);
      return item;
    }
    return [];
  }, [location, routes, permission]);

  // 一级菜单标题
  const aMenuTtitle = useMemo(() => aMenu?.meta?.title, [aMenu]);

  // 获取二级菜单
  const bMenu = useMemo(() => {
    if (aMenu) {
      const arr = route?.children?.find((v) => v.name === aMenu?.name);
      if (isNil(arr?.children)) {
        return [] as ItemType[];
      }
      return renderMenuItems(
        permission,
        arr.children,
        (info) => {
          // 二级菜单点击事件
          // console.log('bMenu click', info, buildPath(routes, info.name))
          // navigate(buildPath(routes, info.name))
          const key = buildPath(routes, info.name);
          const openprops = {
            key: key,
            label: info.label
          };
          open(openprops);
        },
        undefined,
        routes
      );
    }
    return [];
  }, [aMenu?.children, route, permission]);

  // 获取二级菜单选中项
  const bMenuSelectedKeys: string[] = useMemo(() => {
    if (location.pathname && routes) {
      const arr = location.pathname.split('/').filter((v) => v);
      if (arr?.length > 1) {
        arr.shift();
        return arr as unknown as string[];
      }
      return [];
    }
    return [];
  }, [location.pathname, routes, permission]);

  const [width, setWidth] = useLocalStorageState<number>('side_nav_width', {
    defaultValue: 150
  });

  const [opened, setOpened] = useLocalStorageState<boolean>('side_nav_opened', {
    defaultValue: true
  });

  return (
    <>
      <Layout.Sider width={70} className={styles['app-layout__mini-sidebar']} style={{ backgroundColor: sideBgColor }}>
        <LayoutMiniSidebar items={items2} selectedKeys={matchRouteObj?.selectedKeys} />
      </Layout.Sider>
      <Layout id="app-layout" style={{ position: 'relative', width: '100%', height: '100vh' }} className={styles['app-layout__content']}>
        <Head />
        <div style={{ position: 'relative', flex: 1 }}>
          <SidenavContainer
            hoverAble
            mode="side"
            width={width}
            onWidth={setWidth}
            opened={opened}
            onOpened={setOpened}
            direction="left"
            sidenavProps={{
              toggleableBtn: true,
              isResize: true,
              minWidth: 60
            }}
            sidenav={
              <div
                style={{
                  width: '100%',
                  overflowY: 'auto',
                  overflowX: 'hidden',
                  height: '100%',
                  backgroundColor: menuBgColor,
                  borderRightColor: token.colorBorderSecondary
                }}
                className={clx(styles.bMenuContainer)}
              >
                <LayoutSidebarMenu title={aMenuTtitle} items={bMenu} selectedKeys={bMenuSelectedKeys} />
              </div>
            }
          >
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              {children}
            </div>
          </SidenavContainer>
        </div>
      </Layout>
    </>
  );
};

export default BaseNavLeft;

import { FC } from 'react';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';
import { getDefaultUrl } from '@/utils/menu';
// import routes from '@/routes'
import NProgress from '@/config/nprogress';
import { BitzLocalStorage } from 'bitz-react-admin-ui';
import { isMobile } from 'react-device-detect';

interface AuthRouterProps {
  /**
   * title-项目标题
   */
  title?: any;
  /**
   * 嵌套页面
   */
  children?: any;
}

const AuthRouter: FC<AuthRouterProps> = ({ title, children }) => {
  const defaultUrl = getDefaultUrl();
  // const routesData = getFlatRoutes(routes)
  //NProgress.start()
  const { pathname } = useLocation();
  const lzhHostToken = BitzLocalStorage.getItem('lzhHostToken') || null;
  const isLock = localStorage.getItem('lzhHostLock') !== null;
  const [params] = useSearchParams();

  if (isLock) {
    return <Navigate to="/LockScreen" replace />;
  }
  if (lzhHostToken && pathname === '/login') {
    const NavigateRouer = isMobile ? '/apphome' : defaultUrl;
    return <Navigate to={NavigateRouer} replace />;
  }

  if (pathname === '/login') {
    NProgress.done();
    return children;
  }

  if (pathname === '/loginToken') {
    return children;
  }

  if (!lzhHostToken && ['/login', '/permission', '/404', '/LockScreen'].includes(pathname)) return <Navigate to="/login" replace />;
  // const temp = routesData.filter((item: { path: string }) => item.path === pathname)
  // if (temp[0]?.title) {
  //   title += '-' + temp[0].title
  // }
  document.title = title;
  //NProgress.done()
  return children;
};

export default AuthRouter;

import React, {FC, useEffect, useMemo, useState} from "react";
import {Button, Flex, InputNumber, InputNumberProps, Popover, Space} from "antd";
import {FieldTimeOutlined, MinusOutlined, PlusOutlined} from "@ant-design/icons";
import {workDateStore} from "@/store";
import {useShallow} from "zustand/react/shallow";
import {useControllableValue} from "ahooks";
import { Decimal } from "decimal.js"
import styles from "@/businessComponents/BnTimer/index.module.less";

interface WorkInoutNumberProps extends InputNumberProps {
  isFilter?: boolean
  hideBtn?: boolean
}

const WorkInoutNumber: FC<WorkInoutNumberProps> = (props) => {
  const minTime = workDateStore(useShallow((state) => state.minTime))
  const {
    step = minTime,
    value,
    onChange,
    isFilter,
    hideBtn = true,
    ...rest
  } = props
  const [open, setOpen] = useState(false);

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const [state, setState] = useControllableValue(props, {
    defaultValue: minTime || 0.1,
  });

  const addDisabled = useMemo(() => {
    const num = new Decimal(state || 0.1).add(step)
    return num.toNumber() > 23.9
  }, [state, step])
  const cutDisabled = step >= state

  const add = () => {
    const state1 = new Decimal(state || 0.1).add(step)
    setState(state1.toNumber())
  }

  const cut = () => {
    const state1 = new Decimal(state || 0.1).sub(step)
    setState(state1.toNumber())
  }

  const array = [
    0.1, 0.2, 0.3, 0.4, 0.5,
    0.6, 0.7, 0.8, 0.9, 1.0, 1.2, 1.4, 1.5, 1.9, 2.2, 2.5, 2.9,
    3.5, 4.2, 4.9, 5.5, 6.2, 6.9, 7.5, 8.0
  ];

  const handleClick = (num) => {
    setState(num)
    setOpen(false);
  }

  const content = (
    <div className={styles.container}>
      {
        array.map(v => (
          <div
            onClick={() => handleClick(v)}
            className={styles.tagstyle}
            key={v}
          >{v.toFixed(1)}</div>
        ))
      }
    </div>
  )

  const contentInputNumber = (
    <Space.Compact block>
      {
        hideBtn && (
          <Button
            onClick={cut}
            disabled={cutDisabled || rest?.disabled}
            icon={<MinusOutlined />}
          />
        )
      }

      <InputNumber
        precision={1}
        style={{ width: '100%' }}
        value={state}
        onChange={setState}
        step={step}
        controls={false}
        min={minTime || 0.1}
        max={23.9}
        {...rest}
      />
      {
        hideBtn && (
          <Button
            onClick={add}
            disabled={addDisabled || rest?.disabled}
            icon={<PlusOutlined />}
          />
        )
      }
    </Space.Compact>
  )

  return isFilter ? (
    <Flex gap={10}>
      {contentInputNumber}
      <Popover
        trigger="click"
        color="var(--ant-color-primary-bg)"
        content={content}
        placement="bottomRight"
        open={open}
        onOpenChange={handleOpenChange}
      >
        <Button icon={<FieldTimeOutlined />} />
      </Popover>
    </Flex>
  ) : contentInputNumber
}

export default WorkInoutNumber;

/*
 * # Title: BnSelectRole Component
 * - Description:
 * - Date: 2024-03-25
 * - LastEditors: liujin liujin@elinklaw.com
 * - LastEditTime: 2024-05-13 10:22:13
 */

import { useMemo } from 'react'
import BnListSelect from '../BnListSelect'

const BnRolePicker = (props) => {

  const enumKey = useMemo(() => {
    return props?.roleType ?? 'Role'
  }, [props])

  return <BnListSelect mode={props.mode} placeholder="请选择" enumKey={enumKey} {...props} />
}

export default BnRolePicker

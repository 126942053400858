import { run } from '@/utils';
import { useControllableValue } from 'ahooks';
import { Switch, SwitchProps } from 'antd';
import { isNil } from 'lodash-es';
import { FC } from 'react';
import { compact } from '../utils';

export type BzSwitchValue = boolean | 'Y' | 'N';
export interface BzSwitchProps extends Omit<SwitchProps, 'onChange' | 'value' | 'defaultValue'> {
  isYn?: boolean;
  label?: ReactNode;
  labelGap?: number;
  labelPosition?: 'left' | 'right';
  bodyStyle?: CSSProperties;
  defaultValue?: BzSwitchValue;
  value?: BzSwitchValue;
  onChange?: (value: boolean | 'Y' | 'N') => void;
}

/**
 * 重写 antd.Switch
 * - 支持 label 文本
 * - 支持 labelPosition 显示位置
 * - 支持 isYn 以及嗅探 isYn
 */
export const BzSwitch: FC<BzSwitchProps> = (props) => {
  const { isYn, checked, value = checked, defaultValue, labelGap = 8, label, labelPosition = 'left', bodyStyle, ...extra } = props;
  // 自动嗅探isYn模式
  const isYn$ = isYn ? true : run(value, typeof value === 'string', typeof defaultValue === 'string');
  const value$ = run(isYn$ && !isNil(value), () => value === 'Y', value);
  const defaultValue$ = run(isYn$ && !isNil(defaultValue), () => defaultValue === 'Y', defaultValue);
  const props$ = compact({ value: value$, defaultValue: defaultValue$, ...extra }, isNil);
  const [state, setState] = useControllableValue(props$);
  const state$ = run(isYn$ && !isNil(state), () => (typeof state === 'string' ? state === 'Y' : state), value);

  return (
    <Flex gap={labelGap} style={{ display: 'inline-flex', ...bodyStyle }}>
      {run(label, () => (
        <span style={{ order: labelPosition === 'left' ? 1 : 20 }}>{label}</span>
      ))}
      <span style={{ flex: 1, order: 10 }}>
        <Switch
          {...props$}
          value={state$}
          onChange={(value) => {
            const value$ = isYn$ ? (value ? 'Y' : 'N') : value;
            console.log('value$', label, isYn$, value$);
            setState(value$);
          }}
        />
      </span>
    </Flex>
  );
};

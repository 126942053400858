import { Col, FormProps, Row, theme } from 'antd';
import React, { FC, memo, ReactElement, useEffect, useMemo } from 'react';
import clx from 'classnames';
import { css } from '@emotion/css';

export interface DefaultFilterProps {
  searchGutter?: number | [number, number];

  collapsed?: boolean;

  items: React.ReactNode[];

  spanSize: {
    span: number;
    layout: FormProps['layout'];
  };

  showLength: number;
  baseClassName: string;
  preserve?: boolean;

  setNeedCollapseRender: (show: boolean) => void;
  setCollapsed: (show: boolean) => void;
}

const DefaultFilter: FC<DefaultFilterProps> = (props) => {
  const { searchGutter = [24, 0], collapsed, items, spanSize, showLength, setNeedCollapseRender, setCollapsed } = props;
  const { token } = theme.useToken();
  // totalSpan 统计控件占的位置，计算 offset 保证查询按钮在最后一列
  let totalSpan = 0;
  let itemLength = 0;
  // 首个表单项是否占满第一行
  let firstRowFull = false;
  // totalSize 统计控件占的份数
  let totalSize = 0;

  // for split compute
  let currentSpan = 0;

  // 处理过，包含是否需要隐藏的 数组
  const processedList = items?.map((item, index) => {
    const colSize = React.isValidElement<any>(item) ? (item?.props?.colSize ?? 1) : 1;
    const colSpan = Math.min(spanSize.span * (colSize || 1), 24);

    // 计算总的 totalSpan 长度
    totalSpan += colSpan;
    // 计算总的 colSize 长度
    totalSize += colSize;

    if (index === 0) {
      firstRowFull = colSpan === 24 && !(item as ReactElement<{ hidden: boolean }>)?.props?.hidden;
    }

    // const hidden: boolean = (item as ReactElement<{ hidden: boolean }>)?.props?.hidden ||
    //   // 如果收起了
    //   // 如果 超过显示长度 且 总长度超过了 24
    //   (collapsed && (firstRowFull || totalSize > showLength - 1) && !!index && totalSpan >= 24)

    // 首先，尝试从React元素的props中获取hidden属性
    const reactElement = item as ReactElement<{ hidden: boolean }>;
    const isHiddenInProps = reactElement?.props?.hidden;

    // 然后，定义一些条件，用于判断元素是否应该被隐藏
    // const isOverShowLength = totalSize > showLength - 1;
    const isOverShowLength = totalSize > showLength;
    const isOverTotalSpan = totalSpan >= 24;
    const isNotFirstItem = !!index;

    // 判断是否收起了且满足隐藏条件
    const shouldHideDueToCollapse = collapsed && (firstRowFull || isOverShowLength) && isNotFirstItem && isOverTotalSpan;

    // 最后，将两个条件进行逻辑或操作，得出元素是否应该被隐藏
    const hidden: undefined | boolean = isHiddenInProps || shouldHideDueToCollapse;

    itemLength += 1;

    const itemKey = (React.isValidElement(item) && (item.key || `${(item.props as Record<string, any>)?.name}`)) || index;

    if (React.isValidElement(item) && hidden) {
      if (!props.preserve) {
        return {
          itemDom: null,
          colSpan: 0,
          hidden: true
        };
      }
      return {
        itemDom: React.cloneElement(item, {
          hidden: true,
          key: itemKey || index
        } as Record<string, any>),
        hidden: true,
        colSpan
      };
    }

    return {
      itemDom: item,
      colSpan,
      hidden: false
    };
  });

  /** 是否需要展示 collapseRender */
  const needCollapseRender = useMemo(() => {
    if (totalSpan < 24 || totalSize <= showLength) {
      return false;
    }
    return true;
  }, [totalSize, showLength, totalSpan]);

  useEffect(() => {
    setNeedCollapseRender(needCollapseRender);
  }, [needCollapseRender]);

  const doms = processedList?.map((itemProps, index: number) => {
    const { itemDom, colSpan } = itemProps;
    const hidden: boolean = (itemDom as ReactElement<{ hidden: boolean }>)?.props?.hidden;

    if (hidden) return itemDom;

    // 每一列的key, 一般是存在的
    // @ts-ignore
    const itemKey = (React.isValidElement(itemDom) && (itemDom.key || `${itemDom.props?.name}`)) || index;

    if (24 - (currentSpan % 24) < colSpan) {
      // 如果当前行空余位置放不下，那么折行
      totalSpan += 24 - (currentSpan % 24);
      currentSpan += 24 - (currentSpan % 24);
    }

    currentSpan += colSpan;

    if (currentSpan % 24 === 0 && index < itemLength - 1) {
      return (
        <Col key={itemKey} span={colSpan} className={`bitz-row-split-line bitz-row-split`.trim()}>
          {itemDom}
        </Col>
      );
    }

    return (
      <Col key={itemKey} className={`bitz-row-split`.trim()} span={colSpan}>
        {itemDom}
      </Col>
    );
  });

  return [
    <Row gutter={searchGutter} justify="start" className="bitz-row normalFilter" key="resize-observer-row">
      {doms}
    </Row>,
    !!doms?.length && (
      <div key="advancedFilterDivider" className="advancedFilterDivider">
        {needCollapseRender && (
          <span
            key="advancedFilterDivider-text"
            onClick={() => setCollapsed(!collapsed)}
            className={clx(
              'advancedFilterDivider-text',
              css`
                color: ${token.colorPrimary};
              `
            )}
          >
            {collapsed ? '展开' : '收起'}
          </span>
        )}
      </div>
    )
  ];
};

export default memo(DefaultFilter);

import React, { createContext, Dispatch, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { StateProps, ActionProps } from './reducer'
import { isArray, merge } from 'lodash-es'
import useMergedState from '@/hooks/useMergedState'
import { genColumnKey } from './utils'
import { GetAdvancedWhereFieldDto } from '@/services/GeneralPublicInterfaceApi'

export interface ContextProps {
  state: StateProps
  dispatch: Dispatch<ActionProps>
}

export type ColumnsState = {
  show?: boolean
  name?: string
  label?: string
  order?: number
  disable?:
    | boolean
    | {
        checkbox: boolean
      }
}

export type ColumnStateType = {
  /**
   * 持久化的类型，支持 localStorage 和 sessionStorage
   *
   * @param localStorage 设置在关闭浏览器后也是存在的
   * @param sessionStorage 关闭浏览器后会丢失
   */
  persistenceType?: 'localStorage' | 'sessionStorage'
  /** 持久化的key，用于存储到 storage 中 */
  persistenceKey?: string
  /** ColumnsState 的值，columnsStateMap将会废弃 */
  defaultValue?: Record<string, any>
  /** ColumnsState 的值，columnsStateMap将会废弃 */
  value?: Record<string, any>
  onChange?: (map: Record<string, any>) => void
}

export type UseQueryContainerProps<T = any> = {
  columns?: any[]
  columnsState?: ColumnStateType
  children?: ReactNode | ReactNode[]
}

function useQueryContainer(props: UseQueryContainerProps = {} as Record<string, any>) {
  // 用于排序的数组
  const sortKeyColumns = useRef<string[]>([])
  // 启用高级搜索
  const advanced = useRef<boolean>(false)
  // 高级搜索数据列表
  const advancedWhere = useRef<GetAdvancedWhereFieldDto[]>([])
  const inputWhere = useRef<GetAdvancedWhereFieldDto[]>([])

  const children = useMemo(() => {
    // @ts-ignore
    if (!props?.children?.props?.children) {
      return []
    }
    // @ts-ignore
    if (isArray(props?.children?.props?.children)) {
      // @ts-ignore
      return props?.children?.props?.children
    }
    // @ts-ignore
    return [props?.children?.props?.children]
  }, [props?.children])

  /** 默认全选中 */
  const defaultColumnKeyMap = useMemo(() => {
    if (props?.columnsState?.defaultValue) {
      return props.columnsState.defaultValue
    }

    const columnKeyMap = {} as Record<string, any>

    children?.forEach((item, index) => {
      // eslint-disable-next-line no-unsafe-optional-chaining
      const { name, disable, label } = item?.props
      const columnKey = genColumnKey(name as React.Key, index)
      if (columnKey) {
        columnKeyMap[columnKey] = {
          show: true,
          disable,
          label,
        }
      }
    })

    return columnKeyMap
  }, [children])

  const [columnsMap, setColumnsMap] = useMergedState<Record<string, ColumnsState>>(
    () => {
      const { persistenceType, persistenceKey } = props.columnsState || {}

      if (persistenceKey && persistenceType && typeof window !== 'undefined') {
        /** 从持久化中读取数据 */
        const storage = window[persistenceType]
        try {
          const storageValue = storage?.getItem(persistenceKey)
          if (storageValue) {
            if (props?.columnsState?.defaultValue) {
              return merge(JSON.parse(storageValue), props?.columnsState?.defaultValue)
            }
            return JSON.parse(storageValue)
          }
        } catch (error) {
          console.warn(error)
        }
      }
      return props.columnsState?.value || props.columnsState?.defaultValue || defaultColumnKeyMap
    },
    {
      value: props.columnsState?.value,
      onChange: props.columnsState?.onChange,
    },
  )

  /**  配置或列更改时对columnsMap重新赋值 */
  useEffect(() => {
    const { persistenceType, persistenceKey } = props.columnsState || {}

    if (persistenceKey && persistenceType && typeof window !== 'undefined') {
      /** 从持久化中读取数据 */
      const storage = window[persistenceType]
      try {
        const storageValue = storage?.getItem(persistenceKey)
        if (storageValue) {
          if (props?.columnsState?.defaultValue) {
            setColumnsMap(merge(JSON.parse(storageValue), props?.columnsState?.defaultValue))
          } else {
            setColumnsMap(JSON.parse(storageValue))
          }
        } else {
          setColumnsMap(defaultColumnKeyMap)
        }
      } catch (error) {
        console.warn(error)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.columnsState?.persistenceKey, props.columnsState?.persistenceType, defaultColumnKeyMap])

  /** 清空一下当前的 key */
  const clearPersistenceStorage = useCallback(() => {
    const { persistenceType, persistenceKey } = props.columnsState || {}

    if (!persistenceKey || !persistenceType || typeof window === 'undefined') return

    /** 给持久化中设置数据 */
    const storage = window[persistenceType]
    try {
      storage?.removeItem(persistenceKey)
    } catch (error) {
      console.warn(error)
    }
  }, [props.columnsState])

  useEffect(() => {
    if (!props.columnsState?.persistenceKey || !props.columnsState?.persistenceType) {
      return
    }
    if (typeof window === 'undefined') return
    /** 给持久化中设置数据 */
    const { persistenceType, persistenceKey } = props.columnsState
    const storage = window[persistenceType]
    try {
      storage?.setItem(persistenceKey, JSON.stringify(columnsMap))
    } catch (error) {
      console.warn(error)
      clearPersistenceStorage()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.columnsState?.persistenceKey, columnsMap, props.columnsState?.persistenceType])

  const renderValue = {
    advancedWhere: advancedWhere.current,
    setAdvancedWhere: (keys: GetAdvancedWhereFieldDto[]) => {
      advancedWhere.current = keys
    },
    inputWhere: inputWhere.current,
    setInputWhere: (keys: GetAdvancedWhereFieldDto[]) => {
      inputWhere.current = keys
    },
    sortKeyColumns: sortKeyColumns.current,
    setSortKeyColumns: (keys: string[]) => {
      sortKeyColumns.current = keys
    },
    advanced: advanced.current,
    setAdvanced: (keys: boolean) => {
      advanced.current = keys
    },
    columnsMap,
    setColumnsMap,
    clearPersistenceStorage,
    defaultColumnKeyMap,
  }

  Object.defineProperty(renderValue, 'sortKeyColumns', {
    get: (): string[] => sortKeyColumns.current,
  })

  Object.defineProperty(renderValue, 'advanced', {
    get: (): boolean => advanced.current,
  })

  Object.defineProperty(renderValue, 'advancedWhere', {
    get: (): GetAdvancedWhereFieldDto[] => advancedWhere.current,
  })

  Object.defineProperty(renderValue, 'inputWhere', {
    get: (): GetAdvancedWhereFieldDto[] => inputWhere.current,
  })

  return renderValue
}

export type ContainerType = typeof useQueryContainer

type ContainerReturnType = ReturnType<ContainerType>

export const MyContext = createContext({} as ContextProps & ContainerReturnType)

const MyProvide = (props: React.PropsWithChildren<ContextProps>) => {
  const value = useQueryContainer(props)

  return (
    <MyContext.Provider
      value={{
        // state: props.state,
        // dispatch: props.dispatch,
        ...props,
        ...value,
      }}
    >
      {props.children}
    </MyContext.Provider>
  )
}

export default MyProvide

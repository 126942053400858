import { ColumnsConfigProps } from 'bitz-react-admin-ui/dist/components/BitzTable';
import { ExportConfigProps, StylesConfigProps } from 'bitz-react-admin-ui/dist/components/BitzTable/Store';
import { create } from 'zustand';
import api from '@/services/GeneralPublicInterface';
import { CreateUserCustomSettingInput } from '@/services/GeneralPublicInterfaceApi';
import { HeaderNoProcess } from '@/constants';

type TableStylesConfigType = Partial<EnumToObj<TableKeys, StylesConfigProps>>;

interface FetchParams<T> {
  module: T;
  settingType: CreateUserCustomSettingInput['settingType'];
}

interface UpdateParams<T, P> {
  module: T;
  settingType: CreateUserCustomSettingInput['settingType'];
  setting: P;
}

export const fetchStylesConfig = async (params: FetchParams<TableSizeKey>) => {
  const { response } = await api.commonUsercustomsettingInfoList(params, { headers: { ...HeaderNoProcess } });
  return response?.setting as TableStylesConfigType;
};

export const updateStylesConfig = async (params: UpdateParams<TableSizeKey, TableStylesConfigType>) => {
  await api.commonUsercustomsettingCreate(params);
  return params.setting;
};

export const fetchColumns = async (params: FetchParams<TableColumnsKey>) => {
  const { response } = await api.commonUsercustomsettingInfoList(params);
  return response?.setting as Record<string, ColumnsConfigProps>;
};

export async function updateColumns(params: UpdateParams<TableColumnsKey, Record<string, ColumnsConfigProps>>) {
  await api.commonUsercustomsettingCreate(params);
  return params.setting;
}

export const fetchExportColumns = async (params: FetchParams<TableExportColumnsKey>) => {
  const { response } = await api.commonUsercustomsettingInfoList(params);
  return response?.setting as ExportConfigProps;
};

export async function updateExportColumns(params: UpdateParams<TableExportColumnsKey, ExportConfigProps>) {
  await api.commonUsercustomsettingCreate(params);
  return params.setting;
}

interface TableConfigState {
  stylesConfig?: TableStylesConfigType;
  columns?: Record<string, ColumnsConfigProps>;
  exportColumns?: ExportConfigProps;
  setStylesConfig: typeof updateStylesConfig;
  getStylesConfig: typeof fetchStylesConfig;
  setColumns: typeof updateColumns;
  getColumns: typeof fetchColumns;
  setExportColumns: typeof updateExportColumns;
  getExportColumns: typeof fetchExportColumns;
}

const tableConfigStore = create<TableConfigState>()((set) => ({
  tylesConfig: undefined,
  columns: undefined,
  exportColumns: undefined,
  getStylesConfig: async (params) => {
    const data = await fetchStylesConfig(params);
    set({ stylesConfig: data });
    return data || undefined;
  },
  setStylesConfig: async (params) => {
    const newData = await updateStylesConfig(params);
    set({ stylesConfig: newData });
    return newData || undefined;
  },
  getColumns: async (params) => {
    const data = await fetchColumns(params);
    set({ columns: data });
    return data || undefined;
  },
  setColumns: async (params) => {
    const newData = await updateColumns(params);
    set({ columns: newData });
    return newData || undefined;
  },
  getExportColumns: async (params) => {
    const data = await fetchExportColumns(params);
    set({ exportColumns: data });
    return data || undefined;
  },
  setExportColumns: async (params) => {
    const newData = await updateExportColumns(params);
    set({ exportColumns: newData });
    return newData || undefined;
  }
}));

export default tableConfigStore;

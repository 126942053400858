import { RouteConfig } from '@/router/config'

const flattenRoutes = (routes: RouteConfig[]) => {
  const flattenedRoutes: RouteConfig[] = []
  const flattenRoute = (route, parentPath = '') => {
    const isRoot = parentPath === ''
    const routePath = isRoot ? route.path : `${parentPath}/${route.path}`
    // @ts-ignore
    flattenedRoutes.push({
      path: routePath,
      meta: route.meta,
      name: route.name,
      color: route.color,
      iconName: route.iconName,
    })
    if (route.children) {
      route.children.forEach((childRoute) => {
        flattenRoute(childRoute, routePath)
      })
    }
  }
  routes.forEach((route) => flattenRoute(route))
  return flattenedRoutes
}

export default flattenRoutes

import { FC, memo, useContext, useEffect, useMemo } from 'react';
import { DatePickerProps, TimePicker, TimePickerProps } from 'antd';
import { MyContext } from '../../BitzQueryFilterContext';
import dayjs from 'dayjs';
import withFluent from '@/utils/WithFluent';
import { omit } from 'lodash-es';
// import { BitzFluentTimePicker } from 'bitz-react-admin-ui'

function Picker({ placeholder, ...props }) {
  return <TimePicker {...props} placeholder={' '} />;
}

const BitzFluentTimePicker = withFluent(Picker);

export interface BQFTimePicker extends TimePickerProps {}

const BQFTimePicker: FC<BQFTimePicker> = (props) => {
  const { dispatch } = useContext(MyContext);
  const { onChange, ...rest } = props;

  const formatType = 'HH:mm';

  const id = useMemo(() => {
    if (props.id) {
      // @ts-ignore
      if (props.id?.indexOf('advanced') !== -1) {
        // @ts-ignore
        const list = split(props.id, '_');
        return list[list.length - 1];
      }
      return props.id;
    }
    return '';
  }, [props.id]);

  useEffect(() => {
    if (id) {
      dispatch?.({
        type: 'update',
        name: id,
        value: props?.value
      });
    }
  }, [props?.value, id]);

  const handleChange: DatePickerProps['onChange'] = (date, dateString) => {
    const time = date ? dayjs(date) : undefined;
    const timeText = time?.format(formatType);
    // @ts-ignore
    onChange?.(time?.format('HH:mm:ss'));
    if (id) {
      dispatch?.({
        type: 'update',
        name: id,
        valueName: timeText
      });
    }
  };

  const onValuesChange = (dates: any, dateStrings: any, info: any) => {
    handleChange(dates, dateStrings);
  };

  const value = useMemo<DatePickerProps['value']>(() => {
    if (props?.value) {
      const timeStr: string = props?.value && dayjs().format('YYYY-MM-DD') + ' ' + props.value;
      return dayjs(timeStr);
    }
    return undefined;
  }, [props?.value]);

  return (
    // @ts-ignore
    <BitzFluentTimePicker
      onCalendarChange={onValuesChange}
      format={formatType}
      style={{ width: '100%' }}
      value={value}
      onChange={handleChange}
      {...omit(rest, 'value')}
    />
  );
};

export default memo(BQFTimePicker);

import { FC, useEffect, useState } from 'react'
import apiCommon from '@/services/GeneralPublicInterface'
import { Flex, SelectProps, Spin, theme, Typography } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { useQuery } from '@tanstack/react-query'
import { ItemDto } from '@/services/GeneralPublicInterfaceApi'
import BitzQueryFilter2 from '@/components/BitzQueryFilter2'
import { getShowSearch } from '@/utils/mobile'
import { BitzSelect } from 'bitz-react-admin-ui'
import { camelCase } from 'lodash-es'

interface BnEnumSelectProps
  extends Omit<SelectProps, 'loading' | 'options' | 'fieldNames' | 'maxTagCount' | 'optionFilterProp' | 'notFoundContent'> {
  /** 枚举key，首字母小写 */
  enumKey: string
  fluent?: boolean
  currentKey?: string
  filterKeys?: string[]
}

/** enum下拉列表 */
const BnEnumSelect: FC<BnEnumSelectProps> = ({ enumKey, fluent = false, currentKey, filterKeys = [], ...rest }) => {
  const [options, setOptions] = useState<ItemDto[]>()
  const { token } = theme.useToken()

  const loadAgainStyle = {
    paddingBlock: 40,
    cursor: 'pointer',
  }

  /** 获取enum列表 */
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: ['bnEnumSelect', enumKey],
    queryFn: ({ signal }) => {
      return apiCommon.enumList({ enumTypeString: camelCase(enumKey) }, { signal })
    },
    retry: false,
    networkMode: 'always',
    staleTime: 3 * 60 * 1000
  })

  useEffect(() => {
    if (data) {
      let arr: any[] = data?.response?.[currentKey ? currentKey : camelCase(enumKey)] || []
      if (filterKeys?.length > 0 && enumKey === 'sectionTypeEnum') {
        // 分段类型枚举处理禁用项
        arr = arr.map((m: any) => {
          if (filterKeys.includes(m.key)) {
            m = { ...m, disabled: true }
          }
          return m
        })
      }
      setOptions(arr)
    }
  }, [data])

  const dropdownRender = (node) => {
    if (isLoading) {
      return (
        <Flex justify="center" align="center" style={{ height: 50 }}>
          <Spin />
        </Flex>
      )
    } else if (isError) {
      return (
        <Flex vertical align="center" style={loadAgainStyle} onClick={() => refetch()}>
          <InfoCircleOutlined style={{ fontSize: 20, color: token.colorPrimary }} />
          <Typography.Text type="danger">{'加载失败，点击重试'}</Typography.Text>
        </Flex>
      )
    } else {
      return node
    }
  }

  return (
    <>
      {!fluent && (
        <BitzQueryFilter2.Select
          placeholder="请选择"
          loading={isLoading}
          // @ts-ignore
          options={options}
          fieldNames={{
            label: 'description',
            value: 'key',
          }}
          maxTagCount="responsive"
          optionFilterProp="description"
          allowClear
          showSearch={getShowSearch()}
          dropdownRender={dropdownRender}
          {...rest}
        />
      )}
      {fluent && (
        <BitzSelect
          // @ts-ignore
          placeholder="请选择"
          loading={isLoading}
          options={options}
          fieldNames={{
            label: 'description',
            value: 'key',
          }}
          maxTagCount="responsive"
          notFoundContent={
            isError ? (
              <Flex vertical align="center" style={loadAgainStyle} onClick={() => refetch()}>
                <InfoCircleOutlined style={{ fontSize: 20, color: token.colorPrimary }} />
                <Typography.Text type="danger">{'加载失败，点击重试'}</Typography.Text>
              </Flex>
            ) : undefined
          }
          optionFilterProp="description"
          allowClear
          showSearch={getShowSearch()}
          {...rest}
        />
      )}
    </>
  )
}

export default BnEnumSelect

import dayjs from 'dayjs';
import { DictDB } from './dict.db';
import { groupBy, isEmpty } from 'lodash-es';
import { mapping, picking, toTry } from '@/utils';
import { refetch } from '@/utils/refetch';
import { chunkFetch } from '@/v2/utils';

export type DictInfo = { inx?: number; updateDate: string };
export const dictInfoKey = 'dict/info';
export const formatter = 'YYYY-MM-DD';
export const dictInfoDefaultValue = { inx: 0, updateDate: dayjs().format(formatter) };
export const dictMapper = { key: 'id', label: 'displayName', value: 'code', group: 'class', code: 'code', parentCode: 'parentCode' };

export function workerApi(path: string) {
  const prefix = import.meta.env.VITE_API_URL;
  return `${prefix}${path}`;
}

/**
 * 使用分片拉取数据的形式本地缓存数据
 */
export async function cacheChunksFetch(key: string, api: string, options: Row = {}) {
  const data = await chunkFetch(api, options);

  return data;

  /**
   * 数据为空 则不写入数据
   * @todo 更多的判空手段
   */
  // if (data?.length) {
  //   await DictDB.setItem(key, data);
  //   await DictDB.setItem(getUpdateDictKey(key), Date.now());
  // }
}

/**
 * 设置缓存数据key
 * @param key
 * @returns
 */
export function getUpdateDictKey(key: string) {
  return `${key}/update`;
}

/**
 * 获取apis
 * dict/key
 */
export function getDictApisKey() {
  return `dict/apis`;
}

export async function getDictInfo() {
  const dictInfo = await DictDB.getItem<DictInfo>(dictInfoKey);
  return dictInfo;
}
export async function setDictInfo(key, api) {
  const dictInfo = await DictDB.getItem<DictInfo>(dictInfoKey);
  const dictInfo$ = { ...dictInfo, [key]: api };
  await DictDB.setItem(dictInfoKey, dictInfo$);
}

/**
 * 判断是否可以更新字典
 * @param key
 */
export async function canUpdateDict(key: string) {
  const updateDictKey = getUpdateDictKey(key);
  const prev = dayjs(await DictDB.getItem(updateDictKey));
  const now = dayjs();
  return !now.isSame(prev, 'day');
}

/**
 * 判断是否可以缓存字典
 */
// export async function canCacheDict(dictLength: number) {
//   const formatter = 'YYYY-MM-DD';

//   const { inx, updateDate } = await getDictInfo();
//   const current = dayjs().format(formatter);

//   // 若为同一天且字典索引小于总长度，则更新缓存数据
//   if (current === updateDate && inx < dictLength) {
//     return true;
//   }

//   // 若时间不同，即当天第一次访问，索引从头到尾重新更新
//   if (current !== updateDate && inx === dictLength) {
//     // 更新缓存数据
//     await DictDB.setItem(dictInfoKey, { inx: 0, updateDate: current });
//     return true;
//   }

//   return false;
// }

// 一天一更新
export async function canCacheDict(key?: string) {
  if (key) {
    // 若本地没有缓存，直接执行
    const cache = await DictDB.getItem(key);
    if (isEmpty(cache)) return true;
  }

  const info = await getDictInfo();
  if (isEmpty(info)) return true;

  const { updateDate } = info;
  const formatter = 'YYYY-MM-DD';
  const current = dayjs().format(formatter);
  return current !== updateDate;
}

/**
 * 数据字典数据转换
 */
export async function cacheDictDB(data: Rows = []) {
  console.log('cacheDictDB', data);
  if (isEmpty(data)) return;
  const data$ = mapping(data, dictMapper);
  const data$group = groupBy(data$, 'group');

  for await (const dict of Object.entries(data$group)) {
    const [key, value] = dict;
    // @todo 判断是否需要保存 parentCode/code
    const data = picking(value, {
      value: 'code',
      label: 'displayName',
      display: 'displayName',
      code: 'code',
      parentCode: 'parentCode',
      group: 'class',
      sort: 'sort'
    });
    await DictDB.setItem(key, data);
    await DictDB.setItem(getUpdateDictKey(key), Date.now());
  }

  await setDictInfo('updateDate', dayjs().format(formatter));
}

export async function cacheDictAll({ headers }) {
  const api = workerApi('/api/generalcode/list');
  const { response } = await refetch(api, { method: 'get', headers });
  cacheDictDB(response);
}

export async function cacheOffice({ headers }) {
  const key = 'SysOffice';
  const api = workerApi('/api/web/sys/organizationunit/page');
  const options = { method: 'post', body: { thresholdFlag: 1, parentId: '1795887842078101504', page: 1, size: 99 } };
  const mapper = { value: 'id', label: 'displayName' };
  const { response } = await refetch(api, { ...options, headers });
  const data = mapping(response?.data, { value: 'id', label: 'displayName' });
  await DictDB.setItem(key, data);
  await DictDB.setItem(getUpdateDictKey(key), Date.now());
  await setDictInfo(key, { api, mapper, ...options, last: dayjs().format() });
}
export async function cacheOfficeTaxRate({ headers }) {
  const key = 'ExtraOfficeTaxRates';
  const api = workerApi('/api/web/financial/base/financialtaxrate/list');
  const options = { method: 'post', body: { page: 1, size: 9999 } };
  const mapper = { value: 'taxRate', label: `taxRate ?? {taxRate}%` };
  const { response } = await refetch(api, { method: 'post', body: { page: 1, size: 9999 }, headers });
  const data = mapping(response?.data ?? response, mapper);
  await DictDB.setItem(key, data);
  await DictDB.setItem(getUpdateDictKey(key), Date.now());
  await setDictInfo(key, { api, mapper, ...options, last: dayjs().format() });
}

/**
 * 保存不属于字典的缓存数据接口信息
 * 用于 useDict 找不到值，重新请求数据使用
 */
export async function saveExtraDict(key, value) {
  const info = (await DictDB.getItem('apis')) ?? {};
  await DictDB.setItem('apis', { ...info, [key]: value });
}

/**
 * 写入UI信息
 */
export async function cacheUserInfo({ headers }) {
  const key = 'userInfo';
  const api = workerApi('/api/web/sys/userinfo/page');

  // 300, 29561
  // 100, 24692
  // 200, 22675
  // 500, 29756

  toTry(async () => {
    const { response } = await refetch(api, { body: { page: 1, size: 1, onlyReturnBaseEntity: true }, method: 'post', headers });
    const size = 200;
    const time = Math.ceil(response.dataCount / size);
    const temp: Promise<any>[] = [];
    for (let page = 1; page <= time; page++) {
      const promise = refetch(api, { body: { page, size }, method: 'post', headers });
      temp.push(promise);
    }
    const result = await Promise.all(temp);
    const data = result.map(({ response }) => response.data);
    const data$flat = data.flat();
    const data$mapping = mapping(data$flat, {
      value: 'id',
      label: 'displayName ?? name',
      display: 'displayName'
    });
    if (data$mapping?.length) {
      await DictDB.setItem(key, data$mapping);
      await DictDB.setItem(getUpdateDictKey(key), Date.now());
      await saveExtraDict(key, api);
    }
  });
}

export const getLastUpdateRange = async (key: string) => {
  const startDate = await DictDB.getItem<number>(getUpdateDictKey(key));
  const endDate = Date.now();
  console.log(startDate, endDate);
  return { startDate: dayjs(startDate).toISOString(), endDate: dayjs(endDate).toISOString() };
};

export const getLastUpdate = async (key: string) => {
  return await DictDB.getItem<number>(getUpdateDictKey(key));
};

/**
 * 增量更新机制
 */
export const incrementalUpdate = async (key: string, { init, inc, mapper }) => {
  /**
   * 判断Cache是否存在
   * - 若存在则增量请求
   * - 若不存在，普通更新
   */
  const cache = (await DictDB.getItem(key)) as Rows;
  const isExist = !!cache?.length;

  console.log('ooOooooo', key, cache);

  // 增量请求
  if (isExist) {
    const data = await inc?.(key);

    // 没有数据，则返回
    // @todo 验证是否需要重写数据
    if (isEmpty(data)) return;

    // @todo 先粗暴解决
    // 根据值判断是否存在，不存在则新增，存在则更新

    const temp: Rows = [];

    data.forEach((item) => {
      const item$ = mapping(item, mapper);
      const index = cache.findIndex(({ value }) => item$.value === value);
      const isUpdate = index > 0;
      if (isUpdate) {
        cache[index] = item$;
      } else {
        temp.push(item$);
      }
    });

    await DictDB.setItem(key, [...cache, ...temp]);
    await DictDB.setItem(getUpdateDictKey(key), Date.now());

    return;
  }

  // 初始化数据
  const data = await init?.(key);
  if (isEmpty(data)) return;
  await DictDB.setItem(key, data);
  await DictDB.setItem(getUpdateDictKey(key), Date.now());
};

import { create } from 'zustand'
import { persist, createJSONStorage, devtools } from 'zustand/middleware'

/**
 * @description BranchState取名采用大驼峰,State前面的命名与文件名保持一致
 */
interface SetupState {
  setupData: any
}

/**
 * @description BranchStore取名采用大驼峰,Store前面的命名与文件名保持一致
 * @description 以下是有Storage的store写法
 */
const SetupStore = create<SetupState>()(
  devtools(
    persist(
      (set) => ({
        setupData: {},
        setData: (setupData: any) => set(() => ({ setupData })),
      }),
      /**
       * @description languageStorage取名采用大驼峰,language与文件名保持一致，后面则取名为Storage
       */
      {
        name: 'setupStorage',
        storage: createJSONStorage(() => SetupStore),
      },
    ),
  ),
)

export default SetupStore

import React, { lazy } from 'react';
import Layout from '@/layout';
import {
  IconBanbenliebiao1,
  IconBiaodanshezhi,
  IconDuoyuyanguanli,
  IconJiaose,
  IconJigou,
  IconShenjirizhi,
  IconShezhi1,
  IconXitongguanliLinear,
  IconXitongpeizhi,
  IconYonghu1,
  IconZiyuanguanliLinear,
  IconDisanfangchajian
} from '@/assets/iconfont';
import IconRender from '@/components/IconRender';
import NoFoundPage from '@/pages/NotFound';
import { IconNames } from '@/components/iconfont';
import { ENV } from '@/constants';
import { Navigate, RouteObject } from 'react-router-dom';
import { NoPermission } from './NoPermission';

export type Component = React.ComponentType<never> | React.LazyExoticComponent<never> | React.LazyExoticComponent<() => React.ReactElement>;

// export interface RouteConfig extends Omit<RouteObject, 'path' | 'children'> {
export interface RouteConfig {
  index?: boolean;
  path: string; // 路由路径
  component?: Component; // 路由组件
  element?: React.ReactNode; // 路由组件
  name?: string; // 唯一key
  icon?: React.ReactNode; // 菜单图标
  cache?: boolean; // 不填默认缓存
  meta?: { title: string }; // 路由元信息
  notMenu?: boolean; // 不在菜单显示
  children?: Array<this>; // 子路由
  // checkToken?: boolean // 是否需要验证token
  redirect?: string; // 重定向
  search?: boolean; // 是否可以被搜索到
  searchParam?: string; // 用于搜索跳转携带的参数 例如 type=search 会跳转到 /xxx?type=search
  searchKeyWords?: string[]; // 搜索关键字 用于搜索额外匹配 默认匹配 name 和 meta.title
  layout?: boolean; // 是否需要layout
  access?: string[]; // 权限定义返回值的某个 key
  color?: string;
  iconName?: IconNames;
  activeIconName?: IconNames;
}

export type PageConfig = {
  url: string;
  // 路由的名称
  label: string;
  // 路由的 path 值 例如 /home /user?id=1
  key: string;
  // 路由的参数
  state?: any;
};

const isActiveRouter = [ENV.PROD, ENV.UAT].includes(import.meta.env.VITE_ENV);

export const adminRoutes: RouteConfig[] = [
  {
    path: 'Business',
    name: 'Business',
    meta: { title: '业务管理' },
    icon: <IconRender size={16} icon={<IconZiyuanguanliLinear />} />,
    component: React.lazy(() => import('@/components/Children')),
    color: '#465EB8',
    iconName: 'ic_fluent_news_24_regular',
    activeIconName: 'ic_fluent_news_24_filled',
    children: [
      {
        path: 'Conflict',
        name: 'Conflict',
        meta: { title: '利冲管理' },
        icon: <IconRender icon={<IconBanbenliebiao1 />} />,
        iconName: 'a-ic_fluent_position_to_front_24_regular1',
        activeIconName: 'ic_fluent_position_to_front_24_filled',
        component: React.lazy(() => import('@/pages/Works/WorksFlowPre')),
        children: [
          {
            cache: true,
            path: 'ConflictList',
            name: 'ConflictList',
            meta: { title: '利冲列表' },
            component: React.lazy(() => import('@/pages/Business/Conflict/ConflictList'))
            // component: React.lazy(() => import('@/pages/Business/Conflict/ConflictList/Layout')),
            // children: [
            //   {
            //     path: 'InitiatedTable',
            //     name: 'InitiatedTable',
            //     meta: { title: '我发起的' },
            //     notMenu: true,
            //     component: React.lazy(() => import('@/pages/Business/Conflict/ConflictList/InitiatedTable')),
            //   },
            //   {
            //     path: 'UnConfirmedConflictTable',
            //     name: 'UnConfirmedConflictTable',
            //     meta: { title: '待我确认的利冲' },
            //     notMenu: true,
            //     component: React.lazy(() => import('@/pages/Business/Conflict/ConflictList/UnConfirmedConflictTable')),
            //   },
            //   {
            //     path: 'ConfirmedConflictHistoryTable',
            //     name: 'ConfirmedConflictHistoryTable',
            //     meta: { title: '利冲确认历史' },
            //     notMenu: true,
            //     component: React.lazy(() => import('@/pages/Business/Conflict/ConflictList/ConfirmedConflictHistoryTable')),
            //   },
            //   {
            //     path: 'AllConflictTable',
            //     name: 'AllConflictTable',
            //     meta: { title: '所有利冲' },
            //     notMenu: true,
            //     component: React.lazy(() => import('@/pages/Business/Conflict/ConflictList/AllConflictTable')),
            //   }
            // ]
          },
          {
            cache: true,
            path: 'ConflictApplication',
            name: 'ConflictApplication',
            meta: { title: '利冲申请' },
            // notMenu: true,
            component: React.lazy(() => import('@/pages/Business/Conflict/ConflictApplication'))
          },
          {
            cache: true,
            path: 'PreConflictList',
            name: 'PreConflictList',
            meta: { title: '利冲预检' },
            component: React.lazy(() => import('@/pages/Business/Conflict/PreConflictList2'))
          },
          {
            cache: true,
            path: 'ConflictManage',
            name: 'ConflictManage',
            meta: { title: '利冲管理' },
            component: React.lazy(() => import('@/pages/Business/Conflict/ConflictManage'))
          },
          {
            cache: true,
            path: 'ConflictDetailsPage',
            name: 'ConflictDetailsPage',
            meta: { title: '案件详情' },
            notMenu: true,
            component: React.lazy(() => import('@/pages/Business/Conflict/ConflictList/ConflictDetailsPage'))
          }
        ]
      },
      {
        path: 'CaseInfoChange',
        name: 'CaseInfoChange',
        meta: { title: '变更管理' },
        icon: <IconRender icon={<IconBanbenliebiao1 />} />,
        iconName: 'a-24Regular1x1-01',
        activeIconName: 'a-24Filled1x',
        component: React.lazy(() => import('@/components/Children')),
        children: [
          {
            cache: true,
            path: 'ChangeCreate',
            name: 'ChangeCreate',
            meta: { title: '变更申请' },
            component: React.lazy(() => import('@/pages/Business/CaseInfoChange/ChangeCreate'))
          },
          {
            cache: true,
            path: 'ChangeList',
            name: 'ChangeList',
            meta: { title: '变更列表' },
            component: React.lazy(() => import('@/pages/Business/CaseInfoChange/ChangeList'))
          },
          {
            cache: true,
            path: 'ChangeAudit',
            name: 'ChangeAudit',
            meta: { title: '变更审核' },
            component: React.lazy(() => import('@/pages/Business/CaseInfoChange/ChangeAudit'))
          }
        ]
      },
      {
        path: 'CaseRecord',
        name: 'CaseRecord',
        meta: { title: '立案管理' },
        icon: <IconRender icon={<IconBanbenliebiao1 />} />,
        iconName: 'ic_fluent_folder_add_24_regular-01',
        activeIconName: 'ic_fluent_folder_add_24_filled',
        component: React.lazy(() => import('@/pages/Works/WorksFlow')),
        children: [
          {
            cache: true,
            path: 'CaseCreate',
            name: 'CaseCreate',
            meta: { title: '立案申请' },
            notMenu: true,
            component: React.lazy(() => import('@/pages/Business/CaseRecord/CaseCreate'))
          },
          {
            cache: true,
            path: 'CaseList',
            name: 'CaseList',
            meta: { title: '立案列表' },
            component: React.lazy(() => import('@/pages/Business/CaseRecord/CaseList'))
          },
          {
            cache: true,
            path: 'CaseAudit',
            name: 'CaseAudit',
            meta: { title: '立案审核' },
            component: React.lazy(() => import('@/pages/Business/CaseRecord/CaseAudit'))
          },
          {
            cache: true,
            path: 'CaseManage',
            name: 'CaseManage',
            meta: { title: '立案管理' },
            component: React.lazy(() => import('@/pages/Business/CaseRecord/CaseManage'))
          }
        ]
      },
      {
        path: 'CaseClose',
        name: 'CaseClose',
        meta: { title: '结案管理' },
        icon: <IconRender icon={<IconBanbenliebiao1 />} />,
        iconName: 'ic_fluent_folder_zip_24_regular',
        activeIconName: 'ic_fluent_folder_zip_24_filled',
        component: React.lazy(() => import('@/components/Children')),
        children: [
          {
            cache: true,
            path: 'CaseCloseCreate',
            name: 'CaseCloseCreate',
            meta: { title: '结案申请' },
            component: React.lazy(() => import('@/pages/Business/CaseClose/CloseCreate'))
          },
          {
            cache: true,
            path: 'CaseCloseCreateList',
            name: 'CaseCloseCreateList',
            meta: { title: '结案列表' },
            component: React.lazy(() => import('@/pages/Business/CaseClose/CloseList'))
          },
          {
            cache: true,
            path: 'CaseCloseCreateListAudit',
            name: 'CaseCloseCreateListAudit',
            meta: { title: '结案审核' },
            component: React.lazy(() => import('@/pages/Business/CaseClose/CloseAudit'))
          }
        ]
      },
      {
        path: 'Customers',
        name: 'Customers',
        meta: { title: '客户管理' },
        icon: <IconRender icon={<IconBanbenliebiao1 />} />,
        iconName: 'ic_fluent_people_settings_24_regular',
        activeIconName: 'people_settings_24_filled',
        component: React.lazy(() => import('@/components/Children')),
        children: [
          {
            cache: true,
            path: 'CustomersList',
            name: 'CustomersList',
            meta: { title: '客户列表' },
            component: React.lazy(() => import('@/pages/Business/Customers/CustomersList'))
          },
          {
            cache: true,
            path: 'ConflictProtection',
            name: 'ConflictProtection',
            meta: { title: '利冲保护名单' },
            component: React.lazy(() => import('@/pages/Business/Customers/ConflictProtection'))
          },
          {
            cache: true,
            path: 'PayerManagement',
            name: 'PayerManagement',
            meta: { title: '付款方管理' },
            component: React.lazy(() => import('@/pages/Business/Customers/PayerManagement'))
          }
        ]
      }
    ]
  },
  {
    path: 'Works',
    name: 'Works',
    meta: { title: '工时管理' },
    component: React.lazy(() => import('@/pages/Works/WorksLayout')),
    color: '#4EA6E6',
    iconName: 'ic_fluent_calendar_ltr_24_regular',
    activeIconName: 'a-ic_fluent_calendar_ltr_24_filled1',
    children: [
      {
        path: 'WorkHourList',
        name: 'WorkHourList',
        meta: { title: '我的工时' },
        // iconName: 'a-ic_fluent_position_to_front_24_regular1',
        // activeIconName: 'ic_fluent_position_to_front_24_filled',
        component: React.lazy(() => import('@/pages/Works/WorkHourList'))
      },

      /*       {
        path: 'WorkHourShortcut',
        name: 'WorkHourShortcut',
        meta: { title: '工时速记' },
        // iconName: 'a-ic_fluent_position_to_front_24_regular1',
        // activeIconName: 'ic_fluent_position_to_front_24_filled',
        component: React.lazy(() => import('@/pages/Works/WorkHourShortcut')),
      }, */
      {
        path: 'WorkHourManager',
        name: 'WorkHourManager',
        meta: { title: '工时列表' },
        // iconName: 'a-ic_fluent_position_to_front_24_regular1',
        // activeIconName: 'ic_fluent_position_to_front_24_filled',
        component: React.lazy(() => import('@/pages/Works/WorkHourManager'))
      },
      {
        path: 'WorkHourCalendar',
        name: 'WorkHourCalendar',
        meta: { title: '工时日历' },
        // iconName: 'a-ic_fluent_position_to_front_24_regular1',
        // activeIconName: 'ic_fluent_position_to_front_24_filled',
        component: React.lazy(() => import('@/pages/Works/WorkHourCalendar'))
      },
      {
        path: 'WorkHoursDetails',
        name: 'WorkHoursDetails',
        meta: { title: '工时报表' },
        // iconName: 'a-ic_fluent_position_to_front_24_regular1',
        // activeIconName: 'ic_fluent_position_to_front_24_filled',
        component: React.lazy(() => import('@/pages/Works/WorkHoursDetails'))
      },
      {
        path: 'WorkHourSettings',
        name: 'WorkHourSettings',
        meta: { title: '工时设置' },
        // iconName: 'a-ic_fluent_position_to_front_24_regular1',
        // activeIconName: 'ic_fluent_position_to_front_24_filled',
        component: React.lazy(() => import('@/pages/Works/WorkHourSettings'))
      }
    ]
  },
  {
    path: 'Financial',
    name: 'Financial',
    meta: { title: '财务管理' },
    component: React.lazy(() => import('@/components/Children')),
    color: '#4EA6E6',
    iconName: 'ic_fluent_wallet_24_regular',
    activeIconName: 'ic_fluent_wallet_24_filled',
    children: [
      {
        path: 'Billings',
        name: 'Billings',
        meta: { title: '账单管理' },
        icon: <IconRender icon={<IconBanbenliebiao1 />} />,
        iconName: 'zhangdanguanli-xianxing',
        activeIconName: 'zhangdanguanli',
        component: React.lazy(() => import('@/components/Children')),
        children: [
          {
            cache: true,
            path: 'BillApplication',
            name: 'BillApplication',
            meta: { title: '账单申请' },
            component: React.lazy(() => import('@/pages/Financial/Billings/BillApplication'))
          },
          {
            // notMenu: isActiveRouter,
            cache: true,
            path: 'BillList',
            name: 'BillList',
            meta: { title: '账单列表' },
            component: React.lazy(() => import('@/pages/Financial/Billings/BillList'))
            // redirect: '/Billings/BillList/paid',
            // children: [
            //   { notMenu: true, path: 'unpaid', component: React.lazy(() => import('@/pages/Financial/Billings/BillList')), meta: { title: '未出账单' } }
            // ]
          },
          {
            notMenu: isActiveRouter,
            cache: true,
            path: 'BillManagement',
            name: 'BillManagement',
            meta: { title: '账单管理' },
            component: React.lazy(() => import('@/pages/Financial/Billings/BillManagement'))
          }
        ]
      },
      {
        path: 'Invoices',
        name: 'Invoices',
        meta: { title: '发票管理' },
        icon: <IconRender icon={<IconBanbenliebiao1 />} />,
        iconName: 'fapiaoguanli-xianxing',
        activeIconName: 'fapiaoguanli',
        component: React.lazy(() => import('@/components/Children')),
        children: [
          {
            cache: true,
            path: 'InvoiceApplication',
            name: 'InvoiceApplication',
            meta: { title: '发票申请' },
            component: React.lazy(() => import('@/pages/Financial/Invoices/InvoiceApplication'))
          },
          {
            cache: true,
            path: 'InvoiceList',
            name: 'InvoiceList',
            meta: { title: '发票列表' },
            component: React.lazy(() => import('@/pages/Financial/Invoices/InvoiceList'))
          },
          {
            cache: true,
            path: 'InvoiceManagement',
            name: 'InvoiceManagement',
            meta: { title: '发票管理' },
            component: React.lazy(() => import('@/pages/Financial/Invoices/InvoiceManagement'))
          }
        ]
      },
      {
        path: 'Receipts',
        name: 'Receipts',
        meta: { title: '收款管理' },
        icon: <IconRender icon={<IconBanbenliebiao1 />} />,
        iconName: 'shoukuanguanli-xianxing',
        activeIconName: 'shoukuanguanli',
        notMenu: isActiveRouter,
        component: React.lazy(() => import('@/pages/Works/PayWorks')),
        children: [
          {
            cache: true,
            path: 'PaymentRegistration',
            name: 'PaymentRegistration',
            meta: { title: '收款登记' },
            component: React.lazy(() => import('@/pages/Financial/Receipts/PaymentRegistration'))
          },
          {
            cache: true,
            path: 'MyPaymentList',
            name: 'MyPaymentList',
            meta: { title: '我的收款' },
            component: React.lazy(() => import('@/pages/Financial/Receipts/MyPaymentList'))
          },
          {
            cache: true,
            path: 'PaymentList',
            name: 'PaymentList',
            meta: { title: '收款列表' },
            component: React.lazy(() => import('@/pages/Financial/Receipts/PaymentList'))
          },
          {
            cache: true,
            path: 'PaymentClaim',
            name: 'PaymentClaim',
            meta: { title: '收款认领' },
            component: React.lazy(() => import('@/pages/Financial/Receipts/PaymentClaim'))
          },
          {
            cache: true,
            path: 'InternalTransfer',
            name: 'InternalTransfer',
            meta: { title: '内部划转' },
            component: React.lazy(() => import('@/pages/Financial/Receipts/InternalTransfer'))
          },
          {
            path: 'ReceiptsManagement',
            name: 'ReceiptsManagement',
            meta: { title: '收款管理' },
            component: React.lazy(() => import('@/pages/Financial/Receipts/ReceiptsManagement'))
          }
        ]
      },
      {
        path: 'Allocations',
        name: 'Allocations',
        meta: { title: '分配管理' },
        notMenu: isActiveRouter,
        icon: <IconRender icon={<IconBanbenliebiao1 />} />,
        iconName: 'ic_fluent_branch_24_regular',
        activeIconName: 'ic_fluent_branch_24_filled',
        component: React.lazy(() => import('@/components/Children')),
        children: [
          {
            cache: true,
            path: 'AllocationList',
            name: 'AllocationList',
            meta: { title: '分配列表' },
            component: React.lazy(() => import('@/pages/Financial/Allocations/AllocationList'))
          },
          {
            cache: true,
            path: 'AllocationManagement',
            name: 'AllocationManagement',
            meta: { title: '分配管理' },
            component: React.lazy(() => import('@/pages/Financial/Allocations/AllocationManagement'))
          },
          {
            cache: true,
            path: 'AllocationSettings',
            name: 'AllocationSettings',
            meta: { title: '分配设置' },
            component: React.lazy(() => import('@/pages/Financial/Allocations/AllocationSettings'))
          }
        ]
      },
      {
        path: 'Voucher',
        name: 'Voucher',
        meta: { title: '凭证管理' },
        icon: <IconRender icon={<IconBanbenliebiao1 />} />,
        iconName: 'pingzhengguanli-xianxing',
        activeIconName: 'pingzhengguanli',
        notMenu: isActiveRouter,
        component: React.lazy(() => import('@/components/Children')),
        children: [
          {
            cache: true,
            path: 'VoucherReceivable',
            name: 'VoucherReceivable',
            meta: { title: '应收凭证' },
            component: React.lazy(() => import('@/pages/Financial/Voucher/VoucherReceivable'))
          },
          {
            cache: true,
            path: 'VoucherPayment',
            name: 'VoucherPayment',
            meta: { title: '收款凭证' },
            component: React.lazy(() => import('@/pages/Financial/Voucher/VoucherPayment'))
          },
          {
            cache: true,
            path: 'VoucherWriteOff',
            name: 'VoucherWriteOff',
            meta: { title: '核销凭证' },
            component: React.lazy(() => import('@/pages/Financial/Voucher/VoucherWriteOff'))
          }
        ]
      },
      {
        path: 'Charges',
        name: 'Charges',
        meta: { title: '费用管理' },
        icon: <IconRender icon={<IconBanbenliebiao1 />} />,
        iconName: 'ic_fluent_money_24_regular',
        activeIconName: 'ic_fluent_money_24_filled',
        notMenu: isActiveRouter,
        component: React.lazy(() => import('@/components/Children')),
        children: [
          {
            cache: true,
            path: 'ReimbursementList',
            name: 'ReimbursementList',
            meta: { title: '报销列表' },
            component: React.lazy(() => import('@/pages/Financial/Charges/ReimbursementList'))
          },
          {
            cache: true,
            path: 'SyncList',
            name: 'SyncList',
            meta: { title: '同步列表' },
            component: React.lazy(() => import('@/pages/Financial/Charges/SyncList'))
          }
        ]
      },
      {
        path: 'FinancialSettings',
        name: 'FinancialSettings',
        meta: { title: '财务设置' },
        icon: <IconRender icon={<IconBanbenliebiao1 />} />,
        iconName: 'ic_fluent_money_24_regular',
        activeIconName: 'ic_fluent_money_24_filled',
        // notMenu: isActiveRouter,
        component: React.lazy(() => import('@/pages/Financial/FinancialSettings'))
      }
    ]
  },
  {
    path: 'HumanResource',
    name: 'HumanResource',
    meta: { title: '人力资源' },
    iconName: 'renliziyuan-xianxing',
    activeIconName: 'renliziyuan',
    component: React.lazy(() => import('@/components/Children')),
    color: '#27B080',
    children: [
      {
        path: 'EmployeesManage',
        name: 'EmployeesManage',
        meta: { title: '人员管理' },
        component: React.lazy(() => import('@/pages/HumanResource/EmployeesManage'))
      }
    ]
  },
  // {
  //   path: 'Editions',
  //   name: 'Editions',
  //   meta: { title: '资源管理' },
  //   icon: <IconRender size={16} icon={<IconZiyuanguanliLinear />} />,
  //   iconName: 'ic_fluent_data_pie_24_regular',
  //   activeIconName: 'data_pie_24_filled',
  //   component: React.lazy(() => import('@/components/Children')),
  //   color: '#FAC209',
  //   children: [
  //     {
  //       path: 'VersionList',
  //       name: 'VersionList',
  //       meta: { title: '版本列表' },
  //       icon: <IconRender icon={<IconBanbenliebiao1 />} />,
  //       iconName: 'ic_fluent_apps_list_detail_24_regular',
  //       activeIconName: 'ic_fluent_apps_list_detail_24_filled',
  //       component: React.lazy(() => import('@/pages/Editions')),
  //     },
  //   ],
  // },
  {
    path: 'Administration',
    name: 'Administration',
    meta: { title: '系统管理' },
    icon: <IconRender size={16} icon={<IconXitongguanliLinear />} />,
    iconName: 'ic_fluent_desktop_24_regular',
    activeIconName: 'desktop_24_filled',
    component: React.lazy(() => import('@/components/Children')),
    color: '#006D75',
    children: [
      {
        path: 'User',
        name: 'User',
        meta: { title: '账号管理' },
        icon: <IconRender icon={<IconYonghu1 />} />,
        iconName: 'ic_fluent_person_note_24_regular',
        activeIconName: 'person_note_24_filled',
        component: React.lazy(() => import('@/pages/Administration/User'))
      },
      {
        path: 'Role',
        name: 'Role',
        meta: { title: '角色' },
        icon: <IconRender icon={<IconJiaose />} />,
        iconName: 'ic_fluent_people_24_regular',
        activeIconName: 'people_24_filled',
        component: React.lazy(() => import('@/pages/Administration/Role'))
      },
      {
        path: 'Organization',
        name: 'Organization',
        meta: { title: '组织机构' },
        icon: <IconRender icon={<IconJigou />} />,
        iconName: 'ic_fluent_building_24_regular',
        activeIconName: 'building_24_filled',
        component: React.lazy(() => import('@/pages/Administration/Organization'))
      },
      {
        path: 'Languages',
        name: 'Languages',
        meta: { title: '语言列表' },
        icon: <IconRender icon={<IconDuoyuyanguanli />} />,
        iconName: 'ic_fluent_earth_24_regular',
        activeIconName: 'ic_fluent_globe_24_filled',
        component: React.lazy(() => import('@/pages/Administration/Languages'))
      },
      {
        path: 'Configuration',
        name: 'Configuration',
        meta: { title: '配置页' },
        icon: <IconRender icon={<IconXitongpeizhi />} />,
        iconName: 'ic_fluent_dual_screen_settings_24_regular-01',
        activeIconName: 'ic_fluent_dual_screen_settings_24_filled',
        component: React.lazy(() => import('@/pages/Administration/Configuration'))
      },
      {
        path: 'FormDesigner',
        name: 'FormDesigner',
        meta: { title: '表单设计' },
        icon: <IconRender icon={<IconBiaodanshezhi />} />,
        iconName: 'ic_fluent_slide_layout_24_regular',
        activeIconName: 'ic_fluent_slide_layout_24_filled',
        component: React.lazy(() => import('@/pages/Administration/FormDesigner'))
      },
      {
        path: 'Setting',
        name: 'Setting',
        meta: { title: '设置' },
        icon: <IconRender icon={<IconShezhi1 />} />,
        iconName: 'settings_24_regular',
        activeIconName: 'settings_24_filled',
        component: React.lazy(() => import('@/pages/Administration/Setting'))
      },
      {
        path: 'AuditLog',
        name: 'AuditLog',
        meta: { title: '审计日志' },
        icon: <IconRender icon={<IconShenjirizhi />} />,
        iconName: 'ic_fluent_notepad_24_regular',
        activeIconName: 'shenjirizhi',
        component: React.lazy(() => import('@/pages/Administration/AuditLog'))
      },
      {
        path: 'CodeRule',
        name: 'CodeRule',
        meta: { title: '编码规则' },
        icon: <IconRender icon={<IconShenjirizhi />} />,
        iconName: 'ic_fluent_notepad_24_regular',
        activeIconName: 'shenjirizhi',
        component: React.lazy(() => import('@/pages/Administration/CodeRule'))
      },
      {
        path: 'SupplierPlugin',
        name: 'SupplierPlugin',
        meta: { title: '第三方插件' },
        icon: <IconRender icon={<IconDisanfangchajian />} />,
        iconName: 'ic_fluent_store_microsoft_24_regular',
        activeIconName: 'store_microsoft_24_filled',
        component: React.lazy(() => import('@/pages/Administration/SupplierPlugin'))
      },
      {
        path: 'DataDictionary',
        name: 'DataDictionary',
        meta: { title: '数据字典' },
        icon: <IconRender icon={<IconDisanfangchajian />} />,
        iconName: 'ic_fluent_store_microsoft_24_regular',
        activeIconName: 'store_microsoft_24_filled',
        component: React.lazy(() => import('@/pages/Administration/DataDictionary'))
      }
    ]
  },
  {
    notMenu: true,
    path: 'ExportCenter',
    name: 'ExportCenter',
    meta: { title: '导出中心' },
    iconName: 'daochuzhongxin-xianxing',
    activeIconName: 'daochuzhongxin',
    component: React.lazy(() => import('@/components/Children')),
    color: '#FA9841',
    children: [
      {
        path: 'ExportManage',
        name: 'ExportManage',
        meta: { title: '导出中心' },
        component: React.lazy(() => import('@/pages/ExportCenter/ExportManage'))
      }
    ]
  },

  {
    path: '/apphome',
    name: 'apphome',
    meta: { title: '首页' },
    notMenu: true,
    // icon: <IconRender size={24} icon={<IconKaifaguanli />} />,
    component: React.lazy(() => import('@/appLayout/Home'))
  },
  {
    path: '/appfunction',
    name: 'appfunction',
    meta: { title: '功能' },
    notMenu: true,
    // icon: <IconRender size={24} icon={<IconKaifaguanli />} />,
    component: React.lazy(() => import('@/appLayout/Function'))
  }
  // {
  //   path: "*",
  //   redirect: "/404",
  //   notMenu: true,
  //   component: NoFoundPage,
  //   meta: { title: "404" },
  //   name: "404",
  // },
];

export const routes: Array<RouteConfig> = [
  {
    path: 'loginToken',
    name: 'loginToken',
    meta: { title: 'token登录' },
    component: React.lazy(() => import('@/pages/Login/loginToken'))
  },
  {
    path: 'login',
    name: 'login',
    meta: { title: '登录' },
    component: React.lazy(() => import('@/pages/Login'))
  },
  {
    path: 'LockScreen',
    name: 'LockScreen',
    meta: { title: '锁屏' },
    component: React.lazy(() => import('@/pages/LockScreen'))
  },

  {
    path: '/*',
    name: 'admin',
    meta: { title: 'admin' },
    // component: LayoutBase,
    component: Layout,
    children: adminRoutes
  },

  {
    path: '404',
    name: '404',
    component: NoFoundPage
  },
  {
    path: 'permission',
    name: 'permission',
    component: NoPermission
  },
  {
    path: 'test',
    name: 'test',
    component: React.lazy(() => import('@/pages/Test'))
  }
];

export default routes;

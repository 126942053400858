//
export enum WorkFlowViewEnum {
  Manage = 'Manage',
  MyList = 'MyList',
  Audit = 'Audit',

  //收款管理
  //内部划转
  InternalTransfer = 'InternalTransfer',
  //收款管理-我的收款
  MyReceipt = 'MyReceipt',
  //收款管理-收款认领
  ReceiptClaimed = 'ReceiptClaimed'
  //收款管理-收款审批
}

import { useNavigate } from 'react-router-dom';
import { Button, Result } from 'antd';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';

const NoFoundPage = () => {
  const { t } = useTranslation();
  const history = useNavigate();
  const { pathname } = useLocation();
  return (
    <Result
      status="404"
      title="404"
      style={{ width: '100%' }}
      subTitle={
        <>
          <div style={{ fontSize: 12, color: '#999' }}>{pathname}</div>
          <div>{t('System_NotFound', '此页面未找到')}</div>
        </>
      }
      extra={
        <Button
          type="primary"
          onClick={() => {
            const URL = isMobile ? '/apphome' : '/login';
            history('/login');
          }}
        >
          {t('System_ReturnHome', '返回主页')}
        </Button>
      }
    />
  );
};

export default NoFoundPage;

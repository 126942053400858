import { ReactNode, Suspense } from 'react';
import { Spin } from 'antd';

interface Props {
  children: ReactNode;
}

const Loading = () => (
  <div
    style={{
      // paddingTop: '20vh',
      // minHeight: '80vh',
      height: '100%',
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'var(--ant-color-bg-layout, #f5f5f5)'
    }}
  >
    <Spin />
  </div>
);

export function SuspenseLoading({ children }: Props) {
  return <Suspense fallback={<Loading />}>{children}</Suspense>;
}

export default SuspenseLoading;

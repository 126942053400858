import { Input, InputProps } from 'antd';
import { FC, memo, useContext, useEffect, useMemo } from 'react';
import { MyContext } from '../../BitzQueryFilterContext';
import { split } from 'lodash-es';
import { BitzFluentInput } from 'bitz-react-admin-ui';

interface BQFInputProps extends InputProps {
  /**
   * Form.Item 会传入id = name
   */
  id?: string;
  value?: string | number;
  /**
   * 是否启用Fluent组件模式
   */
  fluent?: boolean;
}

const BQFInput: FC<BQFInputProps> = (props) => {
  const { fluent } = props;
  const { dispatch } = useContext(MyContext);

  // 处理高级筛选问题，因为高级是列表 name是通过_拼接
  const id = useMemo(() => {
    if (props.id) {
      if (props.id?.indexOf('advanced') !== -1) {
        const list = split(props.id, '_');
        return list[list.length - 1];
      }
      return props.id;
    }
    return '';
  }, [props.id]);

  useEffect(() => {
    if (props.id) {
      dispatch?.({
        type: 'update',
        name: id,
        value: props?.value
      });
    }
  }, [props?.value, id]);

  const Component = useMemo(() => (fluent ? BitzFluentInput : Input), [fluent]);

  // @ts-ignore
  return <Component allowClear {...props} />;
};

export default memo(BQFInput);

import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
  IHttpConnectionOptions
} from '@microsoft/signalr'
import { notification } from 'antd'
import { BitzLocalStorage } from 'bitz-react-admin-ui'
import genApi from '@/services/GeneralPublicInterface'
import webApi from '@/services/Web'

// public const string Notice = "App.User.Notice";
// public const string ExportCenter = "App.User.ExportCenter.Notice";
// public const string TimerStateChanged = "App.User.Timesheet.Timer";
// public const string AppWorkFlowNotice = "App.User.WorkFlow.Notice";

const Notification = ({
  fileId,
  title,
  url,
}) => {
  const downloadWithCount = async () => {
    window.open(url, '_blank')
    await webApi.webSysSysexcelexportcenterUpdate(fileId)
  }

  return (
    <div className='exportNotificationBlock'>
      <span>{title}，</span>
      <span className="exportNotificationLink" onClick={downloadWithCount}>点击下载</span>
    </div>
  )
}

const url = import.meta.env.VITE_API_SIGNALR + '/api2/chatHub'

let connection: HubConnection

export const connectionSignalr = async () => {
  try {
    const lzhHostToken = BitzLocalStorage.getItem('lzhHostToken') || null
    const options: IHttpConnectionOptions = {
      skipNegotiation: true,
      transport: HttpTransportType.WebSockets,
      accessTokenFactory: () => `Bearer ${lzhHostToken}`
    }
    const logLevel= import.meta.env.DEV ? LogLevel.Debug : LogLevel.None
    connection = new HubConnectionBuilder()
      .withUrl(url, options)
      .configureLogging(logLevel)
      .withAutomaticReconnect() // 断线重连 [0, 2000, 10000, 30000] 产生默认行为
      .build()

    connection.onreconnected((connectionId) => {
      console.log(connectionId, '自动重新连接成功')
      // store.dispatch('getUnreadCount')
    })

    connection.onclose((res) => {
      console.log('监听关闭', res)
    })

    // {
    //   "notificationTitle": "导出中心列表导出成功",
    //   "notificationName": "xxxx",
    //   "data": {},
    //   "userId": "用户id",
    //   "dataId": "文件id"
    // }

    connection.on('ReceiveMessage', async (data) => {

      if (data?.notificationName === 'App.User.ExportCenter.Notice') {
        try {
          const res = await genApi.fileDownloadDetail(data.dataId)
          if(res.response?.downloadUrl) {
            notification.info({
              key: data.dataId,
              message: '导出中心',
              description: <Notification fileId={data.dataId} title={data.notificationTitle} url={res.response?.downloadUrl} />,
              duration: 10,
              onClose: () => notification.destroy(data.dataId)
            })
          }
        } catch (e) {
          const key = Date.now()
          notification.info({
            key,
            message: '导出中心',
            description: (
              <div className='exportNotificationBlock'>
                <span>导出成功，请前往{' '}</span>
                <span><a className="exportNotificationLink" href="/ExportCenter/ExportManage" target='blank'>导出中心</a></span>
                <span>{' '}下载</span>
              </div>
            ),
            duration: 10,
            onClose: () => notification.destroy(key)
          })
          console.log('获取文件链接报错');
        }
      }
      // console.log('getNotification', { data })
      // 通知
      // store.dispatch('getUnreadCount')
    })

    await connection.start()
  } catch (e) {
    console.log('signalr 启动异常', e)
  }
  return connection
}

export const disconnectSignalr = async () => {
  try {
    await connection.stop()
    // connection = undefined
  } catch (e) {
    console.log('signalr Stop异常', e)
  }
}

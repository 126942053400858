/*
 * # Title: If Component
 * - Description: if组件
 * - Date: 2024-03-31
 * - LastEditors: liujin liujin@elinklaw.com
 * - LastEditTime: 2024-04-03 09:26:25
 */

import React from 'react'

const If = ({ condition, children, key }: { condition: boolean; children: React.ReactNode; key?: React.Key }) => (
  <React.Fragment key={key}>{condition ? children : null}</React.Fragment>
)

export default If

import { navData } from '@/utils/nav';

/**
 *
 * @param arr 数组
 * @returns 数组去重
 */
export const some = (arr: any) => {
  const some: any = [];
  arr.forEach((el: any) => {
    if (!some.some((e: any) => e.id == el.id)) {
      some.push(el);
    }
  });
  return some;
};

/**
 *
 * @param data 数组
 * @param value ID
 * @returns 根据ID寻找所有父级的ID
 */
export const getParentIds = (data: any, value: any) => {
  const getTreeItems = (arr: any, temp = []) => {
    for (const node of arr) {
      if (node.id == value) {
        return temp;
      } else if (node.childrens) {
        const result = getTreeItems(node.childrens, temp.concat(node.id.toString()));
        if (result.length > 0) {
          return result;
        }
      }
    }
    return [];
  };
  return getTreeItems(data);
};

/**
 *
 * @param routes 所有的自定义路由
 * @param array  返回新的数组
 * @returns 获取所有的扁平路由
 */

export const getFlatRoutes = (routes: any, array: any = []) => {
  routes.forEach((item: any) => {
    array.push({
      path: item.path,
      title: item.title
    });
    if (item.children) {
      getFlatRoutes(item.children, array);
    }
  });
  return [...array];
};

/**
 *
 * @returns 获取默认首页
 */
export const getDefaultUrl = () => {
  // const defaultUrl = navData[0]?.url;
  // return defaultUrl;
  return '/';
};

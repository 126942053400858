import { create } from 'zustand'
import {devtools, persist} from 'zustand/middleware'
import { HostUserInfoDto } from '@/services/WebApi'
import api from '@/services/Web'

interface UserState extends HostUserInfoDto {}

interface UserAction {
  getCurrentUserInfo: () => Promise<void>
  updateEmail: (emailAddress: string) => void
  updatephoneNumber: (phoneNumber: string) => void
  setUserInfo: (data: HostUserInfoDto) => void
}

type UserStore = UserState & UserAction

const useUserStore = create<UserStore>()(
  devtools(
    (set) => ({
      getCurrentUserInfo: async () => {
        try {
          const data = await api.webSysUserinfoCurrentList()
          if (data.success) {
            return set(
              (state) => ({
                ...state,
                ...data.response,
              }),
              false,
              'UserStore/getCurrentUserInfo',
            )
          }
          return
        } catch (e) {
          console.error('获取用户信息失败', e)
        }
      },
      updateEmail: (emailAddress) => set({ emailAddress }, false, 'UserStore/updateEmail'),
      updatephoneNumber: (phoneNumber) => set({ phoneNumber }, false, 'UserStore/updatephoneNumber'),
      setUserInfo: (data) => set((state) => ({
        ...state,
        ...data,
      }))
    }),
    {
      name: 'UserStore',
    },
  ),
)

export default useUserStore

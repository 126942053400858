/*
 * # Title: Timer Component
 * - Description: 集成计时器和时长
 * - Date: 2024-06-19
 * - LastEditors: liujin liujin@elinklaw.com
 * - LastEditTime: 2024-06-30 14:59:06
 */

import { css } from '@emotion/css';
import { isNumber } from 'lodash-es';
import { FC, useContext, useEffect, useState } from 'react';
import BitzIf from '@/components/BitzIf';
import { Timer } from './Timer';
import BitzInputNumber from './InputNumber';
import { Spin } from 'antd';
import { TimerContext } from '@/components/BitzTimerContext';

interface TimerComponentProps {
  record: any;
  value?: number;
  accumulatedTime?: number;
  disabledRun?: boolean;
  onChange?: (time: number) => Promise<any>;
  onInputNumberChange?: (value: number) => void;
  onInputBlur?: () => void;
  hideBtn?: boolean;
  setMinTime: any;
  max?: number;
}

const TimerComponent: FC<TimerComponentProps> = (props) => {
  const { record, disabledRun, onChange, value = 0, hideBtn = false, setMinTime, max } = props;
  const [time, setTime] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { runningId, runningTimer, onGlobalStart, onGlobalPause, onGlobalContinue, onOnlyPause } = useContext(TimerContext);

  // 计时器监听处理
  useEffect(() => {
    if (runningId && runningId == record.id) {
      if (record.timerState === 'Pause') {
        // 状态Pause，停止计时器
        onOnlyPause();
      }
    } else {
      if (record.timerState === 'Start') {
        // 状态Start，开启计时器
        onGlobalContinue(record?.id);
      }
    }
  }, [record]);

  useEffect(() => {
    if (runningId == record?.id) {
      setTime(runningTimer);
    }
  }, [runningTimer]);

  const isStart: boolean = runningId == record?.id;
  // const queryClient = useQueryClient()
  // const refreshFun = () => {
  //   // 刷新工时列表
  //   queryClient.refetchQueries({ queryKey: ['Works', 'WorkHourList', 'DayPanel'], type: 'active' })
  //   // 刷新刷新计时弹窗
  //   queryClient.refetchQueries({ queryKey: ['Base', 'BnHeadTimer', 'TimerModal'], type: 'active' })
  // }
  // 开启
  const startHandle = () => {
    setIsLoading(true);
    onGlobalStart(record?.id);
    setTimeout(() => {
      setIsLoading(false);
    }, 300);
  };
  // 暂停
  const pauseHandle = () => {
    setIsLoading(true);
    onGlobalPause(record?.id);
    setTimeout(() => {
      setIsLoading(false);
    }, 300);
  };

  return (
    <div
      className={css`
        position: relative;
        width: 100%;
      `}
    >
      <Spin spinning={isLoading} size="small">
        <div>
          <Timer
            type={disabledRun ? 'default' : 'primary'}
            option={{ duration: time, start: isStart }}
            style={{ width: '100%' }}
            start={startHandle}
            pause={pauseHandle}
          />
        </div>
        <BitzIf condition={!isStart}>
          <div
            className={css`
              position: absolute;
              top: 0;
              left: 0;
              right: 33px;
              background-color: var(--ant-color-bg-base);
            `}
          >
            <BitzInputNumber
              max={max}
              hideBtn={hideBtn}
              value={value}
              min={0.1}
              onChange={(value) => {
                if (isNumber(value)) {
                  setIsLoading(true);
                  onChange?.(value)?.finally(() => {
                    setIsLoading(false);
                  });
                }
              }}
              setMinTime={setMinTime}
            />
          </div>
        </BitzIf>
      </Spin>
    </div>
  );
};

export default TimerComponent;

import apiWeb from '@/services/Web';
import { selectorFamily, useRecoilCallback } from 'recoil';
import { billBaseInfoDB } from '../BillApplication/BillApplication.store';
import { WidgetModule } from '@/constants';
import { BillModuleRequestMap } from '../BillConstants';
import { totalBillListDB } from '../BillManagement/BillManagement.store';

// @todo 基本设置也应该为 atom
/**
 * billListDB 调用参数
 * 统一定义为 {name}${func}
 */
export const billListDB$Payload = atomFamily<Row, WidgetModule>({
  key: 'billListDB/payload',
  default: (module) => ({ page: 1, size: 10 })
});

/**
 * 账单列表
 */
export const billListDB = selectorFamily({
  key: 'billListDB',
  get:
    (module: WidgetModule) =>
    async ({ get }) => {
      const payload = get(billListDB$Payload(module));
      const request = BillModuleRequestMap[module];
      const { response } = await request({ ...payload });
      return response!;
    }
});

/**
 * 账单详情
 */
export const billDetailDB = selectorFamily({
  key: 'billDetailDB',
  get:
    (billingId: string) =>
    async ({ get }) => {
      // 使用 foredit 替换 foredit 接口
      // const { response } = await apiWeb.webFinancialBillingFinancialbillingGetCreate(billingId);
      // return response!;
      const { data } = get(billBaseInfoDB(billingId));
      return data;
    }
});

export const useRemoveBilling = (module: WidgetModule) => {
  return useRecoilCallback(({ refresh }) => async (billingId: string) => {
    await apiWeb.webFinancialBillingFinancialbillingDeleteDelete(billingId, { id: billingId });
    refresh(billListDB(module));
    refresh(totalBillListDB);
  });
};

// export const paymentDB = selectorFamily({
//   key: 'entity/paymentDB',
//   get:
//     (paymentId: string) =>
//     async ({ get }) => {
//       if (!paymentId) return [];
//       const { response } = await apiWeb.webCustomerPayerinfoGetpayerinfopageCreate({ page: 1, size: 99999 });
//       return response?.data?.find((item) => item.id === paymentId);
//     }
// });

import { FC, memo, useContext, useEffect, useMemo } from 'react';
import type { DatePickerProps } from 'antd';
import { DatePicker } from 'antd';
import { MyContext } from '../../BitzQueryFilterContext';
import dayjs from 'dayjs';
import { omit } from 'lodash-es';

export interface BQFDatePicker extends DatePickerProps {}

const BQFDatePicker: FC<BQFDatePicker> = (props) => {
  const { dispatch } = useContext(MyContext);
  const { onChange, ...rest } = props;

  const formatType = {
    week: 'YYYY-wo',
    month: 'YYYY-MM',
    quarter: 'YYYY-QQ',
    year: 'YYYY'
  };

  const format = useMemo<string>(() => {
    if (props?.format) {
      return props?.format;
    }
    if (props?.picker) {
      return formatType[props.picker];
    }
    return 'YYYY-MM-DD';
  }, [props?.format, props?.picker]);

  const id = useMemo(() => {
    if (props.id) {
      // @ts-ignore
      if (props.id?.indexOf('advanced') !== -1) {
        // @ts-ignore
        const list = split(props.id, '_');
        return list[list.length - 1];
      }
      return props.id;
    }
    return '';
  }, [props.id]);

  useEffect(() => {
    if (id) {
      dispatch?.({
        type: 'update',
        name: id,
        value: props?.value
      });
    }
  }, [props?.value, id]);

  const handleChange: DatePickerProps['onChange'] = (date, dateString) => {
    const time = date ? dayjs(date) : undefined;

    const timeText = time?.format(format);

    // @ts-ignore
    onChange?.(time?.format('YYYY-MM-DD HH:mm:ss'));

    if (id) {
      dispatch?.({
        type: 'update',
        name: id,
        valueName: timeText
      });
    }
  };

  const value = useMemo<DatePickerProps['value']>(() => {
    if (props?.value) {
      const time = props?.value && dayjs(props?.value);
      return time;
    }
    return undefined;
  }, [props?.value]);

  return <DatePicker allowClear style={{ width: '100%' }} value={value} onChange={handleChange} {...omit(rest, 'value')} />;
};

export default memo(BQFDatePicker);

/*
 * # Title: BitzInputNumber Component
 * - Description:
 * - Date: 2024-05-07
 * - LastEditors: liujin liujin0505@icloud.com
 * - LastEditTime: 2024-06-28 23:39:21
 */

import { FieldTimeOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { InputNumber, InputNumberProps, GetRef, Button, Flex, Popover } from 'antd';
import React, { useEffect, useState, useRef, FC } from 'react';
import { add, sub } from '@/utils/fraction';
import { useDebounceFn } from 'ahooks';
import styles from './index.module.less';

interface BitzInputNumberProps extends InputNumberProps {
  value?: number;
  hideBtn?: boolean;
  isFilter?: boolean;
  setMinTime?: any;
  max?: number;
}

function decimalPlaces(num) {
  // 将数字转换为字符串
  const numString = num.toString();
  // 使用正则表达式查找小数点后的部分
  const match = numString.match(/\.(\d+)$/);
  // 如果匹配成功，返回小数点后的数字长度，否则返回0
  return match ? match[1].length : 0;
}

const BitzInputNumber: FC<BitzInputNumberProps> = (props) => {
  const { hideBtn = false, value, isFilter = false, setMinTime, max = 23.59, ...rest } = props;
  const inputRef = useRef<GetRef<typeof InputNumber>>(null);
  const [val, setVal] = useState(value);
  const [open, setOpen] = useState(false);

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const { run } = useDebounceFn(
    (type) => {
      inputRef.current?.focus();
      if (type === 'add') {
        setVal((prev) => {
          const val = add(prev ? prev : setMinTime, setMinTime);
          const value = val > 23.59 ? 23.59 : val;
          rest.onChange?.(value);
          return value;
        });
      } else {
        setVal((prev) => {
          const val = sub(prev ? prev : setMinTime, setMinTime);
          const value = val < setMinTime ? setMinTime : val;
          rest?.onChange?.(value);
          return value;
        });
      }
    },
    {
      wait: 300,
      leading: true,
      trailing: false
    }
  );

  const { run: handleChange } = useDebounceFn(
    (val) => {
      const value = val < setMinTime ? setMinTime : val > 23.59 ? 23.59 : val;
      setVal(value);
      rest?.onChange?.(value);
    },
    {
      wait: 1000
    }
  );

  useEffect(() => {
    setVal(value);
  }, [value]);

  const array = [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0, 1.2, 1.4, 1.5, 1.9, 2.2, 2.5, 2.9, 3.5, 4.2, 4.9, 5.5, 6.2, 6.9, 7.5, 8.0];

  const handleClick = (num) => {
    setVal(num);
    rest?.onChange && rest.onChange(num);
    setOpen(false);
  };

  const content = (
    <div className={styles.container}>
      {array.map((v) => (
        <div onClick={() => handleClick(v)} className={styles.tagstyle} key={v}>
          {v.toFixed(1)}
        </div>
      ))}
    </div>
  );

  const precision = setMinTime ? decimalPlaces(setMinTime) : 1;

  return (
    <Flex gap={10}>
      <InputNumber
        changeOnBlur
        precision={precision}
        ref={inputRef}
        controls={false}
        style={{ width: '100%' }}
        // placeholder="0.1"
        min={setMinTime || 0.1}
        max={max}
        disabled={hideBtn}
        addonBefore={
          hideBtn ? null : (
            <a
              onClick={() => {
                run('sub');
              }}
            >
              <MinusOutlined />
            </a>
          )
        }
        addonAfter={
          hideBtn ? null : (
            <a
              onClick={() => {
                run('add');
              }}
            >
              <PlusOutlined />
            </a>
          )
        }
        {...rest}
        value={val}
        onChange={handleChange}
      />

      {/*<Space.Compact block>*/}
      {/*  <Button*/}
      {/*    icon={<MinusOutlined />}*/}
      {/*    onClick={() => run('sub')}*/}
      {/*  />*/}
      {/*  */}
      {/*  <Button*/}
      {/*    onClick={() => run('add')}*/}
      {/*    icon={<PlusOutlined />}*/}
      {/*  />*/}
      {/*</Space.Compact>*/}
      {isFilter && (
        <Popover trigger="click" color="var(--ant-color-primary-bg)" content={content} placement="bottomRight" open={open} onOpenChange={handleOpenChange}>
          <Button disabled={rest?.disabled} icon={<FieldTimeOutlined />} />
        </Popover>
      )}
    </Flex>
  );
};

export default BitzInputNumber;

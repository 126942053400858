import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import useUserStore from '@/store/userStore';
import useBasicSettingsStore from '@/store/useBasicSettingsStore';
// import useFlowAction from '@/store/useFlowAction'
// import  useFlowActionquest from '@/store/useFlowActionCsrRequest'
import { RouteConfig } from '@/router/config';
import LayoutBase, { getMatchRouteObj, makeRouteObject } from '@/layout/Base';
import './index.less';
import { isNil } from 'lodash-es';
import { runp } from '@/utils';
import NotFound from '@/components/NotFound';
import NoFoundPage from '@/pages/NotFound';
import path from 'path';
import { BitzLocalStorage } from 'bitz-react-admin-ui';

const Layouts = ({ route }: { route: RouteConfig }) => {
  const [isGetUserinfo, setIsGetUserinfo] = useState<boolean>(false);
  const navigate = useNavigate();
  const userStore = useUserStore(useShallow((state) => state));
  const settingsStore = useBasicSettingsStore(useShallow((state) => state));
  // const FlowAction = useFlowAction(useShallow((state) => state))
  // const FlowActionquest = useFlowActionquest(useShallow((state) => state))
  useEffect(() => {
    if (!settingsStore.themeConfig?.theme) {
      settingsStore.getBasicSettings();
    }
  }, [settingsStore.themeConfig?.theme]);

  useEffect(() => {
    userStore.getCurrentUserInfo();
  }, []);

  return <LayoutBase route={route} />;
  // return isGetUserinfo ?
  //   <LayoutBase route={route} /> :
  //   <Flex style={{ height: "100vh" }} justify="center" align="center"><Spin size="large" /></Flex>
};

/**
 * 先判断route是否存在
 * @param param
 */
const RouteLayout = ({ route }: { route: RouteConfig }) => {
  const location = useLocation();
  const { pathname } = location;
  const lzhHostToken = BitzLocalStorage.getItem('lzhHostToken') || null;
  const routes = useMemo(() => {
    if (isNil(route.children)) {
      return [] as never[];
    }
    return makeRouteObject(route.children);
  }, [route]);
  const ele = useRoutes(routes, location);

  const matchRouteObj = useMemo(() => {
    return getMatchRouteObj(ele);
  }, [routes, location]);

  if (pathname === '/') {
    return lzhHostToken ? <Layouts route={route} /> : <Navigate to={'/login'} />;
  }

  return runp(
    matchRouteObj,
    () => {
      return <Layouts route={route} />;
    },
    () => {
      return <NoFoundPage />;
    }
  );
};

export default RouteLayout;

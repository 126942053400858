import React from 'react';
import { GetAdvancedWhereFieldDto } from '@/services/GeneralPublicInterfaceApi';
import BitzQueryFilter2 from '@/components/BitzQueryFilter2';
import BnEnumSelect from '@/businessComponents/BnEnumSelect';
import BnWhetherSelect from '@/businessComponents/BnWhetherSelect';
import BQFDatePicker from '@/components/BitzQueryFilter2/components/DatePicker';
import BnOrganizationUnitTree from '@/businessComponents/BnOrganizationUnitTree';
import BnRolePicker from '@/businessComponents/BnRolePicker';
import BnGeneralCodeSelect from '@/businessComponents/BnGeneralCodeSelect';
import BnFlowStatusSelect from '@/businessComponents/BnFlowStatusSelect';
import BzUserSelect from '@/businessComponents/BzUserSelect';
import { rangePresets } from '@/utils/datePicker';
import BnCaseSelect from '@/businessComponents/caseType/BnCaseSelect';
import BnClientSelect from '@/pages/Works/Components/BnSelectClient';
import { lowerFirst } from 'lodash-es';

export interface RenderFormItemProps {
  name: string | any[];
  item: GetAdvancedWhereFieldDto;
  advanced?: boolean;
}

const RenderFormItem: React.FC<RenderFormItemProps> = (props) => {
  const { name: fieldsName, item, advanced } = props;
  // console.log("RenderFormItem", item);
  // @ts-ignore
  const name = lowerFirst(fieldsName)
  // 字典
  // @ts-ignore
  if (item.dataSource === 'GeneralCode' && item.dataSourceKey) {
    return (
      <BitzQueryFilter2.Item name={name} label={item?.description} labelHidden>
        <BnGeneralCodeSelect fluent={!advanced} name={item.dataSourceKey} mode="multiple" placeholder={item?.description} />
      </BitzQueryFilter2.Item>
    );
  }
  // 系统枚举
  // @ts-ignore
  if (item.dataSource === 'Enum' && item?.dataSourceKey) {
    const enumKey = lowerFirst(item?.dataSourceKey);
    // @ts-ignore
    const mode = item?.valueType !== 'Object' ? 'multiple' : undefined
    return (
      <BitzQueryFilter2.Item name={name} label={item?.description} labelHidden>
        <BnEnumSelect fluent={advanced} mode={mode} enumKey={enumKey} placeholder={item.description} />
      </BitzQueryFilter2.Item>
    );
  }
  // 组织机构选择器
  // @ts-ignore
  if (item.dataSource === 'OrganizationPicker') {
    return (
      <BitzQueryFilter2.Item name={name} label={item?.description} labelHidden>
        <BnOrganizationUnitTree fluent={!advanced} multiple placeholder={item?.description} />
      </BitzQueryFilter2.Item>
    );
  }
  // 客户选择器
  // @ts-ignore
  if (item.dataSource === 'ClientPicker') {
    return (
      <BitzQueryFilter2.Item name={name} label={item?.description} labelHidden>
        <BnClientSelect searchIcon={false} fluent={!advanced} mode="multiple" allowClear placeholder={item?.description ?? ''} maxTagCount="responsive" />
      </BitzQueryFilter2.Item>
    );
  }
  // 用户选择器
  // @ts-ignore
  if (item.dataSource === 'UserPicker') {
    return (
      <BitzQueryFilter2.Item name={name} label={item?.description} labelHidden>
        <BzUserSelect searchIcon={false} autoOptions fluent={!advanced} mode="multiple" allowClear placeholder={item?.description ?? ''} maxTagCount="responsive" />
      </BitzQueryFilter2.Item>
    );
  }
  // 案件选择器
  // @ts-ignore
  if (item.dataSource === 'CasePicker') {
    return (
      <BitzQueryFilter2.Item name={name} label={item?.description} labelHidden>
        <BnCaseSelect searchIcon={false} fluent={!advanced} mode="multiple" allowClear placeholder={item?.description ?? ''} maxTagCount="responsive" />
      </BitzQueryFilter2.Item>
    );
  }
  // 角色选择器
  // @ts-ignore
  if (item.dataSource === 'RolePicker') {
    return (
      <BitzQueryFilter2.Item name={name} label={item?.description} labelHidden>
        <BnRolePicker fluent={!advanced} mode="multiple" placeholder={item?.description} />
      </BitzQueryFilter2.Item>
    );
  }
  // 位置选择器
  // @ts-ignore
  if (item.dataSource === 'PositionPicker') {
    return (
      // <BitzQueryFilter2.Item name={name} label={item?.description} labelHidden>
      //   位置选择器
      // </BitzQueryFilter2.Item>
      <></>
    );
  }
  // 用户组选择器
  // @ts-ignore
  if (item.dataSource === 'UserGroupPicker') {
    return (
      // <BitzQueryFilter2.Item name={name} label={item?.description} labelHidden>
      //   用户组选择器
      // </BitzQueryFilter2.Item>
      <></>
    );
  }
  // @ts-ignore
  if (item.dataSource === 'RequestUrl') {
    return (
      // <BitzQueryFilter2.Item name={name} label={item?.description} labelHidden>
      //   RequestUrl
      // </BitzQueryFilter2.Item>
      <></>
    );
  }
  // @ts-ignore
  if (item.dataSource === 'TenantPicker') {
    return (
      // <BitzQueryFilter2.Item name={name} label={item?.description} labelHidden>
      //   TenantPicker
      // </BitzQueryFilter2.Item>
      <></>
    );
  }
  // 日期范围选择器
  // @ts-ignore
  if (item.dataSource === 'DateRangePicker') {
    const placeholderStart = item?.description ?? '开始日期';
    return (
      <BitzQueryFilter2.Item name={name} label={item?.description} labelHidden>
        <BitzQueryFilter2.DataRangePicker allowEmpty={[true, true]} placeholder={[placeholderStart, '结束日期']} fluent={!advanced} presets={rangePresets} />
      </BitzQueryFilter2.Item>
    );
  }
  // 日期选择器
  // @ts-ignore
  if (item.dataSource === 'DatePicker') {
    return (
      <BitzQueryFilter2.Item name={name} label={item?.description} labelHidden>
        <BQFDatePicker placeholder={item?.description ?? ''} />
      </BitzQueryFilter2.Item>
    );
  }
  // 时间选择器
  // @ts-ignore
  if (item.dataSource === 'TimePicker') {
    return (
      <BitzQueryFilter2.Item name={name} label={item?.description} labelHidden>
        <BitzQueryFilter2.TimePicker placeholder={item?.description ?? ''} />
      </BitzQueryFilter2.Item>
    );
  }
  // 时间范围选择器
  // @ts-ignore
  if (item.dataSource === 'RangeTimeInput') {
    const placeholderStart = item?.description ?? '开始时间';
    return (
      <BitzQueryFilter2.Item name={name} label={item?.description} labelHidden>
        <BitzQueryFilter2.TimeRangePicker placeholder={[placeholderStart, '结束时间']} />
      </BitzQueryFilter2.Item>
    );
  }
  // 是否
  if (item.fieldType === 'Boolean') {
    return (
      <BitzQueryFilter2.Item name={name} label={item?.description} labelHidden>
        <BnWhetherSelect fluent={!advanced} placeholder={item?.description ?? ''} />
      </BitzQueryFilter2.Item>
    );
  }
  if (item.fieldType === 'Int64') {
    return (
      <BitzQueryFilter2.Item name={name} label={item?.description} labelHidden>
        {/*<BnWhetherSelect placeholder={item?.description ?? ''} />*/}
        Int64
      </BitzQueryFilter2.Item>
    );
  }
  // 流程选择器选择器
  // @ts-ignore
  if (item.dataSource === 'FlowStatus' && item.dataSourceKey) {
    return (
      <BitzQueryFilter2.Item name={name} label={item?.description} labelHidden>
        <BnFlowStatusSelect
          fluent={!advanced}
          flowBusiness={item.dataSourceKey}
          mode="multiple"
          placeholder={item?.description}
        />
      </BitzQueryFilter2.Item>
    );
  }

  return (
    <BitzQueryFilter2.Item name={name} label={item?.description} labelHidden>
      <BitzQueryFilter2.Input fluent={!advanced} placeholder={item?.description ?? ''} />
      {/* <BitzQueryFilter2.TimeRangePicker placeholder={[item?.description ?? '', '结束时间']} /> */}
      {/* <BitzQueryFilter2.DataRangePicker placeholder={[item?.description, '结束日期']} /> */}
      {/* <BitzQueryFilter2.TimePicker placeholder={item?.description ?? ''} /> */}
    </BitzQueryFilter2.Item>
  );
};

export default React.memo(RenderFormItem);

type NullableString = string | null | any

const getValueOrThrow = (value?: NullableString): string => {
  const emptyValues = new Set(['', 'null', 'undefined', '--', '-', 'N/A', 'n.a.'])

  if (value === null || value === undefined || emptyValues.has(value)) {
    return '-'
  }

  return value
}

const replaceSpace = (input: string) => {
  try {
    if (typeof input !== 'string') {
      throw new TypeError('Input must be a string')
    }

    input = input.trim()

    if (input.includes(' ')) {
      const [beforeString, ...restStrings] = input.split(' ')
      const afterString = restStrings.join(' ')
      return {
        beforeString,
        afterString,
      }
    }
    return {
      beforeString: input,
      afterString: '',
    }
  } catch (error) {
    // console.error('error', error)
    return {
      beforeString: '',
      afterString: '',
    }
  }
}

const noop = () => {}

export { noop, getValueOrThrow, replaceSpace }

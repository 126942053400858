import { JSXElementConstructor, ReactElement, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Layout, notification } from 'antd';
import styles from './index.module.less';
import { RouteMatch, useLocation, useNavigate, useRoutes } from 'react-router-dom';
import mergePath from '@/utils/mergePath';
import { PageConfig, RouteConfig } from '@/router/config';

import useBasicSettingsStore from '@/store/useBasicSettingsStore';
import tableConfigStore from '@/store/tableConfigStore';
import { useShallow } from 'zustand/react/shallow';
import useUserStore from '@/store/userStore';
import { usePageContext } from '@/providers/PageManageProvider';
import BaseNavLeft from './BaseNavLeft';
import BaseContent from './BaseContent';
import LayoutContentTabs from '@/components/LayoutContentTabs';
import Head from './Head';
import LayoutHorizontalMenu from '@/components/LayoutHorizontalMenu';
import { connectionSignalr } from '@/utils/signalr';
import { TimerContext } from '@/components/BitzTimerContext';
import { isNil, last, reduce } from 'lodash-es';
import { RouteGuard } from '@/router/RouteGuard';
import { useCanPermsision } from '@/v2/components';
import { isFalsy, isPowerFalsy } from '@/v2/utils';
import { useUnwrapRecoilValue } from '@/v2/hooks';
import { permissionDB } from '@/v2/store/store';
import { useTimeoutLogout } from '@/hooks/useTimeoutLogout';
import NotFound from '@/components/NotFound';
import NoFoundPage from '@/pages/NotFound';

// @ts-ignore
export function getRouteContext(data: any): any {
  if (isNil(data.children)) {
    return null;
  }
  return isNil(data.routeContext) ? getRouteContext(data.children.props) : data.routeContext;
}

export function getMatchRouteByEle(ele: ReactElement): RouteMatch[] | null {
  if (ele) {
    const data = getRouteContext(ele.props);
    return isNil(data?.outlet) ? (data?.matches as RouteMatch[]) : getMatchRouteByEle(data?.outlet);
  }
  return null;
}

export function getMatchRouteObj(ele: ReactElement | null) {
  if (isNil(ele)) {
    return null;
  }
  const matchRoutes = getMatchRouteByEle(ele);
  if (isNil(matchRoutes)) {
    return null;
  }
  const selectedKeys: string[] = reduce(
    matchRoutes,
    (selectedKeys: string[], res) => {
      const route = res.route;
      // @ts-ignore
      if (route.name) {
        // @ts-ignore
        selectedKeys.push(route.name);
      }
      return selectedKeys;
    },
    []
  );
  const crumbs = reduce(
    matchRoutes,
    (crumbs: { name: string; title: string }[], res) => {
      const route = res.route;
      // @ts-ignore
      if (route.name && route.meta?.title) {
        crumbs.push({
          // @ts-ignore
          name: route.name,
          // @ts-ignore
          title: route.meta?.title
        });
      }
      return crumbs;
    },
    []
  );
  const matchRoute = last(matchRoutes);
  const data = matchRoute?.route;
  return {
    // @ts-ignore
    key: data.layout ? (matchRoute?.pathnameBase ?? '') : (matchRoute?.pathname ?? ''),
    // @ts-ignore
    title: data?.meta?.title ?? '',
    // @ts-ignore
    name: data?.name ?? '',
    selectedKeys,
    crumbs,
    // @ts-ignore
    cache: data.cache
  };
}

// @ts-ignore
export function makeRouteObject(routes, upperPath?: string) {
  const RouteObjectDtoList = [];
  for (let i = 0; i < routes.length; i++) {
    const route = routes[i];
    const fullPath = mergePath(route.path, upperPath);
    const cache = isNil(route.cache) ? false : route.cache;

    // @ts-ignore
    const routeObjectDto = {
      path: route.path,
      name: route.name,
      meta: route.meta,
      cache,
      element: <route.component meta={route.meta} />,
      children: isNil(route.children) ? undefined : makeRouteObject(route.children, fullPath)
    };
    // @ts-ignore
    RouteObjectDtoList.push(routeObjectDto);
  }
  return RouteObjectDtoList;
}

const Base = ({ route }: { route: RouteConfig }) => {
  // 两个小时自动登出
  useTimeoutLogout();

  const getCurrentUserInfo = useUserStore(useShallow((state) => state.getCurrentUserInfo));
  const { getBasicSettings, themeConfig } = useBasicSettingsStore(
    useShallow((state) => ({
      getBasicSettings: state.getBasicSettings,
      themeConfig: state.themeConfig
    }))
  );
  const getStylesConfig = tableConfigStore(useShallow((state) => state.getStylesConfig));
  const { active, open } = usePageContext();
  // const keepAliveRef = getKeepAliveRef()
  const location = useLocation();
  const navigate = useNavigate();
  const eleRef = useRef<ReactElement<any, string | JSXElementConstructor<any>> | null>();
  const { getCurDayMyWorkList } = useContext(TimerContext);

  const [connection, setConnection] = useState(true);
  const permission = useUnwrapRecoilValue(permissionDB) ?? new Set();
  const canPermisision = useCanPermsision();

  useEffect(() => {
    if (connection) {
      connectionSignalr().then((connection) => {
        setConnection(false);
        connection.on('ReceiveMessage', (data) => {
          if (data?.notificationName === 'App.User.Timesheet.Timer') {
            if (data?.notificationTitle === '计时器状态变更') {
              getCurDayMyWorkList();
              return;
            }
            notification.info({
              message: data?.notificationTitle,
              duration: 0
            });
          }
        });
      });
    }
  }, [connection]);

  useEffect(() => {
    // 获取当前登录用户信息
    // getCurrentUserInfo()
    // getBasicSettings()

    // 获取全局的表格外观设置
    getStylesConfig({
      module: 'global-tableStylesConfig',
      // @ts-ignore
      settingType: 0
    }).then((r) => {});
  }, []);

  const routes = useMemo(() => {
    if (isNil(route.children)) {
      return [] as never[];
    }
    return makeRouteObject(route.children);
  }, [route]);

  /**
   * 路由从配置表从上向下查找，只要有权限打开，则返回该路由
   * // by mizi.20240912
   */
  function findFirstPath2(routes: RouteConfig[]): string | void {
    // 遍历路由数组
    for (const route of routes) {
      // 判断当前路由有没有权限，若没有权限则跳过
      // 只有子节点没有权限时，才验证父节点
      if (isFalsy(route.children)) {
        if (canPermisision(route.name)) {
          return '/' + route.path;
        } else {
          continue;
        }
      } else {
        // 若有children，递归调用本函数，传入children数组
        const childPath = findFirstPath2(route.children!);
        // 若子路由有符合条件的，返回子路由的path
        if (childPath) return '/' + route.path + childPath;
        continue;
      }
    }
  }
  // function findFirstPath2(routes: RouteConfig[]): string {
  //   // 遍历路由数组
  //   for (const route of routes) {
  //     // 如果当前路由没有children，返回当前路由的path
  //     if (!route.children || route.children.length === 0) {
  //       return '/' + route.path;
  //     }
  //     // 如果有children，递归搜索
  //     const childPath = findFirstPath2(route.children);
  //     if (childPath) return '/' + route.path + childPath;
  //   }
  //   // 如果没有找到符合条件的路由，返回空字符串
  //   return '/';
  // }

  // 匹配 当前路径要渲染的路由
  const ele = useRoutes(routes, location);

  const matchRouteObj = useMemo(() => {
    eleRef.current = ele;
    return getMatchRouteObj(ele);
  }, [routes, location]);

  const routerPathKey = useMemo(() => {
    return location.pathname + location.search;
  }, [location.pathname, location.search]);

  // 初始化选中
  useEffect(() => {
    if (!isFalsy(permission) && location.pathname === '/' && routes) {
      const defaultPath = findFirstPath2(routes) ?? '/permission';
      navigate(defaultPath, { replace: true });
    }
  }, [location, routes, permission]);

  useEffect(() => {
    if (matchRouteObj) {
      open({
        key: routerPathKey,
        label: matchRouteObj.title
      } as PageConfig);
    }
  }, [routerPathKey]);

  useEffect(() => {
    if (matchRouteObj?.title) {
      document.title = `EF-SNAKE - ${matchRouteObj.title}`;
    }
  }, [matchRouteObj]);

  return (
    <RouteGuard route={matchRouteObj!}>
      <Layout className={styles.container}>
        {themeConfig?.navigationBarStyle === 'left' ? (
          <BaseNavLeft matchRouteObj={matchRouteObj} routes={routes} route={route}>
            {themeConfig?.isMorePages && <LayoutContentTabs />}
            <BaseContent active={active} matchRouteObj={matchRouteObj} eleRef={eleRef} />
          </BaseNavLeft>
        ) : (
          <div id="app-layout">
            <div className={styles.topheader}>
              <Head />
              <LayoutHorizontalMenu />
              {themeConfig?.isMorePages && <LayoutContentTabs />}
            </div>
            <BaseContent active={active} matchRouteObj={matchRouteObj} eleRef={eleRef} />
          </div>
        )}
      </Layout>
    </RouteGuard>
  );
};

export default Base;

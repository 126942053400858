import { FC, useState } from 'react'
import { create, useModal, antdModalV5 } from '@ebay/nice-modal-react'
import { Button, Form } from 'antd'
import BitzFormModal from '@/components/BitzFormModal'
import './index.less'
import { useTranslation } from 'react-i18next'

interface ForgetPasswordProps {
  open?: boolean
  onClose?: () => void
}

const ForgetPassword: FC<ForgetPasswordProps> = () => {
  const { t } = useTranslation(['login', 'common'])
  const modal = useModal()
  const [form] = Form.useForm()
  const [loading, setLoading] = useState<boolean>(false)

  const onCheck = async () => {
    try {
      const values = await form.validateFields()
      console.log('Success:', values)
    } catch (errorInfo) {
      console.log('Failed:', errorInfo)
    }
  }

  const onReset = () => {
    form.resetFields()
  }

  const handleClose = () => {
    modal.hide()
  }

  return (
    <BitzFormModal
      title={t('PasswordReset', '忘记密码')}
      zIndex={999999}
      {...antdModalV5(modal)}
      footer={[
        <Button key="submit" type="primary" loading={loading} onClick={handleClose}>
          {t('Determine', '确定', { ns: 'common' })}
        </Button>,
        <Button onClick={handleClose}>{t('Cancel', '取消', { ns: 'common' })}</Button>,
      ]}
    >
      {t('ContactAdministrator', '联系运维管理员更改', { ns: 'common' })}
      {/* <Form form={form} name="dynamic_rule" labelCol={{ span: 4 }} wrapperCol={{ span: 20 }} style={{ maxWidth: 600 }} autoComplete="off">
        <Form.Item label="邮箱地址" name="email" rules={[{ required: true, message: '请输入邮箱地址' }]}>
          <Input allowClear />
          <div className="warnRow">系统在几十秒内向您发送一封密码重置邮件，如果在3分钟内还没收到这封邮件，请重试.</div>
        </Form.Item>
      </Form> */}
    </BitzFormModal>
  )
}

export default create(ForgetPassword)

import {FC, memo, useMemo} from 'react'
import { TreeSelect, TreeSelectProps } from 'antd'
import { useQuery } from '@tanstack/react-query'
import api from '@/services/Web'
import BitzQueryFilter2 from '@/components/BitzQueryFilter2'

// export interface BnOrganizationUnitTreeProps extends TreeSelectProps {}

interface BnOrganizationUnitTreeProps extends Omit<TreeSelectProps, 'options'> {
  /**
   * 是否启用Fluent组件模式
   */
  fluent?: boolean
}

const BnOrganizationUnitTree: FC<BnOrganizationUnitTreeProps> = (props) => {

  const { fluent } = props

  const organizationUnitTree = useQuery({
    queryKey: ['BnOrganizationTreeSelect'],
    queryFn: ({ signal, queryKey }) =>
      api.webSysOrganizationunitTreeList(
        // @ts-ignore
        queryKey[3],
        {
          signal,
        },
      ),
    retry: false,
    select: (data) => (data.success ? [data.response] : []),
    // 缓存60分钟
    staleTime: 60 * 60 * 1000
  })

  const Component = useMemo(() => fluent ? BitzQueryFilter2.TreeSelect : TreeSelect, [fluent])

  return (
    <Component
      treeNodeFilterProp="displayName"
      fieldNames={{ label: 'displayName', value: 'id', children: 'children' }}
      loading={organizationUnitTree.isLoading}
      showSearch
      style={{ width: '100%' }}
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      placeholder="请选择"
      allowClear
      treeDefaultExpandAll
      // @ts-ignore
      treeData={organizationUnitTree?.data}
      {...props}
    />
  )
}

export default memo(BnOrganizationUnitTree)

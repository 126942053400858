import { FC } from 'react'
import { ButtonProps } from 'antd'
import { CheckCircleOutlined } from '@ant-design/icons'
import { BitzButton } from 'bitz-react-admin-ui'

type SbumitButtonProps = Omit<ButtonProps, 'icon' | 'type'>
const SbumitButton: FC<SbumitButtonProps> = ({ ...rest }) => {
  return (
    <BitzButton type="primary" icon={<CheckCircleOutlined />} {...rest}>
      {rest.children || '保存'}
    </BitzButton>
  )
}

export default SbumitButton

import { createContext, useEffect, useState } from 'react';
import api from '@/services/Web';
import { message } from '../BitzAntApp/Static';
import { useInterval } from 'ahooks';
import { getFirstMyWorkFilrst, hourToSec } from '@/pages/Works/Common';
import { useQueryClient } from '@tanstack/react-query';
import { GetTimerOutput } from '@/services/WebApi';
import dayjs from 'dayjs';

const parsingTime = (timeString) => {
  if (timeString) {
    const timeParts = timeString.split(':');
    const hours = parseInt(timeParts[0], 10);
    const minutes = parseInt(timeParts[1], 10);
    const seconds = parseInt(timeParts[2], 10);

    // 总毫秒数计算
    const totalMilliseconds = dayjs
      .duration({
        hours: hours,
        minutes: minutes,
        seconds: seconds
      })
      .asMilliseconds();

    return totalMilliseconds;
  }
  return 0;
};

const TimerContext = createContext({
  runningId: '',
  runningTimer: 0,
  runningTimerState: false,
  workInfo: {} as GetTimerOutput | null | undefined,
  onGlobalStart: (id?: string) => Promise.resolve(),
  onGlobalPause: (id) => Promise.resolve(),
  createNewTimer: (data) => {},
  getCurDayMyWorkList: () => {},
  onGlobalContinue: (id) => Promise.resolve(),
  onOnlyPause: () => {},
  deleteCurTimer: () => {}
});

const TimerProvider = ({ children }) => {
  const [runningId, setRunningId] = useState('');
  const [runningTimer, setRunningTimer] = useState<number>(0);
  const [runningTimerState, setRunningTimerState] = useState<boolean>(false);
  const [workInfo, setworkInfo] = useState<GetTimerOutput>();
  const [delay, setDelay] = useState<number | undefined>(void 0);
  const queryClient = useQueryClient();

  const refreshList = () => {
    // 刷新刷新计时弹窗
    queryClient.refetchQueries({ queryKey: ['Base', 'BnHeadTimer', 'TimerModal'], type: 'active' });
    // 刷新工时列表
    queryClient.refetchQueries({ queryKey: ['Works', 'WorkHourList', 'DayPanel'], type: 'active' });
    // 刷新工时日历
    queryClient.refetchQueries({ queryKey: ['webTimesheetsTimesheetTimesheetGetmycalendarCreate'], type: 'active' });
  };

  // 计时器
  const timer = useInterval(() => {
    setRunningTimer(runningTimer + 1000);
  }, delay);

  // 初始化
  useEffect(() => {
    // 重新取时间
    if (runningId) {
      onChangeTimerFun(runningId, 2).then(() => {});
    }

    return () => {
      if (timer) {
        // @ts-ignore
        clearInterval(timer);
      }
    };
  }, []);

  // 开启
  const onStart = () => {
    setDelay(1000);
    setRunningTimerState(true);
  };

  // 暂停
  const onPause = () => {
    setDelay(void 0);
    setRunningId('');
    setRunningTimerState(false);
    refreshList();
  };

  /***
   * 计时器状态变更
   * @param record
   * @param timerState 0:开始 1:暂停 2:继续 3:停止 4:重置 5:完成
   */
  const onChangeTimerFun = (runningId, timerState) => {
    return api
      .webTimesheetsTimesheetTimesheetRunningtimerCreate({
        ids: [runningId],
        timerState
      })
      .then((res) => {
        if (res?.success) {
          const data = res?.response;
          const { id, watchTime } = data as GetTimerOutput;
          const millisecond = parsingTime(watchTime);
          id && setRunningId(id);
          setRunningTimer(millisecond);
          setworkInfo(data);
          if (timerState == 0 || timerState == 2) {
            onStart();
          }
          if (timerState == 1) {
            onPause();
          }
          // 刷新
          refreshList();
        } else {
          message.error(res.msg);
        }
        return res;
      });
  };
  // 刷新-获取当天运行中的计时器，取第一条开始计时
  const getCurDayMyWorkList = () => {
    console.log('登陆或者刷新重新获取计时器');
    return getFirstMyWorkFilrst(100)
      .then((res) => {
        if (res?.success) {
          const response = res?.response ?? [];
          const startItem = response.find((v) => v?.timerState === 'Start');
          const dayPauseItem = response.find((v) => {
            return dayjs(v?.workDate).isToday() && v?.timerState === 'Pause';
          });
          if (!startItem && runningId) {
            refreshList();
          }
          if (startItem) {
            // 正在运行的计时器
            const millisecond = parsingTime(startItem?.watchTime);
            startItem?.id && setRunningId(startItem.id);
            setRunningTimer(millisecond);
            setworkInfo(startItem);
            onStart();
            // 刷新
            refreshList();
          } else if (dayPauseItem) {
            // 取当天暂停的填充数据
            const millisecond = parsingTime(dayPauseItem?.watchTime);
            setRunningTimer(millisecond);
            setworkInfo(dayPauseItem);
            onPause();
          } else {
            onPause();
          }
        }
      })
      .catch(() => {
        console.log('获取运行计时器列表失败');
      });
  };

  // 开始
  const onGlobalStart = (id?: string) => {
    if (id) {
      return onChangeTimerFun(id, 0);
    } else {
      return api
        .webTimesheetsTimesheetTimesheetCreatetimerCreate({
          workDate: dayjs().format('YYYY-MM-DD')
        })
        .then((res) => {
          if (res?.success) {
            createNewTimer(res?.response);
            refreshList();
          }
        });
      // return getFirstMyWorkFilrst(1).then(r => {
      //   if (r.success) {
      //     if ((r?.response ?? []).length > 0) {
      //       const data = r?.response?.[0]
      //       // 开启计时器
      //       onChangeTimerFun(data?.id, 0)
      //     } else {
      //       console.log('顶部开启无运行计时，创建新的');
      //       api.webTimesheetsTimesheetTimesheetCreatetimerCreate({}).then(r => {
      //         if (r.success) {
      //           message.success('计时器创建成功')
      //           createNewTimer(r?.response)
      //         } else {
      //           message.error('计时器创建失败')
      //         }
      //       }).finally(() => refreshList())
      //     }
      //   }
      // })
    }
  };

  // 暂停
  const onGlobalPause = (id) => {
    setDelay(void 0);
    return onChangeTimerFun(id, 1);
  };

  // 继续执行
  const onGlobalContinue = (id) => {
    return onChangeTimerFun(id, 2);
  };

  // 创建新的计时器
  const createNewTimer = (data) => {
    const millisecond = hourToSec(data?.watchTime);
    setRunningId(data?.id);
    setRunningTimer(millisecond);
    setworkInfo(data);
    onStart();
  };

  /**
   * 清空运行状态
   */
  const deleteCurTimer = () => {
    setDelay(void 0);
    setRunningId('');
    setRunningTimer(0);
    setRunningTimerState(false);
    setworkInfo(undefined);
  };

  // @ts-ignore
  return (
    <TimerContext.Provider
      value={{
        runningId,
        runningTimer,
        runningTimerState,
        workInfo,
        // @ts-ignore
        onGlobalStart,
        // @ts-ignore
        onGlobalPause,
        createNewTimer,
        getCurDayMyWorkList,
        // @ts-ignore
        onGlobalContinue,
        onOnlyPause: onPause,
        deleteCurTimer: deleteCurTimer
      }}
    >
      {children}
    </TimerContext.Provider>
  );
};

export { TimerContext, TimerProvider };

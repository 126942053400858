export function flattenPaths(routes) {
  return routes
    ?.filter((i) => !i?.path?.startsWith('/'))
    ?.map((route) => {
      const { path, children, ...rest } = route

      if (!children) {
        return {
          ...rest,
          path,
          label: rest?.meta?.title,
          key: path,
        }
      }
      const flattenedChildren = flattenPaths(
        children?.filter((i) => !i?.path?.startsWith('/'))?.map((item) => ({
          ...item,
          path: path + '/' + item.path,
          label: item?.meta?.title,
          key: path
        })),
      )
      return { ...rest, path, label: rest?.meta?.title, key: path, children: flattenedChildren }
    })
}

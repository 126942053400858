import { memo, useContext, useEffect, useState } from 'react';
import { CaretRightOutlined, PauseOutlined } from '@ant-design/icons';
import { Spin, theme } from 'antd';
import { css, cx } from '@emotion/css';
import { Time, timeBtnClass, timerwrap } from './style';
import { TimerContext } from '@/components/BitzTimerContext';
import { hourToSec } from '@/pages/Works/Common';

/**
 * - description: 格式化毫秒数
 * - param {number} duration 毫秒数
 * - return `天.时:分:秒`
 */
function formatMilliseconds(duration: number) {
  if (isNaN(duration)) return '00:00:00';
  const days = Math.floor(duration / (1000 * 3600 * 24));
  duration %= 1000 * 3600 * 24;
  const hours = Math.floor(duration / (1000 * 3600));
  duration %= 1000 * 3600;
  const minutes = Math.floor(duration / (1000 * 60));
  duration %= 1000 * 60;
  const seconds = Math.floor(duration / 1000);
  const padded = (num) => (num < 10 ? '0' + num : num);
  if (days <= 0) return `${padded(hours)}:${padded(minutes)}:${padded(seconds)}`;
  return `${days}.${padded(hours)}:${padded(minutes)}:${padded(seconds)}`;
}

interface Timer {
  record?: any;
  showTime?: boolean;
  style?: React.CSSProperties;
  type?: string;
}

export const Timer = memo(({ record, showTime = true, style, type }: Timer) => {
  const { token } = theme.useToken();
  const [time, setTime] = useState(hourToSec(record?.watchTime));
  const [isLoading, setIsLoading] = useState(false);
  const { runningId, runningTimer, runningTimerState, onGlobalStart, onGlobalPause, onGlobalContinue, onOnlyPause } = useContext(TimerContext);

  useEffect(() => {
    console.log('计时弹窗');
    if (runningId && runningId == record.id) {
      if (record.timerState === 'Pause') {
        // 状态Pause，停止计时器
        onOnlyPause();
      }
    } else {
      if (record.timerState === 'Start') {
        // 状态Start，开启计时器
        onGlobalContinue(record?.id);
      }
    }
  }, [record]);

  useEffect(() => {
    // @ts-ignore
    if (runningId == record?.id) {
      setTime(runningTimer);
    }
  }, [runningTimer]);

  // @ts-ignore
  const isStart: boolean = runningId == record?.id;

  // const queryClient = useQueryClient()
  // const refreshFun = () => {
  //   // 刷新工时列表
  //   queryClient.refetchQueries({ queryKey: ['Works', 'WorkHourList', 'DayPanel'], type: 'active' })
  //   // 刷新刷新计时弹窗
  //   queryClient.refetchQueries({ queryKey: ['Base', 'BnHeadTimer', 'TimerModal'], type: 'active' })
  // }

  const onPause = () => {
    setIsLoading(true);
    onGlobalPause(record?.id);
    setTimeout(() => {
      setIsLoading(false);
    }, 300);
  };

  const onStart = () => {
    setIsLoading(true);
    onGlobalStart(record?.id);
    setTimeout(() => {
      setIsLoading(false);
    }, 300);
  };

  const bgcClass = css`
    background-color: ${type === 'default' ? '#86909C' : token.colorPrimary};
    border-color: ${type === 'default' ? '#86909C' : token.colorPrimary};
    pointer-events: ${type === 'default' ? 'none' : 'auto'};
  `;

  return (
    <Spin spinning={isLoading} size="small">
      <div className={timerwrap(true)} style={style}>
        {showTime && <Time>{formatMilliseconds(time)}</Time>}
        {isStart ? (
          <PauseOutlined className={cx(timeBtnClass, bgcClass)} onClick={onPause} />
        ) : (
          <CaretRightOutlined className={cx(timeBtnClass, bgcClass)} onClick={onStart} />
        )}
      </div>
    </Spin>
  );
});

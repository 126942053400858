/**
 * Web接口
 * https://api.vnext.ailinkedlaw.com/index.html?urls.primaryName=Web
 */
import { Api } from './WebApi';
import request from '@/api/request';

const api = new Api();
api.instance = request;

const apiWeb = api.api;

export default apiWeb;

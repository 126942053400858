import { FC, memo, useContext, useEffect, useMemo } from 'react';
// @ts-ignore
import { BitzFluentRangeTimePicker } from 'bitz-react-admin-ui';
import { RangePickerProps } from 'antd/es/date-picker';
import { isEmpty, omit } from 'lodash-es';
import dayjs from 'dayjs';
import { MyContext } from '../../BitzQueryFilterContext';

export interface BQFTimeRangePickerProps extends Omit<RangePickerProps, 'onChange'> {
  onChange?: ({ startTime, endTime }: { startTime?: string; endTime?: string }) => void;
}

const BQFTimeRangePicker: FC<BQFTimeRangePickerProps> = (props) => {
  const { dispatch } = useContext(MyContext);
  const { onChange, ...rest } = props;

  const formatType = 'HH:mm';

  const id = useMemo(() => {
    if (props.id) {
      // @ts-ignore
      if (props.id?.indexOf('advanced') !== -1) {
        // @ts-ignore
        const list = split(props.id, '_');
        return list[list.length - 1];
      }
      return props.id;
    }
    return '';
  }, [props.id]);

  useEffect(() => {
    if (id) {
      dispatch?.({
        type: 'update',
        name: id,
        value: props?.value
      });
    }
  }, [props?.value, id]);

  const handleChange: RangePickerProps['onChange'] = (date, dateString) => {
    const start = date?.[0] ? dayjs(date[0]) : undefined;
    const end = date?.[1] ? dayjs(date[1]) : undefined;

    const startText = start?.format(formatType) ?? ' ';
    // @ts-ignore
    const endText = date[1]?.format(formatType) ?? ' ';

    const valueName = `${startText} - ${endText}`;

    // @ts-ignore
    onChange?.({
      // startTime: start?.format('YYYY-MM-DD HH:mm:ss'),
      // endTime: end?.format('YYYY-MM-DD HH:mm:ss'),
      startTime: start?.format('HH:mm:ss'),
      endTime: end?.format('HH:mm:ss')
    });

    if (id) {
      dispatch?.({
        type: 'update',
        name: id,
        valueName: valueName
      });
    }
  };

  const onValuesChange = (dates: any, dateStrings: any, info: any) => {
    handleChange(dates, dateStrings);
  };

  const value: RangePickerProps['value'] = useMemo(() => {
    // @ts-ignore
    if (props?.value) {
      // // @ts-ignore
      // const start = props?.value?.startTime ? dayjs(props?.value?.startTime) : null
      // // @ts-ignore
      // const end = props?.value?.endTime ? dayjs(props?.value?.endTime) : null

      // @ts-ignore
      const startStr: string = props?.value?.startTime ? `${dayjs().format('YYYY-MM-DD')} ${props?.value?.startTime}` : null;
      // @ts-ignore
      const endStr: string = props?.value?.endTime ? `${dayjs().format('YYYY-MM-DD')} ${props?.value?.endTime}` : null;
      const start = isEmpty(startStr) ? undefined : dayjs(startStr);
      const end = isEmpty(endStr) ? undefined : dayjs(endStr);
      return [start, end];
    }
    return undefined;
  }, [props?.value]);

  return (
    <BitzFluentRangeTimePicker
      format={formatType}
      onCalendarChange={onValuesChange}
      style={{ width: '100%' }}
      value={value}
      onChange={handleChange}
      {...omit(rest, 'value')}
    />
  );
};

export default memo(BQFTimeRangePicker);

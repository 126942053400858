import ReactDOM from 'react-dom/client';
// import 'dayjs/locale/zh-cn';

/**
 * @description 引入核心的App.ts
 */
import App from './App';
/**
 * @description 引入多语言
 */
import './locales';
/**
 * @description 引入全局样式
 */
import './styles/index.less';
/**
 * @description 引入公共埋点方法
 */
/**
 * @description 引入bitz-react-admin-ui样式
 */
import 'bitz-react-admin-ui/dist/style.css';

// 埋点初始化
// sentryInit()

import initReactFastclick from 'react-fastclick';

import VConsole from 'vconsole';
import { ifnvl, run } from './utils';
import { ENV } from './constants';

initReactFastclick();

/**
 * 在本地和test上显示vconsole,
 * 留个后门，在其他环境下可以打开vconsole
 */
run([ENV.DEV, ENV.TEST].includes(import.meta.env.VITE_ENV), () => {
  console.log('发布分支环境 ---> ', import.meta.env.VITE_ENV, import.meta.env.VITE_BRANCH);
  const isShowVConsole = ifnvl(localStorage.getItem('vconsole') ?? import.meta.env.VITE_VCONSOLE, 'Y') === 'Y';
  isShowVConsole && new VConsole();
});

ReactDOM.createRoot(document.getElementById('root')!).render(<App />);

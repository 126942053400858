import { DBStore } from '@/v2/core/DBStore';
import localforage from 'localforage';

/**
 * 普通字典
 * key 配置规则
 * 字典key: 驼峰
 * SysData: Sys 前缀开头，匈牙利命名法
 * ExtraData: Extra 前缀开头，匈牙利命名法
 */
export const DictDB = localforage.createInstance({
  name: 'ef/dict'
});

/**
 * 字典数据管理
 *
 * apis: 管理字典对应的api信息，请求参数，响应数据，mapper 信息； Map 数据类型
 */
export const DictManageDB = localforage.createInstance({
  name: 'ef/dict/manage'
});

export const DictCacheDB = localforage.createInstance({
  name: 'ef/dict/cache'
});

export const DictRouteTabDB = localforage.createInstance({
  name: 'ef/dict/routeTab'
});

export const cache$db = new DBStore(DictCacheDB, true);

export interface DictAPI {
  api: string;
  method: 'get' | 'post';
  params?: Row;
  body?: Row;
  mappper?: Row;
  /**
   * 更新规则
   * full: 全量更新
   * increment: 增量更新
   */
  upgradeRule?: 'full' | 'increment';
}

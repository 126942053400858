import zhCN from 'antd/es/locale/zh_CN';
import enUS from 'antd/es/locale/en_US';
import jaJP from 'antd/es/locale/ja_JP';
import zhTW from 'antd/es/locale/zh_TW';
import koKR from 'antd/es/locale/ko_KR';
import dayjs from 'dayjs';
import useBasicSettingsStore from '@/store/useBasicSettingsStore';
import { useShallow } from 'zustand/react/shallow';
import useLanguageStore from '@/store/languageStore';
// import { BitzTheme } from 'bitz-react-admin-ui'
import { theme } from 'antd';
import { BitzConfigProviderProps } from 'bitz-react-admin-ui/dist/components/BitzConfigProvider';
import { useMemo } from 'react';

export const getLocaleData = (language?: string) => {
  switch (language) {
    case 'zh-Hans':
      // 中文简体
      import('dayjs/locale/zh-cn').then(() => {
        dayjs.locale('zh-cn');
      });
      document.documentElement.lang = 'zh-cn';
      return zhCN;
    case 'en':
      // 英语
      import('dayjs/locale/es-us').then(() => {
        dayjs.locale('es-us.');
      });
      document.documentElement.lang = 'en';
      return enUS;
    case 'JP':
      // 日语
      import('dayjs/locale/ja').then(() => {
        dayjs.locale('ja');
      });
      document.documentElement.lang = 'ja';
      return jaJP;
    case 'KR':
      return koKR;
    case 'zh-Hant':
      // 中文繁体
      import('dayjs/locale/zh-tw').then(() => {
        dayjs.locale('zh-tw');
      });
      document.documentElement.lang = 'zh-Hant';
      return zhTW;
    default:
      return zhCN;
  }
};

const setScrollBarStyle = (token) => {
  const scrollbarStyle = document.querySelector('head style[data-scrollbar-style]');
  if (scrollbarStyle) {
    document.head.removeChild(scrollbarStyle);
  }
  const styleEl = document.createElement('style');
  styleEl.type = 'text/css';
  styleEl.setAttribute('data-scrollbar-style', 'bitz');

  // 设置全局滚动条样式
  const scrollbarCss = `
  ::-webkit-scrollbar {
    width: ${token?.scrollbarWidth}px;
    height: ${token?.scrollbarHeight}px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${token?.scrollbarThumbColor};
    border-radius: 999px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${token?.scrollbarThumbHoverColor};
  }
  ::-webkit-scrollbar-track {
    background: ${token?.scrollbarTrackColor};
    border-radius: 999px;
  }`;

  // 将CSS样式规则添加到style元素中
  styleEl.innerHTML = scrollbarCss;

  // 将style元素添加到文档的head中
  document.head.appendChild(styleEl);
};

const adjustColor = (hex, offset) => {
  // 将十六进制颜色值转换为RGB
  let r = parseInt(hex.slice(1, 3), 16);
  let g = parseInt(hex.slice(3, 5), 16);
  let b = parseInt(hex.slice(5, 7), 16);

  // 应用偏移量
  r = Math.min(255, Math.max(0, r + offset.r));
  g = Math.min(255, Math.max(0, g + offset.g));
  b = Math.min(255, Math.max(0, b + offset.b));

  // 将RGB转换回十六进制颜色值
  return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase();
};

/** 暗色主题 */
const darkColorPrimaryOffset = { r: -11, g: -14, b: -34 };
const darkToHoverColorOffset = { r: 31, g: 27, b: 18 };
const darkToActiveColorOffset = { r: -26, g: -33, b: -80 };
const darkLinkToActiveColorOffset = { r: -3, g: -20, b: -93 };

/** 亮色主题 */
const lightToHoverColorOffset = { r: 42, g: 40, b: 5 };
const lightToActiveColorOffset = { r: -25, g: -33, b: -38 };
const lightLinkToActiveColorOffset = { r: -2, g: -20, b: -51 };

export const useConfigProvider = () => {
  const themeConfig = useBasicSettingsStore(useShallow((state) => state.themeConfig));
  const language = useLanguageStore(useShallow((state) => state.language));
  const locale = useMemo(() => getLocaleData(language), [language]);
  const colorPrimary = themeConfig?.colorPrimary ?? '#5D73FA';

  if (themeConfig?.theme === 'dark') {
    const darkColorPrimary = adjustColor(colorPrimary, darkColorPrimaryOffset); // #5265d8
    const colorPrimaryHover = adjustColor(colorPrimary, darkToHoverColorOffset); // #7c8ee8
    const colorPrimaryActive = adjustColor(colorPrimary, darkToActiveColorOffset); // #4352aa
    const colorLinkActive = adjustColor(colorPrimary, darkLinkToActiveColorOffset); // #5A5F9D

    const darkConfig: BitzConfigProviderProps = {
      locale,
      theme: {
        cssVar: { key: 'app-theme' },
        algorithm: theme.darkAlgorithm,
        token: {
          mode: 'dark',
          colorPrimary: colorPrimary,
          colorInfo: colorPrimary,
          // colorSuccessBg: '#ecffec',
          // colorSuccessBorder: '#aad8aa',
          fontSize: 14,
          sizeUnit: 4,
          wireframe: true,
          sizeStep: 4,
          colorTextHover: '#fff',
          colorTextActive: '#fff',
          colorSecondaryHighlightText: 'rgba(255, 255, 255, 0.65)',
          // 关键字输入框背景色
          colorKeywordBg: 'rgb(29, 29, 29)',
          // active背景色
          colorBgActive: '#303030',
          // 滚动条
          scrollbarWidth: 8,
          scrollbarHeight: 8,
          scrollbarTrackColor: '#444',
          scrollbarThumbColor: '#555',
          scrollbarThumbHoverColor: '#666'
        },
        components: {
          Breadcrumb: {
            linkHoverColor: colorLinkActive,
            lastItemColor: colorLinkActive,
            itemColor: darkColorPrimary,
            linkColor: darkColorPrimary,
            separatorColor: darkColorPrimary
          },
          Modal: {
            headerBg: '#141414',
            contentBg: '#141414',
            footerBg: '#141414'
          },
          Table: {
            headerSplitColor: 'transparent',
            headerBorderRadius: 0,
            toolbarBgColor: 'rgb(54, 54, 54)',
            headerBg: 'rgb(32, 32, 32)',
            headerSortHoverBg: 'rgb(48, 48, 48)',
            headerSortActiveBg: 'rgb(48, 48, 48)',
            evenRowBgColor: 'rgb(60, 60, 60)',
            rowHoverBg: 'rgb(50, 50, 50)',
            radioCheckboxBorderColor: '#666',
            expandIconBorderColor: '#d0d0d0',
            borderColor: '#303030',
            cellPaddingInline: 10,

            xlTable: {
              fontSize: 16,
              height: 56,
              lineHeight: 24,
              skeletonHeight: 22
            },
            lgTable: {
              fontSize: 14,
              height: 50,
              lineHeight: 20,
              skeletonHeight: 22
            },
            mdTable: {
              fontSize: 14,
              height: 42,
              lineHeight: 18,
              skeletonHeight: 19
            },
            smTable: {
              fontSize: 12,
              height: 36,
              lineHeight: 16,
              skeletonHeight: 16
            },
            xsTable: {
              fontSize: 12,
              height: 32,
              lineHeight: 14,
              skeletonHeight: 14
            },

            mobileListBgColor: '#000000',
            listItemBgColor: '#424242'
          },
          Layout: {
            headerBg: 'rgb(20, 20, 20)',
            headerHeight: 40
          },
          Alert: {
            fontSize: 12
          }
        }
      }
    };
    setScrollBarStyle(darkConfig?.theme?.token);
    return darkConfig;
  }

  const colorPrimaryHover = adjustColor(colorPrimary, lightToHoverColorOffset); // #879bff
  const colorPrimaryActive = adjustColor(colorPrimary, lightToActiveColorOffset); // #4452d4
  const colorLinkActive = adjustColor(colorPrimary, lightLinkToActiveColorOffset); // #5b5fc7

  const configData: BitzConfigProviderProps = {
    locale,
    theme: {
      cssVar: { key: 'app-theme' },
      algorithm: theme.defaultAlgorithm,
      token: {
        mode: 'light',
        colorPrimary: colorPrimary,
        colorInfo: colorPrimary,
        colorWarning: '#ffbe0a',
        colorError: '#f4463c',
        colorSuccess: '#4cb050',
        // colorSuccessBg: '#ecffec',
        // colorSuccessBorder: '#aad8aa',
        colorTextBase: 'rgb(46, 46, 46)',
        fontSize: 14,
        sizeUnit: 4,
        wireframe: true,
        sizeStep: 4,

        colorBgBase: '#ffffff',
        borderRadius: 4,

        // colorText: '#333333',
        colorTextHover: 'rgba(0, 0, 0, 0.6)',
        colorTextActive: 'rgba(0, 0, 0, 0.6)',
        // colorTextSecondary: '#666666',
        colorTextDisabled: '#aaaaaa',
        colorSecondaryHighlightText: 'rgba(0, 0, 0, 0.7)',

        // colorPrimaryBorder: '#B4BEFF',
        // colorPrimaryBg: '#E6E6FF',

        // colorInfoBorder: '#B4BEFF',
        // colorInfoBg: '#E6E6FF',

        // colorWarningBorder: '#ffd250',
        // colorWarningBg: '#fffae6',

        // colorErrorBorder: '#ffa0a0',
        // colorErrorBg: '#ffeaea',
        // 关键字输入框背景色
        colorKeywordBg: '#fbfbfb',
        colorTextPlaceholder: '#aaa',
        // active背景色
        colorBgActive: '#fff',
        // 滚动条
        scrollbarWidth: 8,
        scrollbarHeight: 8,
        scrollbarTrackColor: '#e5e5e5',
        scrollbarThumbColor: '#ccc',
        scrollbarThumbHoverColor: '#959595'
      },
      components: {
        Alert: {
          // colorSuccessBg: 'rgb(235, 253, 240)',
          fontSize: 12
        },
        Button: {
          // colorErrorHover: 'rgb(247, 173, 180)',
          // colorPrimaryHover: 'rgb(141, 146, 215)',
          // borderColorDisabled: 'rgb(246, 246, 246)',
        },
        Breadcrumb: {
          linkHoverColor: colorLinkActive,
          lastItemColor: colorLinkActive,
          itemColor: colorPrimary,
          linkColor: colorPrimary,
          separatorColor: colorPrimary
        },
        Dropdown: {
          // controlItemBgHover: 'rgb(240, 243, 255)',
          // controlItemBgActive: 'rgb(232, 235, 250)',
        },
        Menu: {
          // itemMarginBlock: 0,
          itemMarginInline: 0,
          itemMarginBlock: 0,
          itemHeight: 50
          // itemHoverBg: 'rgb(240, 243, 255)',
          // itemActiveBg: 'rgb(232, 235, 250)',
          // itemSelectedBg: 'rgb(232, 235, 250)',
        },
        Select: {
          // optionActiveBg: 'rgb(240, 243, 255)',
          // optionSelectedBg: 'rgb(232, 235, 250)',
          optionPadding: '5px 12px'
          // algorithm: true,
          // multipleItemBg: 'rgb(224, 227, 255)',

          // optionSelectedColor: 'rgb(91, 95, 199)',
          // colorBgContainer: 'rgb(243, 243, 243)',
          // colorTextPlaceholder: 'rgb(105, 123, 122)',
          // colorText: 'rgb(51, 51, 51)',
        },
        Transfer: {
          // controlItemBgHover: 'rgb(240, 243, 255)',
          // controlItemBgActive: 'rgb(232, 235, 250)',
        },
        Mentions: {
          // controlItemBgHover: 'rgb(240, 243, 255)',
          paddingInline: 8,
          paddingInlineLG: 8
        },
        TreeSelect: {
          // nodeSelectedBg: 'rgb(232, 235, 250)',
          // nodeHoverBg: 'rgb(240, 243, 255)',
        },
        Calendar: {
          // itemActiveBg: 'rgb(232, 235, 250)',
          // controlItemBgHover: 'rgb(240, 243, 255)',
          // controlItemBgActive: 'rgb(232, 235, 250)',
        },
        Segmented: {
          // itemActiveBg: 'rgb(240, 243, 255)',
          // itemHoverBg: 'rgb(240, 243, 255)',
          // itemHoverColor: 'rgb(91, 95, 199)',
          // itemSelectedColor: 'rgb(91, 95, 199)',
        },
        Tree: {
          // directoryNodeSelectedBg: 'rgb(91, 95, 199)',
          // nodeHoverBg: 'rgb(240, 243, 255)',
          // nodeSelectedBg: 'rgb(232, 235, 250)',
          // directoryNodeSelectedColor: 'rgb(232, 235, 250)',
        },
        Modal: {
          headerBg: '#fff',
          contentBg: '#f6f7f9',
          footerBg: '#fff'
        },
        Drawer: {
          colorBgMask: 'rgba(0, 0, 0, 0.1)',
          // colorBgElevated: 'rgb(243, 243, 243)',
          padding: 8
        },
        Tooltip: {
          // algorithm: true,
          // colorBgSpotlight: 'rgb(36, 36, 36)',
          // colorText: 'rgb(255, 255, 255)',
        },
        Collapse: {
          headerPadding: '8px 16px',
          contentPadding: '8px 16px'
        },
        Descriptions: {
          itemPaddingBottom: 8,
          padding: 8,
          paddingXS: 4,
          paddingSM: 16,
          paddingLG: 24
        },
        Divider: {
          margin: 8,
          marginLG: 16
        },
        Form: {
          itemMarginBottom: 10
        },
        Input: {
          paddingInlineLG: 8,
          paddingInline: 8
          // colorBgContainer: 'rgb(243, 243, 243)',
          // colorTextPlaceholder: 'rgb(105, 123, 122)',
          // colorText: 'rgb(51, 51, 51)',
        },
        Cascader: {
          // controlItemBgHover: 'rgb(240, 243, 255)',
          // optionSelectedBg: 'rgb(232, 235, 250)',
        },
        InputNumber: {
          paddingInline: 8,
          paddingInlineLG: 8,
          paddingInlineSM: 8
          // colorTextPlaceholder: 'rgb(105, 123, 122)',
          // colorText: 'rgb(51, 51, 51)',
        },
        DatePicker: {
          // colorTextPlaceholder: 'rgb(105, 123, 122)',
          // colorText: 'rgb(51, 51, 51)',
        },
        Table: {
          headerSplitColor: 'transparent',
          headerBorderRadius: 0,
          cellPaddingBlock: 16,
          cellPaddingBlockMD: 12,
          cellPaddingBlockSM: 8,
          cellPaddingInline: 10,
          cellPaddingInlineMD: 8,
          cellPaddingInlineSM: 8,
          toolbarBgColor: '#fafafa',
          headerBg: 'rgb(232, 232, 232)',
          headerSortHoverBg: 'rgb(220, 220, 220)',
          headerSortActiveBg: 'rgb(220, 220, 220)',
          evenRowBgColor: '#f9f9f9',
          radioCheckboxBorderColor: '#d9d9d9',
          expandIconBorderColor: '#d0d0d0',
          borderColor: '#ddd',
          xlTable: {
            fontSize: 16,
            height: 56,
            lineHeight: 24,
            skeletonHeight: 22
          },
          lgTable: {
            fontSize: 14,
            height: 50,
            lineHeight: 20,
            skeletonHeight: 22
          },
          mdTable: {
            fontSize: 14,
            height: 42,
            lineHeight: 18,
            skeletonHeight: 19
          },
          smTable: {
            fontSize: 12,
            height: 36,
            lineHeight: 16,
            skeletonHeight: 16
          },
          xsTable: {
            fontSize: 12,
            height: 32,
            lineHeight: 14,
            skeletonHeight: 14
          },

          mobileListBgColor: '#fff',
          listItemBgColor: '#f4f7fc'
        },
        Layout: {
          headerBg: '#fff',
          headerHeight: 40
        }
      }
    }
  };
  setScrollBarStyle(configData?.theme?.token);
  return configData;
};

/*
 * # Title: TimeList Component
 * - Date: 2023-12-22
 * - LastEditors: liujin liujin@elinklaw.com
 * - LastEditTime: 2023-12-22 14:56:04
 */
import { cx } from '@emotion/css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { timeListArrowStyle, timeListSlider } from './style';
import { Button } from 'antd';
import { useEffect, useState } from 'react';
import './index.less';
import dayjs from 'dayjs';
import { find } from 'lodash-es';

const settings = {
  infinite: false,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  nextArrow: (
    <Arrow>
      <RightOutlined />
    </Arrow>
  ),
  prevArrow: (
    <Arrow>
      <LeftOutlined />
    </Arrow>
  )
};

function Arrow(props) {
  const { className, onClick } = props;
  return (
    <div className={cx(className, timeListArrowStyle)} onClick={onClick}>
      {props.children}
    </div>
  );
}

const timeList = [
  {
    label: '星期一',
    value: '1'
  },
  {
    label: '星期二',
    value: '2'
  },
  {
    label: '星期三',
    value: '3'
  },
  {
    label: '星期四',
    value: '4'
  },
  {
    label: '星期五',
    value: '5'
  },
  {
    label: '星期六',
    value: '6'
  },
  {
    label: '星期日',
    value: '7'
  }
];
const formatStr = 'YYYY-MM-DD';
const startTime = '00:00:00';
const endTime = '23:59:59';

const TimeList = (props) => {
  const day = dayjs().format('d');
  const [curDay, setCurDay] = useState<any>(day);
  useEffect(() => {
    if (props.show) {
      setCurDay(dayjs().format('d'));
    }
  }, [props.show]);

  useEffect(() => {
    if (props.isSeeAll) {
      setCurDay(null);
    }
  }, [props.isSeeAll]);

  useEffect(() => {
    let date = dayjs();
    const weekText =
      find(timeList, (f) => {
        return f.value == curDay;
      })?.label ?? '';
    switch (curDay) {
      case '1':
        date = dayjs().day(1);
        break;
      case '2':
        date = dayjs().day(2);
        break;
      case '3':
        date = dayjs().day(3);
        break;
      case '4':
        date = dayjs().day(4);
        break;
      case '5':
        date = dayjs().day(5);
        break;
      case '6':
        date = dayjs().day(6);
        break;
      case '7': // 星期天
        date = dayjs().day(7);
        break;
    }

    if (curDay) {
      const ret: any = {
        weekText: `${date.format('YYYY年MM月DD日')} ${weekText}`,
        selectDay: date,
        workDate: {
          startDate: `${date.format(formatStr)} ${startTime}`,
          endDate: `${date.format(formatStr)} ${endTime}`
        }
      };
      props?.onChange?.(ret);
      props?.onRestSeeAll?.();
    } else {
      props?.onChange?.({
        weekText: '',
        selectDay: null,
        workDate: {}
      });
    }
  }, [curDay]);

  const getItemClassName = (value, curValue) => {
    // console.log(value, curValue);
    if (value == 7 && curValue == 0) {
      return 'listItem listItemActive';
    }
    return curValue === value ? 'listItem listItemActive' : 'listItem';
  };

  const onTodayHandle = () => {
    setCurDay(day);
  };

  return (
    // <div className={timeListWrap}>
    <div className="timeListWapper">
      {timeList?.length ? (
        <Slider {...settings} className={timeListSlider}>
          {timeList.map((item) => (
            <div
              key={item.value}
              className={getItemClassName(item.value, curDay)}
              onClick={() => {
                setCurDay(item.value);
              }}
            >
              <div>{item.label}</div>
              <div>{dayjs().day(Number(item.value)).format('YYYY-MM-DD')}</div>
            </div>
          ))}
          {/* <Segmented options={['Map', 'Transit', 'Satellite']} value={curDay} onChange={setCurDay} /> */}
        </Slider>
      ) : (
        <div>暂无数据</div>
      )}
      {/* <div className={curDay === 'today' ? 'todayItem todayItemActive' : 'todayItem'}
        onClick={() => {
          setCurDay('today')
        }}>今天</div> */}
      <Button type={day == curDay ? 'primary' : 'text'} size="small" style={{ marginRight: '24px' }} onClick={onTodayHandle}>
        今天
      </Button>
    </div>
  );
};

export default TimeList;

import { Tree, Typography } from 'antd';
import { useCallback, useContext, useMemo, useRef } from 'react';
import { ColumnsState, MyContext } from './BitzQueryFilterContext';
import { forEach } from 'lodash-es';

const useRefFunction = <T extends (...args: any) => any>(reFunction: T) => {
  const ref = useRef<any>(null);
  ref.current = reFunction;
  return useCallback((...rest: Parameters<T>): ReturnType<T> => {
    return ref.current?.(...(rest as any));
  }, []);
};

const GroupCheckboxList = () => {
  const { columnsMap, setColumnsMap, ...counter } = useContext(MyContext);

  const listHeight = 300;
  const checkable = true;
  // checkedKeys
  /** 选中反选功能 */
  const onCheckTree = useRefFunction((e) => {
    const newColumnMap = { ...columnsMap };

    const loopSetShow = (key: string | number) => {
      const newSetting = { ...newColumnMap[key] };
      newSetting.show = e.checked;
      newColumnMap[key] = newSetting;
    };
    loopSetShow(e.node.key);
    setColumnsMap({ ...newColumnMap });
  });

  const treeDataConfig = useMemo(() => {
    const checkedKeys: string[] = [];

    const loopData = (data: any, parentConfig?: ColumnsState): any[] => {
      const list: any[] = [];
      forEach(data, (value, key) => {
        const config = columnsMap[key || 'null'] || { show: true };

        if (config?.show !== false) {
          checkedKeys.push(key);
        }

        if (value.label) {
          list.push({
            title: value?.label,
            key,
            selectable: false,
            disabled: value?.disable === true
          });
        }
      });

      return list;
    };

    // @ts-ignore
    return { list: loopData(columnsMap), keys: checkedKeys };
  }, [columnsMap]);

  return (
    <Tree
      // draggable
      defaultExpandAll
      itemHeight={24}
      checkable={checkable}
      blockNode
      checkedKeys={treeDataConfig.keys}
      onCheck={(_, e) => onCheckTree(e)}
      selectable={false}
      showLine={false}
      showIcon={false}
      // height={listHeight}
      style={{ maxHeight: listHeight, overflowY: 'auto' }}
      treeData={treeDataConfig.list}
      titleRender={(nodeData) => {
        return (
          <Typography.Text style={{ width: 80 }} ellipsis={{ tooltip: nodeData.title }}>
            {nodeData.title}
          </Typography.Text>
        );
      }}
    />
  );
};

export default GroupCheckboxList;

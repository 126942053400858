/*
 * # Title: BitzSwitch Component
 * - Description:
 * - Date: 2024-03-27
 * - LastEditors: liujin liujin@elinklaw.com
 * - LastEditTime: 2024-04-03 09:30:30
 */

import { css, cx } from '@emotion/css'
import { Space, Switch, SwitchProps } from 'antd'
import { useEffect, useId, useState } from 'react'
interface BitzSwitch extends SwitchProps {
  textPositon?: 'right' | 'left'
}

const BitzSwitch = ({ onChange, checkedChildren = '已启用', unCheckedChildren = '已禁用', textPositon = 'right', ...props }: BitzSwitch) => {
  const id = useId()
  const [state, setState] = useState(props.value)
  useEffect(() => {
    if (props.value !== undefined) {
      setState(props.value)
    }
    if (props.defaultValue !== undefined) {
      setState(props.defaultValue)
    }
  }, [props.defaultValue, props.value])
  return (
    <Space
      className={cx(
        'bn-split-word-switch',
        css`
          flex-direction: ${textPositon === 'right' ? 'row' : 'row-reverse'};
          font-size: 12px;
          user-select: none;
        `,
      )}
    >
      <Switch
        id={id}
        {...props}
        onChange={(value: boolean, e) => {
          setState(value)
          onChange?.(value, e)
        }}
      />
      <label htmlFor={id}>{state ? checkedChildren : unCheckedChildren}</label>
    </Space>
  )
}

export default BitzSwitch

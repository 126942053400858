/*
 * # Title: BnConditionTip Component
 * - Description: 检索条件提示
 * - Date: 2024-05-31
 * - LastEditors: liujin liujin0505@icloud.com
 * - LastEditTime: 2024-06-01 18:01:48
 */
import { PropsWithChildren } from 'react'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { Flex, Space, Tooltip } from 'antd'

const BnConditionTip = ({ children }: PropsWithChildren) => {
  return (
    <Space>
      {children}
      <Tooltip
        placement="right"
        title={
          <Flex vertical gap={10}>
            <div>使用法律意义上的利冲检索方式</div>
            <div>客户方关键词检索：检索作为客户方时是否存在直接或间接的利益对立，即数据筛选出对方和第三方</div>
            <div>对方关键词检索：检索作为对方时是否存在直接或间接的利益对立，即数据筛选出客户方和第三方</div>
            <div>第三方关键词检索：检索作为第三方时是否存在直接或间接的利益对立，即数据筛选出客户方和对方</div>
          </Flex>
        }
        destroyTooltipOnHide
      >
        <QuestionCircleOutlined />
      </Tooltip>
    </Space>
  )
}

export default BnConditionTip

import { RecoilValueReadOnly } from 'recoil';
import { useUnwrapRecoilValueLoadable } from './useUnwrapRecoilValueLoadable';

/**
 * 与 jotai 的 unwrap 功能类似
 * 直接返回最终值，没有中间状态
 * 用于无刷新无loading得场景
 *
 * unwrap 工具将把一个异步原子转换为一个同步原子，就像 loadable 一样。
 * 与 loadable 不同，可以配置回退值。 与 loadable 不同，错误不会被处理，而是直接抛出
 */
export function useUnwrapRecoilValue<T>(recoilValue: RecoilValueReadOnly<T>, defaultValue?: T) {
  const { contents } = useUnwrapRecoilValueLoadable<T>(recoilValue);
  return contents ?? defaultValue;
}

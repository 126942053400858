/**
 * 路由守卫页面
 * 获取当前页面的路由配置
 * 判断是否拥有该路由页面访问的权限
 * 若无则显示无权限页面，3s到自动跳转到首页
 */

import { useCanPermsision } from '@/v2/components';
import { isFalsy } from '@/v2/utils';
import { NoPermission } from './NoPermission';
import { permissionDB } from '@/v2/store/store';
import { useUnwrapRecoilValueLoadable } from '@/v2/hooks';
/**
 * RouteGuard v1 先通过传递获取当前路由的配置信息
 * @param props
 * @returns
 */
interface RouteGuardProps extends PropsWithChildren {
  route: Row;
}
export const RouteGuard: FC<RouteGuardProps> = (props) => {
  const { route } = props;
  const { loading } = useUnwrapRecoilValueLoadable(permissionDB);
  const canPermisision = useCanPermsision();
  if (loading)
    return (
      <Flex justify="center" align="center" style={{ height: '100vh', width: '100vw' }}>
        <Spin />
      </Flex>
    );
  if (isFalsy(route)) return props.children;
  return canPermisision(route.name) || ['admin'].includes(route.name) ? props.children : <NoPermission />;
};

import { DictDB } from '@/hooks/useDict';
import apiCommon from '@/services/GeneralPublicInterface';
import apiWeb from '@/services/Web';
import { mapping, run } from '@/utils';
import { useAsyncEffect } from 'ahooks';
import { camelCase, isEmpty, isNil, uniqBy, uniqueId } from 'lodash-es';
import { atomFamily, isRecoilValue, RecoilValue, RecoilValueReadOnly, selector, selectorFamily, useRecoilCallback } from 'recoil';
import { isPowerFalsy } from '../utils';

/**
 * 空选择器用于程序判断
 */
export const noopDB = selector({ key: 'base/noopDB', get: async () => void 0 });
export const noopFamilyDB = selectorFamily({ key: 'base/noopFamilyDB', get: () => async () => void 0 });

/**
 * 获取 Rows 的 unqiue key
 * 判断rows中是否存在 id 或 key 的字段，若没有重新写入key值作为React渲染的时候的唯一键
 */
export const uniqKeyStore = selectorFamily({
  key: 'uniqKeyStore',
  get: (data: Rows) => () => {
    if (isEmpty(data)) return data;
    if (!Array.isArray(data)) return data;

    // 将key键值作为唯一键
    // 为使键值稳定，将value值集合作为唯一键值
    const data$unqi = mapping(data, {
      key: (record) => {
        const value =
          record?.key ??
          record?.id ??
          Object.values(record)
            .flat()
            .filter((item) => ['string', 'number'].includes(typeof item))
            .join('');
        const unqiKey = value || uniqueId('row_');
        return unqiKey;
      }
    });

    return data$unqi;
  }
});

/**
 * 存储最后一次更新的idKeys,
 * 用于做动画效果
 */
type UpdateKeysParams = { module: string; sub?: string };
export const updateKeysStore = atomFamily<string[], UpdateKeysParams>({
  key: 'updateKeysStore',
  default: () => []
});

/**
 * 数据预加载
 */
export const usePrefetchApi = (...args) => {
  const prefetch = useRecoilCallback(({ snapshot }) => async (args: RecoilValue<any>[]) => {
    for await (const arg of args) {
      if (!isRecoilValue(arg)) continue;
      await snapshot.getPromise(arg);
    }
  });
  useAsyncEffect(async () => {
    setTimeout(async () => {
      await prefetch(args);
    }, 1000);
  }, []);
};

/**
 * 全局税率
 */
export const taxRatesDB = selector({
  key: 'taxRatesDB',
  get: async ({ get }) => {
    const { response } = await apiWeb.webFinancialBaseFinancialtaxrateListCreate({ page: 1, size: 1000 });
    return mapping(response!, { value: 'taxRate', label: ({ taxRate }) => `${taxRate}%` });
  }
});

/**
 * 获取办公室的税率列表
 * 先匹配officeId, 若office的税率不存在，则匹配全部
 * isBilling, isEnabled
 * settingLevel: 'Office' | 'Tenant'
 **/
export const taxRatesByOfficeIdStore = selectorFamily({
  key: 'defaultOfficeTaxRateStore',
  get:
    (officeId: string) =>
    async ({ get }) => {
      const data = get(taxRatesDB);

      // todo 过滤货币

      const rates$office = run(officeId, () => {
        return data?.filter((item: Row) => {
          const { settingLevel, isBilling, isEnabled, officeId: _officeId } = item;
          return settingLevel === 'Office' && isBilling && isEnabled && _officeId === officeId;
        });
      }, []);

      const rates$tenant = run(
        !rates$office?.length,
        () => {
          return data?.filter((item: Row) => {
            const { settingLevel, isBilling, isEnabled } = item;
            return settingLevel === 'Tenant' && isBilling && isEnabled;
          });
        },
        []
      );

      // 去重
      const options = uniqBy([...rates$office, ...rates$tenant], 'value');

      return options;
    }
});

/**
 * 获取办公室的默认税率
 */
export const useGetTaxRateByOfficeId = () => {
  return useRecoilCallback(({ snapshot }) => async (officeId: string) => {
    const taxRates = await snapshot.getPromise(taxRatesByOfficeIdStore(officeId));
    const taxRate = taxRates?.[0] ?? { value: 0 };
    const offices = (await DictDB.getItem('SysOffice')) as Rows;
    const office = offices?.find((item) => item.value === officeId);
    return { office, taxRate };
  });
};

export const useGetSelector = (selector1?: RecoilValueReadOnly<any>) => {
  return useRecoilCallback(({ snapshot, refresh }) => async (selector2?: RecoilValueReadOnly<any>) => {
    const selector = selector2 ?? selector1;
    if (isNil(selector)) return;
    if (!isRecoilValue(selector)) return;
    return (await snapshot.getPromise(selector!)) as any;
  });
};

export const useRefreshSelector = (...selector1: RecoilValueReadOnly<any>[]) => {
  return useRecoilCallback(({ refresh }) => (...selector2: RecoilValueReadOnly<any>[]) => {
    const selectors = isPowerFalsy(selector2) ? selector1 : selector2;
    for (let selector of selectors) {
      if (!isRecoilValue(selector)) continue;
      refresh(selector!);
    }
  });
};

/**
 * Refresh RecoilValue
 * @params sep: 分段匹配, 以 '::' 和 '/'作为分段标识 | default
 * @params like: 模糊匹配
 * @params value: 值匹配
 * @params regex: 正则
 */
export const useRefreshRecoil = (type: 'sep' | 'like' | 'value' | 'regex' = 'sep') => {
  const mapfunc = {
    sep: (atomKey: string, condition: string) => {
      const seps = atomKey.split(/:{2,}|_{2,}|\/+/g);
      return seps.includes(condition);
    },
    like: (atomKey: string, condition: string) => {
      return atomKey.includes(condition);
    },
    value: (atomKey: string, condition: string) => {
      const values = (atomKey.match(/"([^"]*)"/g) || []).map((match) => match.replace(/"/g, ''));
      return values.includes(condition);
    },
    regex: (atomKey: string, reg: string) => {
      return new RegExp(reg).test(atomKey);
    }
  };

  return useRecoilCallback(({ snapshot, refresh }) => (condition: string) => {
    const atoms = snapshot.getNodes_UNSTABLE();
    for (const atom of atoms) {
      const atomKey = atom.key;
      const can = mapfunc[type](atomKey, condition);
      can && refresh(atom);
      can && console.log(atom.key);
    }
  });
};

export const orderByDB = selectorFamily({
  key: 'base/orderByDB',
  get: (api: string) => async () => {
    const requestUrl = api.replace(/^\//, '');
    const { response = [] } = await apiCommon.commonOrderByList({ requestUrl });
    // @ts-ignore
    const keys = response?.fieldList?.map?.(({ fieldName }) => camelCase(fieldName));
    return new Set(keys);
  }
});

/**
 * 获取当前用户
 */

/**
 * 获取当前用户权限
 */
export const permissionDB = selector({
  key: 'permessionDB/current',
  get: async () => {
    const { response } = await apiWeb.webSysUserinfoPermissionList();
    return new Set(response);
  }
});

/**
 * 用于细粒度更新
 */
export const shallowRefreshAtom = atomFamily({
  key: 'updateAtom',
  default: (key: string) => key
});

/**
 * 设置细粒度更新唯一值
 */
export const useRecoilShallowRefresh = () => {
  return useRecoilCallback(({ set }) => (db: RecoilValueReadOnly<any>) => {
    const key = db.key.replace(/(.*?)\/.*$/i, '$1') + '/refresh';
    const value = uniqueId(db.key);
    set(shallowRefreshAtom(key), value);
  });
};

import { run } from '@/utils';
import { Loguot } from '@/utils/loguot';
import { useUnwrapRecoilValue } from '@/v2/hooks';
import { noopDB, permissionDB } from '@/v2/store/store';
import { isFalsy } from '@/v2/utils';
import { useInterval } from 'ahooks';

export const NoPermission = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [time, setTime] = useState(5);
  const permission = useUnwrapRecoilValue(pathname === '/permission' ? noopDB : permissionDB);
  const clear = useInterval(() => {
    if (time) setTime((time) => time - 1);
    if (!time) navigate('/');
  }, 1000);

  const subTitle = run(permission, 'Sorry, 没有权限访问此页面.', 'Sorry, 当前账号没有任何权限访问此系统，请确认并联系管理员; 或切换账号后重试.');

  useEffect(() => {
    isFalsy(permission) && clear();
  }, [permission]);

  return (
    <Result
      status="403"
      title="403"
      subTitle={subTitle}
      extra={run(
        permission,
        <Button
          type="primary"
          onClick={() => {
            clear();
            navigate('/');
          }}
        >
          返回首页 ({time})
        </Button>,
        <Space>
          <Button type="primary" onClick={Loguot}>
            切换账号
          </Button>
        </Space>
      )}
    />
  );
};

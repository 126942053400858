import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useControllableValue, useDebounceFn, useUpdateEffect } from 'ahooks';
import { SelectProps, Space, Select, Button, Spin, Typography, Flex } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useMutation } from '@tanstack/react-query';
import { show } from '@ebay/nice-modal-react';
import { isArray, split, uniqWith } from 'lodash-es';
import apiWeb from '@/services/Web';
import { CaseCheckOutput, GetCaseCheckInput } from '@/services/WebApi';
import { MyContext } from '@/components/BitzQueryFilter2/BitzQueryFilterContext';
import BitzQueryFilter2 from '@/components/BitzQueryFilter2';
import curStyle from './index.module.less';
import CaseModal from './CaseModal';
import { ParagraphProps } from 'antd/es/typography/Paragraph';
import { css } from '@emotion/css';
import { useImmer } from 'use-immer';

type TagRender = SelectProps['tagRender'];
const { Text } = Typography;

interface defaultOptionsItem {
  name?: string;
  id: string;
}

interface BnCaseSelectPropsProps extends Omit<SelectProps, 'options'> {
  defaultOptions?: defaultOptionsItem[];
  /**
   * 是否启用Fluent组件模式
   */
  fluent?: boolean;
  /**
   * 是否是我的案件
   */
  isMy?: boolean;

  /**
   * 查询参数
   */
  reqParams?: GetCaseCheckInput;

  clientId?: string | string[];
  /**
   * 检索图标是否展示
   */
  searchIcon?: boolean;
  /**
   * 禁止选择的案件类型
   */
  disabledCategory?: string[]
}

const BnCaseSelect: React.FC<BnCaseSelectPropsProps> = (props) => {
  const selectRef = useRef(null);
  const { dispatch, advanced = false } = useContext(MyContext);
  const {
    value,
    onChange,
    onSelect,
    searchIcon = true,
    // reqParams,
    ...rest
  } = props;
  const [rows, setRows] = useState([]);
  const [state, setState] = useControllableValue<SelectProps['value']>(props);
  const [optArr, setOptArr] = useControllableValue<CaseCheckOutput[]>(props, {
    defaultValue: [],
    defaultValuePropName: 'defaultOptions',
    valuePropName: 'options',
    trigger: 'onChangeOptions'
  });

  const [params, setParams] = useImmer<GetCaseCheckInput>({});
  const [pagination, setPagination] = useImmer({
    page: 1,
    size: 10
  });

  const [clientId, setClient] = useState<string[]>();

  useEffect(() => {
    if (props?.clientId) {
      if (isArray(props.clientId)) {
        setClient(props.clientId);
      } else {
        setClient([props.clientId]);
      }
    }
  }, [props?.clientId]);

  useEffect(() => {
    if (props?.defaultOptions?.length) {
      setOptArr(props?.defaultOptions);
    }
  }, [props?.defaultOptions]);

  useUpdateEffect(() => {
    setPagination((draft) => ({
      ...draft,
      page: 1
    }));
    setOptArr([]);
  }, [clientId]);

  /**
   * 初始化的时候如果有 defaultOptions
   * 需要进行数据组装 赋值给rows, 因为弹窗需要显示已选中项
   */
  useEffect(() => {
    if (optArr?.length) {
      const optList = optArr?.map((v) => ({
        name: v.name
      }));
      if (props?.mode === 'multiple') {
        const list = optArr?.filter((item) => {
          return value?.includes(item.id);
        });
        // @ts-ignore
        setRows(list);
      } else {
        const optList = optArr.find((v) => v?.id === value);
        // @ts-ignore
        optList && setRows([optList]);
      }
    }
  }, []);

  const id = useMemo(() => {
    if (props.id) {
      if (props.id?.indexOf('advanced') !== -1) {
        const list = split(props.id, '_');
        return list[list.length - 1];
      }
      return props.id;
    }
    return '';
  }, [props.id]);

  useEffect(() => {
    if (id) {
      dispatch?.({
        type: 'update',
        name: id,
        value: props?.value
      });
    }
  }, [props?.value, id]);

  const handleSelect = (value, option) => {
    onSelect?.(value, option);
  };

  const mutation = useMutation({
    mutationFn: () => {
      return apiWeb.webBusinessCaseCasematterGetcasecheckpageCreate({
        clientId,
        ...params,
        // filter: newTodo,
        ...pagination,
        isClient: true,
        toOrderBy: 'createTime desc'
      });
      // }
    },
    onSuccess: (data) => {
      if (data?.success) {
        const list = data?.response?.data ?? [];
        const list2 = optArr?.concat(list);
        const newList = uniqWith(list2, (item1, item2) => item1?.id === item2?.id);
        setOptArr(newList);

        if (pagination.page === 1 && clientId?.length) {
          const item = list?.[0];
          if (item) {
            setState(item.id);
            handleSelect(item.id, item)
          } else {
            setState(undefined);
            handleSelect(undefined, {})
          }
        }
      }
    }
  });

  const { run, cancel } = useDebounceFn((value) => {
    setParams((draft) => {
      draft.filter = value;
    });
    setPagination((draft) => {
      draft.page = 1;
    });
    setClient(undefined);
    setOptArr([]);
  });

  const dropdownVisibleChange = (open: boolean) => {
    if (open && !optArr?.length) {
      mutation.mutate();
    }
  };

  const handleChange: SelectProps['onChange'] = (value, option) => {
    setState(value, option);

    if (isArray(option)) {
      // @ts-ignore
      setRows(option);
    } else {
      // @ts-ignore
      option && setRows([option]);
    }

    if (id && dispatch) {
      let valueName;
      // let valueName = isArray(option) ? option?.map((v) => v[fieldNames?.label ?? 'label']).join(',') : option[fieldNames?.label ?? 'label']
      if (isArray(option)) {
        valueName = option?.map((v) => v?.name).join(',');
      } else {
        valueName = option?.name;
      }
      dispatch?.({
        type: 'update',
        name: id,
        valueName
      });
    }
  };

  const openSearchModal = () => {
    show(CaseModal, {
      rows: rows,
      mode: props?.mode,
      reqParams: {
        clientId
      },
      disabledCategory: props?.disabledCategory
    }).then((state) => {
      // @ts-ignore
      setOptArr(state);
      if (props?.mode === 'multiple') {
        const items = (state as CaseCheckOutput[]).map((item) => item.id);
        // setState(values)
        // @ts-ignore
        handleChange(items, state);
        handleSelect(items, state);
      } else {
        const item = (state as CaseCheckOutput[])?.[0];
        // value && setState(value)
        handleChange(item?.id, item);
        handleSelect(item?.id, item);
      }
    });
  };

  const dropdownRender = useCallback(
    (originNode) => {
      return (
        <Spin size="small" spinning={mutation.isPending}>
          {originNode}
        </Spin>
      );
    },
    [mutation.isPending]
  );

  const Component = useMemo(() => (props?.fluent ? BitzQueryFilter2.Select : Select), [props]);

  const ellipsis: ParagraphProps['ellipsis'] = {
    rows: 1
  };

  const optionRender = useCallback((option, info) => {
    console.log('option', option, info)
    const data = option?.data;
    return (
      <Flex key={option.key} vertical>
        {/*{data?.serialId && <Text ellipsis={ellipsis}>{data?.serialId}</Text>}*/}
        {option?.label && <Text ellipsis={ellipsis}>{option?.label}</Text>}
        {data?.clientName && (
          <Text ellipsis={ellipsis} type="secondary">
            {data?.clientName}
          </Text>
        )}
      </Flex>
    );
  }, []);

  const style = useMemo<React.CSSProperties | undefined>(() => {
    return props?.fluent ? undefined : { width: 'calc(100% - 31px)' };
  }, [props?.fluent]);

  const className = useMemo(() => {
    return props?.fluent
      ? css`
          .ant-select-selector {
            border-right-width: 0 !important;
          }
        `
      : undefined;
  }, [props?.fluent]);

  useUpdateEffect(() => {
    mutation.mutate();
  }, [pagination, params]);

  const onPopupScroll = (event) => {
    event.persist();
    const data = mutation.data?.response;
    // @ts-ignore
    if (data?.page >= data?.pageCount) {
      return;
    }
    const { scrollTop, offsetHeight, scrollHeight } = event.target;
    if (scrollTop + offsetHeight === scrollHeight && !mutation.isPending) {
      setPagination((draft) => {
        draft.page = draft.page + 1;
      });
    }
  };

  const options = optArr?.map((item) => {
    const serialId = item?.serialId ? `${item?.serialId}:` : '';
    return {
      ...item,
      name: `${serialId}${item?.name}`,
      disabled: props?.disabledCategory?.includes(item?.category ?? '')
    };
  });

  const handleClear = () => {
    run(undefined);
  };

  const tagRender: TagRender = useCallback((props) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        // color={value}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginInlineEnd: 4 }}
      >
        <Text style={{ maxWidth: 100 }} ellipsis={{ tooltip: label }}>{label}</Text>
      </Tag>
    );
  }, [])

  const popupClassName = css`
    .ant-select-item-option-disabled {
      .ant-typography {
        color: var(--ant-color-text-disabled);
      }
    }
  `
  const maxTagPlaceholder = useCallback((omittedValues) => {
    const title = omittedValues.map(({ label }, index) => <p>{index + 1}. {label}</p>)
    return (
      <Tooltip
        overlayStyle={{ pointerEvents: 'none' }}
        title={title}
      >
        <span>More</span>
      </Tooltip>
    )
  }, [])

  const content = (
    <Component
      maxTagPlaceholder={maxTagPlaceholder}
      popupClassName={popupClassName}
      tagRender={tagRender}
      onClear={handleClear}
      ref={selectRef}
      onPopupScroll={onPopupScroll}
      // labelRender={labelRender}
      onSelect={handleSelect}
      className={searchIcon ? className : ''}
      optionRender={optionRender}
      loading={mutation.isPending}
      fieldNames={{
        label: 'name',
        value: 'id'
      }}
      style={searchIcon ? style : { width: '100%' }}
      showSearch
      onDropdownVisibleChange={dropdownVisibleChange}
      onSearch={run}
      dropdownRender={dropdownRender}
      // notFoundContent={mutation.isPending ? <Spin size="small" /> : null}
      suffixIcon={null}
      filterOption={false}
      value={state}
      onChange={handleChange}
      options={options}
      {...rest}
    />
  );

  return searchIcon ? (
    <Space.Compact className={curStyle.wapper} block>
      {content}
      <Button disabled={props?.disabled ?? false} style={{ height: 'auto' }} onClick={openSearchModal} icon={<SearchOutlined />} />
    </Space.Compact>
  ) : (
    content
  );
};

export default BnCaseSelect;

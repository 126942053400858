import {  create, useModal } from '@ebay/nice-modal-react'
import {Button, Col, Flex, Form, Row, Space, Spin, TimePicker} from 'antd'
import { CancelButton, SbumitButton } from '@/businessComponents/BnButton'
import BitzFormModal from '@/components/BitzFormModal'
import React, { useEffect, useMemo, useState } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { message } from '@/components/BitzAntApp/Static'
import BzUserSelect from '@/businessComponents/BzUserSelect'
import BnCaseSelect from '@/businessComponents/caseType/BnCaseSelect'
import BnClientSelect from '@/pages/Works/Components/BnSelectClient'
import BnWorksSelectLanguage from '@/pages/Works/Components/BnWorksSelectLanguage'
import BitzTranslateTextarea from '@/components/BitzTranslateTextarea'
import { SaveOutlined } from '@ant-design/icons'
import dayjs from "@/utils/dayjs-facade"
import useUserStore from '@/store/userStore'
import { useShallow } from 'zustand/react/shallow'
import ApiWeb from '@/services/Web'
import Timer from '@/businessComponents/BnTimer'
import WorksDatePicker from '@/pages/Works/Components/WorksDatePicker'
import WorkInoutNumber from '@/pages/Works/MyWorkingHours/components/WorkInoutNumber'
import {endTimeRules, timeDependentCalcs, timeRelationHandling} from '@/pages/Works/WorkHourList/const'
import AliasTempSelect from '@/pages/Works/WorkHourList/components/AliasTempSelect'
import { GetTimesheetForEditOutput } from '@/services/WebApi'
import ConfirmFooter, { useSetTipDate } from "../ConfirmFooter";

interface WorkListCreateOrEditModalProps {
  data?: GetTimesheetForEditOutput

  data2?: GetTimesheetForEditOutput
}

const formatText = 'YYYY-MM-DD HH:mm:ss'

const WorkListCreateOrEditModal: React.FC<WorkListCreateOrEditModalProps> = (props) => {
  const [form] = Form.useForm()
  const [antdModal, contextHolder] = Modal.useModal();
  const nickName = Form.useWatch('nickName', form)
  const [data, setData] = useState<GetTimesheetForEditOutput>()
  const modal = useModal()
  const queryClient = useQueryClient()
  const userInfo = useUserStore(
    useShallow((state) => ({
      id: state.id,
      displayName: state.displayName,
    })),
  )
  // const minTime = workDateStore(useShallow((state) => state.minTime))
  const [caseOptions, setCaseOptions] = useState<any[]>([])
  const [clientOptions, setClientOptions] = useState<any[]>([])
  const [userOptions, setUserOptions] = useState<any[]>([])
  // 是否禁用提交按钮
  const [isSubmit, setIsSubmit] = useState(false)
  // 初始化时是否特殊工时
  const [isSpecial, setIsSpecial] = useState(false)
  //isToDaySubmit:  今天是否已经提示过Late Entry
  const [isToDaySubmit, handleSetTipDate] = useSetTipDate()

  const handleSuccess = (res) => {
    if (res?.success) {
      message.success('操作成功')
      queryClient.refetchQueries({
        queryKey: ['webTimesheetsTimesheetTimesheetGetpageCreate'],
        type: 'active',
      })
      queryClient.refetchQueries({
        queryKey: ['webTimesheetsTimesheetTimesheetGetspecialpageCreate', 'list'],
        type: 'active',
      })
      modal.resolve(true)
      modal.hide()
    }
  }

  useEffect(() => {
    if (props?.data2) {
      const data = props?.data2
      const newData = {
        ...data,
        // 修改字段
      }

      setData({
        ...newData,
      })
    }
  }, [props?.data2])

  // 提交
  const mutation = useMutation({
    mutationFn: (values: any) => {
      if (values?.id) {
        return ApiWeb.webTimesheetsTimesheetTimesheetPutUpdate(values?.id, {
          ...data,
          ...values,
        })
      }
      return ApiWeb.webTimesheetsTimesheetTimesheetPostCreate(values)
    },
    onSuccess: handleSuccess,
  })

  // 暂存
  const draftMutation = useMutation({
    mutationFn: (values: any) => {
      if (values?.id) {
        return ApiWeb.webTimesheetsTimesheetTimesheetPutdraftUpdate(values?.id, {
          ...data,
          ...values,
        })
      }
      return ApiWeb.webTimesheetsTimesheetTimesheetPostdraftCreate(values)
    },
    onSuccess: handleSuccess,
  })

  const getInitData = useMutation({
    mutationFn: (id: string) => {
      return ApiWeb.webTimesheetsTimesheetTimesheetGetforeditCreate(id)
    },
    onSuccess: (data) => {
      if (data?.success) {
        setData(data?.response)
        if (['TBD', 'NCM'].includes(data?.response?.caseCategory ?? '')) {
          setIsSubmit(true)
          setIsSpecial(true)
        }
      }
    },
  })

  useEffect(() => {
    if (props?.data?.id) {
      getInitData.mutate(props?.data?.id)
    }
  }, [props?.data])

  const loading = useMemo(() => {
    return draftMutation.isPending || mutation.isPending
  }, [draftMutation.isPending, mutation.isPending])

  useEffect(() => {
    if (data) {
      // 客户回填
      data?.clientId &&
        setClientOptions([
          {
            id: data?.clientId,
            name: data?.clientName,
            serialId: data?.clientSerialId,
          },
        ])
      // 案件回填
      data?.caseId &&
        setCaseOptions([
          {
            id: data?.caseId,
            name: data?.caseName ?? '',
            serialId: data?.caseSerialId,
            category: data?.caseCategory,
          },
        ])
    }
  }, [data])

  useEffect(() => {
    if (data?.employeeId) {
      setUserOptions([{ displayName: data?.employeeName, id: data?.employeeId }])
    } else {
      setUserOptions([{ displayName: userInfo?.displayName, id: userInfo?.id }])
      form.setFieldValue('employeeId', userInfo?.id)
    }
  }, [data, userInfo])

  useEffect(() => {
    if (nickName) {
      form.validateFields(['caseId', 'clientId'])
    }
  }, [!!nickName])

  const handleFinish = (values) => {
    values.workDate =  values.workDate ? dayjs(values.workDate).startOf('day').format(formatText) : null
    // values.startTime = values.startTime ? dayjs(values.startTime, 'HH:mm:ss').format('HH:mm:ss') : null
    // values.endTime = values.endTime ? dayjs(values.endTime, 'HH:mm:ss').format('HH:mm:ss') : null
    mutation.mutate(values)
  }

  const handleSave = () => {
    form.validateFields(['employeeId', 'endTime']).then((v) => {
      const values = form.getFieldsValue()
      values.workDate =  values.workDate ? dayjs(values.workDate).startOf('day').format(formatText) : null
      // values.startTime = values.startTime ? dayjs(values.startTime, 'HH:mm:ss').format('HH:mm:ss') : null
      // values.endTime = values.endTime ? dayjs(values.endTime, 'HH:mm:ss').format('HH:mm:ss') : null

      draftMutation.mutate(values)
    })
  }

  function formatTime(timeString) {
    let parsedTime
    const fractionalSecondPattern = /\.\d+$/
    if (fractionalSecondPattern.test(timeString)) {
      parsedTime = dayjs(timeString, 'HH:mm:ss.SSSSSSS')
    } else {
      parsedTime = dayjs(timeString, 'HH:mm:ss')
    }
    if (!parsedTime.isValid()) {
      throw new Error('Invalid Date')
    }
    // const formattedTime = parsedTime.format("HH:mm:ss");
    const formattedTime = parsedTime
    return formattedTime
  }

  useEffect(() => {
    if (data) {
      //const startTime = data?.startTime ? dayjs(data?.startTime, 'HH:mm:ss') : undefined
      // const endTime = data?.endTime ? dayjs(data?.endTime, 'HH:mm:ss') : undefined

      const initData = {
        ...data,
        // startTime,
        // endTime,
      }
      form.setFieldsValue(initData)
    }
  }, [data])

  // const clientId = Form.useWatch('clientId', form)
  // const caseId = Form.useWatch('caseId', form)

  /**
   * 选择案件后回调
   */
  const handleCaseSelect = (value, option) => {
    // form.resetFields(['clientId'])
    setIsSubmit(['TBD', 'NCM'].includes(option?.category ?? ''))
    if (value) {
      ApiWeb.webCustomerClientGetclientpageCreate({
        caseId: [value],
      }).then((res) => {
        if (res.success) {
          const item = res.response?.data?.[0]
          if (item?.id) {
            setClientOptions(res.response?.data ?? [])
            form.setFieldValue('clientId', item.id)
            form.validateFields(['clientId'])
          } else {
            form.resetFields(['clientId'])
          }
        }
      })
    }
  }

  const [clientId, setClientId] = useState('')

  /**
   * 选择客户回填案件
   */
  const handleClientSelect = (value, option) => {
    setClientId(value || undefined)
    form.validateFields(['caseId'])
  }

  const handleonChange = (value, name: string) => {
    if (!value) {
      return
    }
    const { startTime, endTime } = form.getFieldsValue(['startTime', 'endTime'])
    const hoursWorked = form.getFieldValue('hoursWorked')

    const {
      start,
      end,
      hour
    } = timeRelationHandling(
      hoursWorked,
      name,
      dayjs(startTime, 'HH:mm:ss'),
      dayjs(endTime, 'HH:mm:ss')
    )

    form.setFieldValue('startTime', start?.format('HH:mm:ss'))
    form.setFieldValue('endTime', end?.format('HH:mm:ss'))
    form.setFieldValue('hoursWorked', hour)
    form.validateFields(['endTime', 'hoursWorked'])
  }

  const title = useMemo(() => (props?.data?.id ? '编辑工时' : '添加工时'), [props?.data])

  const handleHoursWorked = (value) => {
    const { startTime, endTime } = form.getFieldsValue(['startTime', 'endTime'])
    const { start, end } = timeDependentCalcs(
      value,
      dayjs(startTime, 'HH:mm:ss'),
      dayjs(endTime, 'HH:mm:ss')
    )
    form.setFieldValue("startTime", start?.format('HH:mm:ss'))
    form.setFieldValue("endTime", end?.format('HH:mm:ss'))
    form.validateFields(['endTime'])
  }

  const modalProps = {
    onCancel:() => {
      modal.resolve(false)
      modal.hide()
    },
    onClose: () => {
      modal.resolve(false)
      modal.hide()
    },
    afterOpenChange: (v: boolean) => {
      if (!v) {
        modal.resolveHide();
      }
      !v && !modal.keepMounted && modal.remove();
    },
  }

  const formSubmit = async () => {
    // onFinisha
    await form.validateFields()
    const values = form.getFieldsValue(true)
    // 获取当前月份的第一天
    const startOfCurrentMonth = dayjs().startOf('month');
    const isDate = values?.workDate ? dayjs(values?.workDate).isBefore(startOfCurrentMonth) : false

    /**
     * 工时日期早于当前月的TBD案件工时转为正常案件后
     * 律师提交HKM以外的案件工时，给出弹窗提示
     * 点击确认，当前工时即标记为Late Entry，状态为Submitted，工时隶属案件类型为正常案件类型
     * 点击取消，当前工时状态为Ready，工时隶属案件类型保留TBD，待财务老师提交后转为正常案件类型
     */
    // isToDaySubmit: 今天是否已经提过了，提示过并提交了就不会提示了
    if (isDate && !isToDaySubmit) {
      const confirmed = await antdModal.confirm({
        title: '提示',
        content: '当前工时若提交，涉及Late Entry，是否确认提交？',
        okText: '确认',
        cancelText: '取消',
        footer: (params) => <ConfirmFooter params={params} />
      })
      if (confirmed) {
        handleFinish(values)
        handleSetTipDate()
      } else {
        // 特殊案件类型的取消调用一下暂存
        isSpecial && handleSave()
      }
      return
    } else {
      handleFinish(values)
    }
  }

   return (
     // @ts-ignore
    <BitzFormModal
      title={title}
      // width={500}
      footer={
        <Space size="middle">
          <SbumitButton disabled={isSubmit || getInitData?.isPending} loading={loading} onClick={formSubmit}>
            提交
          </SbumitButton>
          <Button disabled={getInitData?.isPending} loading={loading} onClick={handleSave} icon={<SaveOutlined />}>
            保存
          </Button>
          <CancelButton disabled={loading} onClick={modalProps.onClose} />
        </Space>
      }
      open={modal.visible}
      {...modalProps}
    >
      {contextHolder}
      <Spin spinning={getInitData.isPending}>
        <Form
          disabled={mutation.isPending}
          form={form}
          autoComplete="off"
          onFinish={handleFinish}
          layout="vertical"
        >
          <Form.Item hidden name="id" initialValue={props?.data?.id} />
          <Row gutter={[16, 8]}>
            <Col span={12}>
              <Form.Item
                name="workDate"
                label="日期"
                initialValue={dayjs().startOf('date')}
                rules={[{ required: true, message: '请选择工作日期' }]}
                getValueProps={(value) => ({
                  value: value && dayjs(value).startOf('day'),
                })}
                normalize={(value) => value && `${dayjs(value).startOf('day').format(formatText)}`}
              >
                <WorksDatePicker format="YYYY-MM-DD" style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            {data?.timerState !== 'Start' && (
              <Col span={12}>
                <Form.Item
                  label="工作时间"
                  // name="workTime"
                >
                  <Flex gap={10} align="center">
                    <Form.Item
                      name="startTime"
                      noStyle
                      initialValue={dayjs().format('HH:mm:ss')}
                      getValueProps={(value) => ({
                        value: value && dayjs(value, 'HH:mm:ss'),
                      })}
                      normalize={(value) => value && `${dayjs(value).format('HH:mm:ss')}`}
                    >
                      <TimePicker
                        onChange={(v) => handleonChange(v , "startTime")}
                        needConfirm={false}
                        placeholder="开始时间"
                        format={'HH:mm'}
                        allowClear
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                    <div>至</div>
                    <Form.Item
                      name="endTime"
                      noStyle
                      dependencies={['startTime']}
                      rules={endTimeRules}
                      initialValue={dayjs().add(0.1, 'hour').format('HH:mm:ss')}
                      getValueProps={(value) => ({
                        value: value && dayjs(value, 'HH:mm:ss'),
                      })}
                      normalize={(value) => value && `${dayjs(value).format('HH:mm:ss')}`}
                    >
                      <TimePicker
                        onChange={(v) => handleonChange(v , "endTime")}
                        needConfirm={false}
                        placeholder="结束时间"
                        format={'HH:mm'}
                        allowClear
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  </Flex>
                </Form.Item>
              </Col>
            )}
            {data?.timerState !== 'Start' ? (
              <Col span={12}>
                <Form.Item name="hoursWorked" label="工作时长" initialValue={0.1} rules={[{ required: true, message: '请填写工作时长' }]}>
                  <WorkInoutNumber onChange={handleHoursWorked} isFilter min={0.1} />
                </Form.Item>
              </Col>
            ) : (
              <Col span={12}>
                <Form.Item label="工作时长">
                  <Timer hideBtn record={data} disabledRun setMinTime={0.1} />
                </Form.Item>
              </Col>
            )}

            <Col span={12}>
              <Form.Item name="employeeId" label="计时人员" rules={[{ required: true, message: '请选择计时人员' }]} initialValue={userInfo?.id}>
                <BzUserSelect defaultOptions={userOptions} allowClear placeholder="请选择计时人员" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="caseId" label="案件" rules={[{ required: !nickName, message: '请选择案件' }]}>
                <BnCaseSelect
                  // reqParams={{ clientId }}
                  clientId={clientId}
                  defaultOptions={caseOptions}
                  fluent={false}
                  allowClear
                  placeholder="请选择案件"
                  onSelect={handleCaseSelect}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="clientId" label="客户" rules={[{ required: !nickName, message: '请选择客户' }]}>
                <BnClientSelect
                  // reqParams={{ caseId: caseId ? [caseId] : undefined }}
                  defaultOptions={clientOptions}
                  fluent={false}
                  allowClear
                  placeholder="请选择客户"
                  onSelect={handleClientSelect}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="nickName" label="暂代昵称">
                {/*<Input placeholder="请输入暂代昵称" />*/}
                <AliasTempSelect allowClear placeholder="请输入暂代昵称" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="writtenLanguage" label="书写语言">
                <BnWorksSelectLanguage placeholder="请选择书写语言" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="narrative" label="工时描述" rules={[{ required: true, message: '请填写工时描述' }, { max: 2000 }]}>
                <BitzTranslateTextarea placeholder="请输入工时描述" maxLength={2000} showCount />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </BitzFormModal>
  )
}

export default create(WorkListCreateOrEditModal)

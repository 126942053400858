import { create } from 'zustand'
import { persist, createJSONStorage, devtools } from 'zustand/middleware'
import api from '@/services/GeneralPublicInterface'
import { CreateUserCustomSettingInput } from '@/services/GeneralPublicInterfaceApi'

type TipsConfigType = Partial<EnumToObj<TipKeys, boolean>>

interface FetchParams {
  settingType: CreateUserCustomSettingInput['settingType']
}

interface UpdateParams {
  settingType: CreateUserCustomSettingInput['settingType']
  setting: TipsConfigType
}

interface TipConfigStoreState {
  tips?: TipsConfigType
  setTips: (params: UpdateParams) => Promise<TipsConfigType>
  getTips: (params: FetchParams) => Promise<TipsConfigType>
}

const fetch = async (params: FetchParams) => {
  const { response } = await api.commonUsercustomsettingInfoList({ module: 'tipsConfig', ...params })
  return response?.setting
}

const update = async (params: UpdateParams) => {
  await api.commonUsercustomsettingCreate({ module: 'tipsConfig', ...params })
  return params.setting
}

/** modal tip 和 alert tip */
const tipConfigStoreStore = create<TipConfigStoreState>()(
  devtools(
    persist(
      (set) => ({
        tips: {},
        getTips: (params) => {
          return fetch(params).then((data) => {
            set({ tips: data })
            return data || {}
          })
        },
        setTips: (params) => {
          return update(params).then((newData) => {
            set({ tips: newData })
            return newData || {}
          })
        },
      }),
      {
        name: 'tipConfigStorage',
        storage: createJSONStorage(() => localStorage),
      },
    ),
  ),
)

export default tipConfigStoreStore

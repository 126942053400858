import React, { useCallback, useEffect, useMemo } from 'react';
import { antdModalV5, create, useModal } from '@ebay/nice-modal-react';
import BitzFormModal from '@/components/BitzFormModal';
import { Col, Input, Row, Space, Popover, Flex, Tag, TableProps, Empty, Button } from 'antd';
import { BitzPagination, BitzTable } from 'bitz-react-admin-ui';
import style from './index.module.less';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import apiWeb from '@/services/Web';
import { useImmer } from 'use-immer';
import { CaseCheckOutput, GetClientInput, GetUserInfoInput } from '@/services/WebApi';
import { useDebounceFn, useSelections } from 'ahooks';
import { findIndex, isArray } from 'lodash-es';

interface ClientModalProps {
  mode?: string;
  rows?: any[];
  values?: string[];
  /**
   * 查询入参
   */
  reqParams?: GetClientInput;
}

const ClientModal: React.FC<ClientModalProps> = (props) => {
  const modal = useModal();
  const [params, setParams] = useImmer<GetUserInfoInput>({});
  const [pagination, setPagination] = useImmer({
    page: 1,
    size: 10
  });

  const handleSearch = (value: string) => {
    setParams((draft) => {
      draft.filter = value;
    });
    setPagination((draft) => {
      draft.page = 1;
    });
  };

  const { run: runDebounce } = useDebounceFn(setParams, {
    wait: 500
  });
  const handleChange = (e) => {
    runDebounce({ filter: e.target.value });
    setPagination((draft) => {
      draft.page = 1;
    });
  };

  const onPageChange = (page, size) => {
    setPagination(() => ({
      page,
      size
    }));
  };

  // const { run: runDebounce } = useDebounceFn(setParams, {
  //   wait: 500,
  // })

  const des = useQuery({
    queryKey: [
      'ClientModal',
      'webCustomerClientGetclientpageCreate',
      {
        ...props.reqParams,
        ...params,
        ...pagination
      }
    ],
    queryFn: ({ signal, queryKey }) => {
      return apiWeb.webCustomerClientGetclientpageCreate(queryKey[2] as GetClientInput, { signal });
    },
    select: (data) => data?.response,
    placeholderData: keepPreviousData
  });

  const { selected, select, unSelect, selectAll, unSelectAll, clearAll } = useSelections(des.data?.data ?? [], {
    defaultSelected: props?.rows ?? [],
    itemKey: 'id'
  });

  useEffect(() => {
    console.log('selected', selected);
    console.log(' props?.rows', props?.rows);
  }, [selected, props?.rows]);

  const loading = useMemo(() => {
    if (des.isFetched) {
      return false;
    }
    return des.isFetching;
  }, [des.isFetched, des.isFetching]);

  const handleCloseTag = (e, item) => {
    e.preventDefault();
    unSelect(item);
  };

  const labelRender = useMemo(() => {
    const content = (
      <Flex wrap gap="small">
        {selected?.map((item) => (
          <Tag key={item?.id} closeIcon onClose={(e) => handleCloseTag(e, item)}>
            {item?.displayName || item?.id}
          </Tag>
        ))}
        {selected?.length ? null : (
          <Flex align="center" justify="center" style={{ width: '100%' }}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </Flex>
        )}
      </Flex>
    );
    return (
      <Popover
        content={content}
        arrow={false}
        placement="bottom"
        overlayInnerStyle={{
          width: 304
        }}
      >
        <span className={style.label}>
          已选中
          <span style={{ color: 'var(--ant-color-primary)', padding: '0 2px' }}>{selected?.length ?? 0}</span>
          条数据
        </span>
      </Popover>
    );
  }, [selected]);

  const renderName = (record: CaseCheckOutput) => {
    if (isArray(record)) {
      return record?.map((v: any) => v.name).join(',');
    }
    return '-';
  };
  const columns = [
    {
      title: '客户名称',
      dataIndex: 'name'
    },
    {
      title: '统一社会信用代码/身份证',
      dataIndex: 'creditCode',
      // @ts-ignore
      render: (value, record) => value || record?.clientNameEn || renderName(record)
    },
    {
      title: '客户分类',
      dataIndex: 'creditTypeText',
      render: (_value: any) => _value
    }
  ];

  const rowSelection: TableProps['rowSelection'] = useMemo(() => {
    if (props?.mode === 'multiple') {
      return {
        selectedRowKeys: selected?.map((v) => v?.id),
        type: 'checkbox',
        // onChange: (selectedRowKeys, selectedRows, info) => {
        //   console.log("rowSelection", selectedRowKeys, selectedRows, info)
        // },
        onSelect: (record, selected) => {
          if (selected) {
            select(record);
          } else {
            unSelect(record);
          }
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
          if (selected) {
            selectAll();
          } else {
            unSelectAll();
          }
        }
        // preserveSelectedRowKeys: true,
      };
    }
    return {
      type: 'radio',
      selectedRowKeys: selected?.map((v) => v?.id),
      onSelect: (record, selected) => {
        clearAll();
        select(record);
      }
    };
  }, [selected, props?.mode]);

  const handleConfirm = () => {
    modal.resolve(selected);
    modal.hide();
  };

  const onRow = useCallback((record, selectedLst: any[]) => {
    if (props?.mode === 'multiple') {
      return {
        onClick: () => {
          // console.log(selectedLst)
          const index = findIndex(selectedLst, function (o) {
            return o.id === record.id;
          });
          // console.log(index)
          if (index >= 0) {
            unSelect(record);
          } else {
            select(record);
          }
        }
      };
    }
    return {
      onClick: () => {
        clearAll();
        select(record);
      }
    };
  }, []);

  return (
    <BitzFormModal title="选择客户" footer={null} {...antdModalV5(modal)}>
      <Row gutter={[10, 10]} justify="space-between">
        <Col span={12}>
          <Input.Search placeholder="请输入" enterButton="搜索" onSearch={handleSearch} onChange={handleChange} allowClear />
        </Col>
        <Col span={12}>
          <Space.Compact block style={{ width: '100%' }}>
            {labelRender}
            <Button type="primary" danger onClick={clearAll}>
              清空
            </Button>
            <Button type="primary" onClick={handleConfirm}>
              确认
            </Button>
          </Space.Compact>
        </Col>
      </Row>
      <div style={{ marginTop: 10 }}>
        <BitzTable
          onRow={(row) => onRow(row, selected)}
          size="md"
          rowKey="id"
          loading={loading}
          bordered="row-column"
          // tableLayout="fixed"
          scroll={{ x: 1, y: 400 }}
          showPagination={false}
          showTableTool={false}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={des.data?.data ?? []}
          // className={tableCls}
        />
        <div style={{ marginTop: 10 }}>
          <BitzPagination
            disabled={loading}
            size="small"
            enableControl
            pagination={pagination}
            total={des.data?.dataCount}
            dataSource={des.data?.data ?? []}
            paginationEmit={onPageChange}
          />
        </div>
      </div>
    </BitzFormModal>
  );
};

export default create(ClientModal);

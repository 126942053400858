import { TimeRangePickerProps } from 'antd';
import dayjs from '@/utils/dayjs-facade';

export const rangePresets: TimeRangePickerProps['presets'] = [
  { label: '今天', value: [dayjs().startOf('day'), dayjs().endOf('day')] },
  { label: '昨天', value: [dayjs().add(-1, 'days').startOf('days'), dayjs().add(-1, 'days').endOf('day')] },
  { label: '最近7天', value: [dayjs().add(-7, 'days').startOf('day'), dayjs().endOf('day')] },
  { label: '最近30天', value: [dayjs().add(-30, 'days').startOf('day'), dayjs()] },
  { label: '这个月', value: [dayjs().startOf('months'), dayjs().endOf('day')] },
  { label: '上个月', value: [dayjs().add(-1, 'months').startOf('month'), dayjs().add(-1, 'months').endOf('month')] },
  { label: '本季度', value: [dayjs().startOf('quarter'), dayjs().endOf('quarter')] },
  { label: '上季度', value: [
      dayjs().subtract(1, 'quarter').startOf('quarter'),
      dayjs().subtract(1, 'quarter').endOf('quarter')
    ]},
  { label: '今年', value: [dayjs().startOf('year'), dayjs().endOf('year')] },
  { label: '去年', value: [dayjs().subtract(1, 'year').startOf('year'), dayjs().subtract(1, 'year').endOf('year')] }
];

import { css } from '@emotion/css';
import { theme } from 'antd';
import clx from 'classnames';
import React, { useMemo } from 'react';

interface IconRenderProps {
  icon?: React.ReactNode;
  size?: number;
}

const IconRender: React.FC<IconRenderProps> = (props) => {
  const { icon, size = 14 } = props;

  const { token } = theme.useToken();

  const titleIcon = css`
    flex-shrink: 1;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${token.colorPrimary};
    background-color: #e2e4fd;
    border-radius: 4px;
    margin-right: 10px;
  `;

  const renderItem = () => {
    if (React.isValidElement(icon)) {
      return React.cloneElement(icon, {
        // @ts-ignore
        size,
        color: 'inherit',
        fill: 'currentColor'
      });
    }
    return null;
  };

  return <span className={clx('anticon anticon-apartment ant-menu-item-icon')}>{renderItem()}</span>;
};

export default IconRender;

import dayjs from "dayjs";
import { useEffect } from "react";
import {useEventListener, useLocalStorageState} from "ahooks";
import { Loguot } from "@/utils/loguot";


const IDLE_LIMIT = 1000 * 60 * 120; // 两小时
// const IDLE_LIMIT = 1000 * 60 * 5;

export const useTimeoutLogout = () => {
  // const [lastActivity, setLastActivity] = useState(dayjs().valueOf());
  const [lastActivity = dayjs().valueOf(), setLastActivity] = useLocalStorageState<number>(
    'useTimeoutLogout_lastActivity',
    {
      defaultValue: dayjs().valueOf()
    }
  );

  const handleIdleTimeout = () => {
    console.log('登出');
    Loguot()
  };

  // 鼠标和键盘事件更新时间
  const updateActivity = () => {
    setLastActivity(dayjs().valueOf());
  };

  const checkIdleTime = () => {
    const now = dayjs().valueOf();
    const idleDuration = now - lastActivity;
    if (idleDuration >= IDLE_LIMIT) {
      handleIdleTimeout();
      updateActivity();
    }
  };

  useEventListener('visibilitychange', () => {
    if (document.visibilityState === 'visible') {
      checkIdleTime();
    }
  })
  useEventListener('mousemove', updateActivity)
  useEventListener('keypress', updateActivity)


  useEffect(() => {
    const interval = setInterval(() => {
      checkIdleTime();
    }, 1000);

    return () => {
      clearInterval(interval)
      localStorage.removeItem('useTimeoutLogout_lastActivity');
    };
  }, [lastActivity]);
}

import React, { useCallback, useMemo } from 'react'
import BitzQueryFilter2 from '@/components/BitzQueryFilter2'
import { getShowSearch } from '@/utils/mobile'
import { useQuery } from '@tanstack/react-query'
import apiCommon from '@/services/GeneralPublicInterface'
import { Flex, Select, SelectProps, Spin } from 'antd'

export interface BnWorksSelectLanguageProps extends SelectProps {
  // /**
  //  * 数据字典名称
  //  */
  // name: string
  /**
   * 是否启用Fluent组件模式
   */
  fluent?: boolean
}
/**
 * 只显示中文、英文、日语 选择项
 * @param props
 * @returns
 */
const BnWorksSelectLanguage: React.FC<BnWorksSelectLanguageProps> = (props) => {
  const { fluent = false, ...rest } = props
  const name = 'Language'
  const optionKeys: string[] = ['ZH', 'EN', 'JA']
  const { data, isFetched, isFetching } = useQuery({
    queryKey: ['generalcodeTreeSelectCreate', name],
    queryFn: ({ signal }) => {
      return apiCommon.generalcodeTreeSelectCreate(
        {
          class: name,
          code: '',
          parentCode: '',
        },
        { signal },
      )
    },
    select: (data) => {

      const arr = data?.response?.filter((f: any) => { return optionKeys.includes(f.value) }).map((item) => ({
        value: item.value,
        label: item.displayText,
      }))
      return arr
    },
    enabled: !!name,
    // 缓存60分钟
    staleTime: 60 * 60 * 1000
  })

  const loading = useMemo(() => {
    if (isFetched) {
      return false
    }
    return isFetching
  }, [isFetched, isFetching])

  const Component = useMemo(() => fluent ? BitzQueryFilter2.Select : Select, [fluent])

  const dropdownRender = useCallback((originNode) => {
    if (loading) {
      return <Flex align="center" justify="center" style={{ width: '100%', height: 50 }}><Spin /></Flex>
    }
    return originNode
  }, [loading])

  return (
    <Component
      style={{ width: '100%' }}
      placeholder="请选择"
      loading={loading}
      // @ts-ignore
      options={data}
      maxTagCount="responsive"
      optionFilterProp="label"
      showSearch={getShowSearch()}
      // @ts-ignore
      dropdownRender={dropdownRender}
      {...rest}
    />
  )
}

export default BnWorksSelectLanguage

import KeepAlive from 'react-activation'
import SuspenseLoading from '@/components/SuspenseLoading'
import React, { useMemo } from 'react'
import { Layout, theme } from 'antd'
import useBasicSettingsStore from '@/store/useBasicSettingsStore'
import { useShallow } from 'zustand/react/shallow'
import useStore from '@/components/LayoutContentTabs/useStore'
import { ErrorBoundary } from 'react-error-boundary'
import FallbackRender from '@/components/FallbackRender'

export interface BaseContentProps {
  matchRouteObj: any
  active: string
  eleRef: any
}

const BaseContent: React.FC<BaseContentProps> = (props) => {
  const { matchRouteObj, active, eleRef } = props
  const isMorePages = useBasicSettingsStore(useShallow((state) => state.themeConfig?.isMorePages))

  const { token } = theme.useToken()

  const { refrshKey } = useStore()

  // useEffect(() => {
  //   console.log('matchRouteObj', matchRouteObj)
  // }, [matchRouteObj])

  const dom = useMemo(() => {
    if (isMorePages && matchRouteObj?.cache) {
      // console.log({
      //   isMorePages,
      //   cache: matchRouteObj?.cache,
      //   active,
      // })
      console.log('active', active)
      return (
        <ErrorBoundary fallbackRender={FallbackRender}>
          <SuspenseLoading>{eleRef.current}</SuspenseLoading>
        </ErrorBoundary>
      )
    }
    return (
      <ErrorBoundary fallbackRender={FallbackRender}>
        <SuspenseLoading>{eleRef.current}</SuspenseLoading>
      </ErrorBoundary>
    )
  }, [isMorePages, matchRouteObj, active])

  return (
    <Layout.Content
      id="layout_content"
      key={refrshKey}
      style={{
        padding: 0,
        margin: 0,
        height: '100%',
        minHeight: 280,
        background: token.colorBgContainer,
        borderRadius: token.borderRadiusLG,
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      {dom}
    </Layout.Content>
  )
}

export default BaseContent

import { FormItemProps } from 'antd';
import dayjs from 'dayjs';
import {GetTimesheetSubtotalOutput} from "@/services/WebApi";
import Decimal from "decimal.js";

// 不可编辑状态
export const NonEditableStatus = ['Approved', 'Submitted'];

// 可编辑状态
export const EditableStatus = ['Incomplete', 'Ready'];

export const formatText = 'YYYY-MM-DD HH:mm:ss';
export const timeText = 'HH:mm:ss';

export const toStatusColor = (status) => {
  switch (status) {
    case 'Incomplete':
      return { bg: '#e4e2e2', tip: '#b3b3b3' };
    case 'Ready':
      return { bg: '#b9dc9f', tip: '#1f5e22' };
    case 'Submitted':
      return { bg: '#a8dbf8', tip: '#236ab8' };
    case 'Approved':
      return { bg: '#ffc5cc', tip: '#d11428' };
    default:
      return { bg: '#e4e2e2', tip: '#b3b3b3' };
  }
};

export const endTimeRules: FormItemProps['rules'] = [
  ({ getFieldValue }) => ({
    validator(_, value) {
      const start = getFieldValue('startTime');
      if (!dayjs.isDayjs(start)) {
        return Promise.resolve();
      }
      if (value?.isBefore(start, 'hour')) {
        return Promise.reject(new Error('结束时间不能小于开始时间'));
      }
      return Promise.resolve();
    }
  })
];

export const timeDependentCalcs = (hour: number, start?: dayjs.Dayjs, end?: dayjs.Dayjs) => {
  if (start && !end) {
    const date = start.add(hour, 'hour');
    // 是不是在同一天
    if (start.isSame(date, 'day')) {
      return { start, end: date };
    } else {
      return { start, end: start.endOf('day') };
    }
  }
  if (!start && end) {
    const date = end.add(-hour, 'hour');
    if (end.isSame(date, 'day')) {
      return { start: date, end };
    } else {
      return { start: end.endOf('day'), end };
    }
  }
  if (start && end) {
    const date = start.add(hour, 'hour');
    if (start.isSame(date, 'day')) {
      return { start, end: date };
    } else {
      return { start, end: start.endOf('day') };
    }
  }
  return { start: undefined, end: undefined };
};

export const timeRelationHandling = (hour: number, name: string, start?: dayjs.Dayjs, end?: dayjs.Dayjs) => {
  if (start && !end) {
    const date = start.add(hour, 'hour');
    // 是不是在同一天
    if (start.isSame(date, 'day')) {
      // form.setFieldValue('endTime', date)
      return { start, end: date, hour };
    } else {
      // form.setFieldValue('endTime', startTime.endOf('day'))
      return { start, end: start.endOf('day'), hour };
    }
  }
  if (!start && end) {
    const date = end.add(-hour, 'hour');
    if (end.isSame(date, 'day')) {
      // form.setFieldValue('startTime', date)
      return { start: date, end, hour };
    } else {
      // form.setFieldValue('startTime', endTime.startOf('day'))
      return { start: end.startOf('day'), end, hour };
    }
  }
  if (start && end && hour) {
    if (start.isBefore(end)) {
      const diffInMilliseconds = end.diff(start);
      const diffInHours = diffInMilliseconds / (1000 * 60 * 60);
      return { start, end, hour: diffInHours.toFixed(1) };
    } else {
      if (name === 'startTime') {
        const data = start.add(hour, 'hour');
        if (start.isSame(data, 'day')) {
          // form.setFieldValue('endTime', data)
          return { start, end: data, hour: hour };
        } else {
          // form.setFieldValue('endTime', start.endOf('day'))
          return { start, end: start.endOf('day'), hour };
        }
      }

      if (name === 'endTime') {
        const data = end.add(-hour, 'hour');
        if (end.isSame(data, 'day')) {
          return { start: data, end, hour };
        } else {
          return { start: end.startOf('day'), end, hour };
        }
      }
    }
  }

  if (start && end && !hour) {
    if (start.isBefore(end)) {
      // 计算毫秒差
      const diffInMilliseconds = end.diff(start);
      // 将毫秒差转换为小时
      const diffInHours = diffInMilliseconds / (1000 * 60 * 60);
      // form.setFieldValue('hoursWorked', diffInHours?.toFixed(1))
      return { start, end, hour: diffInHours };
    }
  }

  return { start, end, hour };
};


/**
 * 用于计算周视图底部每个状态的合计
 * @param workDays
 */
export const calculateStatusTotals = (workDays: GetTimesheetSubtotalOutput[]) => {
  const totalsMap: {
    [key: string]: {
      count: Decimal;
      recordedHours: Decimal;
      billableHours: Decimal;
      nonBillableHours: Decimal;
    }
  } = {};

  workDays?.forEach(day => {
    day?.statusTotal?.forEach(status => {
      if (!totalsMap[status.value!]) {
        totalsMap[status.value!] = {
          count: new Decimal(0),
          recordedHours: new Decimal(0),
          billableHours: new Decimal(0),
          nonBillableHours: new Decimal(0),
        };
      }

      totalsMap[status.value!].count = totalsMap[status.value!].count.plus(status.count!);
      totalsMap[status.value!].recordedHours = totalsMap[status.value!].recordedHours.plus(status.recordedHours!);
      totalsMap[status.value!].billableHours = totalsMap[status.value!].billableHours.plus(status.billableHours!);
      totalsMap[status.value!].nonBillableHours = totalsMap[status.value!].nonBillableHours.plus(status.nonBillableHours!);
    });
  });

  const result = {};
  for (const status in totalsMap) {
    if (Object.prototype.hasOwnProperty.call(totalsMap, status)) {
      result[status] = {
        count: totalsMap[status].count.toNumber(),
        recordedHours: totalsMap[status].recordedHours.toNumber(),
        billableHours: totalsMap[status].billableHours.toNumber(),
        nonBillableHours: totalsMap[status].nonBillableHours.toNumber(),
      };
    }
  }

  return result;
};

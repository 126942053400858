import { Button, Form, FormInstance, theme, Tooltip } from 'antd';
import React, { FC, memo, useContext, useMemo } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import clx from 'classnames';
import { MyContext } from '@/components/BitzQueryFilter2/BitzQueryFilterContext';
import { BitzSelect } from 'bitz-react-admin-ui';

import { ConditionalType } from '@/components/BitzQueryFilter2/const';
import RenderFormItem from './components/RenderFormItem';
import { eq, forEach } from 'lodash-es';

export interface AdvancedFilterProps {
  form?: FormInstance<any>;
  api?: string;
}

const AdvancedFilter: FC<AdvancedFilterProps> = (props) => {
  const { form } = props;
  const { token } = theme.useToken();
  // 用于搜集表单信息
  const { advancedWhere } = useContext(MyContext);

  const field = Form.useWatch('advanced', form);

  const options = useMemo(() => {
    if (advancedWhere?.length) {
      const list2: any[] = [];
      forEach(advancedWhere, (value, key) => {
        const disabled = field?.findIndex((v) => v?.fieldNames === value.inputFieldName) != -1;
        list2.push({
          value: value.inputFieldName,
          label: value.description,
          disabled
        });
      });

      return list2;
    }
    return [];
  }, [advancedWhere, field]);

  const renderFormItem = (FormInstance: FormInstance<any>, field: { fieldKey?: number | undefined; key?: number; name: any }) => {
    const fieldName = FormInstance.getFieldValue(['advanced', field.name, 'fieldNames']);
    const item = advancedWhere?.find((v) => v.inputFieldName === fieldName);
    const name = [field.name, 'value'];

    if (fieldName && item) {
      // console.log(item);

      return <RenderFormItem item={item} name={name} advanced />;
    }
    return <div className="placeholderBox app-theme ant-input-css-var">请选择</div>;
  };

  const renderConditionalType = (FormInstance, field) => {
    const fieldName = FormInstance.getFieldValue(['advanced', field.name, 'fieldNames']);
    const conditionalType = FormInstance.getFieldValue(['advanced', field.name, 'conditionalType']);
    const item = advancedWhere?.find((v) => v.inputFieldName === fieldName);
    const name = [field.name, 'conditionalType'];
    let options: undefined | any[] = undefined;

    // @ts-ignore
    if (item?.valueType === 'Object') {
      if (item?.fieldType === 'String') {
        options = ConditionalType.stringObject;
      }
      if (['Int64', 'Long', 'Decimal'].includes(item?.fieldType ?? '')) {
        options = ConditionalType.longintdecimalObject;
      }
      if (['DateTime', 'dateTime'].includes(item?.fieldType ?? '')) {
        options = ConditionalType.dateTimeObject;
      }
      if (item?.fieldType === 'Boolean') {
        options = ConditionalType.boolObject;
      }
      if (item?.fieldType === 'Enum') {
        options = ConditionalType.enumObject;
      }
    }
    // @ts-ignore
    if (item?.valueType === 'List') {
      if (['List`1', 'String[]', 'Decimal[]', 'List']?.includes(item?.fieldType ?? '')) {
        options = ConditionalType.stringList;
      }
      if (['Int64', 'Long', 'Decimal'].includes(item?.fieldType ?? '')) {
        options = ConditionalType.longintdecimalList;
      }
      if (item?.fieldType === 'Enum') {
        options = ConditionalType.enumList;
      }
    }
    // @ts-ignore
    if (item?.valueType === 'Range') {
      if (['Int64', 'Long', 'Decimal'].includes(item?.fieldType ?? '')) {
        options = ConditionalType.longintdecimalRange;
      }
      if (['DateTime', 'dateTime'].includes(item?.fieldType ?? '')) {
        console.log('item', item);

        options = ConditionalType.dateTimeRange;
      }
    }

    if (fieldName && !conditionalType) {
      FormInstance.setFieldValue(['advanced', field.name, 'conditionalType'], options?.[0]?.value ?? undefined);
    }

    return (
      <Form.Item name={name}>
        <BitzSelect
          style={{ width: '100%' }}
          options={options}
          // showSearch={getShowSearch()}
        />
      </Form.Item>
    );
  };

  return (
    <Form.List name="advanced">
      {(fields, { add, remove }) => (
        <React.Fragment>
          {fields.map(({ key, name, ...restField }) => {
            return (
              <div className="advancedFilter" key={key}>
                <Tooltip placement="top" title="移除此项">
                  <Button type="text" onClick={() => remove(name)} icon={<DeleteOutlined style={{ color: token.colorPrimary }} />} />
                </Tooltip>
                <Form.Item {...restField} name={[name, 'fieldNames']}>
                  <BitzSelect style={{ width: '100%' }} options={options} showSearch />
                </Form.Item>
                <Form.Item
                  style={{ margin: 0, padding: 0 }}
                  shouldUpdate={(prevValues, curValues) => {
                    const isEquals = eq(prevValues.advanced[name]?.fieldNames, curValues.advanced[name]?.fieldNames);
                    console.log(name);
                    if (!isEquals) {
                      form?.setFieldValue(['advanced', name, 'conditionalType'], undefined);
                      // 如果name有值 type没有就
                    }
                    return !isEquals;
                  }}
                >
                  {(parme) => renderConditionalType(parme, { key, name, ...restField })}
                </Form.Item>

                <Form.Item
                  style={{ margin: 0, padding: 0 }}
                  shouldUpdate={(prevValues, curValues) => {
                    const isEquals = eq(prevValues.advanced[name]?.fieldNames, curValues.advanced[name]?.fieldNames);
                    if (!isEquals) {
                      form?.setFieldValue(['advanced', name, 'value'], undefined);
                    }
                    console.log(name);
                    return !isEquals;
                  }}
                >
                  {(parme) => renderFormItem(parme, { key, name, ...restField })}
                </Form.Item>
              </div>
            );
          })}

          <div className={clx('advancedFilterDivider')}>
            <span
              onClick={() => !(fields?.length >= advancedWhere?.length) && add()}
              className={clx('advancedFilterDivider-text', {
                disabled: fields?.length >= advancedWhere?.length
              })}
            >
              添加条件
            </span>
          </div>
        </React.Fragment>
      )}
    </Form.List>
  );
};

export default memo(AdvancedFilter);

type NullableString = string | null | undefined

const getValueOrThrow = (value?: NullableString): string => {
  const emptyValues = new Set(['', 'null', 'undefined', '--', '-', 'N/A', 'n.a.'])

  if (value === null || value === undefined || emptyValues.has(value)) {
    return '-'
  }

  return value
}

const mergeDataByCategory = (data: any[], parentCategory: string, childCategory: string) => {
  const result = new Map()

  data.forEach((item: { category: string; name: string }) => {
    const { category, name } = item

    if (category == parentCategory) {
      const trimmedCreditCode = (name && name.trim()) ?? null
      result.set(trimmedCreditCode, { ...item, child: [] })
    }
  })

  data.forEach((item: { category: string; parentId: string }) => {
    const { category, parentId } = item

    if (category == childCategory) {
      const trimmedParentId = (parentId && parentId.trim()) ?? null
      if (result.has(trimmedParentId)) {
        result.get(trimmedParentId).child.push(item)
      }
    }
  })
  return Array.from(result.values())
}

const countChildren = (value: any): number => {
  return Object.values(value).reduce((count: number, val: any) => {
    if (val.child && val.child.length > 0) {
      return count + countChildren(val.child)
    } else {
      return count + 1
    }
  }, 0)
}

const handleCell = (record: any) => {
  if (record?.isChildrenMerge) {
    return {
      rowSpan: record?.childrenRowSpan ?? 1,
    }
  } else {
    return { rowSpan: 0 }
  }
}

const handleChildrenCell = (record: any) => {
  if (record?.isMerge) {
    return {
      rowSpan: record?.item?.rowSpan ?? 1,
    }
  } else {
    return { rowSpan: 0 }
  }
}

const natureMap = {
  'CSC': '央',
  'SOE': '国',
  'PEN': '民',
  'HKE': '港',
  'FIE': '外',
  'CIV': '个',
}

const noop = () => {}

export { getValueOrThrow, mergeDataByCategory, countChildren, handleCell, handleChildrenCell, natureMap, noop }

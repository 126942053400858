type NullableString = string|null|undefined

const getValueOrThrow = (value?: NullableString): string => {
  const emptyValues = new Set(['', 'null', 'undefined', '--', '-', 'N/A', 'n.a.'])

  if(value === null || value === undefined || emptyValues.has(value)) {
    return '-'
  }

  return value
}

/**
 * @description 根据空格分割字符串
 * @param input
 * @returns {{beforeString: string, afterString: string}}
 * @example
 * input: 2024-05-21 17:53:12
 * output: {beforeString: '2024-05-21', afterString: '17:53:12'}
 */
const replaceSpace = (input: string|null|undefined): { beforeString: string; afterString: string } => {
  try {
    if(typeof input !== 'string') {
      return {
        beforeString: '',
        afterString: '',
      }
    }

    if(input == null) {
      return {
        beforeString: '',
        afterString: '',
      }
    }

    input = input.trim()

    // 初始化空格索引
    let firstSpaceIndex = -1
    for(let i = 0; i < input.length; i++) {
      if(input[i] === ' ') {
        firstSpaceIndex = i
        break
      }
    }

    // 如果找到了空格则分割字符串并返回
    if(firstSpaceIndex !== -1) {
      return {
        beforeString: input.slice(0, firstSpaceIndex),
        afterString: input.slice(firstSpaceIndex + 1),
      }
    }

    return {
      beforeString: input,
      afterString: '',
    }
  } catch (error) {
    console.error('error', error)
    return {
      beforeString: '',
      afterString: '',
    }
  }
}

const totalWidth = (columns: any[]) => {
  return columns.reduce((sum, column) => {
    const width = column.width as number
    return sum + width
  }, 0)
}

const ruleSet1 = new Set(['Rule 1', 'Rule 2', 'Rule 3', 'Rule 4', 'Rule 5', 'Rule 6'])
const ruleSet2 = new Set(['Rule 7', 'Rule 8', 'Rule 9', 'Rule 10', 'Rule 11', 'Rule 12'])

const checkRule = (rule: string) => {
  if(ruleSet1.has(rule)) {
    return true
  }
  if(ruleSet2.has(rule)) {
    return false
  }
  return '-'
}

const getFirstValue = (str: string) => {
  const trimmedStr = str.split(' ').join('');

  if(trimmedStr.length === 0) {
    return null
  }

  const values = trimmedStr.split(',')

  if(values.length === 0) {
    return null
  }

  return values[0]
}

export { checkRule, getFirstValue, totalWidth, getValueOrThrow, replaceSpace }

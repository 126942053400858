import React from 'react';
import clx from 'classnames';
import { Popconfirm, Typography, theme } from 'antd';
import * as styles from './styles';
import { DeleteOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';

export interface RetrievalItemProps {
  label?: string;
  valueName?: string;
  onConfirm?: () => void;
}

const RetrievalItem: React.FC<RetrievalItemProps> = (props) => {
  const { token } = theme.useToken();

  return (
    <div
      className={clx(
        styles.filterTagItem,
        css`
          border: 1px solid ${token.colorBorder};
          .filterTagLable {
            color: ${token.colorTextQuaternary};
          }
          .filterTagValue {
            color: ${token.colorText};
          }
          &:hover {
            border: 1px solid ${token.colorPrimary};
            color: ${token.colorPrimary};
            .ant-typography {
              color: ${token.colorPrimary};
            }
            .filterTagLable {
              color: ${token.colorPrimary};
            }
          }
        `,
        'filterTagItem'
      )}
    >
      <div className="filterTagLable"> {props?.label}：</div>
      <div className="filterTagValue">
        <Typography.Text ellipsis={{ tooltip: props?.valueName }}>{props?.valueName}</Typography.Text>
      </div>
      <Popconfirm placement="top" title="删除筛选项" description="您确定要删除该筛选项吗？" onConfirm={props?.onConfirm}>
        <div className={clx('filterTagClose')}>
          <DeleteOutlined />
        </div>
      </Popconfirm>
    </div>
  );
};

export default React.memo(RetrievalItem);

import { create, useModal, antdDrawerV5 } from '@ebay/nice-modal-react'
import React, { FC } from 'react'
import { Button, Drawer, Form } from 'antd'
import IconSousuo from '@/assets/iconfont/IconSousuo'
import IconZhongzhi from '../../assets/iconfont/IconZhongzhi'
import { FormInstance } from 'antd/es/form/hooks/useForm'

export interface MobileFilterModalProps {
  items?: React.ReactNode[]

  onReset?: () => void

  onFinish?: (values: any) => void

  form: FormInstance<any>
}

const MobileFilterModal: FC<MobileFilterModalProps> = (props) => {
  const { items, form } = props

  const modal = useModal()
  // const [form] = Form.useForm()

  const handleFinish = (values) => {
    props?.onFinish?.(values)
    modal.hide()
  }

  const handleReset = () => {
    form?.resetFields()
    props?.onReset?.()
    modal.hide()
  }

  return (
    <Drawer
      title="筛选"
      placement="bottom"
      rootClassName="BitzQueryFilterDrawer"
      footer={
        <div className="colSpace">
          <Button
            block
            type="primary"
            onClick={() => form?.submit()}
            icon={
              <span className="anticon">
                <IconSousuo color="currentColor" size={12} />
              </span>
            }
          >
            搜索
          </Button>
          <Button
            block
            onClick={handleReset}
            icon={
              <span className="anticon">
                <IconZhongzhi color="currentColor" size={12} />
              </span>
            }
          >
            重置
          </Button>
        </div>
      }
      {...antdDrawerV5(modal)}
    >
      <Form form={form} onFinish={handleFinish}>
        {items}
      </Form>
    </Drawer>
  )
}

export default create(MobileFilterModal)

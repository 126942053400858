import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useMemo, useRef, useState } from 'react'
import { message } from 'antd'
// import useSessionStorageState from "@/hooks/useSessionStorageState"
import { useSessionStorageState } from 'ahooks'
import { NavigateOptions, useLocation, useNavigate } from 'react-router-dom'
import { useAliveController } from 'react-activation'
// import {useKeepaliveRef} from "keepalive-for-react/dist/types/components/KeepAlive";

export type PageConfig = {
  // 路由的名称
  label: string
  // 路由的 path 值 例如 /home /user?id=1
  key: string
  // 路由的参数
  state?: any
}

export interface PageManage {
  // 当前激活的路由 key 值
  active: string
  // 所有存在的路由 tabs
  pages: PageConfig[]
  setPages: Dispatch<SetStateAction<PageConfig[]>>
  close: (key: string, cb?: () => void) => string | null | undefined
  open: (page: PageConfig) => void
  closeCurrent: (cb?: () => void) => string | null | undefined
  // getKeepAliveRef: () => RefObject<KeepAliveRef> | undefined
  // getKeepAliveRef: () => any
}

const PageContext = createContext<PageManage>({
  active: '',
  pages: [],
  setPages: () => {
    console.log('setPages')
  },
  close: (key: string, cb?: () => void) => {
    cb && cb()
    console.log(key)
    return key
  },
  open: (page: PageConfig) => {
    console.log(page)
  },
  closeCurrent: (cb?: () => void) => {
    cb && cb()
    return null
  },
  // getKeepAliveRef: () => {
  //   return undefined
  // },
})

export const usePageContext = () => {
  return useContext(PageContext)
}

const TabPageStorageKey = 'admin_pages'

export function PageManageProvider(props: { children: ReactNode }) {
  const location = useLocation()
  const [active, setActive] = useState(location.pathname + location.search)
  // const keepAliveRef = useRef<KeepAliveRef>(null)
  // const keepAliveRef = useKeepaliveRef()
  const [pages = [], setPages] = useSessionStorageState<PageConfig[]>(TabPageStorageKey, {
    defaultValue: [],
  })
  const [messageApi, messageEle] = message.useMessage()
  const lastOpenKey = useRef<string>('')
  const navigate = useNavigate()
  const { dropScope } = useAliveController()

  const navigateTo = (key: string, options?: NavigateOptions) => {
    const pathname = key.indexOf('?') > -1 ? key.split('?')[0] : key
    const search = key.indexOf('?') > -1 ? key.split('?')[1] : ''
    navigate(
      {
        pathname,
        search,
      },
      options,
    )
  }

  // const getKeepAliveRef = () => {
  //   return keepAliveRef
  // }

  /**
   * 关闭一个标签页
   * @param key 路由的 key
   * @param cb 关闭后成功的回调
   * @returns 返回下一个激活的路由 key
   */
  const close = (key: string, cb?: () => void) => {
    const index = pages.findIndex((item) => item.key === key)
    if (index === -1) return
    const newPages = [...pages]
    if (newPages.length <= 1) {
      messageApi.error('至少保留一个标签页')
      return null
    }
    cb && cb()
    // keepAliveRef.current?.removeCache(key)
    dropScope(key)
    newPages.splice(index, 1)
    setPages(newPages)
    let nextActiveKey: null | string = null
    // if close current page
    if (active === key) {
      const lastKey = lastOpenKey.current
      // if last open key is existed in pages
      if (lastKey && newPages.some((item) => item.key === lastKey)) {
        // set last open key to active
        nextActiveKey = lastKey
      } else {
        // if last open key is not existed in pages or last open key is not existed
        // set the last page to active page
        const activeKey = newPages[newPages.length - 1].key
        nextActiveKey = activeKey
      }
      setActive(nextActiveKey)
    }
    // if nextActiveKey is existed, navigate to nextActiveKey
    if (nextActiveKey) {
      navigateTo(nextActiveKey, {
        replace: true,
      })
    }
    return nextActiveKey
  }

  const open = (page: PageConfig) => {
    if (!page || !page.key) {
      throw new Error(`路由信息不正确 ${JSON.stringify(page)}`)
    }
    // 记住上一个打开的路由
    lastOpenKey.current = active
    const newPages = [...pages]
    // 如果已经存在，就不再添加
    const existed = newPages.some((item) => item.key === page.key)
    if (!existed) {
      // newPages.push(page)
      setPages((state = []) => [...state, page])
    }
    // prevent navigate to same page
    // if (page.key === active) return
    if (page.key === window.location.pathname) return
    navigateTo(page.key, {
      state: page.state,
    })
    setActive(page.key)
  }

  /**
   * 关闭当前的标签页
   * @param cb
   * @returns 返回下一个激活的路由 key
   */
  const closeCurrent = (cb?: () => void) => {
    return close(active, cb)
  }

  // @ts-ignore
  const value: PageManage = useMemo(() => {
    return {
      active,
      pages,
      setPages,
      close,
      open,
      closeCurrent,
      // getKeepAliveRef,
    }
  }, [active, pages, setPages])

  return (
    <PageContext.Provider value={value}>
      {messageEle}
      {props.children}
    </PageContext.Provider>
  )
}

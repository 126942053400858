import { Cascader, CascaderProps } from 'antd';
import { FC, memo, useContext, useEffect, useMemo } from 'react';
import { MyContext } from '../../BitzQueryFilterContext';
import { last, split } from 'lodash-es';

export type BQFCascaderProps = CascaderProps & {
  advanced?: boolean;
};

const BQFCascader: FC<BQFCascaderProps> = (props) => {
  const { dispatch, advanced = false } = useContext(MyContext);
  const { onChange, fieldNames, ...rest } = props;

  const id = useMemo(() => {
    if (props.id) {
      if (props.id?.indexOf('advanced') !== -1) {
        const list = split(props.id, '_');
        return list[list.length - 1];
      }
      return props.id;
    }
    return '';
  }, [props.id]);

  useEffect(() => {
    if (id) {
      dispatch?.({
        type: 'update',
        name: id,
        value: props?.value
      });
    }
  }, [props?.value, id]);

  const handleChange: CascaderProps['onChange'] = (value, selectedOptions) => {
    onChange?.(value, selectedOptions);
    if (id && dispatch) {
      // const valueName = isArray(selectedOptions) ? selectedOptions?.map((v) => v[fieldNames?.label ?? 'label']).join(',') : option[fieldNames?.label ?? 'label']
      const valueName = last(selectedOptions)?.[fieldNames?.label ?? 'label'];

      dispatch?.({
        type: 'update',
        name: id,
        valueName
      });
    }
  };

  return (
    // @ts-ignore
    <Cascader onChange={handleChange} fieldNames={fieldNames} {...rest} />
  );
};

export default memo(BQFCascader);

import { Key } from 'react'
import { ColumnsState } from '../BitzQueryFilterContext'

/**
 * 根据 key 和 dataIndex 生成唯一 id
 *
 * @param key 用户设置的 key
 * @param index 序列号，理论上唯一
 */
export const genColumnKey = (key?: string | number | Key, index?: number | string): string => {
  if (key) {
    return Array.isArray(key) ? key.join('-') : key.toString()
  }
  return `${index}`
}

export const columnSort = (columnsMap: Record<string, ColumnsState>) => (a: any, b: any) => {
  const { fixed: aFixed, index: aIndex } = a
  const { fixed: bFixed, index: bIndex } = b

  // 如果没有index，在 dataIndex 或者 key 不存在的时候他会报错
  const aKey = a.name || `${aIndex}`
  const bKey = b.name || `${bIndex}`
  if (columnsMap[aKey]?.order || columnsMap[bKey]?.order) {
    return (columnsMap[aKey]?.order || 0) - (columnsMap[bKey]?.order || 0)
  }
  return (a.index || 0) - (b.index || 0)
}

import React, { FC, useEffect, useMemo, useState } from 'react'
import { Modal, ModalProps, theme } from 'antd'
import { isMobile } from 'react-device-detect'
import useBasicSettingsStore from '@/store/useBasicSettingsStore'
import { useShallow } from 'zustand/react/shallow'
import className from './style'
import { merge } from 'lodash-es'

interface BitzFormModalProps extends ModalProps {
  /**
   * 嵌套内容
   */
  children?: any
}

const BitzFormModal: FC<BitzFormModalProps> = (props) => {
  const {
    children,
    style,
    ...rest
  } = props
  const [height, setHeight] = useState(0)
  const [width, setWidth] = useState(0)
  const { token } = theme.useToken()
  const themeConfig = useBasicSettingsStore(useShallow((state) => state.themeConfig))

  const getWinowInner = () => {
    setHeight(window.innerHeight - 186)
    setWidth(window?.innerWidth)
  }

  useEffect(() => {
    getWinowInner()
    window.addEventListener('resize', getWinowInner)
    return () => {
      window.removeEventListener('resize', getWinowInner)
    }
  }, [])


  const style2: React.CSSProperties = isMobile
    ? {
      // @ts-ignore
      '--bitz-form-modal-maxheight': `${height}px`,
      ...style,
    }
    : {
      '--bitz-form-modal-maxheight': `${height}px`,
        top: 40,
      }

  const modalWidth = useMemo(() => {
    if (isMobile) {
      return '100%'
    }
    return width > 928 ? 928 : '100%'
  }, [isMobile, width])


  return (
    <Modal
      style={style2}
      rootClassName={className(token)}
      width={modalWidth}
      maskClosable={false}
      {...props}
    >
      {children}
    </Modal>
  )
}

export default BitzFormModal

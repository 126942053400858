import { run } from '@/utils';
import { RecoilValueReadOnly } from 'recoil';

/**
 * Recoil unwrap hooks
 * 状态state改变为loading时, contents值不改变，依旧为上次加载的值
 */
export function useUnwrapRecoilValueLoadable<T>(recoilValue: RecoilValueReadOnly<T>, initValue?: T) {
  const { state, contents } = useRecoilValueLoadable(recoilValue);
  const storeRef = useRef<Row>({ loading: true, contents: initValue });

  run(['hasValue'].includes(state), () => {
    storeRef.current.contents = contents;
    /**
     * 第一次请求成功后，loading值就不再变化
     * 即，仅在第一次请求有loading状态
     * 通常应用在实时刷新数据，又不期望数据变换状态时
     */
    storeRef.current.loading = false;
  });
  // 当请求错误的时候，不返回错误值
  run(['hasError'].includes(state), () => {
    storeRef.current.loading = false;
  });
  return { state, loading: storeRef.current.loading, contents: storeRef.current.contents };
}

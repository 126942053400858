const longintdecimal = [
  {
    value: 'Equal',
    label: '等于',
  },
  {
    value: 'NoEqual',
    label: '不等于',
  },
  {
    value: 'GreaterThan',
    label: '大于',
  },
  {
    value: 'GreaterThanOrEqual',
    label: '大于等于',
  },
  {
    value: 'LessThan',
    label: '小于',
  },
  {
    value: 'LessThanOrEqual',
    label: '小于等于',
  },
]

export const ConditionalType = {
  // 字符串
  stringObject: [
    {
      value: 'Equal',
      label: '等于',
    },
    {
      value: 'Like',
      label: '模糊查询',
    },
    {
      value: 'LikeLeft',
      label: '左模糊',
    },
    {
      value: 'LikeRight',
      label: '右模糊',
    },
    {
      value: 'NoEqual',
      label: '不等于',
    },
    // {
    //   value: 'IsNullOrEmpty',
    //   label: '空或null',
    // },
    // {
    //   value: 'NoLike',
    //   label: '模糊查询取反',
    // },
    // {
    //   value: 'EqualNull',
    //   label: '等于/null',
    // },
    // {
    //   value: 'InLike',
    //   label: '集合模糊查',
    // },
  ],
  // 多用于人员，租户组织机构，分所查询
  longintdecimalObject: longintdecimal,
  // 时间类型
  dateTimeObject: [
    {
      value: 'Equal',
      label: '等于',
    },
    {
      value: 'GreaterThan',
      label: '大于',
    },
    {
      value: 'GreaterThanOrEqual',
      label: '大于等于',
    },
    {
      value: 'LessThan',
      label: '小于',
    },
    {
      value: 'LessThanOrEqual',
      label: '小于等于',
    },
  ],
  // 是否
  boolObject: [
    {
      value: 'Equal',
      label: '等于',
    },
    // {
    //   value: 'EqualNull',
    //   label: '等于/null',
    // },
  ],
  enumObject: [
    {
      value: 'Equal',
      label: '等于',
    },
    {
      value: 'NoEqual',
      label: '不等于',
    },
    {
      value: 'GreaterThan',
      label: '大于',
    },
    {
      value: 'GreaterThanOrEqual',
      label: '大于等于',
    },
    {
      value: 'LessThan',
      label: '小于',
    },
    {
      value: 'LessThanOrEqual',
      label: '小于等于',
    },
  ],
  stringList: [
    {
      value: 'In',
      label: '在集合中',
    },
    {
      value: 'NotIn',
      label: '不在集合中',
    },
  ],
  longintdecimalList: [
    {
      value: 'In',
      label: '在集合中',
    },
    {
      value: 'NotIn',
      label: '不在集合中',
    },
  ],
  enumList: [
    {
      value: 'In',
      label: '在集合中',
    },
    {
      value: 'NotIn',
      label: '不在集合中',
    },
  ],
  longintdecimalRange: [
    {
      value: 'GreaterThan',
      label: '大于',
    },
    {
      value: 'GreaterThanOrEqual',
      label: '大于等于',
    },
  ],
  dateTimeRange: [
    {
      value: 'In',
      label: '在集合中',
    },
    // {
    //   value: 'GreaterThan',
    //   label: '大于',
    // },
    // {
    //   value: 'GreaterThanOrEqual',
    //   label: '大于等于',
    // },
  ],
}

export const data = {
  status: 0,
  success: true,
  msg: 'string',
  msgDev: 'string',
  response: {
    inputName: 'string',
    description: 'string',
    fieldList: [
      {
        fieldType: 'string',
        dataSource: 'None',
        dataSourceKey: 'string',
        description: 'string',
        type: 'List',
        inputFieldName: 'string',
        fieldNames: ['string'],
      },
    ],
  },
}

const data2 = [
  {
    type: 'List',
    inputFieldName: 'string',
    fieldNames: ['string'],
    conditionalType: 'Equal',
    value: 'string',
  },
]

import { data } from './../../../../../components/BitzQueryFilter2/const';
import { HeaderNoProcess, WidgetModule } from '@/constants';
import apiWeb from '@/services/Web';
import { CreateFinancialBillingDisbursementInput } from '@/services/WebApi';
import { isEmpty } from 'lodash-es';
import { selectorFamily, useRecoilCallback } from 'recoil';
import { billBaseInfoDB, billTokenAtom, useBillIds, billDefaultOfficeTaxStore, billTotalFeeDB } from '../BillApplication.store';
import { mapping, upArray } from '@/utils';
import dayjs from 'dayjs';
import { Form, message } from 'antd';

export const billDisbursementDB = selectorFamily({
  key: 'billDisbursementDB',
  get:
    (billingId: string) =>
    async ({ get }) => {
      if (!billingId) return {};
      get(billTokenAtom(billingId));
      const { response } = await apiWeb.webFinancialBillingFinancialbillingdisbursementGetpageCreate(
        { billingId, page: 1, size: 10 },
        { headers: { ...HeaderNoProcess } }
      );

      const data = mapping(response?.data, {
        adjustmentType: `adjustmentType ?? ^FlatDiscount`
      });

      return { ...response, data };
    }
});

export const useCreateDisbursement = (billingId: string) => {
  const from = Form.useFormInstance();
  return useRecoilCallback(({ snapshot, refresh }) => async (values: CreateFinancialBillingDisbursementInput) => {
    if (isEmpty(values)) return;
    const baseInfo = await snapshot.getPromise(billBaseInfoDB(billingId));
    const tax = await snapshot.getPromise(billDefaultOfficeTaxStore(billingId));
    // const list = await snapshot.getPromise(billDisbursementDB(billingId));

    try {
      // list?.dataCount && (await from.validateFields());
      await apiWeb.webFinancialBillingFinancialbillingdisbursementPostCreate({
        ...values,
        // @ts-ignore
        expenseDate: dayjs().format('YYYY-MM-DD'),
        adjustmentType: 'FlatDiscount',
        // @ts-ignore
        currency: baseInfo?.data?.currency,
        // @ts-ignore
        taxRate: tax?.taxRate,
        count: 1,
        isBillable: 'Y'
      });
      refresh(billDisbursementDB(billingId));
    } catch (e) {
      message.error('数据不完整，请补充完整，方能新建数据');
      return;
    }
  });
};

export const useUpdateDisbursement = (billingId: string) => {
  return useRecoilCallback(({ refresh }) => async (values: CreateFinancialBillingDisbursementInput, id: string) => {
    await apiWeb.webFinancialBillingFinancialbillingdisbursementPutUpdate(id, values, { headers: { ...HeaderNoProcess } });
    refresh(billDisbursementDB(billingId));
    refresh(billDisbursementSubtotalDB(billingId));
    refresh(billTotalFeeDB(billingId));
  });
};

/**
 * 删除费用
 */
export const useRemoveDisbursement = (billingId: string) => {
  return useRecoilCallback(({ refresh }) => async (id: string) => {
    await apiWeb.webFinancialBillingFinancialbillingdisbursementDeleteDelete(id, { id }, { headers: { ...HeaderNoProcess } });
    refresh(billDisbursementDB(billingId));
    refresh(billDisbursementSubtotalDB(billingId));
    refresh(billTotalFeeDB(billingId));
  });
};

/**
 * 批量删除费用
 */
export const useBatchRemoveDisbursement = (billingId: string) => {
  const { setBillIds } = useBillIds(billingId, WidgetModule.BillDisbursement);
  return useRecoilCallback(({ refresh }) => async (ids: string[]) => {
    const ids$: Row[] = ids.map((id) => ({ id }));
    await apiWeb.webFinancialBillingFinancialbillingdisbursementBatchdeleteCreate(ids$, { headers: { ...HeaderNoProcess } });
    refresh(billDisbursementDB(billingId));
    refresh(billDisbursementSubtotalDB(billingId));
    // 批量删除后，删除全局选中标签
    setBillIds([]);
  });
};

/**
 * 批量取消销账
 */
export const useBatchCancelWriteOffDisbursement = (billingId: string) => {
  return useRecoilCallback(({ refresh }) => async (ids: string | string[]) => {
    // const ids$ = upArray(ids).map((id) => ({ id }));
    await apiWeb.webFinancialBillingFinancialbillingdisbursementWriteoffcandidatesexpensesCreate({ ids: upArray(ids), isWriteOff: 'N' });
    refresh(billDisbursementWirteOffDB(billingId));
  });
};

/**
 * 费用候选项列表
 */
export const billDisbursementCandidateDB = selectorFamily({
  key: 'billDisbursementCandidateDB',
  get:
    (billingId: string) =>
    async ({ get }) => {
      if (!billingId) return {};
      get(billTokenAtom(billingId));
      const baseInfo = get(billBaseInfoDB(billingId));
      // @todo api更改
      const { response } = await apiWeb.webFinancialBillingFinancialbillingdisbursementGetcandidatespageCreate(
        // @ts-ignore
        // { caseId: baseInfo?.data?.caseId, billingId, page: 1, size: 10000 },
        { billingId, page: 1, size: 10000 },
        { headers: { ...HeaderNoProcess } }
      );

      return response;
    }
});

/**
 * 费用销账列表
 */
export const billDisbursementWirteOffDB = selectorFamily({
  key: 'billDisbursementWirteOffDB',
  get:
    (billingId: string) =>
    async ({ get }) => {
      if (!billingId) return {};
      get(billTokenAtom(billingId));
      // @todo api更改
      const { response } = await apiWeb.webFinancialBillingFinancialbillingdisbursementGetwriteoffpageCreate({ billingId, page: 1, size: 10000 });
      return response?.data;
    }
});

/**
 * 候选项添加到费用列表
 */
export const useInsertDisbursementServiceFee = (billingId: string) => {
  return useRecoilCallback(({ snapshot, refresh }) => async (values: Row) => {
    if (isEmpty(billingId)) return;
    // const baseInfo = await snapshot.getPromise(billBaseInfoDB(billingId));
    await apiWeb.webFinancialBillingFinancialbillingdisbursementAddcandidatesexpensetobillingCreate(
      { ...values, billingId },
      { headers: { ...HeaderNoProcess } }
    );
    refresh(billDisbursementDB(billingId));
    refresh(billDisbursementSubtotalDB(billingId));
    refresh(billDisbursementCandidateDB(billingId));
  });
};

export const billDisbursementSubtotalDB = selectorFamily({
  key: 'billDisbursementSubtotalDB',
  get:
    (billingId: string) =>
    async ({ get }) => {
      if (!billingId) return [];
      get(billTokenAtom(billingId));
      // @todo api更改
      const { response } = await apiWeb.webFinancialBillingFinancialbillingdisbursementGetsubtotalpageCreate(
        { id: billingId },
        { headers: { ...HeaderNoProcess } }
      );

      return { data: mapping(response!, { key: () => Math.random().toString() }) };
    }
});

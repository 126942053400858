import { useContext, useEffect, useMemo, useState, forwardRef } from 'react';
import { CaretRightOutlined, PauseOutlined } from '@ant-design/icons';
import IconFont from '@/components/iconfont';
import { theme, Typography } from 'antd';
import { css, cx } from '@emotion/css';
import { Time, timeBtnClass, timerwrap } from './style';
import { TimerContext } from '../BitzTimerContext';
import dayjs from 'dayjs';
import './index.less';

/**
 * - description: 格式化毫秒数
 * - param {number} duration 毫秒数
 * - return `天.时:分:秒`
 */
function formatMilliseconds(duration: number) {
  if (isNaN(duration)) return '00:00:00';
  const days = Math.floor(duration / (1000 * 3600 * 24));
  duration %= 1000 * 3600 * 24;
  const hours = Math.floor(duration / (1000 * 3600));
  duration %= 1000 * 3600;
  const minutes = Math.floor(duration / (1000 * 60));
  duration %= 1000 * 60;
  const seconds = Math.floor(duration / 1000);
  const padded = (num) => (num < 10 ? '0' + num : num);
  if (days <= 0) return `${padded(hours)}:${padded(minutes)}:${padded(seconds)}`;
  return `${days}.${padded(hours)}:${padded(minutes)}:${padded(seconds)}`;
}

interface BitzTopTimerProps {
  source?: any;
  clock?: boolean;
  clockClick?: () => void;
  loadFun?: () => void;
}

type Ref = HTMLDivElement;
const IDLE_LIMIT = 1000 * 60 * 5; // 5 minutes in milliseconds

export const BitzTopTimer = forwardRef<Ref, BitzTopTimerProps>((props, ref) => {
  const { clockClick } = props;
  const { token } = theme.useToken();
  const [time, setTime] = useState(0);

  const { runningId, runningTimer, runningTimerState, workInfo, onGlobalStart, onGlobalPause, getCurDayMyWorkList } = useContext(TimerContext);

  const [lastActivity, setLastActivity] = useState(dayjs().valueOf());

  // Function to be executed after idle time exceeds threshold
  const handleIdleTimeout = () => {
    getCurDayMyWorkList();
    console.log('超过5分钟无操作，已执行超时方法。');
  };

  const updateActivity = () => {
    setLastActivity(dayjs().valueOf());
  };

  const checkIdleTime = () => {
    const now = dayjs().valueOf();
    const idleDuration = now - lastActivity;
    if (idleDuration >= IDLE_LIMIT) {
      handleIdleTimeout();
      updateActivity();
    }
  };

  // Use visibilitychange to detect tab activation
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        checkIdleTime();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [lastActivity]);

  useEffect(() => {
    const interval = setInterval(() => {
      checkIdleTime();
    }, 1000);

    return () => clearInterval(interval);
  }, [lastActivity]);

  useEffect(() => {
    window.addEventListener('mousemove', updateActivity);
    window.addEventListener('keypress', updateActivity);

    return () => {
      window.removeEventListener('mousemove', updateActivity);
      window.removeEventListener('keypress', updateActivity);
    };
  }, []);

  useEffect(() => {
    if (!runningId) {
      getCurDayMyWorkList();
    }
  }, []);

  useEffect(() => {
    setTime(runningTimer);
  }, [runningTimer]);

  // const queryClient = useQueryClient()
  // const refreshFun = () => {
  //   // 刷新工时列表
  //   queryClient.refetchQueries({ queryKey: ['Works', 'WorkHourList', 'DayPanel'], type: 'active' })
  //   // 刷新刷新计时弹窗
  //   queryClient.refetchQueries({ queryKey: ['Base', 'BnHeadTimer', 'TimerModal'], type: 'active' })
  // }

  const onPause = () => {
    onGlobalPause(runningId);
  };

  const onStart = () => {
    // 这里需要判断workinfo是否有数据
    if (workInfo?.id) {
      onGlobalStart(workInfo?.id);
    } else {
      onGlobalStart(undefined);
    }
  };

  const bgcClass = css`
    background-color: ${token.colorPrimary};
    border-color: ${token.colorPrimary};
    pointer-events: auto;
  `;
  const dataSource = useMemo(() => workInfo, [workInfo]);

  const [expanded, setExpanded] = useState(false);
  const clientName = `${dataSource?.clientId ?? '-'} - ${dataSource?.clientName ?? '-'}`;
  const caseName = `${dataSource?.caseId ?? '-'} - ${dataSource?.caseName ?? '-'}`;

  const popContent = (
    <div className="popPanel">
      <div>
        <Typography.Text type="secondary">客户：</Typography.Text>
        <Typography.Text>{clientName}</Typography.Text>
      </div>
      <div>
        <Typography.Text type="secondary">案件：</Typography.Text>
        <Typography.Text>{caseName}</Typography.Text>
      </div>
      <div>
        <Typography.Text type="secondary">工时描述：</Typography.Text>
        <div>
          <Typography.Paragraph
            ellipsis={{
              rows: 3,
              expandable: 'collapsible',
              expanded,
              onExpand: (_, info) => setExpanded(info.expanded)
            }}
            // copyable
          >
            {dataSource?.narrative}
          </Typography.Paragraph>
        </div>
      </div>
    </div>
  );

  const isStartting = workInfo?.timerState !== 'Start';

  const startOrPauseIcon = () => {
    if (isStartting) {
      return <CaretRightOutlined className={cx(timeBtnClass, bgcClass)} onClick={onStart} />;
    }
    return <PauseOutlined className={cx(timeBtnClass, bgcClass)} onClick={onPause} />;
  };

  return (
    <div className="bitzTopTimer" ref={ref}>
      <div className={timerwrap(true, token.colorBgBase)}>
        {startOrPauseIcon()}
        <IconFont
          className={css`
            margin: 0 6px;
            cursor: pointer;
          `}
          color="#86909C"
          size={18}
          name="jishiqi"
          onClick={clockClick}
        />
        <Time
          primary
          className={css`
            margin-right: 6px;
          `}
          onClick={clockClick}
        >
          {formatMilliseconds(time)}
        </Time>
      </div>
    </div>
  );
});

// 客户说取消气泡显示案件记录
// isStartting ?
//   <Popover overlayStyle={popoverStyle} content={popContent} className="popPanel" placement={'bottomLeft'}>
//     <div className={timerwrap(true, token.colorBgBase)} >
//       {
//         !isStartting ? <CaretRightOutlined className={cx(timeBtnClass, bgcClass)} onClick={onStart} /> :
//           <PauseOutlined className={cx(timeBtnClass, bgcClass)} onClick={onPause} />
//       }
//       <IconFont
//         className={css`
//     margin: 0 6px;
//     cursor: pointer;
//   `}
//         color="#86909C"
//         size={18}
//         name="jishiqi"
//         onClick={clockClick}

//       />
//       <Time
//         primary
//         className={css`
//       margin-right: 6px;
//     `}
//         onClick={clockClick}
//       >
//         {formatMilliseconds(time)}
//       </Time>
//     </div>
//   </Popover>
//   :

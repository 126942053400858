import { compact, isFalsy, isPowerFalsy } from '@/v2/utils';
import apiWeb from '@/services/Web';
import { CreateFinancialBillingInput, GetFinancialBillingTotalOutput } from '@/services/WebApi';
import { widgetModuleAtom } from '@/store/v2/base';
import { mapping, picking, run, upArray } from '@/utils';
import dayjs from 'dayjs';
import { difference, isEmpty, mapValues, uniqBy, forEach } from 'lodash-es';
import { atom, atomFamily, RecoilValueReadOnly, selector, selectorFamily, useRecoilCallback, useRecoilValue } from 'recoil';
import {
  billTimesheetServiceFeeDB,
  billTimesheetWriteoffDB,
  billTimesheetCandidateDB,
  billTimesheetTotalDB
} from './TimesheetServiceFee/TimesheetServiceFee.store';
import { HeaderNoProcess, WidgetModule } from '@/constants';
import { billDisbursementCandidateDB, billDisbursementDB } from './Disbursement/Disbursement.store';
import { FormInstance } from 'antd';
import { taxRatesByOfficeIdStore } from '@/v2/store/store';
import { exchangeListDB } from './Exchange/store';
import { useUnwrapRecoilValueLoadable } from '@/v2/hooks';
import { billFixedServiceFeeDB } from './FixedServiceFee/FixedServiceFee.store';
import { billListDB } from '../BillList/BillList.store';
import { cache$db } from '@/hooks/useDict';

export const baseTaxRateStore = selector({
  key: 'baseTaxRateStore',
  get: async () => {
    const { response } = await apiWeb.webFinancialBaseFinancialtaxrateListCreate({});
    const taxRate = (response?.[0]?.taxRate as unknown as string) ?? '6';
    return parseInt(taxRate, 10);
  }
});

/**
 * 用于批量设置时关联数据更新
 */
export const billTokenAtom = atomFamily({
  key: 'billDBAtom',
  default: () => Math.random()
});

/**
 * 当前编辑id
 */
export const billIdAtom = atom<string>({
  key: 'billIdAtom',
  default: ''
});

/**
 * 账单基本信息状态
 */
export const isBillDraftAtom = atom({
  key: 'isBillDraftAtom',
  default: false
});

/**
 * 根据账单申请表单状态获取请求api
 */
export const requestByBillStatusStore = selectorFamily({
  key: 'statusDraftAtom',
  get:
    (id?: string) =>
    ({ get }) => {
      const apiStatusMap = {
        'draft.save': apiWeb.webFinancialBillingFinancialbillingPostdraftCreate,
        'draft.update': apiWeb.webFinancialBillingFinancialbillingPutdraftUpdate,
        'active.save': apiWeb.webFinancialBillingFinancialbillingPostCreate,
        'active.update': apiWeb.webFinancialBillingFinancialbillingPutUpdate
      };
      const isDraft = get(isBillDraftAtom);
      const stat = isDraft ? 'draft' : 'active';
      const method = id ? 'update' : 'save';
      const status = `${stat}.${method}`;
      return apiStatusMap[status];
    }
});

/**
 * 账单基本信息
 */
export const billBaseInfoDB = selectorFamily({
  key: 'billBaseInfoDB',
  get:
    (id: string) =>
    async ({ get }) => {
      if (!id)
        return {
          data: {
            // currency: 'CNY',
            exchangeRate: '1',
            workRangeDate: [dayjs().startOf('month'), dayjs().endOf('month')],
            billingDate: dayjs(),
            paymentTerms: 'None',
            isBatchBilling: 'N',
            isCrossBorderBilling: 'N',
            isBadDebts: 'N'
          }
        };
      // const { response = {} } = await apiWeb.webFinancialBillingFinancialbillingGetCreate(id);
      const { response = {} } = await apiWeb.webFinancialBillingFinancialbillingGetforeditCreate(id);
      const { startDate, endDate, caseId, clientId } = response?.data ?? {};
      const data = { ...response?.data, workRangeDate: [dayjs(startDate), dayjs(endDate)] };
      return { data };
    }
});

/**
 * 新建或更新表单基本信息
 */
export const useSaveOrUpdateBillBaseInfo = () => {
  return useRecoilCallback(({ snapshot, refresh }) => async (values: CreateFinancialBillingInput & { workRangeDate }, billingId?: string) => {
    const request = await snapshot.getPromise(requestByBillStatusStore(billingId));
    if (isEmpty(values)) return {};
    const { workRangeDate, ...rest } = values;
    const body = { ...rest };

    if (workRangeDate) {
      const [startDate, endDate] = workRangeDate;
      body.startDate = dayjs(startDate).format('YYYY-MM-DD HH:mm:ss');
      body.endDate = dayjs(endDate).format('YYYY-MM-DD HH:mm:ss');
    }

    const { response } = billingId ? await request(billingId, body, { headers: { ...HeaderNoProcess } }) : await request(body);
    billingId && refresh(billBaseInfoDB(billingId));
    // // todo 校验案件名称修改后是否已变化，变化后将初始化服务与费用等统计信息
    return { id: response, values };
  });
};

/**
 * 仅保存基础信息
 * @param billingId
 * @returns
 */
export const useSaveOnlyBaseInfo = (billingId) => {
  return useRecoilCallback(({ snapshot }) => async (values: Row) => {
    if (isPowerFalsy(values)) return;
    const { data } = await snapshot.getPromise(billBaseInfoDB(billingId));
    const request = await snapshot.getPromise(requestByBillStatusStore(billingId));
    await request(billingId, { ...data, ...values });
  });
};

/**
 * 上限信息
 */
export const billUpperLimitDB = selectorFamily({
  key: 'billUpperLimitDB',
  get: (billingId: string) => async () => {
    const { response } = await apiWeb.webFinancialBillingFinancialbillingupperlimitGetpageCreate({ billingId });
    return response!;
  }
});

/**
 * 获取每个栏目的上限信息
 */
export const billUpperLimitScopeStore = selectorFamily({
  key: 'billUpperLimitScopeStore',
  get:
    ({ billingId, scope }: Row) =>
    async ({ get }) => {
      const { data } = get(billUpperLimitDB(billingId));
      const { module } = get(widgetModuleAtom(WidgetModule.BillWriteOffModal));
      return data?.find?.((item) => {
        return item.scope === (scope ?? module);
      });
    }
});

export const useSaveUpperLimit = (billingId: string) => {
  return useRecoilCallback(({ snapshot, reset, refresh }) => async (values: Row, scope) => {
    const { module } = await snapshot.getPromise(widgetModuleAtom(WidgetModule.BillWriteOffModal));
    const values$ = mapping(values, { amount: 'amount::join' });

    values$?.id
      ? await apiWeb.webFinancialBillingFinancialbillingupperlimitPutUpdate(values$?.id, { ...values$, scope, billingId })
      : await apiWeb.webFinancialBillingFinancialbillingupperlimitPostCreate({ ...values$, scope, billingId });

    refresh(billUpperLimitDB(billingId));
    refresh(billBaseInfoDB(billingId));
    refresh(billTotalFeeDB(billingId));
  });
};

export const useRemoveUpperLimit = (billingId: string) => {
  return useRecoilCallback(({ snapshot, refresh }) => async (id: string) => {
    await apiWeb.webFinancialBillingFinancialbillingupperlimitDeleteDelete(id, { id });
    refresh(billUpperLimitDB(billingId));
    refresh(billBaseInfoDB(billingId));
    refresh(billTotalFeeDB(billingId));
  });
};

/**
 * 销账
 * 支持批量销账和全局销账
 * widgetModuleAtom(WidgetModule.BillWriteOffModal): {
 *  open: 打开窗口
 *  module: 当前操作模块，module / 'globla',
 *  ids: [] 当前ids, 单项也是传数组
 * }
 */
export const useSaveWriteOff = (billingId: string) => {
  return useRecoilCallback(({ snapshot, set, reset, refresh }) => async (values: Row, extra?: Row) => {
    const widgetAtom = widgetModuleAtom(WidgetModule.BillWriteOffModal);
    const { module, ids } = await snapshot.getPromise(widgetAtom);
    const { [module]: currentIds, ...extraIds } = await snapshot.getPromise(billIdsAtom(billingId));
    const values$ = mapping(values, { writeOffType: 'writeOffType::join' });

    const apiMap = {
      [WidgetModule.BillTimesheetServiceFeeModule]: apiWeb.webFinancialBillingFinancialbillingtimesheetservicefeeWriteofftimesheetsCreate,
      [WidgetModule.BillTimesheetServiceFeeCandidateModule]: apiWeb.webFinancialBillingFinancialbillingtimesheetservicefeeWriteoffcandidatestimesheetsCreate,
      [WidgetModule.BillDisbursementCandidateModule]: apiWeb.webFinancialBillingFinancialbillingdisbursementWriteoffcandidatesexpensesCreate,
      [WidgetModule.BillPage]: apiWeb.webFinancialBillingFinancialbillingBatchwriteoffbillingitemsCreate
    };

    const refreshMap = {
      [WidgetModule.BillTimesheetServiceFeeModule]: [billTimesheetServiceFeeDB(billingId), billTimesheetWriteoffDB(billingId), billTotalFeeDB(billingId)],
      [WidgetModule.BillTimesheetServiceFeeCandidateModule]: [
        billTimesheetCandidateDB(billingId),
        billTimesheetWriteoffDB(billingId),
        billTotalFeeDB(billingId)
      ],
      [WidgetModule.BillDisbursementCandidateModule]: [billDisbursementCandidateDB(billingId)],
      [WidgetModule.BillPage]: [
        billTimesheetServiceFeeDB(billingId),
        billTimesheetWriteoffDB(billingId),
        billTimesheetCandidateDB(billingId),
        billTimesheetWriteoffDB(billingId),
        billDisbursementCandidateDB(billingId),
        billTotalFeeDB(billingId)
      ]
    };

    let adjust$ = {};

    run(module === WidgetModule.BillPage, () => {
      adjust$ = {
        timesheetIds: [...(ids?.[WidgetModule.BillTimesheetServiceFeeModule] ?? []), ...(ids?.[WidgetModule.BillTimesheetServiceFeeCandidateModule] ?? [])],
        disbursementIds: [...(ids?.[WidgetModule.BillDisbursement] ?? []), ...(ids?.[WidgetModule.BillDisbursementCandidateModule] ?? [])]
      };
    });

    // 销账
    if (apiMap[module]) {
      // 请求
      await apiMap[module]({ ...values$, ...adjust$, ids, isWriteOff: 'Y' });
      // 更新数据
      refreshMap[module]?.forEach?.(refresh);
      // 关闭弹窗
      set(widgetAtom, { open: false });
      // @todo 全局ids删除销账id
      set(billIdsAtom(billingId), extraIds);
    }
  });
};

/**
 * 账单统计
 * /api/web/financial/billing/financialbilling/getbillingtotallist
 */

export const billTotalFeeDB = selectorFamily({
  key: 'billTotalDB',
  get:
    (billingId: string) =>
    async ({ get }) => {
      if (!billingId) return void 0;
      const { response } = await apiWeb.webFinancialBillingFinancialbillingGetbillingtotallistCreate({ id: billingId }, { headers: { ...HeaderNoProcess } });
      return response!;
    }
});

export interface TotalFeeProps extends GetFinancialBillingTotalOutput {
  // 调整额比例（净服务费调整比例）
  totalAdjustmentRatio: number;
  // 税率
  taxRate: number;
  // 最终净服务费
  lasestNetServiceFee: number;

  // 税前金额
  totalExclusiveAmount: number;

  // 是否超额
  isExceededSubTotal: boolean;
}

export const billTotalFeeByModuleStore = selectorFamily({
  key: 'billTotalFeeByModuleStore',
  get:
    ({ billingId, module }: { billingId: string; module: WidgetModule }) =>
    async ({ get }) => {
      // const [response] = get(waitForAll([billTotalFeeDB(billingId)]));
      const response = get(billTotalFeeDB(billingId));

      const result = response?.find(({ scope }) => scope === module) ?? {};
      const result$mapping = mapping(result, {
        isIncludeTaxFee: 'isIncludeTaxFee::toIs',
        isSetAsFixedFee: 'isSetAsFixedFee::toIs'
      });

      const { isIncludeTaxFee, upperLimitAmount = 0, totalSubtotal = 0, totalAmount = 0, totalTaxAmount = 0, totalExclusiveAmount = 0 } = result$mapping;

      /**
       * 净服务上限
       * - 税前 + 不固定(上限) : upperLimitAmount
       * - 税前 + 固定: upperLimitAmount
       * - 税后 + 不固定(上限) : 0
       * - 税后 + 固定 : 0
       */
      const upperLimitNetService = !isIncludeTaxFee ? upperLimitAmount : 0;
      /**
       * 净服务费是否超额
       */
      const isExceededNetSerive = upperLimitNetService > 0 && upperLimitNetService < totalExclusiveAmount;

      // console.log(module, 'isExceededNetSerive', isExceededNetSerive, upperLimitNetService, totalExclusiveAmount, {
      //   isIncludeTaxFee,
      //   // isSetAsFixedFee,
      //   upperLimitNetService,
      //   upperLimitAmount,
      //   totalExclusiveAmount
      // });

      /**
       * 最终经服务费
       * 根据上限情况，取上限或者实际金额
       */
      const lasestNetServiceFee = isExceededNetSerive ? upperLimitNetService : totalExclusiveAmount;

      /**
       * 合计上限
       * - 税前 + 不固定(上限) : 0
       * - 税前 + 固定: 0
       * - 税后 + 不固定(上限) : upperLimitAmount
       * - 税后 + 固定 : upperLimitAmount
       */
      const upperLimitSubTotal = isIncludeTaxFee ? upperLimitAmount : 0;
      /**
       * 上限是否超过合计
       */
      const isExceeedSubTotal = upperLimitSubTotal > 0 && upperLimitSubTotal < totalSubtotal;

      const lasestTotalSubtotal = lasestNetServiceFee + totalTaxAmount;

      return { ...result$mapping, upperLimitNetService, isExceededNetSerive, upperLimitSubTotal, isExceeedSubTotal, lasestNetServiceFee, lasestTotalSubtotal };
    }
});

/**
 * 批量Ids, 按module
 */
export const billIdsAtom = atomFamily<Row, string>({
  key: 'billIdsAtom',
  default: {}
});

export const useBillIds = (billingId: string, module: string) => {
  const data = useRecoilValue(billIdsAtom(billingId));
  const setBillIds = useRecoilCallback(({ snapshot, set }) => async (ids: string[]) => {
    const data = await snapshot.getPromise(billIdsAtom(billingId));
    set(billIdsAtom(billingId), { ...data, [module]: ids });
  });

  const billIds = data[module] ?? [];
  const clearBillIds = () => setBillIds([]);
  const removeBillIds = (ids: string | string[]) => {
    const ids$ = upArray(ids);
    const ids$result = difference(billIds, ids$);
    setBillIds(ids$result);
  };

  return { billIds, setBillIds, clearBillIds, removeBillIds };
};

/**
 * 账单全局批量销账
 */
export const useSaveBatchVBillWriteoff = (billingId: string) => {
  return useRecoilCallback(({ set, snapshot }) => async (values: Row) => {
    const ids = await snapshot.getPromise(billIdsAtom(billingId));
    const ids$ = picking(ids, { timesheetIds: WidgetModule.BillFixedServiceFeeModule, disbursementIds: WidgetModule.BillDisbursement });
    await apiWeb.webFinancialBillingFinancialbillingBatchwriteoffbillingitemsCreate({ ...ids$, ...values });
    set(billTokenAtom(billingId), Math.random());
  });
};

/**
 * 批量设置税率
 */
export const useBatchTaxRate = (billingId: string) => {
  return useRecoilCallback(({ snapshot, refresh, set }) => async (values: Row) => {
    const ids = await snapshot.getPromise(billIdsAtom(billingId));
    const ids$ = picking(ids, {
      fixedServiceFeeIds: WidgetModule.BillFixedServiceFeeModule,
      timesheetIds: WidgetModule.BillTimesheetServiceFeeModule,
      disbursementIds: WidgetModule.BillDisbursement
    });
    await apiWeb.webFinancialBillingFinancialbillingBatchchangebillingitemstaxrateCreate({ ...ids$, ...values });
    refresh(billFixedServiceFeeDB(billingId));
    refresh(billTimesheetServiceFeeDB(billingId));
    refresh(billDisbursementDB(billingId));
    refresh(billTotalFeeDB(billingId));
  });
};

/**
 * 本位币换算
 */
export const useExchangeRate = (form: FormInstance) => {
  return useRecoilCallback(() => async (target: string) => {
    const base = 'CNY';
    let rate: number | void;

    // 修改汇率时，清空其他汇率
    form.setFieldValue('exchangeRate', void 0);

    // 货币为本位币时，汇率为1
    if (base === target) {
      rate = 1;
    } else {
      const { response } = await apiWeb.webFinancialBaseForeignexchangeratesGetrefertoforeignexchangerateCreate(
        { base, target, amount: 1 },
        { headers: { ...HeaderNoProcess } }
      );
      // 汇率为0时，取默认汇率
      rate = response?.targetRate || void 0;
      form.setFieldValue('exchangeRate', rate);
    }

    return rate;
  });
};

// 状态计算
export const useLoadBillingDB = (config: {
  billingId: string;
  // 状态key
  dataIndex?: string;
  module: WidgetModule;
  dbselector: (billingId: string) => RecoilValueReadOnly<any>;
}) => {
  const { billingId, dataIndex, dbselector, module } = config;

  const store = selector({
    key: `db/${module}/${billingId}`,
    get: ({ get }) => {
      const { data: baseInfo } = get<Row>(billBaseInfoDB(billingId));
      const { data } = get(dbselector(billingId));

      const start = dayjs(baseInfo?.startDate);
      const end = dayjs(baseInfo?.endDate);

      const status = (record) => {
        if (!dataIndex) return;
        const { [dataIndex]: serviceDate } = record;
        const between = dayjs(serviceDate).isBetween(start, end);
        return between ? { value: 'none' } : { value: 'warning', label: '超出账单周期' };
      };

      const result = mapping(data, {
        [`${dataIndex}::status`]: status,
        STATUS: (record) => status(record)?.value
      });

      return result;
    }
  });
  return useUnwrapRecoilValueLoadable(store);
};

/**
 * 获取基于账单本位币全局汇率
 */
export const billExchangeRatesDB = selectorFamily({
  key: 'billExchangeRateDB',
  get:
    (billingId: string) =>
    async ({ get }) => {
      const { data } = get<Row>(billBaseInfoDB(billingId));
      const { response } = await apiWeb.webFinancialBaseForeignexchangeratesGetrefertoforeignexchangerateCreate(
        { base: data?.currency },
        { headers: { ...HeaderNoProcess } }
      );
      const base = run(data?.currency, (currency) => ({ [currency]: 1 }), {});
      return { ...base, ...response?.rates };
    }
});

/**
 * 继承全局，获取当前账单的所有可能的汇率
 */
export const billExchangeMapStore = selectorFamily({
  key: 'billExchangeDB',
  get:
    (billingId: string) =>
    async ({ get }) => {
      // 账单汇率
      const response = get(exchangeListDB(billingId));

      // 系统默认值
      const rates = get(billExchangeRatesDB(billingId));

      const result = response?.reduce((temp, item: Row) => {
        const exchangeRate = 1 / (item.exchangeRate as number);
        temp[item.targetCurrency] = { ...item, exchangeRate };
        return temp;
      }, {});

      return {
        ...mapValues(rates, (value) => {
          return { exchangeRate: value };
        }),
        ...result
      };
    }
});

/**
 * 反向汇率
 */
export const billReverseExchangeMapStore = selectorFamily({
  key: 'reverseExchangeMapStore',
  get:
    (billingId: string) =>
    async ({ get }) => {
      const data = get(billExchangeMapStore(billingId));
      return mapValues(data, (value: Row) => {
        return { exchangeRate: 1 / value.exchangeRate };
      });
    }
});

/**
 * 账单办公室税率
 */
export const billOfficeTaxRateStore = selectorFamily({
  key: 'officeTaxRateStore',
  get:
    (billingId: string) =>
    async ({ get }) => {
      const baseInfo = get(billBaseInfoDB(billingId));
      // @ts-ignore
      const officeId = baseInfo?.data?.officeId;
      return get(taxRatesByOfficeIdStore(officeId));
    }
});

/**
 * 获取默认的税率
 */
export const billDefaultOfficeTaxStore = selectorFamily({
  key: 'officeTaxRateStore',
  get:
    (billingId: string) =>
    async ({ get }) => {
      const data = get(billOfficeTaxRateStore(billingId));
      return data?.[0];
    }
});

/**
 * 获取本币与办公室的关系
 * @todo
 */
export const officeWithCurrencyDB = selector({
  key: 'officeWithCurrencyDB',
  get: async ({ get }) => {
    const { response } = await apiWeb.webFinancialBaseFinancialsettingsFinancialcurrencysettingsforeditList();
    return response ?? {};
  }
});

export const useGetCurrencyByOfficeId = () => {
  return useRecoilCallback(({ snapshot }) => async (officeId: string) => {
    const { defaultCurrency, currencyList } = await snapshot.getPromise(officeWithCurrencyDB);
    const matcher = currencyList?.find?.((item: Row) => item.officeId === officeId) ?? {};
    // 若办公室没有配置本币，则读取租户配置的本币，若租户也没有配置，则读取默认的CNY
    return matcher?.currency ?? defaultCurrency ?? 'CNY';
  });
};

/**
 * 付款方列表
 */
export const payersDB = selectorFamily({
  key: 'payerDB',
  get:
    (params: { clientId: string[] }) =>
    async ({ get }) => {
      if (isFalsy(params)) return [];
      if (isFalsy(params?.clientId)) return [];
      // const { response } = await apiWeb.webCustomerPayerinfoGetpayerinfopageCreate({ ...parmas$, page: 1, size: 99999 });
      const { response } = await apiWeb.webSysSysdataselectGetclientpaymentCreate({ ...compact(params), page: 1, size: 99999 });
      return mapping(response?.data, { label: 'displayName ?? name', value: 'id' });
    }
});

/**
 * 获取单个付款方信息，从付款方列表派生而来
 */
export const paymentStore = selectorFamily({
  key: 'paymentStore',
  get:
    (params: { clientId: string[]; paymentId: string }) =>
    async ({ get }) => {
      const { paymentId, ...rest } = params;
      if (!paymentId) return {};
      const payers = get(payersDB(rest));
      return payers.find((item) => item.value === paymentId);
    }
});

/**
 * 收款账户
 * 从付款方派生而来
 */
export const banksByPaymentStore = selectorFamily({
  key: 'banksByPaymentStore',
  get:
    (params: { clientId: string[]; paymentId: string }) =>
    async ({ get }) => {
      const { paymentId } = params;
      if (!paymentId) return [];
      const { entityInvoiceTitles } = get(paymentStore(params));
      return mapping(entityInvoiceTitles, { label: ({ bank, bankAccount }) => `${bank} - ${bankAccount}`, value: 'bankAccount' });
    }
});

/**
 * 账单管理人
 * // 其实跟案件相关
 */
export const partnerDB = selectorFamily({
  key: 'partnerDB',
  get:
    (caseId: string) =>
    async ({ get }) => {
      const { response } = await apiWeb.webSysSysdataselectGetcaselawyerCreate({
        caseId: [caseId],
        // isOnlyBillingLawyer: false,
        page: 1,
        size: 1000
      });
      return mapping(response?.data, { label: 'displayName', value: 'userId' });
    }
});

/**
 * 账单联系人
 */
export const entityContactDB = selectorFamily({
  key: 'entityContactDB',
  get: (params: Row) => async () => {
    if (isEmpty(params)) return [];
    if (!(params?.clientId && params?.caseId)) return [];
    const { response: clientContact } = await apiWeb.webCustomerEntitycontactGetpageCreate({ clientId: params.clientId, page: 1, size: 99999 });
    const { response: caseContact } = await apiWeb.webCustomerEntitycontactGetpageCreate({ caseId: params.caseId, page: 1, size: 99999 });
    const data = uniqBy([...(clientContact?.data ?? []), ...(caseContact?.data ?? [])], 'id');
    return mapping(data, { label: 'displayName ?? name', value: 'id' });
  }
});

/**
 * 收款账号
 * - 跟随办公室
 */
export const billingBankDB = selectorFamily({
  key: 'entityContactDB',
  get: (params: Row) => async () => {
    const { response } = await apiWeb.webSysSysdataselectGetbranchofficeCreate({ ...compact(params), page: 1, size: 99999 });
    return mapping(response?.data ?? response, { label: ({ bankName, bankAccount }) => `${bankName} - ${bankAccount}`, value: 'id' });
  }
});

/**
 * 选择器，案件
 */
export const caseSelectorDB = selectorFamily({
  key: 'selector/caseSelectorDB',
  get: (params: Row) => async () => {
    const { response } = await apiWeb.webSysSysdataselectGetcaseCreate({ ...compact(params), page: 1, size: 30 });
    const result = mapping(response?.data ?? response, { label: 'displayName ?? name', value: 'id' });

    const cacheMap = await cache$db.getMapValue('caseSelectorDB');

    return result;
  }
});

/**
 * 客户选择器
 */
export const clientSelectorDB = selectorFamily({
  key: 'selector/clientSelectorDB',
  get: (params: Row) => async () => {
    const { response } = await apiWeb.webSysSysdataselectGetclientCreate({ ...params, page: 1, size: 30 });
    return mapping(response?.data ?? response, { label: 'displayName ?? name', value: 'id' });
  }
});

export const useSubmitBilling = () => {
  return useRecoilCallback(
    ({ set, snapshot, refresh }) =>
      async (billingId: string) => {
        const baseInfo = await snapshot.getPromise(billBaseInfoDB(billingId));
        const total = await snapshot.getPromise(billTotalFeeDB(billingId));
        const res = await apiWeb.webFinancialBillingFinancialbillingSubmitbillingCreate({
          id: billingId,
          isEnabled: true,
          ...(baseInfo?.data as Row)
        });

        // 刷新列表数据
        refresh(billListDB(WidgetModule.BillMyList));
        refresh(billListDB(WidgetModule.BillAudit));
        refresh(billListDB(WidgetModule.BillManage));
        refresh(billBaseInfoDB(billingId));
      },
    []
  );
};

import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useControllableValue, useDebounceFn, useUpdateEffect } from 'ahooks';
import {SelectProps, Space, Select, Button, Spin, Typography} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useMutation } from '@tanstack/react-query';
import { show } from '@ebay/nice-modal-react';
import apiWeb from '@/services/Web';
import { ClientOutput, GetClientInput } from '@/services/WebApi';
import { MyContext } from '@/components/BitzQueryFilter2/BitzQueryFilterContext';
import BitzQueryFilter2 from '@/components/BitzQueryFilter2';
import curStyle from './index.module.less';
// import CaseModal from './ClientModal'
import ClientModal from './ClientModal';
import { css } from '@emotion/css';
import { split, isArray, uniqWith } from 'lodash-es';
import { useImmer } from 'use-immer';

type TagRender = SelectProps['tagRender'];
const { Text } = Typography;

interface defaultOptionsItem {
  name?: string;
  id: string;
}

interface BnSelectClientProps extends Omit<SelectProps, 'options'> {
  defaultOptions?: defaultOptionsItem[];
  /**
   * 是否启用Fluent组件模式
   */
  fluent?: boolean;

  /**
   * 查询入参
   */
  reqParams?: GetClientInput;
  /**
   * 检索图标是否展示
   */
  searchIcon?: boolean;
}

const BnClientSelect: React.FC<BnSelectClientProps> = (props) => {
  const { dispatch, advanced = false } = useContext(MyContext);
  const { value, onChange, onSelect, reqParams, searchIcon = true, ...rest } = props;
  const [rows, setRows] = useState([]);
  const [state, setState] = useControllableValue<SelectProps['value']>(props);
  const [optArr, setOptArr] = useControllableValue<ClientOutput[]>(props, {
    defaultValue: [],
    defaultValuePropName: 'defaultOptions',
    valuePropName: 'options',
    trigger: 'onChangeOptions'
  });
  const [searchValue, setSearchValue] = useState<string>();
  const [params, setParams] = useImmer<GetClientInput>({});
  const [pagination, setPagination] = useImmer({
    page: 1,
    size: 10
  });

  useUpdateEffect(() => {
    setOptArr(props?.defaultOptions ?? []);
  }, [props?.defaultOptions]);

  /**
   * 初始化的时候如果有 defaultOptions
   * 需要进行数据组装 赋值给rows, 因为弹窗需要显示已选中项
   */
  useEffect(() => {
    if (optArr?.length) {
      const optList = optArr?.map((v) => ({
        name: v.name
      }));
      if (props?.mode === 'multiple') {
        const list = optArr?.filter((item) => {
          return value?.includes(item.id);
        });
        // @ts-ignore
        setRows(list);
      } else {
        const optList = optArr.find((v) => v?.id === value);
        // @ts-ignore
        setRows([optList]);
      }
    }
  }, []);

  useUpdateEffect(() => {
    mutation.mutate();
  }, [pagination, params]);

  const id = useMemo(() => {
    if (props.id) {
      if (props.id?.indexOf('advanced') !== -1) {
        const list = split(props.id, '_');
        return list[list.length - 1];
      }
      return props.id;
    }
    return '';
  }, [props.id]);

  useEffect(() => {
    if (id) {
      dispatch?.({
        type: 'update',
        name: id,
        value: props?.value
      });
    }
  }, [props?.value, id]);

  const mutation = useMutation({
    mutationFn: () => {
      return apiWeb.webCustomerClientGetclientpageCreate({
        ...reqParams,
        ...params,
        ...pagination,
        toOrderBy: 'createTime desc'
      });
      // }
    },
    onSuccess: (data) => {
      if (data?.success) {
        const list = data?.response?.data ?? [];
        const list2 = optArr?.concat(list);
        const newList = uniqWith(list2, (item1, item2) => item1?.id === item2?.id);
        setOptArr(newList);
      }
    }
  });

  const { run, cancel } = useDebounceFn((value) => {
    setParams((draft) => {
      draft.filter = value;
    });
    setPagination((draft) => {
      draft.page = 1;
    });
    setOptArr([]);
  });

  const dropdownVisibleChange = (open: boolean) => {
    if (open && !optArr?.length) {
      mutation.mutate();
    }
  };

  const handleSelect = (value, option) => {
    onSelect?.(value, option);
  };

  const handleChange: SelectProps['onChange'] = (value, option) => {
    setState(value, option);
    if (isArray(option)) {
      // @ts-ignore
      setRows(option);
    } else {
      // @ts-ignore
      option && setRows([option]);
    }

    if (id && dispatch) {
      let valueName;
      // let valueName = isArray(option) ? option?.map((v) => v[fieldNames?.label ?? 'label']).join(',') : option[fieldNames?.label ?? 'label']
      if (isArray(option)) {
        valueName = option?.map((v) => v?.name).join(',');
      } else {
        valueName = option?.name;
      }
      dispatch?.({
        type: 'update',
        name: id,
        valueName
      });
    }
  };

  const openSearchModal = () => {
    show(ClientModal, {
      rows: rows?.filter((v) => v),
      mode: props?.mode,
      reqParams
    }).then((state) => {
      // @ts-ignore
      setOptArr(state);
      if (props?.mode === 'multiple') {
        const items = (state as ClientOutput[]).map((item) => item.id);
        // setState(values)
        // @ts-ignore
        handleChange(items, state);
        handleSelect(items, state);
      } else {
        const item = (state as ClientOutput[])?.[0];
        handleChange(item?.id, item);
        handleSelect(item?.id, item);
      }
    });
  };

  const onPopupScroll = (event) => {
    event.persist();
    const data = mutation.data?.response;
    // @ts-ignore
    if (data?.page >= data?.pageCount) {
      return;
    }
    const { scrollTop, offsetHeight, scrollHeight } = event.target;
    if (scrollTop + offsetHeight === scrollHeight && !mutation.isPending) {
      setPagination((draft) => {
        draft.page = draft.page + 1;
      });
    }
  };

  const dropdownRender = useCallback(
    (originNode) => {
      return (
        <Spin size="small" spinning={mutation.isPending}>
          {originNode}
        </Spin>
      );
    },
    [mutation.isPending]
  );

  const Component = useMemo(() => (props?.fluent ? BitzQueryFilter2.Select : Select), [props]);

  const style = useMemo<React.CSSProperties | undefined>(() => {
    return props?.fluent ? undefined : { width: 'calc(100% - 31px)' };
  }, [props?.fluent]);

  // const labelRender = useCallback((labelItem) => {
  //   const item = optArr?.find(v => v.id === labelItem.value)
  //   if (item?.serialId) {
  //     return `${item?.serialId}:${labelItem?.label ?? labelItem.value}`
  //   }
  //   return `${labelItem?.label ?? labelItem.value}`
  // }, [optArr])

  const className = useMemo(() => {
    return props?.fluent
      ? css`
          .ant-select-selector {
            border-right-width: 0 !important;
          }
        `
      : undefined;
  }, [props?.fluent]);

  const options = optArr?.map((item) => {
    const serialId = item?.serialId ? `${item?.serialId}:` : '';
    return {
      ...item,
      nameold: item?.name,
      name: `${serialId}${item?.name}`
    };
  });

  const handleClear = () => {
    // setSearchValue(undefined)
    run(undefined);
  };

  const tagRender: TagRender = (props) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        // color={value}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginInlineEnd: 4 }}
      >
        <Text style={{ maxWidth: 100 }} ellipsis={{ tooltip: label }}>{label}</Text>
      </Tag>
    );
  };

  const maxTagPlaceholder = useCallback((omittedValues) => {
    const title = omittedValues.map(({ label }, index) => <p>{index + 1}. {label}</p>)
    return (
      <Tooltip
        overlayStyle={{ pointerEvents: 'none' }}
        title={title}
      >
        <span>More</span>
      </Tooltip>
    )
  }, [])

  const content = (
    <Component
      maxTagPlaceholder={maxTagPlaceholder}
      tagRender={tagRender}
      onClear={handleClear}
      // labelRender={labelRender}
      onSelect={handleSelect}
      loading={mutation.isPending}
      onPopupScroll={onPopupScroll}
      fieldNames={{
        label: 'name',
        value: 'id'
      }}
      className={searchIcon ? className : ''}
      style={searchIcon ? style : {}}
      showSearch
      onDropdownVisibleChange={dropdownVisibleChange}
      onSearch={run}
      // searchValue={searchValue}
      dropdownRender={dropdownRender}
      suffixIcon={null}
      filterOption={false}
      value={state}
      onChange={handleChange}
      options={options}
      {...rest}
    />
  );

  return searchIcon ? (
    <Space.Compact className={curStyle.wapper} block>
      {content}
      <Button disabled={props?.disabled ?? false} style={{ height: 'auto' }} onClick={openSearchModal} icon={<SearchOutlined />} />
    </Space.Compact>
  ) : (
    content
  );
};

export default BnClientSelect;

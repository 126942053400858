import { create } from 'zustand'
import { persist, createJSONStorage, devtools } from 'zustand/middleware'
/**
 * @description BranchState取名采用大驼峰,State前面的命名与文件名保持一致
 */
interface FormState {
  schemaDesign?: any
  setSchemaDesign?: (val: any) => void
}
/**
 * @description BranchStore取名采用大驼峰,Store前面的命名与文件名保持一致
 * @description 以下是有Storage的store写法
 */
const formStore = create<FormState>()(
  devtools(
    persist(
      (set) => ({
        schemaDesign: { type: 'object', properties: {} },
        setSchemaDesign: (schemaDesign: any) => set(() => ({ schemaDesign })),
      }),
      /**
       * @description languageStorage取名采用大驼峰,language与文件名保持一致，后面则取名为Storage
       */
      {
        name: 'formStorage',
        storage: createJSONStorage(() => localStorage),
      },
    ),
  ),
)

export default formStore

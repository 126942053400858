import { useLayoutEffect, useRef, useId, useState, useEffect, CSSProperties, FC } from 'react';
import { Button, Flex, GetRef, Input, GetProps, Dropdown, Space } from 'antd';
import { useReactive, useRequest } from 'ahooks';
import { css } from '@emotion/css';
import api from '@/services/GeneralPublicInterface';
import { isEmpty, map, replace } from 'lodash-es';
import IconFont from '@/components/iconfont';
import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import { message } from '@/components/BitzAntApp/Static';
import React from 'react';

const ChildComponent = React.forwardRef((props, ref) => {
  return (
    // @ts-ignore
    <div ref={ref} style={{ opacity: '0' }}></div>
  );
});

interface BnTextAreaProps extends GetProps<typeof Input.TextArea> {
  editable?: boolean;
  lang?: string;
  isSuggest?: boolean | null;
  showbut?: boolean;
  onSave?: (value?: any) => Promise<any>;
  rowId?: any;
  btnBottom?: boolean;
  rowNumber?: number;
}

const BnTextArea: FC<BnTextAreaProps> = (props) => {
  const { editable = true, lang, isSuggest, showbut = false, onSave, rowId, btnBottom = false, rowNumber = 1, ...rest } = props;
  const textareaId = useId();
  const wrapRef = useRef(null);
  const textareaRef = useRef<GetRef<typeof Input.TextArea>>(null);
  const rState = useReactive<{ [name: string]: any }>({
    css: '',
    value: '',
    editable: editable,
    result: null
  });
  const [focusId, setFocusId] = useState(null);
  const init = rest?.value ?? '';
  const [curValue, setCurValue] = useState(rest?.value ?? '');
  const [butLoading, setButLoading] = useState(false);
  const [redState, setRedState] = useState(false);

  const { loading, runAsync } = useRequest((opt = {}) => api.spllcheckChecksentenceCreate(opt), { manual: true });

  async function handleCheck() {
    try {
      const sentence = rState.value || rest.value;
      const res = await runAsync({
        sentence,
        lang: lang || 'en',
        isSuggest: isSuggest || null
      });
      const list = res?.response || [];

      if (list.length === 0) {
        message.success('拼写检查成功');
        return;
      }
      rState.editable = false;
      const result = map(list, ({ suggestions, word }) => {
        const suggestionsList = suggestions && suggestions.length ? suggestions : ['no suggestion'];
        const items = map(suggestionsList, (item, index) => ({
          label: item,
          key: index
        }));

        // console.log('word', word);

        const dropdown = (
          <Dropdown
            overlayClassName={css`
              ul {
                max-height: 300px;
                overflow: auto;
              }
            `}
            menu={{
              items: items,
              onClick({ key }) {
                const value = items[key].label;
                if (value !== 'no suggestion') {
                  rState.editable = true;
                  const newSentence = replace(sentence, word || '', value);
                  rState.value = newSentence;
                  // @ts-ignore
                  rest?.onChange?.(newSentence);
                  // @ts-ignore
                  document.getElementById(textareaId).value = newSentence;
                  //
                  setCurValue(newSentence);
                  setRedState(false);
                }
              }
            }}
            trigger={['click']}
          >
            <span
              className={css`
                text-decoration: underline;
                text-decoration-color: red;
                text-decoration-style: wavy;
                cursor: pointer;
              `}
            >
              {word}
            </span>
          </Dropdown>
        );
        return {
          word,
          dropdown
        };
      });
      const words = map(result, 'word');
      const regex = new RegExp(`(${words.join('|')})`, 'gi');
      const parts = sentence.split(regex);
      console.log(words);
      const highlighted = parts.map((part, index) => (words.includes(part) ? result[result?.findIndex((item) => item.word === part) || 0].dropdown : part));
      console.log(parts, highlighted);
      rState.result = highlighted;
    } catch (e: any) {
      console.error(e.message);
    }
  }

  const childRef = useRef(null);
  const handleClickOutside = (event) => {
    if (
      wrapRef.current &&
      // @ts-ignore
      !wrapRef.current?.contains(event.target) &&
      childRef.current &&
      // @ts-ignore
      !childRef.current.contains(event.target)
    ) {
      setFocusId(null);
      setRowCount(rowNumber);
      // @ts-ignore
      // props?.onBlur?.(curValue?.target?.value ?? '')
    } else {
      // console.log(22222);
    }
  };

  useLayoutEffect(() => {
    if (textareaRef.current) {
      const style: any = window.getComputedStyle((textareaRef.current as any)?.resizableTextArea?.textArea);
      rState.css = style;

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, []);

  useEffect(() => {
    // @ts-ignore
    setCurValue(rest?.value);
  }, [rest?.value]);

  const btnStyle: CSSProperties = {
    width: btnBottom ? '100px' : '70px',
    marginTop: btnBottom ? '5px' : '0px'
  };

  const [rowCount, setRowCount] = useState<number>(rowNumber || 2);
  const onFocusHandle = () => {
    setFocusId(rowId);
    setRowCount(2);
  };

  const onCloseHandle = () => {
    rState.editable = true;
    // @ts-ignore
    rest?.onChange?.(init);
    setCurValue(init);
    setFocusId(null);
    setRowCount(rowNumber);
  };

  const onSaveHandle = async () => {
    setButLoading(true);
    onSave?.(curValue)
      ?.then((res) => {
        if (res?.success) {
          // setButLoading(false)
          setRedState(false);
          setFocusId(null);
        }
      })
      ?.finally(() => {
        setButLoading(false);
        // setButLoading(false)
        // setRedState(false)
        // setFocusId(null)
      });
  };

  return (
    <div
      ref={wrapRef}
      // onBlur={(e) => {
      //   setFocusId(null)
      // }}
      className={css`
        position: relative;
        width: 100%;
      `}
    >
      <Flex gap={1} vertical={btnBottom}>
        <div style={{ width: '100%', marginRight: '5px', position: 'relative' }}>
          <div
            className={css`
              ${rState.css};
              margin-top: 1px;
              position: absolute;
              inset: 0;
              z-index: ${rState.editable ? -1 : 1};
              line-height: var(--ant-line-height);
              width: 100%;
              // padding: var(--ant-input-padding-block) var(--ant-input-padding-inline);
              font-family: var(--ant-font-family);
              min-height: var(--ant-control-height);
            `}
            onDoubleClick={() => {
              rState.editable = true;
            }}
          >
            {rState.result}
          </div>
          <Input.TextArea
            id={textareaId}
            ref={textareaRef}
            rows={rowCount}
            showCount
            maxLength={2000}
            {...rest}
            value={curValue}
            onChange={(e) => {
              rState.value = e.target.value;
              rest?.onChange?.(rState.value);
              //
              setCurValue(e.target.value);
            }}
            onFocus={onFocusHandle}
          />
        </div>
        <div style={btnStyle}>
          {showbut ? (
            <>
              {!isEmpty(focusId) && (
                <Flex wrap gap={3}>
                  <Button
                    loading={butLoading}
                    size="small"
                    onClick={(e) => {
                      handleCheck();
                    }}
                  >
                    <IconFont
                      className={css`
                        color: #fff;
                      `}
                      size={24}
                      name="pinxiejiancha"
                    />
                  </Button>
                  <Button loading={butLoading} size="small" type="primary" icon={<SaveOutlined />} onClick={onSaveHandle} />
                  <Button tabIndex={0} loading={butLoading} size="small" danger icon={<CloseOutlined />} onClick={onCloseHandle} />
                </Flex>
              )}
            </>
          ) : (
            <Space style={{ marginTop: '8px' }}>
              <Button loading={butLoading} size="small" onClick={handleCheck}>
                <IconFont
                  className={css`
                    color: #fff;
                  `}
                  size={24}
                  name="pinxiejiancha"
                />
              </Button>
            </Space>
          )}
        </div>

        <ChildComponent ref={childRef} />
      </Flex>
    </div>
  );
};

export default BnTextArea;

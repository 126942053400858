import { CSSProperties, FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { ModalHeader, ModalTitle, modalClose, modalWrap, modalWrapHide, modalWrapShow } from './style';
import { ClockCircleOutlined, CloseOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { loadFun, onChangeHourWork, onDeleteWork } from '@/pages/Works/Common';
import { App, Empty, Button, Space, Flex, theme, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import { css, cx } from '@emotion/css';
import api from '@/services/Web';
import TimeList from './TimeList';
import dayjs from 'dayjs';
import { message } from '@/components/BitzAntApp/Static';
import { Timer } from './Timer';
import { TimerContext } from '@/components/BitzTimerContext';
import BnTextArea from '@/pages/Works/WorkHourList/DailyView/BnTextArea';
import { useWorkRecordDesc } from '@/pages/Works/WorkHourList/hooks';
import { NonEditableStatus } from '@/pages/Works/WorkHourList/const';
import TimeItem from './TimeItem';
import { isEmpty } from 'lodash-es';

const { useToken } = theme;

interface TimerModalProps {
  show?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
}

export const TimerModal: FC<TimerModalProps> = (props) => {
  const { show = true, onOpen = () => {}, onClose = () => {} } = props;
  const { token } = useToken();
  const { modal } = App.useApp();
  const { confirm } = modal;
  const containerRef = useRef<null | HTMLDivElement>(null);
  // 检索
  const formatStr = 'YYYY-MM-DD';
  const startTime = '00:00:00';
  const endTime = '23:59:59';
  const [params, setParams] = useState<any>({
    workDate: {
      startDate: `${dayjs().format(formatStr)} ${startTime}`,
      endDate: `${dayjs().format(formatStr)} ${endTime}`
    }
  });
  const [pageInfo, setPageInfo] = useState<any>({ page: 1, size: 199 });

  /**
   * 我的工时列表
   */
  const dayData = useQuery({
    queryKey: ['Base', 'BnHeadTimer', 'TimerModal', { ...pageInfo, ...params }],
    queryFn: ({ signal }) => {
      // debugger
      return api.webTimesheetsTimesheetTimesheetGetmytimerpageCreate(
        {
          ...pageInfo,
          ...params
        },
        { signal }
      );
    },
    select: (data) => {
      return data.response;
    },
    placeholderData: keepPreviousData,
    enabled: show
  });

  // console.log(dayData.data);

  useEffect(() => {
    show ? onShow() : onHide();
  }, [show]);

  const onShow = () => {
    onOpen?.();
  };

  const onHide = () => {
    onClose?.();
  };

  const navigate = useNavigate();
  const onEdit = (row) => {
    // timerList.forEach((i, index) => {
    //   if (id === i!.id) {
    //     setTimerList((prev) => {
    //       prev[index]!.edit = true
    //     })
    //   } else {
    //     setTimerList((prev) => {
    //       prev[index]!.edit = false
    //     })
    //   }
    // })
    navigate(`/Works/WorkHourList?editId=${row?.id}`);
    onHide();
  };

  const listStyle: CSSProperties = {
    // border: '1px solid',
    height: 'calc(100% - 200px)',
    overflowY: 'auto'
  };
  const butDivStyle: CSSProperties = {
    margin: '20px'
  };

  const divStyle: CSSProperties = {
    display: 'flex',
    // alignItems: 'center',
    marginTop: '16px',
    paddingBottom: '10px',
    borderBottom: '1px solid #f3f4f4'
  };

  const queryClient = useQueryClient();
  // // 创建新计时器
  // const openNewWork = async () => {
  //   const res: any = await createNewWork()
  //   if (res.success) {
  //     createNewTimer(res?.response)
  //     message.success('计时器创建成功')
  //     loadFun(queryClient)
  //   } else {
  //     message.error('计时器创建失败')
  //   }
  // }
  // 开始计时
  const { createNewTimer, runningId, deleteCurTimer } = useContext(TimerContext);

  const addTimerMutation = useMutation({
    mutationFn: () => {
      const params = { workDate: dayjs().format('YYYY-MM-DD') };
      // @ts-ignore
      return api.webTimesheetsTimesheetTimesheetCreatetimerCreate(params);
    },
    onSuccess: async (r: any) => {
      if (r?.success) {
        createNewTimer(r?.response);
        message.success('计时器创建成功');
        loadFun(queryClient);
      } else {
        message.error(r.msg);
      }
    }
  });

  const [weekText, setWeekText] = useState('');
  const onTimeListChange = (val: any) => {
    const { weekText, workDate } = val;
    setWeekText(weekText);
    setParams({ workDate });
  };

  const [isSeeAll, setIsSeeAll] = useState<boolean>(false);
  const onSeeAllHandle = () => {
    setIsSeeAll(true);
  };
  const onRestSeeAll = () => {
    setIsSeeAll(false);
  };

  const workRecordDesc = useWorkRecordDesc();

  const handleSave = (id: string, narrative: string) => {
    return workRecordDesc.mutateAsync({
      id,
      narrative
    });
  };

  const handleDelete = (id) => {
    onDeleteWork([id], () => {
      loadFun(queryClient);
      if (runningId === id) {
        deleteCurTimer();
      }
    });
  };

  const TimerType = useCallback((item) => {
    if (isEmpty(item?.workDate) || !dayjs(item.workDate).isToday() || NonEditableStatus.includes(item.status)) {
      return 'default';
    }
    // (isEmpty(item?.workDate) || dayjs(item.workDate).format('YYYY-MM-DD') !== dayjs().format('YYYY-MM-DD') || item.status === 'Approved') ? 'default' : 'primary'
    return 'primary';
  }, []);

  return (
    <Flex vertical className={cx(modalWrap, show ? modalWrapShow : modalWrapHide)} ref={containerRef}>
      <ModalHeader bgc={token.colorPrimary}>
        <ModalTitle as="h3">
          <ClockCircleOutlined
            className={css`
              margin-right: 10px;
            `}
          />
          {weekText || ''}
        </ModalTitle>
        <Button className={modalClose} type="text" icon={<CloseOutlined onClick={onHide} />} />
      </ModalHeader>
      <TimeList onChange={onTimeListChange} show={show} isSeeAll={isSeeAll} onRestSeeAll={onRestSeeAll} />
      <div style={listStyle}>
        <Spin spinning={dayData.isLoading} size="small">
          {(dayData.data || []).length > 0 ? (
            <>
              {(dayData.data || []).map((item) => (
                <div style={divStyle} key={item.id}>
                  <div style={{ margin: '0px 10px', paddingTop: '15px' }}>
                    <Button type="link" icon={<EditOutlined />} onClick={() => onEdit(item)} />
                  </div>
                  <div style={{ marginRight: '10px', width: '120px', paddingTop: '15px' }}>
                    <Timer
                      key={item.id}
                      style={{ width: '100%' }}
                      record={item}
                      // @ts-ignore
                      type={TimerType(item)}
                    />
                  </div>
                  <div style={{ width: '65%', marginRight: '10px' }}>
                    <BnTextArea
                      style={{ width: '100%' }}
                      value={item?.narrative ?? ''}
                      placeholder="请输入"
                      lang={item?.writtenLanguage ?? ''}
                      showbut={true}
                      disabled={NonEditableStatus.includes(item?.status ?? '')}
                      // onSave={(v) => onChangeHourWork({ ...m, narrative: v }, () => { loadFun(queryClient) })}
                      onSave={(value) => handleSave(item?.id ?? '', value)}
                      rowId={item?.id}
                      btnBottom={true}
                      rowNumber={2}
                    />
                  </div>
                  <div style={{ marginRight: '10px', paddingTop: '15px' }}>
                    <Button type="link" danger icon={<DeleteOutlined />} onClick={() => handleDelete(item.id)} />
                  </div>
                </div>
              ))}
            </>
          ) : (
            <Empty style={{ marginTop: '30%' }} />
          )}
        </Spin>
      </div>
      <div style={butDivStyle}>
        <Space>
          <Button type="primary" onClick={onSeeAllHandle}>
            查看全部
          </Button>
          <Button loading={addTimerMutation.isPending} type="primary" onClick={() => addTimerMutation.mutate()}>
            启动新的计时器
          </Button>
        </Space>
      </div>
    </Flex>
  );
};

import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { TimesheetPreferencesSettingDto } from '@/services/WebApi'
import ApiWeb from "@/services/Web";
import {getMinTimeFun} from "@/pages/Works/Common";

type weekDataProps = {
  openEditIds: any[]
}
interface workDateStoreState {
  preferenceSet: TimesheetPreferencesSettingDto
  setPreferenceSetFun: (val: TimesheetPreferencesSettingDto) => void
  daySelectionRows: any[],
  setDaySelectionRowsFun: (val: any[]) => void
  weekData: weekDataProps
  setWeekDataFun: (val: weekDataProps) => void
  resetWeekDataFun: () => void
  workSelects: any[],
  setWorkSelectFun: (rows: any[]) => void
  minTime: any,
  setMinTime: (val: any) => void
  getWorkHourSettings: () => void
}
/**
 * 编码规则批量设置参数
 */
const workDateStore = create<workDateStoreState>()(
  persist((set) => ({
    getWorkHourSettings: () => {
      ApiWeb.webTimesheetsTimesheetpreferencesTimesheetpreferencesGetpreferencesCreate().then((res) => {
          if (res.success) {
            const minTime = getMinTimeFun(res?.response)
            res?.response && set({
              preferenceSet: res.response,
              minTime
            })
          }
      })
    },
    preferenceSet: {},
    setPreferenceSetFun: (preferenceSet: TimesheetPreferencesSettingDto) => set(
      () => ({ preferenceSet })
    ),
    daySelectionRows: [],
    setDaySelectionRowsFun: (daySelectionRows: any[]) => set(() => ({ daySelectionRows })),
    weekData: { openEditIds: [] },
    setWeekDataFun: (weekData: weekDataProps) => set(() => ({ weekData })),
    resetWeekDataFun: () => set(() => ({ weekData: { openEditIds: [] } })),
    workSelects: [],
    setWorkSelectFun: (workSelects: any[]) => set(() => ({ workSelects })),
    minTime: 0.02,
    setMinTime: (minTime: any) => set(() => ({ minTime })),

  }), {
    // 本地储存名称
    name: 'WorkHourSettings',
  }),
)

export default workDateStore

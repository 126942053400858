import {Modal, Typography} from "antd";
import React, { useEffect } from "react";
import {ExclamationCircleFilled} from "@ant-design/icons";
import {useQuery} from "@tanstack/react-query";
import apiWeb from "@/services/Web";
import {useNavigate} from "react-router-dom";


const useApplicationRulesHooks = (isInit = false, modalProps = {}): [typeof applicationRules, () => void] => {
  const navigate = useNavigate()

  const applicationRules = useQuery({
    queryKey: [],
    queryFn: ({ signal }) => {
      return apiWeb.webBusinessCsrCsrrequestGetmyinprocesscsrrequestcheckcountList({ signal })
    },
    select: data => (data?.response ?? 0) > 0
  })

  const applicationRulesModal = () => {
    Modal.confirm({
      title: <Typography.Title level={5} type="danger">合伙人有确认的利冲，且24小时未确认的，需要确认完才能发起利冲。</Typography.Title>,
      icon: <ExclamationCircleFilled />,
      centered: true,
      okCancel: false,
      keyboard: false,
      okText: '去处理',
      onOk() {
        navigate('/Business/Conflict/ConflictList?tab=unconfirmedconflicttable')
      },
      ...modalProps
    })
  }

  useEffect(() => {
    if (applicationRules.data && isInit) {
      applicationRulesModal()
    }
  }, [applicationRules.data, isInit])

  return [applicationRules, applicationRulesModal]
}

export default useApplicationRulesHooks;

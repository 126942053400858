/*
 * # Title: LayoutHorizontalMenu Component
 * - Description: 横向导航菜单
 * - Date: 2024-04-11
 * - LastEditors: liujin liujin@elinklaw.com
 * - LastEditTime: 2024-05-10 13:15:34
 */

import { useReactive } from 'ahooks';
import { Divider, Dropdown, Menu, Typography } from 'antd';
import useStyle from './useStyle';
import Iconfont, { IconNames } from '@/components/iconfont';
import { DownOutlined } from '@ant-design/icons';
import { useEffect } from 'react';
import { flattenPaths } from './flattenRoutes';
import { adminRoutes } from '@/router/config';
import { useLocation } from 'react-router-dom';
import { usePageContext } from '@/providers/PageManageProvider';
import { useCanPermsision } from '@/v2/components';

function filterMenuItems(canPermisision, data) {
  // Helper function to perform deep cloning of the object
  function deepClone(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  // Recursive function to filter out items with 'notMenu' equal to true
  function filter(items, canPermisision) {
    return items.reduce((acc, item) => {
      // if (item.notMenu === true) {
      //   return acc;
      // }

      if (!canPermisision(item.name)) {
        return acc;
      }

      // Clone the current item to prevent modifications to the original data
      const clonedItem = deepClone(item);

      if (clonedItem.children) {
        // Recursively filter children
        clonedItem.children = filter(clonedItem.children, canPermisision);
      }

      // Only add the item to the result if it passes the condition
      acc.push(clonedItem);
      return acc;
    }, []);
  }

  // Start the filtering process
  return filter(data, canPermisision);
}

const LayoutHorizontalMenu = () => {
  const style = useStyle();
  // const navigate = useNavigate()
  const { open } = usePageContext();
  const navigate = (key) => open({ key, label: key });
  const location = useLocation();
  const pathname = location.pathname;
  const canPermisision = useCanPermsision();
  const router = filterMenuItems(canPermisision, adminRoutes || []);
  const menuData = flattenPaths(router);
  const currentKey = menuData.find((item) => pathname.includes(item.path))?.key;
  const rState = useReactive({
    currentKey: currentKey ? currentKey : menuData?.[0]?.key,
    selectedKeys: ['']
  });
  const menu2 = menuData.find((item) => item.key === rState.currentKey)?.children || [];

  useEffect(() => {
    const currentMenu = menu2?.find((item) => pathname.includes(item.path));
    const currentSubMenu = currentMenu?.children?.find((item) => pathname.includes(item.path));
    rState.selectedKeys = [currentMenu?.key, currentSubMenu?.key];
  }, [pathname, rState.currentKey]);

  return (
    <div className={style.wrapper} id="layoutHeaderMenu">
      <div className={style.title}>
        <Dropdown
          placement="bottomLeft"
          menu={{
            items: menuData.map(({ children, ...item }) => ({
              ...item,
              icon: <Iconfont name={item.iconName as IconNames} size={20} color="inherit" fill="currentColor" />
            })),
            onClick({ key }) {
              rState.currentKey = key;
              const menu2 = menuData.find((item) => item.key === key)?.children || [];
              let pathname = menu2[0]?.path;
              if (menu2[0]?.children) {
                pathname = menu2[0].children[0]?.path;
              }
              if (pathname !== location.pathname.slice(1)) {
                navigate(pathname);
              }
            }
          }}
        >
          <Typography.Title level={2}>
            {menuData.find((item) => item.key === rState.currentKey)?.label}
            <DownOutlined />
          </Typography.Title>
        </Dropdown>
        <Divider type="vertical" />
      </div>
      {menu2.length && (
        <div className={style.menu}>
          <Menu
            mode="horizontal"
            selectedKeys={rState.selectedKeys}
            onClick={({ key }) => {
              navigate(key);
            }}
            items={menu2.map((item) => {
              const subItem = {
                ...item,
                label: (
                  <>
                    {item.label}
                    {/*{rState.selectedKeys.includes(item.key) ? (*/}
                    {/*  <Dropdown*/}
                    {/*    trigger={['click']}*/}
                    {/*    menu={{*/}
                    {/*      items: [*/}
                    {/*        {*/}
                    {/*          key: '1',*/}
                    {/*          label: <a>新建1</a>,*/}
                    {/*        },*/}
                    {/*        {*/}
                    {/*          key: '2',*/}
                    {/*          label: <a>新建2</a>,*/}
                    {/*        },*/}
                    {/*        {*/}
                    {/*          key: '3',*/}
                    {/*          label: <a>新建3</a>,*/}
                    {/*        },*/}
                    {/*      ],*/}
                    {/*    }}*/}
                    {/*  >*/}
                    {/*    <Button type="text" size="small" icon={<DownOutlined />} />*/}
                    {/*  </Dropdown>*/}
                    {/*) : null}*/}
                  </>
                ),
                popupClassName: style.popupMenu
              };
              if (item.iconName) {
                subItem.icon = (
                  <div className={style.icon}>
                    <Iconfont name={item.iconName as IconNames} size={20} color="inherit" fill="currentColor" />
                  </div>
                );
              }
              return subItem;
            })}
          />
        </div>
      )}
    </div>
  );
};

export default LayoutHorizontalMenu;

import { refetch } from '@/utils/refetch';
import {
  cacheDictAll,
  canCacheDict,
  cacheUserInfo,
  cacheOffice,
  cacheOfficeTaxRate,
  workerApi,
  cacheChunksFetch,
  incrementalUpdate,
  getLastUpdateRange,
  setDictInfo
} from './utils';

const DictWorker: Worker = self as any;

DictWorker.onmessage = async (res) => {
  const { type, headers } = res.data;

  // DictWorker.postMessage(`子线程准备完毕 ${res.data.type}`);

  if (type === 'cacheDict') {
    if (await canCacheDict()) {
      await cacheDictAll({ headers });
    }
  }

  // if (type === 'cacheUserInfo') {
  //   // 用户信息
  //   if (await canCacheDict('userInfo')) {
  //     await cacheUserInfo({ headers });
  //   }
  // }

  if (type === 'cacheUserInfo') {
    const key = 'userInfo';
    const can = await canCacheDict(key);
    const api = workerApi('/api/web/sys/sysdataselect/getuserinfo');
    const mapper = {};
    const body = {};
    const method = 'post';

    if (can) {
      await incrementalUpdate(key, {
        init: async () => {
          return await cacheChunksFetch(key, api, { headers });
        },
        inc: async () => {
          const lastUpdate = await getLastUpdateRange(key);
          const { data } = await refetch(api, {
            method: 'post',
            body: { lastUpdate, page: 1, size: 10000 },
            headers
          });
          return data;
        },
        mapper
      });
      // todo
      // mode: chunks, inc, normal, stack
      await setDictInfo(key, { api, mode: 'chunks', mapper, method, body, inc: { lastUpdate: { startDate: Date.now() }, page: 1, size: 10000 } });
    }
  }

  // 客户信息
  if (type === 'cacheClient') {
    const key = 'SysClient';
    const can = await canCacheDict(key);
    const api = workerApi('/api/web/sys/sysdataselect/getclient');
    const mapper = {};
    const method = 'post';
    const payload = {};

    if (can) {
      await incrementalUpdate(key, {
        init: async () => {
          return await cacheChunksFetch(key, api, { headers });
        },
        inc: async () => {
          const lastUpdate = await getLastUpdateRange(key);
          const { data } = await refetch(api, {
            method: 'post',
            body: { lastUpdate, page: 1, size: 10000 },
            headers
          });
          return data;
        },
        mapper: {}
      });
      await setDictInfo(key, { api, mapper, method, payload });
    }
  }

  // 客户信息
  if (type === 'cacheCase') {
    const key = 'SysCase';
    const api = workerApi('/api/web/sys/sysdataselect/getcase');
    if (await canCacheDict(key)) {
      await incrementalUpdate(key, {
        init: async () => {
          return await cacheChunksFetch(key, api, { headers });
        },
        inc: async () => {
          const lastUpdate = await getLastUpdateRange(key);
          const { data } = await refetch(api, {
            method: 'post',
            body: { lastUpdate, page: 1, size: 10000 },
            headers
          });
          return data;
        },
        mapper: {}
      });

      // @todo
      await setDictInfo(key, { api });
    }
  }

  if (type === 'cacheOffice') {
    if (await canCacheDict('SysOffice')) {
      await cacheOffice({ headers });
    }
  }

  if (type === 'cacheOfficeTaxRate') {
    if (await canCacheDict('ExtraOfficeTaxRates')) {
      await cacheOfficeTaxRate({ headers });
    }
  }
};

DictWorker.onerror = (err) => {
  console.log('收集错误', err);
};

export default DictWorker;
